import { PropsWithChildren } from "react";
import classNames from "classnames";
import { TabButton } from "../../components/basics/button";
import { matchPath, useLocation } from "react-router-dom";

export function SimpleHeaderWithTitle({ title }: { title: string }) {
  return (
    <SimpleHeader>
      <PageHeaderTitle>{title}</PageHeaderTitle>
    </SimpleHeader>
  );
}

export function SimpleHeader({
  children,
  height,
}: PropsWithChildren<{ height?: string }>) {
  return (
    <div
      className={"py-8 border-b border-neutral-200 shrink-0"}
      style={{ height }}
    >
      <div className={"grid grid-cols-12"}>
        <div className={"col-span-1"} />
        <div className={"p-2 flex items-center col-span-10"}>{children}</div>
        <div className={"col-span-1"} />
      </div>
    </div>
  );
}

export function PageHeaderTitle({
  children,
  isLoading,
}: PropsWithChildren<{ isLoading?: boolean }>) {
  return (
    <h2
      className={classNames(
        "text-3xl font-medium tracking-tight select-none min-w-0 truncate",
        {
          "animate-pulse": isLoading,
        }
      )}
    >
      {children}
    </h2>
  );
}

export function TabbedHeader(
  props: PropsWithChildren<{
    tabs: Array<{ loading?: boolean; path: string; label: string }>;
  }>
) {
  const { pathname, search, hash } = useLocation();

  const currentPath = pathname + hash + search;

  return (
    <div className={"pt-8 shrink-0"}>
      <div className={"grid grid-cols-12 border-b border-neutral-200"}>
        <div className={"col-span-1"} />
        <div className={"flex flex-col col-span-10"}>
          <div className={"p-2 flex items-center"}>{props.children}</div>
          <div className={"flex items-center"}>
            <div
              className={classNames(
                "col-span-12 flex items-end space-x-2 overflow-y-hidden overflow-x-auto"
              )}
            >
              {props.tabs.map((t) => (
                <TabButton
                  key={t.path}
                  loading={t.loading}
                  active={matchPath(t.path, currentPath) !== null}
                  to={t.path}
                >
                  {t.label}
                </TabButton>
              ))}
            </div>
          </div>
        </div>
        <div className={"col-span-1"} />
      </div>
    </div>
  );
}

export function TabbedHeaderWithTitle(
  props: PropsWithChildren<{
    title: string | JSX.Element;
    tabs: Array<{ loading?: boolean; path: string; label: string }>;
  }>
) {
  if (typeof props.title === "string") {
    return (
      <TabbedHeader tabs={props.tabs}>
        <PageHeaderTitle>{props.title}</PageHeaderTitle>
      </TabbedHeader>
    );
  }

  return (
    <TabbedHeader tabs={props.tabs}>
      <>{props.title}</>
    </TabbedHeader>
  );
}
