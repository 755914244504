import { PropsWithChildren, useMemo } from "react";
import classNames from "classnames";
import {
  AvatarURLRenderer,
  EmptyCell,
  EnumRenderer,
  formatDateTime,
  UserIdentityLink,
} from "../../../../../../../database/renderer";
import { CopyButton } from "../../../../../../../../components/basics/button";
import {
  useEnvironmentId,
  useUserIdentityId,
  useWorkspaceId,
} from "../../../../../../../routes";
import {
  useUserManagementUserIdentity,
  useUserManagementUserIdentitySchema,
} from "../../../../../../../../data";
import { DateTimeRendererWithFormats } from "../../../../../../../database/datetime";
import { DatabaseSchema, DatabaseEnumField } from "@anzuhq/backend";

function IdentityDetailBase({
  label,
  children,
}: PropsWithChildren<{
  label: string;
}>) {
  return (
    <div
      className={
        "col-span-6 md:col-span-4 lg:col-span-3 flex flex-col space-y-1"
      }
    >
      <span className={"text-xs font-medium text-neutral-600"}>{label}</span>
      <div className={"flex items-center space-x-1"}>{children}</div>
    </div>
  );
}

function ValueOrLoading({
  value,
}: {
  value: React.ReactNode | null | undefined;
}) {
  const classes = "text-sm text-black truncate";

  if (value === undefined) {
    return (
      <span
        className={classNames(
          classes,
          "animate-pulse w-24 h-8 rounded-md bg-neutral-100 block"
        )}
      />
    );
  }
  if (value === null) {
    return <EmptyCell />;
  }
  return <span className={classes}>{value}</span>;
}

function IdentityDetail({
  label,
  value,
}: {
  label: string;
  value: string | undefined | null;
}) {
  return (
    <IdentityDetailBase label={label}>
      <ValueOrLoading value={value} />

      {value ? (
        <CopyButton role={"ghost"} size={"xs"} text={value}>
          {""}
        </CopyButton>
      ) : null}
    </IdentityDetailBase>
  );
}

function IdentityDetailDate({
  label,
  value,
}: {
  label: string;
  value: string | undefined | null;
}) {
  const parsedValue = useMemo(
    () => (value ? formatDateTime(value) : value),
    [value]
  );
  return (
    <IdentityDetailBase label={label}>
      <ValueOrLoading
        value={!value ? value : <DateTimeRendererWithFormats value={value} />}
      />

      {value ? (
        <CopyButton role={"ghost"} size={"xs"} text={value}>
          {""}
        </CopyButton>
      ) : null}
    </IdentityDetailBase>
  );
}

function IdentityDetailPrimaryIdentity({
  value,
}: {
  value: string | null | undefined;
}) {
  return (
    <IdentityDetailBase label={"Primary Identity"}>
      <ValueOrLoading
        value={value ? <UserIdentityLink value={value} /> : value}
      />
    </IdentityDetailBase>
  );
}

function IdentityDetailAvatarUrl({
  value,
}: {
  value: string | null | undefined;
}) {
  return (
    <IdentityDetailBase label={"Avatar URL"}>
      <ValueOrLoading
        value={value ? <AvatarURLRenderer value={value} /> : value}
      />
    </IdentityDetailBase>
  );
}

function IdentityDetailUserProvisioningStatus({
  value,
  schema,
}: {
  value: string | null | undefined;
  schema: DatabaseSchema;
}) {
  return (
    <IdentityDetailBase label={"Provisioning Status"}>
      <ValueOrLoading
        value={
          value ? (
            <EnumRenderer
              field={
                schema.fields.find(
                  (f) => f.name === "provisioning_status"
                ) as DatabaseEnumField
              }
              value={value}
            />
          ) : (
            value
          )
        }
      />
    </IdentityDetailBase>
  );
}

function IdentityDetailUserAuthProvider({
  value,
  schema,
}: {
  value: string | null | undefined;
  schema: DatabaseSchema;
}) {
  return (
    <IdentityDetailBase label={"Auth Provider"}>
      <ValueOrLoading
        value={
          value ? (
            <EnumRenderer
              field={
                schema.fields.find(
                  (f) => f.name === "provider"
                ) as DatabaseEnumField
              }
              value={value}
            />
          ) : (
            value
          )
        }
      />
    </IdentityDetailBase>
  );
}

export function UserIdentityOverviewView() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const userIdentityId = useUserIdentityId();

  const { identity } = useUserManagementUserIdentity(
    workspaceId,
    environmentId,
    userIdentityId
  );

  const { schema } = useUserManagementUserIdentitySchema(
    workspaceId,
    environmentId
  );
  const schemaOrFallback = schema || { fields: [] };

  return (
    <div className={"grid grid-cols-12"}>
      <div className={"col-span-1"} />

      <div className={"col-span-10 grid grid-cols-12 px-2 py-8 gap-8"}>
        <IdentityDetailUserAuthProvider
          schema={schemaOrFallback}
          value={identity?.provider}
        />
        <IdentityDetailUserProvisioningStatus
          schema={schemaOrFallback}
          value={identity?.provisioning_status}
        />
        <IdentityDetail label={"Email"} value={identity?.email} />
        <IdentityDetail label={"Full Name"} value={identity?.full_name} />
        <IdentityDetail label={"Given Name"} value={identity?.given_name} />
        <IdentityDetail label={"Family Name"} value={identity?.family_name} />
        <IdentityDetailDate label={"Created At"} value={identity?.created_at} />
        <IdentityDetailDate label={"Updated At"} value={identity?.updated_at} />
        <IdentityDetailDate
          label={"Last Login At"}
          value={identity?.last_login_at}
        />
        <IdentityDetailAvatarUrl value={identity?.avatar_url} />

        <IdentityDetailPrimaryIdentity value={identity?.primary_identity} />
        <IdentityDetail label={"Phone Number"} value={identity?.phone_number} />
        <IdentityDetail
          label={"Login Count"}
          value={identity?.login_count.toString()}
        />
      </div>

      <div className={"col-span-1"} />
    </div>
  );
}
