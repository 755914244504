import { useBreadcrumbItems, useContextualNavigation } from "./nav";
import { Page } from "../../../../components/page/page";
import {
  useCountUserManagementAuthAttempts,
  useCountUserManagementUserIdentities,
} from "../../../../../data";
import {
  toEnvironmentUserManagementUserIdentities,
  toEnvironmentUserManagementUserIdentitiesView,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../routes";
import {
  InsightsRelativeTimeRange,
  DatabaseFilterOperator,
  UserProvisioningStatus,
} from "@anzuhq/backend";
import { Link } from "react-router-dom";
import { BookOpen } from "../../../../../icons";
import { pendingProvisioningViewId } from "./user-identities/page";
import { PageHeaderTitle } from "../../../../components/header";

const relatedLinks = [
  {
    title: "User Management Overview",
    description: "Understanding User Management managed on Anzu",
    url: "https://anzuhq.com/docs/blocks/user-management",
  },
  {
    title: "Quickstart with Next.js",
    description: "Setting up User Management with Next.js",

    url: "https://anzuhq.com/docs/blocks/user-management/quickstart/next.js",
  },
  {
    title: "Quickstart with React",
    description: "Setting up User Management with React and Node.js",
    url: "https://anzuhq.com/docs/blocks/user-management/quickstart/react-node",
  },
  {
    title: "User Identities",
    description: "Understanding User Identities",
    url: "https://anzuhq.com/docs/blocks/user-management/user-identities",
  },
  {
    title: "Provisioning Users",
    description: "Making sure your application stays in sync",
    url: "https://anzuhq.com/docs/blocks/user-management/user-provisioning",
  },
  {
    title: "Access Tokens",
    description:
      "Using access tokens to authenticate users in your application",
    url: "https://anzuhq.com/docs/blocks/user-management/access-tokens",
  },
];

export function UserManagementOverview() {
  const navItems = useContextualNavigation();
  const breadcrumbItems = useBreadcrumbItems();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { data: identityCountData } = useCountUserManagementUserIdentities(
    workspaceId,
    environmentId
  );

  const { data: pendingIdentityCountData } =
    useCountUserManagementUserIdentities(workspaceId, environmentId, [
      {
        value: UserProvisioningStatus.Pending,
        operator: DatabaseFilterOperator.Equals,
        field: "provisioning_status",
      },
    ]);

  const { data: attemptCountData24 } = useCountUserManagementAuthAttempts(
    workspaceId,
    environmentId,
    { relative: InsightsRelativeTimeRange.Last24Hours }
  );

  const { data: attemptCountData7Days } = useCountUserManagementAuthAttempts(
    workspaceId,
    environmentId,
    { relative: InsightsRelativeTimeRange.Last7Days }
  );

  return (
    <Page
      title={"Overview - User Management"}
      contextualNavigationItems={navItems}
      breadcrumbItems={breadcrumbItems}
    >
      <div className={"grid place-items-center"}>
        <div className={"p-2 md:p-16"}>
          <div className={"py-8"}>
            <PageHeaderTitle>User Management</PageHeaderTitle>
            <span className={"text-lg text-neutral-500"}>
              Allow users to sign in to your application.
            </span>
          </div>

          <div className={"grid grid-cols-12 gap-4"}>
            <div
              className={
                "col-span-12 md:col-span-3 lg:col-span-2 flex flex-col space-y-4"
              }
            >
              {identityCountData ? (
                <Link
                  to={toEnvironmentUserManagementUserIdentities(
                    workspaceId,
                    environmentId
                  )}
                  className={
                    "flex flex-col space-y-1 p-2 items-center bg-neutral-50 rounded border border-neutral-200"
                  }
                >
                  <span className={"text-lg font-semibold"}>
                    {identityCountData.count}
                  </span>
                  <span
                    className={
                      "text-sm font-medium text-neutral-800 whitespace-nowrap"
                    }
                  >
                    Total User Identities
                  </span>
                </Link>
              ) : (
                <LoadingCard />
              )}

              {pendingIdentityCountData ? (
                <Link
                  to={toEnvironmentUserManagementUserIdentitiesView(
                    workspaceId,
                    environmentId,
                    pendingProvisioningViewId
                  )}
                  className={
                    "flex flex-col space-y-1 p-2 items-center bg-neutral-50 rounded border border-neutral-200 whitespace-nowrap"
                  }
                >
                  <span className={"text-lg font-semibold"}>
                    {pendingIdentityCountData.count}
                  </span>
                  <span className={"text-sm font-medium text-neutral-800"}>
                    Pending User Identities
                  </span>
                </Link>
              ) : (
                <LoadingCard />
              )}

              {attemptCountData24 ? (
                <div
                  className={
                    "flex flex-col space-y-1 p-2 items-center bg-neutral-50 rounded border border-neutral-200 whitespace-nowrap"
                  }
                >
                  <span className={"text-lg font-semibold"}>
                    {attemptCountData24.reduce((prev, curr) => {
                      return prev + parseInt(curr.count, 10);
                    }, 0)}
                  </span>
                  <span className={"text-sm font-medium text-neutral-800"}>
                    Login Attempts (24h)
                  </span>
                </div>
              ) : (
                <LoadingCard />
              )}

              {attemptCountData7Days ? (
                <div
                  className={
                    "flex flex-col space-y-1 p-2 items-center bg-neutral-50 rounded border border-neutral-200"
                  }
                >
                  <span className={"text-lg font-semibold"}>
                    {attemptCountData7Days.reduce((prev, curr) => {
                      return prev + parseInt(curr.count, 10);
                    }, 0)}
                  </span>
                  <span
                    className={
                      "text-sm font-medium text-neutral-800 whitespace-nowrap"
                    }
                  >
                    Login Attempts (7d)
                  </span>
                </div>
              ) : (
                <LoadingCard />
              )}
            </div>

            <div
              className={
                "col-span-12 md:col-span-9 lg:col-span-10 h-full w-full flex flex-col space-y-2"
              }
            >
              <div
                className={
                  "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
                }
              >
                {relatedLinks.map((l) => (
                  <a
                    href={l.url}
                    target={"_blank"}
                    key={l.url}
                    className={
                      "col-span-1 bg-white rounded-md flex flex-col space-y-1 transition group hover:-translate-y-1 border border-neutral-300 hover:border-black"
                    }
                  >
                    <div
                      className={
                        "h-16 bg-gradient-to-tr from-white to-neutral-200 transition group-hover:to-black rounded-t-md"
                      }
                    />
                    <div className={"px-4 pt-4 flex items-center space-x-2"}>
                      <BookOpen size={16} />
                      <span className={"text-sm font-medium whitespace-nowrap"}>
                        {l.title}
                      </span>
                    </div>
                    <span
                      className={
                        "px-4 pb-4 md:w-48 lg:w-64 xl:w-72 text-xs text-neutral-500"
                      }
                    >
                      {l.description}
                    </span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

function LoadingCard() {
  return <div className={"animate-pulse h-16 bg-neutral-50 w-full block"} />;
}
