import classNames from "classnames";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { Checkbox } from "../../../components/basics/checkbox";

export function LightTable<T>({
  data,
  isLoading,
  columns,
  emptyMessage,
}: {
  data: T[] | null;
  isLoading: boolean;
  columns: ColumnDef<T, any>[];
  emptyMessage?: string;
}) {
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    enableColumnResizing: false,
    columnResizeMode: "onChange",

    state: {
      rowSelection,
    },
  });

  return (
    <div className={"grid grid-cols-12 grow"}>
      <div className={"col-span-1"} />

      <div
        // Grow table to fit full page
        className={classNames("col-span-10 flex flex-col py-4 px-2", {
          "animate-pulse": isLoading,
        })}
      >
        <div
          // Prevent overflow of table to enable inline scrolling, set arbitrary base height to prevent table from growing hiding footer
          // Use grow to fill remaining space
          className={"grow overflow-hidden h-2"}
        >
          <div
            // Allow scrolling within container
            className={"overflow-auto h-full rounded"}
          >
            <table
              className={classNames(
                "border-collapse table-auto",
                // Enforce full width size
                "min-w-full w-full"
              )}
            >
              <thead className={"sticky top-0 w-full"}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className={
                      "bg-neutral-50 z-10 rounded-md border border-neutral-200"
                    }
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          className={classNames("relative h-full", {
                            "bg-neutral-100": header.column.getIsResizing(),
                          })}
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{
                            width: header.getSize(),
                          }}
                        >
                          <div
                            className={
                              "flex flex-row items-center h-full w-full p-2"
                            }
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                className={
                                  "font-normal whitespace-nowrap text-sm select-none text-neutral-800"
                                }
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </div>
                            )}
                            {header.column.getCanResize() && (
                              <div
                                onMouseDown={header.getResizeHandler()}
                                onTouchStart={header.getResizeHandler()}
                                className={`resizer ${
                                  header.column.getIsResizing()
                                    ? "isResizing"
                                    : ""
                                }`}
                              >
                                resize
                              </div>
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>

              <tbody className={"z-0"}>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr
                      key={row.id}
                      className={classNames(
                        "group border border-neutral-100 h-12",
                        {
                          "bg-neutral-50": row.getIsSelected(),
                        }
                      )}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            style={{
                              width: cell.column.getSize(),
                            }}
                            className={"p-2 whitespace-nowrap text-sm"}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {table.getRowModel().rows.length === 0 ? (
              <div
                className={
                  "px-4 py-12 flex justify-center w-full rounded-b-md bg-neutral-50 text-neutral-800 whitespace-nowrap text-sm"
                }
              >
                <p>{emptyMessage}</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export function selectAction<T>(): ColumnDef<T> {
  return {
    id: "select",
    enableResizing: false,
    size: 0,
    maxSize: 0,
    minSize: 0,
    header: ({ table }) => (
      <Checkbox
        id={"select-all"}
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={(c) => table.toggleAllRowsSelected()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        id={"select-" + row.id}
        checked={row.getIsSelected()}
        indeterminate={row.getIsSomeSelected()}
        onChange={(c) => row.toggleSelected()}
      />
    ),
  };
}
