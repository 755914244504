import { Navigate } from "react-router-dom";
import {
  toEnvironmentCRMContacts,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../routes";

export function RedirectToCRMContacts() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  return <Navigate to={toEnvironmentCRMContacts(workspaceId, environmentId)} />;
}
