import React from "react";
import { Check, Minus } from "../../icons";
import classNames from "classnames";
import * as RadixCheckbox from "@radix-ui/react-checkbox";
import * as Switch from "@radix-ui/react-switch";
import { motion } from "framer-motion";

export function Checkbox({
  label,
  onChange,
  checked,
  readOnly,
  disabled,
  id,
  indeterminate = false,
}: {
  label?: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  readOnly?: boolean;
  disabled?: boolean;
  id: string;
  indeterminate?: boolean;
}) {
  return (
    <div className={"flex items-center space-x-2"}>
      <RadixCheckbox.Root
        checked={checked || indeterminate}
        onCheckedChange={onChange}
        id={id}
        className={classNames(
          "w-4 h-4",
          "appearance-none border rounded-md cursor-pointer flex items-center justify-center",
          {
            "border-neutral-500 ": !(checked || indeterminate),
            "border-black bg-black ": checked || indeterminate,
          }
        )}
      >
        <RadixCheckbox.Indicator>
          {checked ? (
            <Check className={"stroke-white"} size={12} strokeWidth={3} />
          ) : indeterminate ? (
            <Minus className={"stroke-white"} size={12} strokeWidth={3} />
          ) : null}
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      {label ? (
        <label className={"select-none whitespace-nowrap"} htmlFor={id}>
          {label}
        </label>
      ) : null}
    </div>
  );
}

export function Toggle({
  label,
  onChange,
  checked,
  readOnly,
  disabled,
  id,
  size = "regular",
  labelLeading,
}: {
  label: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  readOnly?: boolean;
  disabled?: boolean;
  id: string;
  size?: "small" | "regular";
  labelLeading?: boolean;
}) {
  const labelEl = (
    <span
      className={classNames(
        "text-sm font-medium text-gray-900 whitespace-nowrap",
        {
          "text-sm": size === "regular",
          "text-xs": size === "small",
        }
      )}
    >
      {label}
    </span>
  );

  return (
    <label
      htmlFor={id}
      className="inline-flex relative items-center cursor-pointer space-x-3 select-none"
    >
      {labelLeading ? labelEl : null}

      <Switch.Root
        checked={checked}
        onCheckedChange={onChange}
        className={classNames(
          "rounded-full flex items-center inner-shadow transition duration-100",
          {
            "w-9 h-5": size === "regular",
            "w-6 h-3": size === "small",
          },
          {
            "justify-start bg-neutral-100": !checked && !disabled,
            "justify-end bg-black": checked && !disabled,
            "bg-neutral-200 cursor-not-allowed": disabled,
          }
        )}
        id={id}
      >
        <Switch.Thumb asChild>
          <motion.div
            layout
            transition={{ duration: 0.1 }}
            className={classNames("block rounded-full bg-white shadow", {
              "w-5 h-5": size === "regular",
              "w-3 h-3": size === "small",
            })}
          />
        </Switch.Thumb>
      </Switch.Root>

      {!labelLeading ? labelEl : null}
    </label>
  );
}
