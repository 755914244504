import { BreadcrumbItem, ContextualNavigationItem } from "./navigation";
import { createContext, PropsWithChildren, useContext, useState } from "react";

interface PageContextData {
  contextualNavigationItems: ContextualNavigationItem[];
  contextualNavigationItemsLoading: boolean;
  breadcrumbItems: BreadcrumbItem[];
}

const defaultState: PageContextData = {
  contextualNavigationItems: [],
  contextualNavigationItemsLoading: false,
  breadcrumbItems: [],
};

export const PageContext = createContext<
  PageContextData & {
    setPage: (data: PageContextData) => void;
  }
>({
  ...defaultState,
  setPage: (data: PageContextData) => {},
});

export function PageContextProvider({ children }: PropsWithChildren<{}>) {
  const [pageContextState, setPageContextState] = useState<PageContextData>({
    ...defaultState,
  });

  return (
    <PageContext.Provider
      value={{ ...pageContextState, setPage: setPageContextState }}
    >
      {children}
    </PageContext.Provider>
  );
}

export function usePageContext(): [
  PageContextData,
  (data: PageContextData) => void
] {
  const { setPage, ...pageContextData } = useContext(PageContext);
  return [pageContextData, setPage];
}
