import React, { useMemo } from "react";
import { BarRounded } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleLinear, scaleTime } from "@visx/scale";
import {
  InsightsCountBucket,
  InsightsTimeRange,
  InsightsRelativeTimeRange,
} from "@anzuhq/backend";
import { addDays, addHours, parseISO, subDays, subHours } from "date-fns";
import tw from "tailwindcss/colors";
import { AnimatedAxis } from "@visx/react-spring";

export function getDomainFromTimeRangeRelative(
  timeRange: InsightsRelativeTimeRange,
  toDate: Date
) {
  switch (timeRange) {
    case InsightsRelativeTimeRange.Last7Days:
      return [subDays(toDate, 7).getTime(), addDays(toDate, 1).getTime()];
    case InsightsRelativeTimeRange.Last24Hours:
      return [subHours(toDate, 24).getTime(), addHours(toDate, 1).getTime()];
    case InsightsRelativeTimeRange.Last30Days:
      return [subDays(toDate, 30).getTime(), addDays(toDate, 1).getTime()];
    case InsightsRelativeTimeRange.Last90Days:
      return [subDays(toDate, 90).getTime(), addDays(toDate, 1).getTime()];
  }
}

export function getDomainFromTimeRange(timeRange: InsightsTimeRange) {
  if (timeRange.from && timeRange.to) {
    return [
      parseISO(timeRange.from).getTime(),
      parseISO(timeRange.to).getTime(),
    ];
  }

  if (timeRange.relative) {
    return getDomainFromTimeRangeRelative(timeRange.relative, new Date());
  }

  return [0, 0];
}

export function BrushChart({
  width: outerWidth,
  height: outerHeight,
  buckets,
  timeRange,
}: {
  width: number;
  height: number;
  buckets: InsightsCountBucket[];
  timeRange: InsightsTimeRange;
}) {
  const margin = {
    top: 40,
    right: 40,
    bottom: 40,
    left: 40,
  };

  const innerWidth = outerWidth - margin.left - margin.right;
  const innerHeight = outerHeight - margin.top - margin.bottom;

  const xMin = margin.left;
  const xMax = outerWidth - margin.right;
  const yMin = margin.top;
  const yMax = outerHeight - margin.bottom;

  // accessors
  const getDate = (d: InsightsCountBucket) => parseISO(d.bucket).getTime();
  const getDateCount = (d: InsightsCountBucket) => d.count;

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleTime({
        range: [0, innerWidth],
        domain: getDomainFromTimeRange(timeRange),
      }),
    [timeRange, xMax]
  );
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [innerHeight, 0],
        domain: [0, Math.max(...buckets.map(getDateCount))],
      }),
    [buckets, yMax]
  );

  return outerWidth < 10 ? null : (
    <svg width={outerWidth} height={outerHeight}>
      <Group left={margin.left} top={margin.top}>
        {buckets.map((d) => {
          const barX = xMin + Math.max(xScale(getDate(d)), 0);

          // for the lowest count value, this returns the highest y value
          // since rendering starts from the top and renders down
          const barY = yScale(getDateCount(d));

          // height is the difference between
          const barHeight = innerHeight - yScale(getDateCount(d));

          const barWidth = innerWidth > 520 ? 10 : 5;
          return (
            <BarRounded
              top
              radius={2}
              key={`bar-${d.bucket}`}
              x={barX}
              y={barY}
              width={barWidth}
              height={barHeight}
              fill={tw.blue["500"]}
            />
          );
        })}

        <AnimatedAxis
          orientation={"bottom"}
          top={innerHeight}
          scale={xScale}
          axisLineClassName={"stroke-neutral-200"}
          tickLineProps={{
            className: "stroke-neutral-200",
          }}
        />

        <AnimatedAxis
          orientation={"left"}
          scale={yScale}
          axisLineClassName={"stroke-neutral-200"}
          tickLineProps={{
            className: "stroke-neutral-200",
          }}
          tickFormat={(d) => `${d}`}
          numTicks={5}
        />
      </Group>
    </svg>
  );
}
