import { useBreadcrumbItems, useContextualNavigation } from "../nav";
import { Navigate } from "react-router-dom";

import { useUserManagementSettingsSections } from "./nav";
import { Page } from "../../../../../components/page/page";
import {
  toUserManagementSettingsSection,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../routes";
import {
  PageHeaderTitle,
  SimpleHeader,
} from "../../../../../components/header";
import { LayoutWithStickySidebar } from "../../../../../components/settings";
import { ButtonLink } from "../../../../../../components/basics/button";
import { ExternalLink } from "../../../../../../icons";
import { useUserManagementSettingsConfig } from "./general/page";

export function UserManagementSettings() {
  const navItems = useContextualNavigation();
  const breadcrumbItems = useBreadcrumbItems();
  const sections = useUserManagementSettingsSections();

  const { userManagementConfig } = useUserManagementSettingsConfig();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  return (
    <Page
      title={"Settings - User Management"}
      contextualNavigationItems={navItems}
      breadcrumbItems={breadcrumbItems}
    >
      <SimpleHeader>
        <PageHeaderTitle>Settings</PageHeaderTitle>

        {userManagementConfig ? (
          <div className={"ml-auto"}>
            <ButtonLink
              openInNewTab
              icon={ExternalLink}
              to={userManagementConfig.hostedPageUrl}
              role={"secondary"}
            >
              Open Hosted Auth Page
            </ButtonLink>
          </div>
        ) : null}
      </SimpleHeader>

      <LayoutWithStickySidebar
        sections={sections}
        sectionUrl={(sectionId) =>
          toUserManagementSettingsSection(workspaceId, environmentId, sectionId)
        }
      />
    </Page>
  );
}

export function UserManagementSettingsOverview() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const sections = useUserManagementSettingsSections();

  return (
    <Navigate
      to={toUserManagementSettingsSection(
        workspaceId,
        environmentId,
        sections[0].id
      )}
      replace
    />
  );
}
