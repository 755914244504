import { PropsWithChildren, useEffect } from "react";
import { BreadcrumbItem } from "../../navigation";
import { usePageContext } from "../../context";

export function Page({
  title,
  children,
  contextualNavigationItems,
  contextualNavigationItemsLoading,
  breadcrumbItems,
}: PropsWithChildren<{
  title: string;
  contextualNavigationItems?: Array<{
    to: string;
    label: string;
    active: boolean;
  }>;
  contextualNavigationItemsLoading?: boolean;
  breadcrumbItems?: BreadcrumbItem[];
}>) {
  const [, setPageContext] = usePageContext();
  useEffect(
    () => {
      setPageContext({
        contextualNavigationItems: contextualNavigationItems || [],
        breadcrumbItems: breadcrumbItems || [],
        contextualNavigationItemsLoading:
          contextualNavigationItemsLoading || false,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      contextualNavigationItems,
      breadcrumbItems,
      contextualNavigationItemsLoading,
    ]
  );

  useEffect(() => {
    document.title = `${title} - Anzu`;
  }, [title]);

  return <>{children}</>;
}
