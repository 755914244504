import React, { PropsWithChildren, useMemo, useState } from "react";

import {
  Dropdown,
  DropdownItemButton,
  DropdownListContainer,
  DropdownTextInput,
} from "./dropdown";
import { RenderIcon } from "./renderer";
import { Check } from "../../icons";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { DatabaseSchema } from "@anzuhq/backend";

export function VisibleFieldsDialog({
  children,
  schema,
  visibleFields,
  setVisibleFields,
}: PropsWithChildren<{
  schema: DatabaseSchema;
  visibleFields: string[];
  setVisibleFields: (fields: string[]) => void;
}>) {
  const [open, setOpen] = useState(false);

  const [search, setSearch] = useState("");
  const filteredFields = useMemo(() => {
    return schema.fields.filter(
      (field) =>
        field.label.toLowerCase().includes(search.toLowerCase()) ||
        field.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [schema, search]);

  const currentlyVisible = useMemo(
    () => new Set(visibleFields),
    [visibleFields]
  );

  return (
    <Dropdown activator={children} open={open} setOpen={setOpen}>
      <DropdownTextInput
        value={search}
        setValue={setSearch}
        placeholder={"Search..."}
      />

      <DropdownListContainer>
        {filteredFields.map((f) => (
          <DropdownItemButton
            key={f.name}
            onClick={() => {
              const nextVisible = new Set(visibleFields);
              if (currentlyVisible.has(f.name)) {
                nextVisible.delete(f.name);
              } else {
                nextVisible.add(f.name);
              }

              setVisibleFields(Array.from(nextVisible));
            }}
          >
            <div className={"group flex items-center space-x-4 w-full"}>
              <AnimatePresence mode={"wait"}>
                {currentlyVisible.has(f.name) ? (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.05 }}
                  >
                    <Check
                      key={"check"}
                      size={16}
                      className={"bg-neutral-800 rounded-md text-white"}
                      strokeWidth={2.5}
                    />
                  </motion.div>
                ) : (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.05 }}
                    key={"icon"}
                  >
                    {f.icon ? <RenderIcon icon={f.icon} /> : null}
                  </motion.div>
                )}
              </AnimatePresence>
              <span>{f.label}</span>
            </div>
          </DropdownItemButton>
        ))}
      </DropdownListContainer>
    </Dropdown>
  );
}
