import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  RowData,
  useReactTable,
} from "@tanstack/react-table";
import { PropsWithChildren, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { getCellRenderer, selectColumn, TData } from "./renderer";
import classNames from "classnames";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  Menu,
} from "../../icons";
import { DatabaseSchema, PageInfo, PaginationArgs } from "@anzuhq/backend";

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

export function useColumnsForSchema(
  schema: DatabaseSchema,
  toDetailPage: (id: string) => string,
  onOpenSplitView?: (id: string) => void
) {
  return useMemo<ColumnDef<any, any>[]>(() => {
    const columnHelper = createColumnHelper<TData>();

    const defs: ColumnDef<any>[] = [selectColumn()];

    for (const field of schema.fields) {
      defs.push(
        columnHelper.accessor(field.name, {
          header: field.label,
          cell: getCellRenderer(
            field,
            toDetailPage,
            "regular",
            "inline",
            onOpenSplitView
          ),
          footer: (props) => props.column.id,
          enableHiding: true,
          enableResizing: true,
        })
      );
    }

    return defs;
  }, [schema, toDetailPage]);
}

export function TableView({
  isLoading,
  data,

  visibleFields,
  schema,

  toDetailPage,

  pagination,
  pageInfo,
  setPagination,
  emptyState,

  countTotal,
  countFiltered,

  onOpenSplitView,
}: {
  isLoading?: boolean;
  data: TData[];

  visibleFields: string[];
  schema: DatabaseSchema;

  toDetailPage: (id: string) => string;

  pageInfo: PageInfo;
  pagination: PaginationArgs;
  setPagination: (pagination: PaginationArgs) => void;

  emptyState?: React.ReactNode;

  countFiltered?: number;
  countTotal?: number;

  onOpenSplitView?: (id: string) => void;
}) {
  const [rowSelection, setRowSelection] = useState({});

  const columns = useColumnsForSchema(schema, toDetailPage, onOpenSplitView);

  // All columns must be present with true/false, we only store the ones that are true
  const visibleColumnsObj = useMemo(
    () =>
      schema.fields.reduce((acc: Record<string, boolean>, field) => {
        acc[field.name] = visibleFields.includes(field.name);
        return acc;
      }, {}),
    [schema, visibleFields]
  );

  const currentPageSize = pagination.first || pagination.last!;

  const table = useReactTable({
    data: data,

    columns,
    // resize interactively (follow drag instead of dragging, then re-rendering)
    columnResizeMode: "onChange",

    getCoreRowModel: getCoreRowModel(),

    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,

    state: {
      rowSelection,
      columnVisibility: visibleColumnsObj,
      columnOrder: ["select", ...visibleFields],
    },

    onRowSelectionChange: setRowSelection,
  });

  const isEmpty = table.getRowModel().rows.length === 0;

  return (
    <div
      // Grow table to fit full page
      className={classNames("grow flex flex-col relative", {
        "animate-pulse": isLoading,
      })}
    >
      <div
        // Prevent overflow of table to enable inline scrolling, set arbitrary base height to prevent table from growing hiding footer
        // Use grow to fill remaining space
        className={"grow overflow-hidden h-2"}
      >
        <div
          // Allow scrolling within container
          className={classNames("h-full", {
            "overflow-auto": !isEmpty,
            "overflow-hidden ": isEmpty,
          })}
        >
          <table
            className={classNames(
              "border-collapse table-auto",
              // Enforce full width size
              "min-w-full"
            )}
            style={{
              width: table.getCenterTotalSize(),
            }}
          >
            <thead
              className={
                "sticky top-0 w-full z-20 border-b-2 border-neutral-300"
              }
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  // TODO Find out why border disappears when scrolling (maybe due to border collapse or some table layouting weirdness)
                  className={"w-full bg-neutral-50 w-full"}
                >
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        className={classNames("h-full", {
                          "bg-neutral-100": header.column.getIsResizing(),
                        })}
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width: header.getSize(),
                        }}
                      >
                        <div
                          className={
                            "flex flex-row items-center h-full w-full p-2"
                          }
                        >
                          <div />
                          {header.isPlaceholder ? null : (
                            <div
                              className={
                                "font-normal whitespace-nowrap text-sm select-none text-neutral-800"
                              }
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </div>
                          )}

                          {header.column.getCanResize() ? (
                            <button
                              className={
                                "ml-auto text-neutral-400 hover:text-neutral-800 active:text-neutral-800"
                              }
                              onMouseDown={header.getResizeHandler()}
                              onTouchStart={header.getResizeHandler()}
                              onClick={() => {}}
                            >
                              <Menu size={16} />
                            </button>
                          ) : null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>

            <tbody className={"z-0"}>
              {table.getRowModel().rows.map((row, idx) => {
                return (
                  <motion.tr
                    // Using row original id instead of row id to re-render for every new page
                    key={row.original.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.025, delay: idx * 0.02 }}
                    className={classNames(
                      "group border-b border-neutral-200 h-12",
                      {
                        "bg-neutral-50/50": row.getIsSelected(),
                      }
                    )}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          style={{
                            width: cell.column.getSize(),
                          }}
                          className={"p-2 whitespace-nowrap"}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </motion.tr>
                );
              })}
            </tbody>
          </table>

          {isEmpty ? (
            <div className={"p-4 flex items-center justify-center h-full"}>
              {emptyState || (
                <p className={"text-neutral-800 whitespace-nowrap text-sm"}>
                  No records added.
                </p>
              )}
            </div>
          ) : null}
        </div>
      </div>

      <div
        // Keep footer always visible do not shrink or grow
        className="flex items-center justify-center p-2 grow-0 shrink-0"
      >
        <div
          className={
            "px-2 py-1 bg-white rounded-md flex items-center justify-center gap-2"
          }
        >
          <PaginationButton
            onClick={() =>
              setPagination({
                before: null,
                after: null,
                first: currentPageSize,
                last: null,
              })
            }
            disabled={isLoading || !pageInfo.hasPreviousPage}
          >
            <ChevronsLeft size={14} />
            <span>First</span>
          </PaginationButton>
          <PaginationButton
            onClick={() =>
              setPagination({
                before: pageInfo.startCursor,
                last: currentPageSize,
                after: null,
                first: null,
              })
            }
            disabled={isLoading || !pageInfo.hasPreviousPage}
          >
            <ChevronLeft size={14} />
            <span>Previous</span>
          </PaginationButton>
          <PaginationButton
            onClick={() =>
              setPagination({
                after: pageInfo.endCursor,
                first: currentPageSize,
                before: null,
                last: null,
              })
            }
            disabled={isLoading || !pageInfo.hasNextPage}
          >
            <span>Next</span>
            <ChevronRight size={14} />
          </PaginationButton>
          <PaginationButton
            onClick={() =>
              setPagination({
                before: null,
                after: null,
                first: null,
                last: currentPageSize,
              })
            }
            disabled={isLoading || !pageInfo.hasNextPage}
          >
            <span>Last</span>
            <ChevronsRight size={14} />
          </PaginationButton>

          <select
            className={
              "text-sm rounded bg-white hover:bg-neutral-100 px-1 py-px appearance-none"
            }
            value={pagination.first || pagination.last!}
            onChange={(e) => {
              setPagination({
                before: pagination.before,
                after: pagination.after,
                first: pagination.first ? parseInt(e.target.value) : null,
                last: pagination.last ? parseInt(e.target.value) : null,
              });
            }}
          >
            {[25, 50, 75].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>

          <div
            className={classNames(
              "flex items-center space-x-1 rounded bg-neutral-100 text-xs px-2 py-1",
              {
                "animate-pulse": !(
                  !isLoading &&
                  typeof countTotal !== "undefined" &&
                  typeof countFiltered !== "undefined"
                ),
              }
            )}
          >
            {countTotal === 0 && countFiltered === 0 ? (
              <span>No records</span>
            ) : (
              <span>
                Showing {countFiltered || 0}/{countTotal || 0} records
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function PaginationButton({
  children,
  onClick,
  disabled,
}: PropsWithChildren<{ onClick: () => void; disabled?: boolean }>) {
  return (
    <button
      className="cursor-pointer disabled:cursor-not-allowed border border-transparent disabled:text-neutral-500 hover:border-neutral-200 disabled:border-transparent hover:bg-neutral-100 active:bg-neutral-200 disabled:bg-white rounded px-1 py-px flex items-center space-x-2 text-sm transition duration-100"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
