import {
  createBrowserRouter,
  Navigate,
  Outlet,
  Route,
  useParams,
  createRoutesFromElements,
} from "react-router-dom";
import {
  isNotFound,
  useProductionEnvironment,
  useWorkspaceEnvironments,
  useWorkspaces,
} from "../data";
import { NotFoundPage } from "./not-found/page";
import { RequireAuth } from "../components/auth";
import { usePageContext } from "./context";
import { UserManagementSettingsAccessTokens } from "./[workspaceId]/environments/[environmentId]/user-management/settings/access-tokens/page";
import { UserIdentityList } from "./[workspaceId]/environments/[environmentId]/user-management/user-identities/page";
import { UserManagementSettingsRedirectUri } from "./[workspaceId]/environments/[environmentId]/user-management/settings/redirect-uri/page";
import { DevelopersOverview } from "./[workspaceId]/environments/[environmentId]/developers/page";
import { UserManagementSettingsGeneral } from "./[workspaceId]/environments/[environmentId]/user-management/settings/general/page";
import { UserManagementOverview } from "./[workspaceId]/environments/[environmentId]/user-management/page";
import { PageSkeleton } from "./components/skeleton/page_skeleton";
import { UserManagementSettingsAuthProviders } from "./[workspaceId]/environments/[environmentId]/user-management/settings/auth-providers/page";
import { UserIdentityPage } from "./[workspaceId]/environments/[environmentId]/user-management/user-identities/[userIdentityId]/page";
import { UserManagementSettingsUserProvisioning } from "./[workspaceId]/environments/[environmentId]/user-management/settings/user-provisioning/page";
import { UserManagementSettingsHostedAuthPage } from "./[workspaceId]/environments/[environmentId]/user-management/settings/hosted-auth-page/page";
import {
  UserManagementSettings,
  UserManagementSettingsOverview,
} from "./[workspaceId]/environments/[environmentId]/user-management/settings/page";
import { CreateWorkspaceDialog, Navigation } from "./navigation";
import {
  RedirectToFittingWorkspace,
  RememberWorkspace,
} from "./[workspaceId]/context";
import {
  RedirectToMembersSettings,
  WorkspaceSettingsPage,
} from "./[workspaceId]/settings/page";
import { InvitePage } from "./join/[inviteId]/page";
import { JoinPage } from "./join/page";
import { RegisterPage } from "./auth/register/page";
import { LoginPage } from "./auth/login/page";
import { MagicLinkPage } from "./auth/magic/page";
import { OverviewPage } from "./[workspaceId]/environments/[environmentId]/page";
import { WithKBar } from "./kbar";
import { UserIdentityAccessTokensView } from "./[workspaceId]/environments/[environmentId]/user-management/user-identities/[userIdentityId]/access_tokens/page";
import { UserIdentityOverviewView } from "./[workspaceId]/environments/[environmentId]/user-management/user-identities/[userIdentityId]/overview/page";
import { UserIdentityRelatedIdentitiesView } from "./[workspaceId]/environments/[environmentId]/user-management/user-identities/[userIdentityId]/related_identities/page";
import { UserIdentityAuthAttemptsView } from "./[workspaceId]/environments/[environmentId]/user-management/user-identities/[userIdentityId]/auth_attempts/page";
import { InsightsPage } from "./[workspaceId]/environments/[environmentId]/insights/overview/page";
import { WebhooksList } from "./[workspaceId]/environments/[environmentId]/developers/webhooks/page";
import {
  WebhookDeliveriesInboxPage,
  WebhookPage,
  WebhookSettingsPage,
} from "./[workspaceId]/environments/[environmentId]/developers/webhooks/[webhookId]/page";
import {
  RedirectToFittingEnvironment,
  RememberEnvironment,
} from "./[workspaceId]/environments/context";
import { WebhookCreatePage } from "./[workspaceId]/environments/[environmentId]/developers/webhooks/create/page";
import {
  InsightsSettings,
  InsightsSettingsOverview,
} from "./[workspaceId]/environments/[environmentId]/insights/settings/page";
import { InsightsSettingsScript } from "./[workspaceId]/environments/[environmentId]/insights/settings/script/page";
import { InsightsSettingsAllowedOrigins } from "./[workspaceId]/environments/[environmentId]/insights/settings/allowed-origins/page";
import { InsightsExplorePage } from "./[workspaceId]/environments/[environmentId]/insights/explore/page";
import { InsightsEventsPage } from "./[workspaceId]/environments/[environmentId]/insights/events/page";
import { CRMContactsList } from "./[workspaceId]/environments/[environmentId]/crm/contacts/page";
import { CRMContactPage } from "./[workspaceId]/environments/[environmentId]/crm/contacts/[contactId]/page";
import { CRMContactOverviewPage } from "./[workspaceId]/environments/[environmentId]/crm/contacts/[contactId]/overview/page";
import { RedirectToCRMContacts } from "./[workspaceId]/environments/[environmentId]/crm/page";
import { CRMCompaniesList } from "./[workspaceId]/environments/[environmentId]/crm/companies/page";
import {
  CRMDealsList,
  RedirectToCRMDeals,
} from "./[workspaceId]/environments/[environmentId]/crm/deals/pipelines/[dealPipelineId]/page";
import { CheckEarlyAccess } from "../early_access";
import { CRMCompanyPage } from "./[workspaceId]/environments/[environmentId]/crm/companies/[companyId]/page";
import { CRMCompanyOverviewPage } from "./[workspaceId]/environments/[environmentId]/crm/companies/[companyId]/overview/page";
import { CRMDealPage } from "./[workspaceId]/environments/[environmentId]/crm/deals/[dealId]/page";
import { CRMDealOverviewPage } from "./[workspaceId]/environments/[environmentId]/crm/deals/[dealId]/overview/page";
import { CRMNotesPage } from "./[workspaceId]/environments/[environmentId]/crm/notes/[notesId]/page";
import { CRMContactNotesList } from "./[workspaceId]/environments/[environmentId]/crm/contacts/[contactId]/notes/page";
import { CRMCompanyNotesList } from "./[workspaceId]/environments/[environmentId]/crm/companies/[companyId]/notes/page";
import { CRMDealNotesList } from "./[workspaceId]/environments/[environmentId]/crm/deals/[dealId]/notes/page";
import { MembersSettings } from "./[workspaceId]/settings/members/page";
import { AccessTokenSettingsPage } from "./[workspaceId]/settings/access-tokens/page";
import { DebugPage } from "./debug";

function PageWrapper() {
  const [data] = usePageContext();

  return (
    <WithKBar>
      <div className={"h-screen flex flex-col"} id={"page-wrapper"}>
        <Navigation
          contextualItems={data.contextualNavigationItems}
          contextualItemsLoading={data.contextualNavigationItemsLoading}
          breadcrumbItems={data.breadcrumbItems}
        />
        <div className={"w-full h-full grow"}>
          <Outlet />
        </div>
      </div>
    </WithKBar>
  );
}

export const createAppRouter = () => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route path={"/"} element={<PageWrapper />}>
        <Route path={"auth"}>
          <Route path={"login"} element={<LoginPage />} />
          <Route path={"register"} element={<RegisterPage />} />
          <Route path={"magic"} element={<MagicLinkPage />} />
        </Route>

        <Route
          path={"*"}
          element={
            <RequireAuth>
              <CheckEarlyAccess />
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <RequireAuth>
                <RedirectToCurrentWorkspace />
              </RequireAuth>
            }
          />

          <Route path="join">
            <Route
              path=":inviteId"
              element={
                <RequireAuth>
                  <InvitePage />
                </RequireAuth>
              }
            />
            <Route
              index
              element={
                <RequireAuth>
                  <JoinPage />
                </RequireAuth>
              }
            />
          </Route>

          <Route path={"debug"} element={<DebugPage />} />

          <Route
            path={":workspaceId"}
            element={
              <RequireAuth>
                <RememberWorkspace />
              </RequireAuth>
            }
          >
            <Route index element={<RedirectToCurrentEnvironment />} />

            <Route path={"environments"}>
              <Route path={":environmentId"} element={<RememberEnvironment />}>
                <Route index element={<OverviewPage />} />

                {/* Insights */}
                <Route path={"insights"}>
                  <Route index element={<InsightsPage />} />
                  <Route path={"explore"} element={<InsightsExplorePage />} />
                  <Route path={"events"} element={<InsightsEventsPage />} />
                  <Route path="settings" element={<InsightsSettings />}>
                    <Route index element={<InsightsSettingsOverview />} />
                    <Route
                      path={"script"}
                      element={<InsightsSettingsScript />}
                    />
                    <Route
                      path={"allowed-origins"}
                      element={<InsightsSettingsAllowedOrigins />}
                    />
                  </Route>
                </Route>

                {/* CRM */}
                <Route path={"crm"}>
                  <Route index element={<RedirectToCRMContacts />} />

                  <Route path={"contacts"}>
                    <Route index element={<CRMContactsList />} />
                    <Route path={":contactId"} element={<CRMContactPage />}>
                      <Route index element={<CRMContactOverviewPage />} />
                      <Route path={"notes"} element={<CRMContactNotesList />} />
                    </Route>
                  </Route>

                  <Route path={"companies"}>
                    <Route index element={<CRMCompaniesList />} />
                    <Route path={":companyId"} element={<CRMCompanyPage />}>
                      <Route index element={<CRMCompanyOverviewPage />} />
                      <Route path={"notes"} element={<CRMCompanyNotesList />} />
                    </Route>
                  </Route>

                  <Route path={"deals"}>
                    <Route index element={<RedirectToCRMDeals />} />
                    <Route path={"pipelines"}>
                      <Route path={":dealPipelineId"}>
                        <Route index element={<CRMDealsList />} />
                      </Route>
                    </Route>
                    <Route path={":dealId"} element={<CRMDealPage />}>
                      <Route index element={<CRMDealOverviewPage />} />
                      <Route path={"notes"} element={<CRMDealNotesList />} />
                    </Route>
                  </Route>

                  <Route path={"notes"}>
                    <Route path={":notesId"} element={<CRMNotesPage />} />
                  </Route>
                </Route>

                {/* User Management */}
                <Route path={"user-management"}>
                  <Route index element={<UserManagementOverview />} />
                  <Route path={"user-identities"}>
                    <Route index element={<UserIdentityList />} />
                    <Route
                      path={":userIdentityId"}
                      element={<UserIdentityPage />}
                    >
                      <Route index element={<UserIdentityOverviewView />} />
                      <Route
                        path={"access-tokens"}
                        element={<UserIdentityAccessTokensView />}
                      />
                      <Route
                        path={"related-identities"}
                        element={<UserIdentityRelatedIdentitiesView />}
                      />
                      <Route
                        path={"auth-attempts"}
                        element={<UserIdentityAuthAttemptsView />}
                      />
                    </Route>
                  </Route>
                  <Route path="settings" element={<UserManagementSettings />}>
                    <Route index element={<UserManagementSettingsOverview />} />
                    <Route
                      path={"general"}
                      element={<UserManagementSettingsGeneral />}
                    />
                    <Route
                      path={"redirect-uri"}
                      element={<UserManagementSettingsRedirectUri />}
                    />
                    <Route
                      path={"user-provisioning"}
                      element={<UserManagementSettingsUserProvisioning />}
                    />
                    <Route
                      path={"hosted-auth-page"}
                      element={<UserManagementSettingsHostedAuthPage />}
                    />
                    <Route
                      path={"auth-providers"}
                      element={<UserManagementSettingsAuthProviders />}
                    />
                    <Route
                      path={"access-tokens"}
                      element={<UserManagementSettingsAccessTokens />}
                    />
                  </Route>
                </Route>

                {/* Developers */}
                <Route path={"developers"}>
                  <Route index element={<DevelopersOverview />} />
                  <Route path={"webhooks"}>
                    <Route index element={<WebhooksList />} />
                    <Route path={"create"} element={<WebhookCreatePage />} />
                    <Route path={":webhookId"} element={<WebhookPage />}>
                      <Route index element={<WebhookDeliveriesInboxPage />} />
                      <Route
                        path={"settings"}
                        element={<WebhookSettingsPage />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route path={"settings"} element={<WorkspaceSettingsPage />}>
              <Route index element={<RedirectToMembersSettings />} />
              <Route path={"members"} element={<MembersSettings />} />
              <Route
                path={"access-tokens"}
                element={<AccessTokenSettingsPage />}
              />
            </Route>
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>
    )
  );
};

function ErrorPage({ title, message }: { message: string; title: string }) {
  return (
    <>
      <h1>{title}</h1>
      <p>{message}</p>
    </>
  );
}

function RedirectToCurrentWorkspace() {
  const { workspaces, isLoading, error, mutate } = useWorkspaces();

  if (isLoading) {
    return <PageSkeleton title={"Redirecting"} />;
  }

  if (error) {
    return (
      <ErrorPage
        title={"Could not redirect to workspace"}
        message={
          "We tried redirecting you to your workspace, but something on our end failed. Please try refreshing the page or contact our support workspace!"
        }
      />
    );
  }
  if (!workspaces || workspaces.length === 0) {
    return <CreateWorkspaceDialog mutate={mutate} forceOpen={true} />;
  }

  return <RedirectToFittingWorkspace workspaces={workspaces} />;
}

function RedirectToCurrentEnvironment() {
  const workspaceId = useWorkspaceId();
  const { environment, isLoading, error } =
    useProductionEnvironment(workspaceId);
  const {
    environments,
    isLoading: isLoadingEnvironments,
    error: environmentsErr,
  } = useWorkspaceEnvironments(workspaceId);

  if (isLoading || isLoadingEnvironments) {
    return <PageSkeleton title={"Redirecting"} />;
  }
  if (error || environmentsErr) {
    if (isNotFound(error) || isNotFound(environmentsErr)) {
      return <Navigate to={"/"} replace={true} />;
    }
    return (
      <ErrorPage
        title={"Could not redirect to default environment"}
        message={
          "We tried redirecting you to the production environment, but something on our end failed. Please try refreshing the page or contact our support workspace!"
        }
      />
    );
  }
  if (!environment || !environments) {
    return (
      <ErrorPage
        title={"Could not redirect to default environment"}
        message={
          "We tried redirecting you to the production environment, but something on our end failed. Please try refreshing the page or contact our support workspace!"
        }
      />
    );
  }
  return (
    <RedirectToFittingEnvironment
      workspaceId={workspaceId}
      environments={environments}
      productionEnvironment={environment}
    />
  );
}

export function useWorkspaceId() {
  const { workspaceId } = useParams();
  if (!workspaceId) {
    throw new Error("Missing workspaceId");
  }

  return workspaceId;
}

export function useOptionalWorkspaceId() {
  const { workspaceId } = useParams();

  return workspaceId;
}

export function useEnvironmentId() {
  const { environmentId } = useParams();
  if (!environmentId) {
    throw new Error("Missing environmentId");
  }

  return environmentId;
}

export function useOptionalEnvironmentId() {
  const { environmentId } = useParams();

  return environmentId;
}

export function useUserIdentityId() {
  const { userIdentityId } = useParams();
  if (!userIdentityId) {
    throw new Error("Missing userIdentityId");
  }

  return userIdentityId;
}

export function useWebhookId() {
  const { webhookId } = useParams();
  if (!webhookId) {
    throw new Error("Missing webhookId");
  }

  return webhookId;
}

export function useContactId() {
  const { contactId } = useParams();
  if (!contactId) {
    throw new Error("Missing contactId");
  }

  return contactId;
}

export function useCompanyId() {
  const { companyId } = useParams();
  if (!companyId) {
    throw new Error("Missing companyId");
  }

  return companyId;
}

export function useDealId() {
  const { dealId } = useParams();
  if (!dealId) {
    throw new Error("Missing dealId");
  }

  return dealId;
}

export function useNotesId() {
  const { notesId } = useParams();
  if (!notesId) {
    throw new Error("Missing notesId");
  }

  return notesId;
}

export function useDealPipelineId() {
  const { dealPipelineId } = useParams();
  if (!dealPipelineId) {
    throw new Error("Missing dealPipelineId");
  }

  return dealPipelineId;
}

export function toWorkspaceDashboard(workspaceId: string) {
  return `/${workspaceId}`;
}

export function toWorkspaceJoin() {
  return `/join`;
}

export function toWorkspaceInvite(inviteId: string) {
  return `/join/${inviteId}`;
}

export function toWorkspaceSettings(workspaceId: string) {
  return `/${workspaceId}/settings`;
}

export function toWorkspaceSettingsSection(
  workspaceId: string,
  section: string
) {
  return `${toWorkspaceSettings(workspaceId)}/${section}`;
}

export function toEnvironmentDashboard(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}`;
}

export function toEnvironmentOverview(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}`;
}

export function toEnvironmentInsights(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/insights`;
}

export function toEnvironmentCRM(workspaceId: string, environmentId: string) {
  return `/${workspaceId}/environments/${environmentId}/crm`;
}

export function toEnvironmentCRMContacts(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/crm/contacts`;
}

export function toEnvironmentCRMContactsView(
  workspaceId: string,
  environmentId: string,
  viewId: string
) {
  return `${toEnvironmentCRMContacts(
    workspaceId,
    environmentId
  )}?view=${viewId}`;
}

export function toEnvironmentCRMContact(
  workspaceId: string,
  environmentId: string,
  contactId: string
) {
  return `/${workspaceId}/environments/${environmentId}/crm/contacts/${contactId}`;
}

export function toEnvironmentCRMContactNotes(
  workspaceId: string,
  environmentId: string,
  contactId: string
) {
  return `${toEnvironmentCRMContact(
    workspaceId,
    environmentId,
    contactId
  )}/notes`;
}

export function toEnvironmentCRMContactCreate(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/crm/contacts/create`;
}

export function toEnvironmentCRMCompanies(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/crm/companies`;
}

export function toEnvironmentCRMNotes(
  workspaceId: string,
  environmentId: string,
  notesId: string
) {
  return `/${workspaceId}/environments/${environmentId}/crm/notes/${notesId}`;
}

export function toEnvironmentCRMCompaniesView(
  workspaceId: string,
  environmentId: string,
  viewId: string
) {
  return `${toEnvironmentCRMCompanies(
    workspaceId,
    environmentId
  )}?view=${viewId}`;
}

export function toEnvironmentCRMCompany(
  workspaceId: string,
  environmentId: string,
  companyId: string
) {
  return `/${workspaceId}/environments/${environmentId}/crm/companies/${companyId}`;
}

export function toEnvironmentCRMCompanyNotes(
  workspaceId: string,
  environmentId: string,
  companyId: string
) {
  return `${toEnvironmentCRMCompany(
    workspaceId,
    environmentId,
    companyId
  )}/notes`;
}

export function toEnvironmentCRMCompanyCreate(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/crm/companies/create`;
}

export function toEnvironmentCRMDealsBase(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/crm/deals`;
}

export function toEnvironmentCRMDeals(
  workspaceId: string,
  environmentId: string,
  dealPipelineId: string
) {
  return `${toEnvironmentCRMDealsBase(
    workspaceId,
    environmentId
  )}/pipelines/${dealPipelineId}`;
}

export function toEnvironmentCRMDealsView(
  workspaceId: string,
  environmentId: string,
  dealPipelineId: string,
  viewId: string
) {
  return `${toEnvironmentCRMDeals(
    workspaceId,
    environmentId,
    dealPipelineId
  )}?view=${viewId}`;
}

export function toEnvironmentCRMDeal(
  workspaceId: string,
  environmentId: string,
  dealId: string
) {
  return `/${workspaceId}/environments/${environmentId}/crm/deals/${dealId}`;
}

export function toEnvironmentCRMDealNotes(
  workspaceId: string,
  environmentId: string,
  dealId: string
) {
  return `${toEnvironmentCRMDeal(workspaceId, environmentId, dealId)}/notes`;
}

export function toEnvironmentCRMDealCreate(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/crm/deals/create`;
}

export function toEnvironmentUserManagement(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/user-management`;
}

export function toEnvironmentUserManagementUserIdentities(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/user-management/user-identities`;
}

export function toEnvironmentUserManagementUserIdentitiesView(
  workspaceId: string,
  environmentId: string,
  viewId: string
) {
  return `/${workspaceId}/environments/${environmentId}/user-management/user-identities?view=${viewId}`;
}

export function toEnvironmentUserManagementUserIdentity(
  workspaceId: string,
  environmentId: string,
  userIdentityId: string
) {
  return `/${workspaceId}/environments/${environmentId}/user-management/user-identities/${userIdentityId}`;
}

export function toEnvironmentUserManagementUserIdentityAccessTokens(
  workspaceId: string,
  environmentId: string,
  userIdentityId: string
) {
  return `${toEnvironmentUserManagementUserIdentity(
    workspaceId,
    environmentId,
    userIdentityId
  )}/access-tokens`;
}

export function toEnvironmentUserManagementUserIdentityRelatedIdentities(
  workspaceId: string,
  environmentId: string,
  userIdentityId: string
) {
  return `${toEnvironmentUserManagementUserIdentity(
    workspaceId,
    environmentId,
    userIdentityId
  )}/related-identities`;
}

export function toEnvironmentUserManagementUserIdentityAuthAttempts(
  workspaceId: string,
  environmentId: string,
  userIdentityId: string
) {
  return `${toEnvironmentUserManagementUserIdentity(
    workspaceId,
    environmentId,
    userIdentityId
  )}/auth-attempts`;
}

export function toEnvironmentUserManagementSettings(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/user-management/settings`;
}

export function toEnvironmentInsightsSettings(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/insights/settings`;
}

export function toEnvironmentInsightsSettingsSection(
  workspaceId: string,
  environmentId: string,
  section: string
) {
  return `${toEnvironmentInsightsSettings(
    workspaceId,
    environmentId
  )}/${section}`;
}

export function toEnvironmentDevelopers(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/developers`;
}

export function toEnvironmentDevelopersWebhooks(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/developers/webhooks`;
}

export function toEnvironmentDevelopersWebhook(
  workspaceId: string,
  environmentId: string,
  webhookId: string
) {
  return `/${workspaceId}/environments/${environmentId}/developers/webhooks/${webhookId}`;
}

export function toEnvironmentDevelopersWebhookSettings(
  workspaceId: string,
  environmentId: string,
  webhookId: string
) {
  return `/${workspaceId}/environments/${environmentId}/developers/webhooks/${webhookId}/settings`;
}

export function toEnvironmentDevelopersWebhookCreate(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/developers/webhooks/create`;
}

export function toUserManagementSettingsSection(
  workspaceId: string,
  environmentId: string,
  sectionId: string
) {
  return `${toEnvironmentUserManagementSettings(
    workspaceId,
    environmentId
  )}/${sectionId}`;
}

export function toLoginPage() {
  return `/auth/login`;
}

export function toRegisterPage() {
  return `/auth/register`;
}

export function toRegisterPageWithCode(
  email: string,
  code: string,
  attemptId: string
) {
  const params = new URLSearchParams();
  params.append("email", email);
  params.append("code", code);
  params.append("attemptId", attemptId);
  return `${toRegisterPage()}?${params.toString()}`;
}

export function toEnvironmentInsightsOverview(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/insights`;
}

export function toEnvironmentInsightsInsightsView(
  workspaceId: string,
  environmentId: string,
  view: string
) {
  const sp = new URLSearchParams();
  sp.append("view", view);
  return `${toEnvironmentInsightsOverview(
    workspaceId,
    environmentId
  )}?${sp.toString()}`;
}

export function toEnvironmentInsightsEvents(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/insights/events`;
}

export function toEnvironmentInsightsExplore(
  workspaceId: string,
  environmentId: string
) {
  return `/${workspaceId}/environments/${environmentId}/insights/explore`;
}
