import {
  CRMCommentCreatedEvent,
  CRMCommentKind,
  RootNode,
} from "@anzuhq/backend";
import { useCRMComment, usePostAPI, withErrorToast } from "../../../../../data";
import { useEnvironmentId, useWorkspaceId } from "../../../../routes";
import { useState } from "react";
import { FakeRichTextRenderer, RichTextContentRenderer } from "./richtext";
import { Button } from "../../../../../components/basics/button";
import toast from "react-hot-toast";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { ActorRenderer } from "./activity";
import { emptyRichText } from "./richtext";

export function CommentSection({
  parentId,
  kind,
  mutateActivity,
}: {
  mutateActivity: () => void;
  kind: CRMCommentKind;
  parentId: string;
}) {
  return (
    <div>
      <CreateComment
        mutateActivity={mutateActivity}
        kind={kind}
        parentId={parentId}
      />
    </div>
  );
}

function CreateComment({
  mutateActivity,
  parentId,
  kind,
}: {
  mutateActivity: () => void;
  kind: CRMCommentKind;
  parentId: string;
}) {
  const postApi = usePostAPI();
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const [doc, setDoc] = useState<RootNode>(emptyRichText());

  const isEmpty = doc.content[0].content[0].content === "";

  const createComment = async () => {
    await withErrorToast(async () => {
      await postApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/crm/comments`,
        {
          kind,
          contact: kind === CRMCommentKind.Contact ? parentId : null,
          company: kind === CRMCommentKind.Company ? parentId : null,
          deal: kind === CRMCommentKind.Deal ? parentId : null,
          notes: kind === CRMCommentKind.Notes ? parentId : null,

          content: doc,
        }
      );
      setDoc(emptyRichText());
      await mutateActivity();
      toast.success("Comment created");
    });
  };

  return (
    <div className={"py-4 flex flex-col space-y-2"}>
      <span className={"text-sm select-none"}>Comment</span>

      <div
        className={
          "p-2 rounded-md bg-neutral-50 text-sm focus-within:border-black border border-transparent transition duration-100"
        }
      >
        <FakeRichTextRenderer
          placeholder={"Leave a comment..."}
          content={doc}
          setContent={setDoc}
        />
      </div>

      <div className={"flex justify-end"}>
        <Button disabled={isEmpty} size={"small"} onClick={createComment}>
          Create comment
        </Button>
      </div>
    </div>
  );
}

export function CRMCommentRenderer({
  activity,
}: {
  activity: CRMCommentCreatedEvent;
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { comment } = useCRMComment(
    workspaceId,
    environmentId,
    activity.payload.id
  );

  if (!comment) {
    return (
      <div
        className={"animate-pulse h-6 w-full rounded-md shadow-md bg-white p-4"}
      ></div>
    );
  }

  return (
    <div
      className={
        "w-full bg-neutral-50 rounded-md p-3 flex flex-col space-y-1 text-sm"
      }
    >
      <div className={"flex items-start"}>
        <div className={"mr-2 flex items-center space-x-1"}>
          <ActorRenderer activity={activity} />
          <span className={"text-sm text-neutral-600"}>commented</span>
        </div>
        <div className={"ml-auto text-neutral-500 text-xs"}>
          {formatDistanceToNowStrict(parseISO(activity.createdAt), {
            addSuffix: true,
          })}
        </div>
      </div>
      <RichTextContentRenderer
        content={comment.content}
        editingDisabled
        setContent={() => {}}
      />
    </div>
  );
}
