import { Navigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { postApi } from "../../../data";
import { CenteredLayout } from "../../../components/basics/layout";
import { toLoginPage } from "../../routes";
import { useApiEndpointContext } from "../../../env";

export function MagicLinkPage() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const code = searchParams.get("code");
  const [done, setDone] = useState(false);
  const [apiEndpoint] = useApiEndpointContext();

  useEffect(
    () => {
      const saveLinkClick = async () => {
        await postApi(apiEndpoint, `/auth/magic/${id}`, { code });

        setDone(true);
      };
      saveLinkClick();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!id || !code) {
    return <Navigate to={toLoginPage()} replace />;
  }

  if (!done) {
    return (
      <div className="h-screen flex flex-col">
        <CenteredLayout heading="Signing In...">
          <p>Please keep this page open for a moment.</p>
        </CenteredLayout>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col">
      <CenteredLayout heading="You are signed in.">
        <p>
          You have been signed in on the other tab. You can now close this page.
        </p>
      </CenteredLayout>
    </div>
  );
}
