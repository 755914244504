import * as HoverCard from "@radix-ui/react-hover-card";
import { EmptyCell, formatDateTime, TData } from "./renderer";
import React, { useMemo, useState } from "react";
import {
  format,
  formatDistanceToNowStrict,
  getHours,
  getMinutes,
  parse,
  parseISO,
  setHours,
  setMinutes,
} from "date-fns";
import { Dropdown } from "./dropdown";
import { Button, RawButtonWithRef } from "../../components/basics/button";
import { DayPicker } from "react-day-picker";
import { editButtonStyle } from "./input_renderer";
import { useHotkeys } from "../../hooks/keypress";
import { Calendar } from "../../icons";

export function DateTimeRendererWithFormats({
  value,
  relative,
  disabled,
}: {
  value: string;
  relative?: boolean;
  disabled?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const parsed = useMemo(() => parseISO(value), [value]);
  const utcTime = useMemo(() => {
    return parsed.toLocaleString("en-US", { timeZone: "UTC" });
  }, [parsed]);
  const localTime = useMemo(() => {
    return parsed.toLocaleString("en-US", {});
  }, [parsed]);

  return (
    <HoverCard.Root open={disabled ? false : open} onOpenChange={setOpen}>
      <HoverCard.Trigger asChild>
        <span
          className={
            "font-normal text-sm text-underline underline underline-offset-2 decoration-neutral-300"
          }
        >
          {relative
            ? formatDistanceToNowStrict(parsed, { addSuffix: true })
            : formatDateTime(value)}
        </span>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content sideOffset={5} className={"z-30"}>
          <HoverCard.Arrow className="fill-neutral-200" />
          <div className={"bg-white shadow flex flex-col rounded"}>
            <div
              className={
                "rounded-t p-2 bg-neutral-100 text-xs text-neutral-700 uppercase border-b border-neutral-200"
              }
            >
              Time conversion
            </div>
            <div className={"flex flex-col space-y-2 p-2 bg-white rounded-b"}>
              <div className={"flex items-center justify-between"}>
                <span className="text-neutral-800 text-xs">UTC time</span>
                <span className={"text-sm text-neutral-600"}>{utcTime}</span>
              </div>
              <div className={"flex items-center justify-between"}>
                <span className="text-neutral-800 text-xs">
                  Local time (your account)
                </span>
                <span className={"pl-4 text-sm text-neutral-600"}>
                  {localTime}
                </span>
              </div>
            </div>
          </div>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
}

export function DateTimeInputDropdownRenderer<T extends TData>({
  value,
  updateValue,
  side,
}: {
  value: string | null;
  updateValue: (value: unknown) => void;
  side?: "left" | "right" | "bottom" | "top";
}) {
  const [open, setOpen] = useState(false);

  const [tempValue, setTempValue] = useState(value);
  const [dirty, setDirty] = useState(false);

  useHotkeys(
    "esc",
    () => {
      if (dirty) {
        updateValue(tempValue);
      }
      setOpen(false);
    },
    [dirty, open],
    open
  );

  return (
    <Dropdown
      side={side}
      noSizing
      activator={
        <RawButtonWithRef className={editButtonStyle} onClick={() => {}}>
          {value ? (
            <DateTimeRendererWithFormats disabled={open} value={value} />
          ) : (
            <EmptyCell />
          )}
        </RawButtonWithRef>
      }
      open={open}
      setOpen={(o) => {
        if (!o && dirty) {
          updateValue(tempValue);
          setDirty(false);
        }
        setOpen(o);
      }}
    >
      <DateTimeInputContentRenderer
        setDirty={setDirty}
        setTempValue={setTempValue}
        tempValue={tempValue}
        setValue={(value) => {
          updateValue(value);
          setDirty(false);
          setOpen(false);
        }}
      />
    </Dropdown>
  );
}

export function DateTimeInputContentRenderer({
  setDirty,
  setTempValue,
  tempValue,
  setValue,
}: {
  tempValue: string | null;
  setDirty: (dirty: boolean) => void;
  setTempValue: (value: string | null) => void;
  setValue: (value: string | null) => void;
}) {
  return (
    <div>
      <div className={"grid grid-cols-3"}>
        <div className={"col-span-2"}>
          <DayPicker
            mode="single"
            selected={tempValue ? parseISO(tempValue) : undefined}
            onSelect={(date) => {
              if (date) {
                setTempValue(
                  setHours(
                    setMinutes(date, getMinutes(new Date())),
                    getHours(new Date())
                  ).toISOString()
                );
                setDirty(true);
              } else {
                setTempValue(null);
                setDirty(true);
              }
            }}
            weekStartsOn={1}
            showOutsideDays
            classNames={{
              caption: "flex justify-between p-2",
              head_cell: "font-normal text-neutral-400/80",
              day: "w-8 h-8 rounded hover:bg-neutral-200/60 active:bg-neutral-300/60 transition",
              day_outside: "text-neutral-300",
              day_selected: "font-medium bg-neutral-300/60",
              day_today: "text-red-500/80 font-medium",
              nav_icon: "text-neutral-500 hover:text-neutral-700 p-px",
              nav: "flex items-center space-x-2",
            }}
            className={"p-2"}
          />
        </div>
        <div className={"col-span-1 px-2 py-4"}>
          <span>Time</span>

          <input
            type="time"
            className={
              "w-full bg-transparent p-2 outline-none hover:bg-neutral-200/60 active:bg-neutral-300/60 focus:bg-neutral-300/60 transition rounded"
            }
            value={tempValue ? format(parseISO(tempValue), "HH:mm") : ""}
            onChange={(e) => {
              const time = e.target.value;
              const timeVal = parse(time, "HH:mm", new Date());
              const date = tempValue ? parseISO(tempValue) : new Date();

              setTempValue(
                setHours(
                  setMinutes(date, getMinutes(timeVal)),
                  getHours(timeVal)
                ).toISOString()
              );
              setDirty(true);
            }}
          />
        </div>
      </div>

      <div className={"flex grow p-1"}>
        <Button
          icon={Calendar}
          role={"secondary"}
          onClick={() => setValue(tempValue)}
          grow
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}
