import { DatabaseSearch } from "@anzuhq/backend";
import { Button } from "../../components/basics/button";
import { Search } from "../../icons";
import { TextInput } from "../../components/basics/input";
import { useState } from "react";

export function DatabaseSearchInput({
  search,
  setSearch,
}: {
  search: DatabaseSearch | null;
  setSearch: (search: DatabaseSearch | null) => void;
}) {
  const [showSearch, setShowSearch] = useState(false);

  if (!showSearch && !search) {
    return (
      <Button
        weight={"regular"}
        onClick={() => setShowSearch(true)}
        role="ghost"
        size={"small"}
        icon={Search}
      >
        Search
      </Button>
    );
  }

  return (
    <div className={"w-32"}>
      <TextInput
        size={"small"}
        labelHorizontal={true}
        placeholder={"Search..."}
        value={search?.value || ""}
        onChange={(v) => {
          if (!v) {
            setSearch(null);
            return;
          }
          setSearch({ value: v });
        }}
        autoFocus={true}
      />
    </div>
  );
}
