import { Page } from "../page/page";
import { BreadcrumbItem, ContextualNavigationItem } from "../../navigation";

export function PageSkeleton({
  title,
  contextualNavigationItemsLoading,
  contextualNavigationItems,
  breadcrumbItems,
}: {
  title: string;
  contextualNavigationItems?: ContextualNavigationItem[];
  contextualNavigationItemsLoading?: boolean;
  breadcrumbItems?: BreadcrumbItem[];
}) {
  return (
    <Page
      title={title}
      contextualNavigationItems={contextualNavigationItems}
      contextualNavigationItemsLoading={contextualNavigationItemsLoading}
      breadcrumbItems={breadcrumbItems}
    >
      <div className="  p-4 max-w-2xl w-full mx-auto">
        <div className="animate-pulse grid grid-cols-12 gap-4">
          <div className="h-40 bg-neutral-200 rounded col-span-8"></div>
          <div className="h-40 bg-neutral-200 rounded col-span-4"></div>
          <div className="h-40 bg-neutral-200 rounded col-span-4"></div>
          <div className="h-40 bg-neutral-200 rounded col-span-8"></div>
        </div>
      </div>
    </Page>
  );
}
