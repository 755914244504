import { SettingsPageLayout } from "../../../components/settings";
import { FieldSet } from "../../../components/fieldset";
import { LearnMoreLink } from "../../../components/docs";
import {
  useDeleteAPI,
  useOwnAccount,
  usePostAPI,
  useWorkspaceInvites,
  useWorkspaceMemberProfile,
  useWorkspaceMembers,
  useWorkspaceRoles,
  withErrorToast,
} from "../../../../data";
import { useWorkspaceId } from "../../../routes";
import classNames from "classnames";
import { WorkspaceInvite, WorkspaceMember } from "@anzuhq/backend";
import { ImageWithFallback } from "../../../database/renderer";
import {
  Check,
  ChevronDown,
  ChevronUp,
  MoreHorizontal,
  PauseCircle,
  Trash2,
  UserPlus,
} from "../../../../icons";
import { Button, ButtonWithRef } from "../../../../components/basics/button";
import toast from "react-hot-toast";
import {
  Dropdown,
  DropdownItemButtonWithAlertDialog,
  DropdownListContainer,
} from "../../../database/dropdown";
import { useEffect, useMemo, useState } from "react";
import * as Select from "@radix-ui/react-select";
import { TextInput } from "../../../../components/basics/input";
import { Dialog, DialogHeader } from "../../../components/dialog";

export function MembersSettings() {
  return (
    <SettingsPageLayout>
      <MembersList />
      <PendingInvites />
    </SettingsPageLayout>
  );
}

function MembersList() {
  const workspaceId = useWorkspaceId();
  const { members, mutate } = useWorkspaceMembers(workspaceId);
  const { mutate: mutateInvites } = useWorkspaceInvites(workspaceId);

  return (
    <FieldSet
      footer={
        <LearnMoreLink to={"https://anzuhq.com/docs/platform/teams/members"}>
          Workspace Members
        </LearnMoreLink>
      }
      title={"Workspace Members"}
      action={<InviteDialog mutate={mutateInvites} />}
    >
      <div className={"divide-y divide-neutral-200 flex flex-col"}>
        {(members || []).map((member) => (
          <MemberProfile key={member.id} member={member} mutate={mutate} />
        ))}
      </div>
    </FieldSet>
  );
}

function MemberProfile({
  member,
  mutate,
}: {
  member: WorkspaceMember;
  mutate: () => void;
}) {
  const workspaceId = useWorkspaceId();
  const { profile } = useWorkspaceMemberProfile(workspaceId, member.id);
  const postApi = usePostAPI();
  const { account } = useOwnAccount();

  const toggleSuspend = async () => {
    await withErrorToast(async () => {
      await postApi(
        `/workspaces/${workspaceId}/members/${member.id}/suspend`,
        {}
      );
      toast.success(
        member.is_suspended ? "Member unsuspended" : "Member suspended"
      );
      mutate();
    });
  };

  const [open, setOpen] = useState(false);

  const canSuspend = member.account !== account?.id;

  return (
    <div
      className={classNames("flex items-center p-2 group", {
        "line-through": member.is_suspended,
      })}
      key={member.id}
    >
      {profile?.gravatar_url ? (
        <ImageWithFallback src={profile.gravatar_url} alt={"member profile"} />
      ) : null}

      <span className={"mx-2 text-neutral-600"}>{member.name}</span>

      <div className={"ml-auto group-hover:opacity-100 opacity-0 transition"}>
        <Dropdown
          activator={
            <ButtonWithRef
              size={"small"}
              onClick={() => {}}
              icon={MoreHorizontal}
              role={"secondary"}
            />
          }
          open={open}
          setOpen={setOpen}
        >
          <DropdownListContainer>
            <DropdownItemButtonWithAlertDialog
              disabled={!canSuspend}
              onClick={toggleSuspend}
              title={
                member.is_suspended
                  ? "Are you sure you want to unsuspend this member?"
                  : "Are you sure you want to suspend this member?"
              }
              message={
                member.is_suspended
                  ? "Once unsuspended, this member will be able to access the workspace again."
                  : "Once suspended, this member will not be able to access the workspace."
              }
            >
              <div
                className={classNames("flex items-center space-x-2", {
                  "text-red-500": canSuspend,
                  "text-neutral-400": !canSuspend,
                })}
              >
                <PauseCircle size={14} />
                <span>
                  {member.is_suspended ? "Unsuspend..." : "Suspend..."}
                </span>
              </div>
            </DropdownItemButtonWithAlertDialog>
          </DropdownListContainer>
        </Dropdown>
      </div>
    </div>
  );
}

function InviteItem({
  invite,
  mutate,
}: {
  invite: WorkspaceInvite;
  mutate: () => void;
}) {
  const [open, setOpen] = useState(false);

  const deleteApi = useDeleteAPI();

  const workspaceId = useWorkspaceId();

  const deleteInvite = async () => {
    await withErrorToast(async () => {
      await deleteApi(`/workspaces/${workspaceId}/invites/${invite.id}`);
      toast.success("Invite deleted");
      mutate();
    });
  };

  return (
    <div className={"flex justify-between group"}>
      <span className={"text-sm"}>{invite.email}</span>

      <div className={"group-hover:opacity-100 opacity-0 transition"}>
        <Dropdown
          activator={
            <ButtonWithRef
              size={"small"}
              onClick={() => {}}
              icon={MoreHorizontal}
              role={"secondary"}
            />
          }
          open={open}
          setOpen={setOpen}
        >
          <DropdownItemButtonWithAlertDialog
            onClick={deleteInvite}
            title={"Are you sure you want to delete this invite?"}
            message={"Once deleted, this invite will no longer be valid."}
          >
            <div className={"flex items-center space-x-2 text-red-500"}>
              <Trash2 size={14} />
              <span>Delete...</span>
            </div>
          </DropdownItemButtonWithAlertDialog>
        </Dropdown>
      </div>
    </div>
  );
}

function PendingInvites() {
  const workspaceId = useWorkspaceId();
  const { invites, mutate } = useWorkspaceInvites(workspaceId);
  return (
    <FieldSet
      footer={
        <LearnMoreLink to={"https://anzuhq.com/docs/platform/teams/members"}>
          Workspace Members
        </LearnMoreLink>
      }
      title={"Pending Invites"}
      action={<InviteDialog mutate={mutate} />}
    >
      <div className={"divide-y divide-neutral-200 flex flex-col"}>
        {(invites || []).map((invite) => (
          <InviteItem key={invite.id} invite={invite} mutate={mutate} />
        ))}
        {invites?.length === 0 ? (
          <span className={"select-none"}>No pending invites.</span>
        ) : null}
      </div>
    </FieldSet>
  );
}

function InviteDialog({ mutate }: { mutate: () => void }) {
  const [open, setOpen] = useState(false);

  const postApi = usePostAPI();

  const workspaceId = useWorkspaceId();

  const [email, setEmail] = useState("");
  const [role, setRole] = useState<string | null>(null);

  const reset = () => {
    setEmail("");
    setRole(null);
    setOpen(false);
  };

  const isValid = useMemo(() => {
    if (!role || !email || !email.includes("@")) {
      return false;
    }
    return true;
  }, [role, email]);

  const sendInvite = async () => {
    if (!isValid) {
      return;
    }
    await withErrorToast(async () => {
      await postApi(`/workspaces/${workspaceId}/invites`, { email, role });
      toast.success("Invite sent!");
      mutate();
      reset();
    });
  };

  return (
    <Dialog
      activator={
        <ButtonWithRef size={"medium"} onClick={() => {}} icon={UserPlus}>
          Invite
        </ButtonWithRef>
      }
      open={open}
      setOpen={setOpen}
    >
      <DialogHeader />

      <div className={"grid grid-cols-12"}>
        <div className={"col-span-1"}></div>

        <div className={"col-span-10 p-4 flex flex-col space-y-8"}>
          <div>
            <h2 className={"text-lg font-medium"}>Invite your team</h2>
            <span className={"text-neutral-400"}>
              It&apos;s time to add your team to your workspace!
            </span>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              sendInvite();
            }}
          >
            <TextInput
              autoFocus
              value={email}
              onChange={setEmail}
              label={"Email"}
              labelHorizontal
              type={"email"}
            />
          </form>

          <RoleSelector role={role} setRole={setRole} />

          <Button disabled={!isValid} onClick={sendInvite}>
            Send Invite
          </Button>
        </div>

        <div className={"col-span-1"} />
      </div>
    </Dialog>
  );
}

function RoleSelector({
  setRole,
  role,
}: {
  role: string | null;
  setRole: (role: string | null) => void;
}) {
  const workspaceId = useWorkspaceId();
  const { roles } = useWorkspaceRoles(workspaceId);

  useEffect(() => {
    if (!role && roles?.length) {
      setRole(roles[0].id);
    }
  }, [role, roles, setRole]);

  const [open, setOpen] = useState(false);

  const selectedRole = roles?.find((r) => r.id === role);

  return (
    <Select.Root
      open={open}
      onOpenChange={setOpen}
      value={role || undefined}
      onValueChange={setRole}
    >
      <Select.Trigger
        className={classNames("flex items-center w-full p-2 border group", {
          "border-neutral-200 rounded-md": !open,
          "border-black rounded-t-md": open,
        })}
        aria-label="Role"
      >
        <Select.Value asChild>
          <span
            className={classNames("text-sm", {
              "text-neutral-300": !selectedRole,
              "text-black": selectedRole,
            })}
          >
            {selectedRole ? selectedRole.name : "Select a role..."}
          </span>
        </Select.Value>
        <Select.Icon
          className={classNames("ml-auto transition-colors ", {
            "text-neutral-400 group-hover:text-black": !open,
            "text-black": open,
          })}
        >
          <ChevronDown size={18} />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          className="z-30"
          style={{
            width: "var(--radix-popper-anchor-width)",
          }}
          position={"popper"}
        >
          <Select.ScrollUpButton className="SelectScrollButton">
            <ChevronUp />
          </Select.ScrollUpButton>
          <Select.Viewport className="bg-white rounded-b shadow-lg flex flex-col grow">
            <Select.Group className={"flex flex-col grow divide-y"}>
              {(roles || []).map((r) => (
                <Select.Item
                  key={r.id}
                  className={classNames(
                    "w-full flex items-center justify-between p-2 ",
                    {
                      "bg-neutral-100": r.id === role,
                      "hover:bg-neutral-100": r.id !== role,
                    }
                  )}
                  value={r.id}
                >
                  <Select.ItemText asChild>
                    <span className={"text-sm"}>{r.name}</span>
                  </Select.ItemText>
                  <Select.ItemIndicator className="SelectItemIndicator">
                    <Check size={18} />
                  </Select.ItemIndicator>
                </Select.Item>
              ))}
            </Select.Group>
          </Select.Viewport>
          <Select.ScrollDownButton className="SelectScrollButton">
            <ChevronDown />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}
