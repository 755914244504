import { useMatch } from "react-router-dom";
import {
  toEnvironmentUserManagement,
  toEnvironmentUserManagementSettings,
  toEnvironmentUserManagementUserIdentities,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../routes";
import {
  BreadcrumbItem,
  ContextualNavigationItem,
} from "../../../../navigation";

export const useContextualNavigation = () => {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const overviewPath = toEnvironmentUserManagement(
    workspaceId,

    environmentId
  );
  const userIdentitiesPath = toEnvironmentUserManagementUserIdentities(
    workspaceId,

    environmentId
  );
  const settingsPath = toEnvironmentUserManagementSettings(
    workspaceId,

    environmentId
  );

  const onOverview = useMatch(overviewPath);
  const onUserIdentities = useMatch({ path: userIdentitiesPath, end: false });
  const onSettings = useMatch({
    path: settingsPath,
    // Mark as active even if we are on a sub-page
    end: false,
  });

  const items: ContextualNavigationItem[] = [
    {
      to: overviewPath,
      active: onOverview !== null,
      label: "Overview",
    },
    {
      to: userIdentitiesPath,
      active: onUserIdentities !== null,
      label: "User Identities",
    },
    {
      to: settingsPath,
      active: onSettings !== null,
      label: "Settings",
    },
  ];

  return items;
};

export function useBreadcrumbItems() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const items: BreadcrumbItem[] = [
    {
      to: toEnvironmentUserManagement(workspaceId, environmentId),
      label: "User Management",
    },
  ];

  return items;
}
