import { Navigate } from "react-router-dom";
import {
  toEnvironmentDevelopersWebhooks,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../routes";

export function DevelopersOverview() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  return (
    <Navigate
      replace={true}
      to={toEnvironmentDevelopersWebhooks(workspaceId, environmentId)}
    />
  );
}
