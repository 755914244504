import { Page } from "./page";

export function PageError({
  title,
  message,
}: {
  title: string;
  message: string;
}) {
  return (
    <Page title={title}>
      <p>{message}</p>
    </Page>
  );
}
