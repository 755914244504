import { PropsWithChildren } from "react";
import { CopyButton } from "./basics/button";
import { Link } from "../icons";

export function RedirectUriHint({
  redirectUri,
  children,
}: PropsWithChildren<{ redirectUri: string }>) {
  return (
    <UriHint title={"Redirect URI"} redirectUri={redirectUri}>
      {children}
    </UriHint>
  );
}

export function UriHint({
  redirectUri,
  title,
  children,
}: PropsWithChildren<{ title: string; redirectUri: string }>) {
  return (
    <div
      className={
        "bg-white rounded flex flex-col p-2 space-y-2 border border-neutral-200"
      }
    >
      <div className={"flex items-center space-x-2"}>
        <Link size={24} className={"rounded p-1 bg-neutral-100"} />
        <h3 className={"font-semibold"}>{title}</h3>
      </div>
      <span className={"text-neutral-800"}>{children}</span>

      <div
        className={
          "bg-neutral-100 rounded-md p-1 flex items-center space-x-2 grow"
        }
      >
        <span
          className={"pl-2 font-mono text-sm grow whitespace-nowrap truncate"}
        >
          {redirectUri}
        </span>
        <CopyButton size={"small"} text={redirectUri} />
      </div>
    </div>
  );
}
