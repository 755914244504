import classNames from "classnames";

export function RadioGroup<T extends string>({
  onChange,
  values,
  value,
  readOnly,
  disabled,
  id,
}: {
  value: T;
  values: Array<{ key: T; label: string; description: string }>;
  onChange: (value: T) => void;
  readOnly?: boolean;
  disabled?: boolean;
  id: string;
}) {
  return (
    <div className={"flex flex-col space-y-2"}>
      {values.map((v, idx) => (
        <div className="flex rounded-md">
          <div className="flex items-center h-5">
            <input
              id={`${id}-${idx}`}
              checked={value === v.key}
              aria-describedby="helper-radio-text"
              type="radio"
              value={v.key}
              readOnly={readOnly}
              onChange={() => onChange(v.key)}
              disabled={disabled}
              className={classNames(
                "w-4 h-4",
                "text-blue-600",
                "bg-neutral-100",
                "border-neutral-300",
                "focus:ring-blue-500 focus:ring-2"
              )}
            />
          </div>
          <div className="ml-2 text-sm">
            <label htmlFor={`${id}-${idx}`} className={"flex flex-col"}>
              <span className="font-medium text-neutral-900"> {v.label}</span>
              <p className="text-xs font-normal text-neutral-500 max-w-lg">
                {v.description}
              </p>
            </label>
          </div>
        </div>
      ))}
    </div>
  );
}
