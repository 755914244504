import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { SWRConfig } from "swr";
import { ApiError, FetcherError, localStorageProvider } from "./data";
import { Toaster } from "react-hot-toast";
import { ApiEndpointContextProvider, disableCache } from "./env";
import { AuthProvider, resetSession } from "./components/auth";
import { ErrorCode } from "@anzuhq/backend";
import { RouterProvider } from "react-router-dom";
import { PageContextProvider } from "./app/context";
import { WorkspaceContextProvider } from "./app/[workspaceId]/context";
import { EnvironmentContextProvider } from "./app/[workspaceId]/environments/context";
import { createAppRouter } from "./app/routes";

const router = createAppRouter();

const container = document.getElementById("app")!;
const root = createRoot(container);

root.render(
  <>
    <Toaster position="bottom-right" />

    <ApiEndpointContextProvider>
      <SWRConfig
        value={{
          provider: disableCache ? undefined : localStorageProvider,
          onError: (error, key) => {
            if (!(error instanceof FetcherError)) {
              return;
            }

            if (
              error instanceof ApiError &&
              error.code === ErrorCode.AuthUnauthorized
            ) {
              resetSession();
            }
          },
        }}
      >
        <AuthProvider>
          <PageContextProvider>
            <WorkspaceContextProvider>
              <EnvironmentContextProvider>
                <RouterProvider router={router} />
              </EnvironmentContextProvider>
            </WorkspaceContextProvider>
          </PageContextProvider>
        </AuthProvider>
      </SWRConfig>
    </ApiEndpointContextProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
