import {
  SettingsPageLayout,
  useUserManagementSettingsConfig,
} from "../general/page";
import {
  UserManagementConfig,
  UserManagementConfigResp,
  UserProvisioningMode,
} from "@anzuhq/backend";
import { useState } from "react";
import { Button } from "../../../../../../../components/basics/button";
import { RadioGroup } from "../../../../../../../components/basics/radio";
import { ConfigLoadErrorPage } from "../../../../../../components/settings";
import { FieldSet } from "../../../../../../components/fieldset";
import { LearnMoreLink } from "../../../../../../components/docs";

export function UserManagementSettingsUserProvisioning() {
  const { userManagementConfig, isLoading, saveConfig } =
    useUserManagementSettingsConfig();

  if (isLoading) {
    return <FieldSet footer={<></>} title={""} loading />;
  }

  if (!userManagementConfig) {
    return <ConfigLoadErrorPage />;
  }

  return (
    <SettingsPageLayout>
      <ProvisioningMode config={userManagementConfig} setConfig={saveConfig} />
    </SettingsPageLayout>
  );
}

function ProvisioningMode({
  config,
  setConfig,
}: {
  config: UserManagementConfigResp;
  setConfig: (
    config: Partial<UserManagementConfig>,
    message?: string
  ) => Promise<void>;
}) {
  const [provisioningMode, setUserProvisioningMode] = useState(
    config.config.user_provisioning_mode
  );

  return (
    <FieldSet
      footer={
        <div className={"flex items-center justify-between"}>
          <LearnMoreLink
            to={
              "https://anzuhq.com/docs/blocks/user-management/user-provisioning"
            }
          >
            User Provisioning
          </LearnMoreLink>

          <Button
            onClick={() => {
              setConfig(
                { user_provisioning_mode: provisioningMode },
                "Updated user provisioning mode"
              );
            }}
            size={"small"}
            disabled={provisioningMode === config.config.user_provisioning_mode}
          >
            Save
          </Button>
        </div>
      }
      title={"User Provisioning Mode"}
    >
      <p className={"pb-2"}>
        Depending on how your application handles new users, you should select a
        fitting provisioning mode.
      </p>

      <RadioGroup
        value={provisioningMode}
        values={[
          {
            label: "Wait on Auth Page",
            key: UserProvisioningMode.WaitOnAuthPage,
            description:
              "When a user signs in for the first time, they will be kept on the hosted auth page until provisioning is completed by your application.",
          },
          {
            label: "Redirect Immediately",
            description:
              "When a user signs in for the first time, they will be sent to your application immediately. Your application must gracefully handle non-existing users.",
            key: UserProvisioningMode.RedirectImmediately,
          },
        ]}
        onChange={setUserProvisioningMode}
        id={"user-provisioning-mode"}
      />
    </FieldSet>
  );
}
