import { createContext, PropsWithChildren, useContext } from "react";
import { useLocalStorage } from "./hooks/localStorage";

/**
 * Use injected API endpoint or fall back to env-supplied default
 */
const endpointOverride = (window as any).APP_API_ENDPOINT;

export const apiEndpoint =
  // eslint-disable-next-line no-template-curly-in-string
  typeof endpointOverride === "string" &&
  endpointOverride.length > 0 &&
  // eslint-disable-next-line no-template-curly-in-string
  endpointOverride !== "${APP_API_ENDPOINT}"
    ? endpointOverride
    : process.env.REACT_APP_API_ENDPOINT!;

export const disableCache = process.env.REACT_APP_DISABLE_CACHE === "true";

export const buildCommitSha =
  process.env.REACT_APP_BUILD_COMMIT_SHA || "development";

export const enableCrisp = process.env.REACT_APP_ENABLE_CRISP === "true";

export const ApiEndpointContext = createContext<{
  apiEndpoint: string;
  setApiEndpoint: (apiEndpoint: string) => void;
}>({
  apiEndpoint,
  setApiEndpoint: (apiEndpoint: string | null) => {},
});

export function ApiEndpointContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const [ctxApiEndpoint, setCtxApiEndpoint] = useLocalStorage<string>(
    "anzu:preferences:apiEndpoint",
    apiEndpoint
  );

  return (
    <ApiEndpointContext.Provider
      value={{ apiEndpoint: ctxApiEndpoint, setApiEndpoint: setCtxApiEndpoint }}
    >
      {children}
    </ApiEndpointContext.Provider>
  );
}

export function useApiEndpointContext(): [
  string,
  (apiEndpoint: string) => void
] {
  const { apiEndpoint, setApiEndpoint } = useContext(ApiEndpointContext);
  return [apiEndpoint, setApiEndpoint];
}
