import React, {
  PropsWithChildren,
  forwardRef,
  ButtonHTMLAttributes,
} from "react";
import classNames from "classnames";
import { Check, Clipboard, Icon, Plus, Trash } from "../../icons";
import { Spinner } from "./spinner";
import toast from "react-hot-toast";
import { Link } from "./link";
import { motion } from "framer-motion";

export const RawButtonWithRef = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>((props, ref) => <button ref={ref} {...props} />);

export const ButtonWithRef = forwardRef<any, ButtonProps>((props, ref) => (
  <Button innerRef={ref} {...props} />
));

export const ButtonLinkWithRef = forwardRef<any, ButtonLinkProps>(
  (props, ref) => <ButtonLink innerRef={ref} {...props} />
);

export type ButtonProps = PropsWithChildren<{
  size?: "xs" | "small" | "medium" | "regular";
  role?: "primary" | "secondary" | "tertiary" | "danger" | "ghost";
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => unknown;
  icon?: Icon;
  isLoading?: boolean;
  raised?: boolean;
  grow?: boolean;
  type?: "button" | "submit" | "reset";
  weight?: "regular" | "medium";
}>;

export function Button({
  disabled,
  size = "regular",
  role = "primary",
  onClick,
  children,
  icon: IconComp,
  isLoading,
  raised,
  grow,
  type,
  weight = "medium",
  innerRef,
}: PropsWithChildren<
  ButtonProps & { innerRef?: React.Ref<HTMLButtonElement> }
>) {
  return (
    <button
      ref={innerRef}
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={classNames(
        "flex flex-row items-center justify-center shrink-0 whitespace-nowrap select-none",
        {
          "font-medium": weight === "medium",
          "font-normal": weight === "regular",
        },
        "transition-colors duration-200 ease-in-out",
        {
          "w-full": grow,
        },
        {
          // Sizing and spacing
          "p-1 rounded text-xs space-x-1": size === "xs",
          "px-4 py-1 rounded text-xs space-x-1": size === "small",
          "px-4 py-1 rounded-md text-sm space-x-2": size === "medium",
          "px-4 py-2 rounded-md text-sm space-x-2": size === "regular",

          // Colors
          "bg-black hover:bg-transparent disabled:bg-transparent disabled:cursor-not-allowed border border-black disabled:border-gray-200 text-white hover:text-black disabled:text-gray-800":
            role === "primary",
          "bg-transparent hover:bg-black disabled:bg-transparent disabled:cursor-not-allowed border border-gray-200 hover:border-black disabled:border-gray-200 text-black hover:text-white disabled:text-gray-800":
            role === "secondary",
          "bg-white hover:bg-gray-100 active:bg-gray-200 disabled:cursor-not-allowed text-black":
            role === "tertiary",
          "bg-transparent hover:bg-gray-100 active:bg-gray-200 disabled:cursor-not-allowed text-neutral-600 hover:text-black":
            role === "ghost",
          "bg-red-500 hover:bg-red-600 active:bg-red-700 disabled:bg-red-200 disabled:cursor-not-allowed border border-red-400 hover:border-red-500 disabled:border-red-200 active:border-red-600 text-white":
            role === "danger",
        },
        {
          "shadow-sm": raised && role !== "tertiary",
          "hover:shadow-sm": raised && role === "tertiary",
        }
      )}
    >
      {isLoading ? (
        <Spinner
          size={size}
          color={classNames({
            "text-white": role === "primary" || role === "danger",
            "text-black": role === "secondary" || role === "tertiary",
          })}
        />
      ) : IconComp ? (
        <IconComp
          strokeWidth={weight === "regular" ? 2 : 2.5}
          size={
            size === "xs"
              ? 13
              : size === "small"
              ? 16
              : size === "medium"
              ? 16
              : 18
          }
        />
      ) : null}
      {children ? <span>{children}</span> : null}
    </button>
  );
}

export interface ButtonLinkProps extends Omit<ButtonProps, "onClick"> {
  to: string;
  openInNewTab?: boolean;
  innerRef?: React.Ref<HTMLButtonElement>;
}

export function ButtonLink({
  size = "regular",
  role = "primary",
  to,
  children,
  innerRef,
  openInNewTab,
  ...props
}: PropsWithChildren<ButtonLinkProps>) {
  return (
    <Link to={to} target={openInNewTab ? "_blank" : undefined}>
      <Button
        innerRef={innerRef}
        {...props}
        size={size}
        role={role}
        onClick={() => {}}
      >
        {children}
      </Button>
    </Link>
  );
}

export function CreateButton({
  disabled,
  size = "regular",
  onClick,
  children,
  icon: IconComp,
  isLoading,
  raised,
}: PropsWithChildren<{
  size?: "small" | "medium" | "regular";
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon?: Icon;
  isLoading?: boolean;
  raised?: boolean;
}>) {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      isLoading={isLoading}
      role={"primary"}
      size={size}
      icon={Plus || IconComp}
      raised={raised}
    >
      {children || "Create"}
    </Button>
  );
}

export function CreateButtonLink({
  disabled,
  size = "regular",
  to,
  children,
  icon: IconComp,
  isLoading,
  raised,
}: PropsWithChildren<{
  size?: "small" | "medium" | "regular";
  disabled?: boolean;
  to: string;
  icon?: Icon;
  isLoading?: boolean;
  raised?: boolean;
}>) {
  return (
    <ButtonLink
      to={to}
      disabled={disabled}
      isLoading={isLoading}
      role={"primary"}
      size={size}
      icon={Plus || IconComp}
      raised={raised}
    >
      {children || "Create"}
    </ButtonLink>
  );
}

export function UpdateButton({
  disabled,
  size = "regular",
  onClick,
  children,
  icon: IconComp,
  isLoading,
  raised,
  type,
}: PropsWithChildren<{
  size?: "xs" | "small" | "medium" | "regular";
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon?: Icon;
  isLoading?: boolean;
  raised?: boolean;
  type?: "button" | "submit" | "reset";
}>) {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      isLoading={isLoading}
      role={"primary"}
      size={size}
      icon={IconComp || Check}
      raised={raised}
      type={type}
    >
      {children || "Update"}
    </Button>
  );
}

export function DeleteButton({
  disabled,
  size = "regular",
  onClick,
  children,
  icon: IconComp,
  isLoading,
  raised,
}: PropsWithChildren<{
  size?: "xs" | "small" | "medium" | "regular";
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon?: Icon;
  isLoading?: boolean;
  raised?: boolean;
}>) {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      isLoading={isLoading}
      role={"danger"}
      size={size}
      icon={Trash || IconComp}
      raised={raised}
    >
      {children || "Delete"}
    </Button>
  );
}

export function CopyButton({
  text,
  children,
  ...props
}: PropsWithChildren<{
  text: string;

  size?: "xs" | "small" | "medium" | "regular";
  role?: "primary" | "secondary" | "tertiary" | "danger" | "ghost";
  raised?: boolean;
  grow?: boolean;
}>) {
  return (
    <Button
      onClick={() => {
        navigator.clipboard?.writeText(text);
        toast.success("Copied to clipboard");
      }}
      icon={Clipboard}
      {...props}
      type={"button"}
    >
      {children !== undefined ? children : "Copy"}
    </Button>
  );
}

export function TabButton({
  active,
  to,
  children,
  loading,
}: PropsWithChildren<{ active: boolean; to: string; loading?: boolean }>) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.075 }}
      className={"flex flex-col space-y-1 select-none"}
    >
      <Link
        to={to}
        className={classNames(
          "px-2 py-1 rounded-md whitespace-nowrap",
          "transition duration-200 ease-in-out",
          "text-sm",
          {
            "text-black": active && !loading,
            "text-neutral-600 hover:text-black active:text-black bg-white hover:bg-neutral-100 active:bg-neutral-200":
              !active && !loading,
            "animate-pulse bg-neutral-200 text-neutral-200": loading,
          }
        )}
      >
        {children}
      </Link>

      <div className={"px-2"}>
        <div
          className={classNames(
            "w-full h-[2px] bg-black rounded-full transition duration-200 ease-in-out",
            {
              "opacity-100": active && !loading,
              "opacity-0": !active && !loading,
            }
          )}
        ></div>
      </div>
    </motion.div>
  );
}
