import {
  useCRMContactNotes,
  useCRMNotesBaseSchema,
} from "../../../../../../../../data";
import {
  useContactId,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../../../routes";

import { NotesList } from "../../../notes";

export function CRMContactNotesList() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const contactId = useContactId();
  const { schema } = useCRMNotesBaseSchema(workspaceId, environmentId);
  const { notes, isLoading } = useCRMContactNotes(
    workspaceId,
    environmentId,
    contactId
  );

  if (!schema) {
    return null;
  }

  return <NotesList data={notes || []} isLoading={isLoading} schema={schema} />;
}
