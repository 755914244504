import { Page } from "../../../../../../components/page/page";
import { useBreadcrumbItems, useContextualNavigation } from "../../nav";
import {
  useDeleteAPI,
  useEnvironmentEvent,
  useEnvironmentWebhook,
  useEnvironmentWebhookDeliveries,
  useUpdateWebhook,
  withErrorToast,
} from "../../../../../../../data";
import {
  toEnvironmentDevelopersWebhook,
  toEnvironmentDevelopersWebhooks,
  toEnvironmentDevelopersWebhookSettings,
  useEnvironmentId,
  useWebhookId,
  useWorkspaceId,
} from "../../../../../../routes";
import { Outlet, useNavigate } from "react-router-dom";
import classNames from "classnames";
import {
  Button,
  ButtonWithRef,
} from "../../../../../../../components/basics/button";
import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CheckCircle,
  Clock,
  CornerUpRight,
  PauseCircle,
  PlayCircle,
  XCircle,
} from "../../../../../../../icons";
import {
  WebhookDeliveryStatus,
  EventKind,
  WebhookDelivery,
} from "@anzuhq/backend";
import { DateTimeRendererWithFormats } from "../../../../../../database/datetime";
import { RenderedCode } from "../create/code/renderer";
import { navigationHeight } from "../../../../../../navigation";

import { TextInput } from "../../../../../../../components/basics/input";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import toast from "react-hot-toast";
import { SelectEvents } from "../create/page";
import { httpStatus } from "../../../../../../../httpstatus";
import {
  PageHeaderTitle,
  TabbedHeader,
} from "../../../../../../components/header";
import { LearnMoreLink } from "../../../../../../components/docs";
import { FieldSet } from "../../../../../../components/fieldset";

export const headerHeight = "129px";

function LoadingDelivery() {
  return <div className={"h-12 w-full animate-pulse bg-neutral-200 rounded"} />;
}

export function LoadingTitle({
  isLoading,
  children,
}: PropsWithChildren<{ isLoading?: boolean }>) {
  if (isLoading) {
    return (
      <div
        className={
          "w-32 h-12 animate-pulse transition duration-200 ease-in-out bg-neutral-200 rounded"
        }
      />
    );
  }
  return (
    <div
      className={classNames({
        "animate-pulse bg-neutral-100 rounded": isLoading,
      })}
    >
      <PageHeaderTitle>{children}</PageHeaderTitle>
    </div>
  );
}

export function WebhookPage() {
  const navItems = useContextualNavigation();
  const breadcrumbItems = useBreadcrumbItems();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const webhookId = useWebhookId();

  const { webhook, isLoading } = useEnvironmentWebhook(
    workspaceId,
    environmentId,
    webhookId
  );

  const { mutate: mutateDeliveries } = useEnvironmentWebhookDeliveries(
    workspaceId,
    environmentId,
    webhookId
  );

  const subviews = useMemo(() => {
    return [
      {
        label: "Deliveries",
        path: toEnvironmentDevelopersWebhook(
          workspaceId,
          environmentId,
          webhookId
        ),
      },
      {
        label: "Settings",
        path: toEnvironmentDevelopersWebhookSettings(
          workspaceId,
          environmentId,
          webhookId
        ),
      },
    ];
  }, [workspaceId, environmentId, webhookId]);

  const { updateWebhook, isUpdatingWebhook } = useUpdateWebhook(
    workspaceId,
    environmentId,
    webhookId
  );

  const toggleWebhookEnabled = async () => {
    if (!webhook) {
      return;
    }
    await updateWebhook({
      message: webhook.is_enabled ? "Disabled webhook" : "Enabled webhook",
      data: { is_enabled: !webhook.is_enabled },
    });
    mutateDeliveries();
  };

  const isSubmitting = isUpdatingWebhook;

  return (
    <Page
      title={"Webhook"}
      contextualNavigationItems={navItems}
      breadcrumbItems={breadcrumbItems}
    >
      <div className={"flex flex-col h-full"}>
        <TabbedHeader tabs={subviews}>
          <LoadingTitle isLoading={isLoading}>
            {webhook?.name || "Loading"}
          </LoadingTitle>

          {webhook ? (
            <div
              className={classNames(
                "ml-4 p-1 text-xs font-medium  rounded-full flex items-center space-x-1",
                {
                  "bg-amber-100": !webhook.is_enabled,
                  "bg-green-100": webhook.is_enabled,
                }
              )}
            >
              {webhook.is_enabled ? (
                <PlayCircle className={"stroke-green-800"} size={16} />
              ) : (
                <PauseCircle className={"stroke-amber-800"} size={16} />
              )}
              <span
                className={classNames("pr-1", {
                  "text-amber-800": !webhook.is_enabled,
                  "text-green-800": webhook.is_enabled,
                })}
              >
                {webhook.is_enabled ? "Active" : "Paused"}
              </span>
            </div>
          ) : null}

          {webhook ? (
            <div className="ml-auto flex space-x-2">
              <Button
                disabled={isSubmitting}
                isLoading={isSubmitting}
                icon={webhook?.is_enabled ? PauseCircle : PlayCircle}
                onClick={toggleWebhookEnabled}
                role={"secondary"}
              >
                {webhook?.is_enabled ? "Disable" : "Enable"}
              </Button>
            </div>
          ) : null}
        </TabbedHeader>

        <Outlet />
      </div>
    </Page>
  );
}

function LongTextView({
  children,
  noCache,
}: PropsWithChildren<{ noCache?: boolean }>) {
  const isJson = useMemo(() => {
    try {
      const parsed = JSON.parse(children as string);
      return JSON.stringify(parsed, null, 2);
    } catch {
      return null;
    }
  }, [children]);

  return (
    <div className={"text-sm p-2 rounded bg-neutral-50 "}>
      {isJson ? (
        <>
          {isJson === "{}" ? (
            <p className={"italic text-neutral-500"}>Empty</p>
          ) : (
            <RenderedCode noCache={noCache} code={isJson} language={"json"} />
          )}
        </>
      ) : (
        <pre className={"break-words whitespace-pre-wrap"}>{children}</pre>
      )}
    </div>
  );
}

function RenderDeliveryStatusPill({
  status,
}: {
  status: WebhookDeliveryStatus;
}) {
  let bgColor;
  let strokeColor;
  let textColor;
  let Icon;
  let text;
  switch (status) {
    case WebhookDeliveryStatus.Failure:
    case WebhookDeliveryStatus.Timeout:
      bgColor = "bg-red-100";
      strokeColor = "stroke-red-800";
      textColor = "text-red-800";
      Icon = XCircle;
      text = "Failed";
      break;
    case WebhookDeliveryStatus.Success:
      bgColor = "bg-green-100";
      strokeColor = "stroke-green-800";
      textColor = "text-green-800";
      text = "Success";
      Icon = CheckCircle;
      break;
    case WebhookDeliveryStatus.Pending:
      bgColor = "bg-amber-100";
      strokeColor = "stroke-amber-800";
      textColor = "text-amber-800";
      text = "Pending";
      Icon = Clock;
      break;
    case WebhookDeliveryStatus.Forwarded:
      bgColor = "bg-blue-100";
      strokeColor = "stroke-blue-800";
      textColor = "text-blue-800";
      Icon = CornerUpRight;
      text = "Forwarded";
      break;
  }

  return (
    <div
      className={classNames(
        "p-1 text-xs font-medium  rounded-full flex items-center space-x-1",
        bgColor
      )}
    >
      <Icon className={strokeColor} size={16} />
      <span className={classNames("pr-1", textColor)}>{text}</span>
    </div>
  );
}

export function WebhookDeliveriesInboxPage() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const webhookId = useWebhookId();

  const { webhookDeliveries, isLoading } = useEnvironmentWebhookDeliveries(
    workspaceId,
    environmentId,
    webhookId
  );
  const [selectedId, setSelectedId] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedId && webhookDeliveries && webhookDeliveries.length > 0) {
      setSelectedId(webhookDeliveries[0].id);
    }
  }, [selectedId, webhookDeliveries]);

  const selected = useMemo(() => {
    return webhookDeliveries?.find((d) => d.id === selectedId);
  }, [webhookDeliveries, selectedId]);

  return (
    <div className={"grid grid-cols-12 h-full"}>
      <div className={"hidden md:block col-span-1"} />
      <div className={"col-span-10 grid grid-cols-1 lg:grid-cols-2 h-full"}>
        <div
          className={
            "col-span-1 border-r border-neutral-200 h-full overflow-hidden"
          }
          style={{
            height: `calc(100vh - ${navigationHeight} - ${headerHeight})`,
          }}
        >
          <div className={"flex flex-col space-y-1 p-2 h-full overflow-auto"}>
            {isLoading ? (
              <>
                {Array.from({ length: 4 }).map((_, i) => (
                  <LoadingDelivery key={i} />
                ))}
              </>
            ) : (
              <>
                {webhookDeliveries && webhookDeliveries.length > 0 ? (
                  <>
                    {webhookDeliveries.map((d) => (
                      <DeliveryListItem
                        isSelected={d.id === selectedId}
                        delivery={d}
                        key={d.id}
                        onClick={() => setSelectedId(d.id)}
                      />
                    ))}
                  </>
                ) : (
                  <div className={"flex items-center justify-center h-full"}>
                    <p className={"text-neutral-500 text-sm"}>
                      No deliveries found.
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div
          className={"col-span-1 overflow-hidden w-full"}
          style={{
            height: `calc(100vh - ${navigationHeight} - ${headerHeight})`,
          }}
        >
          {selected ? (
            <div
              className={
                "p-4 flex flex-col space-y-4 overflow-auto h-full w-full"
              }
            >
              {selected.response_body || selected.response_headers ? (
                <div className={"flex flex-col space-y-2"}>
                  <h4 className={"font-medium"}>Response</h4>

                  {selected.response_headers ? (
                    <>
                      {selected.response_status_code ? (
                        <div
                          className={classNames(
                            "max-w-max py-1 px-4 text-xs font-medium rounded-full flex items-center",
                            {
                              "bg-green-100":
                                selected.response_status_code >= 200 &&
                                selected.response_status_code < 300,
                              "bg-amber-100":
                                selected.response_status_code >= 300 &&
                                selected.response_status_code < 400,
                              "bg-red-100":
                                selected.response_status_code >= 400 &&
                                selected.response_status_code < 500,
                            }
                          )}
                        >
                          <span
                            className={classNames({
                              "text-green-800":
                                selected.response_status_code >= 200 &&
                                selected.response_status_code < 300,
                              "text-amber-800":
                                selected.response_status_code >= 300 &&
                                selected.response_status_code < 400,
                              "text-red-800":
                                selected.response_status_code >= 400 &&
                                selected.response_status_code < 500,
                            })}
                          >
                            {selected.response_status_code}{" "}
                            {httpStatus[selected.response_status_code]}
                          </span>
                        </div>
                      ) : null}

                      <span className={"text-sm font-normal text-neutral-600"}>
                        Response Headers
                      </span>
                      <LongTextView noCache>
                        {JSON.stringify(
                          selected.response_headers,
                          undefined,
                          2
                        )}
                      </LongTextView>
                    </>
                  ) : null}

                  {selected.response_body ? (
                    <>
                      <span className={"text-sm font-normal text-neutral-600"}>
                        Response Body
                      </span>
                      <LongTextView noCache>
                        {selected.response_body}
                      </LongTextView>
                    </>
                  ) : null}
                </div>
              ) : null}

              {selected.request_body || selected.request_headers ? (
                <div className={"flex flex-col space-y-2"}>
                  <h4 className={"font-medium"}>Request</h4>

                  {selected.request_headers ? (
                    <>
                      <span className={"text-sm font-normal text-neutral-600"}>
                        Request Headers
                      </span>
                      <LongTextView noCache>
                        {JSON.stringify(selected.request_headers, undefined, 2)}
                      </LongTextView>
                    </>
                  ) : null}

                  {selected.request_body ? (
                    <>
                      <span className={"text-sm font-normal text-neutral-600"}>
                        Request Body
                      </span>
                      <LongTextView noCache>
                        {selected.request_body}
                      </LongTextView>
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : (
            <div className={"flex items-center justify-center h-full"}>
              <p className={"text-neutral-500 text-sm"}>
                No delivery selected.
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={"hidden md:block col-span-1"} />
    </div>
  );
}

export function WebhookSettingsPage() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const webhookId = useWebhookId();
  const { webhook, isLoading: isLoadingWebhook } = useEnvironmentWebhook(
    workspaceId,
    environmentId,
    webhookId
  );
  const deleteAPI = useDeleteAPI();
  const navigate = useNavigate();

  const { updateWebhook, isUpdatingWebhook } = useUpdateWebhook(
    workspaceId,
    environmentId,
    webhookId
  );

  const [firstLoad, setFirstLoad] = useState(true);

  const [endpoint, setEndpoint] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [eventKinds, setEventKinds] = useState<EventKind[]>([]);

  useEffect(() => {
    if (firstLoad && webhook) {
      setEndpoint(webhook.endpoint);
      setName(webhook.name);
      setEventKinds(webhook.event_kinds);

      setFirstLoad(false);
    }
  }, [firstLoad, webhook]);

  const deleteWebhook = async () => {
    await withErrorToast(async () => {
      await deleteAPI(
        `/workspaces/${workspaceId}/environments/${environmentId}/webhooks/${webhookId}`
      );
      navigate(toEnvironmentDevelopersWebhooks(workspaceId, environmentId));
      toast.success("Webhook deleted");
    });
  };

  const isLoading = isLoadingWebhook || isUpdatingWebhook;
  return (
    <div className={"grid grid-cols-12 py-8 "}>
      <div className={"col-span-1"} />
      <div className={"col-span-3"} />

      <div className={"col-span-7 p-2 flex flex-col space-y-8"}>
        <FieldSet
          loading={isLoading}
          footer={
            <div className={"flex items-center justify-between"}>
              <LearnMoreLink to={"https://anzuhq.com/docs/platform/webhooks"}>
                Webhooks
              </LearnMoreLink>

              <Button
                onClick={() => {
                  if (!endpoint) {
                    return;
                  }
                  updateWebhook({
                    message: "Updated webhook endpoint",
                    data: {
                      endpoint,
                    },
                  });
                }}
                size={"small"}
                disabled={isLoading || webhook?.endpoint === endpoint}
                isLoading={isUpdatingWebhook}
              >
                Save
              </Button>
            </div>
          }
          title={"Endpoint"}
        >
          <p>Configure the endpoint webhook requests are sent against.</p>

          <TextInput value={endpoint || ""} onChange={setEndpoint} />
        </FieldSet>

        <FieldSet
          loading={isLoading}
          footer={
            <div className={"flex items-center justify-between"}>
              <LearnMoreLink to={"https://anzuhq.com/docs/platform/webhooks"}>
                Webhooks
              </LearnMoreLink>

              <Button
                onClick={() => {
                  if (!endpoint) {
                    return;
                  }
                  updateWebhook({
                    message: "Updated selected events",
                    data: {
                      event_kinds: eventKinds,
                    },
                  });
                }}
                size={"small"}
                disabled={
                  isLoading ||
                  (webhook
                    ? JSON.stringify(webhook.event_kinds) ===
                      JSON.stringify(eventKinds)
                    : false)
                }
                isLoading={isUpdatingWebhook}
              >
                Save
              </Button>
            </div>
          }
          title={"Selected Events"}
        >
          <SelectEvents
            selectedEvents={eventKinds}
            setSelectedEvents={setEventKinds}
          />
        </FieldSet>

        <FieldSet
          loading={isLoading}
          footer={
            <div className={"flex items-center justify-between"}>
              <LearnMoreLink to={"https://anzuhq.com/docs/platform/webhooks"}>
                Webhooks
              </LearnMoreLink>

              <Button
                onClick={() => {
                  if (!endpoint) {
                    return;
                  }
                  updateWebhook({
                    message: "Updated webhook name",
                    data: {
                      name,
                    },
                  });
                }}
                size={"small"}
                disabled={isLoading || webhook?.name === name}
                isLoading={isUpdatingWebhook}
              >
                Save
              </Button>
            </div>
          }
          title={"Name"}
        >
          <TextInput value={name || ""} onChange={setName} />
        </FieldSet>

        <FieldSet
          loading={isLoading}
          footer={
            <div className={"flex items-center justify-between"}>
              <LearnMoreLink to={"https://anzuhq.com/docs/platform/webhooks"}>
                Webhooks
              </LearnMoreLink>
            </div>
          }
          title={"Secret"}
        >
          <p>Use this secret to verify incoming webhook requests.</p>

          <TextInput
            readOnly
            isSecret
            value={webhook?.secret || "***"}
            onChange={() => {}}
          />
        </FieldSet>

        <div className={"rounded-md border border-red-200"}>
          <div className={"p-8 text-sm flex flex-col space-y-2"}>
            <div className={"flex items-center"}>
              <h2 className={"pb-2 text-xl font-medium"}>Delete Webhook</h2>
            </div>
            <span>
              You can delete this webhook at any time. This action cannot be
              undone.
            </span>
          </div>

          <div
            className={
              "bg-red-50 rounded-b-md border-t border-red-200 px-6 py-4 text-sm text-red-800 flex justify-end"
            }
          >
            <DeleteWebhookAlert onClick={() => deleteWebhook()} />
          </div>
        </div>
      </div>
      <div className={"col-span-1"} />
    </div>
  );
}

function DeliveryListItem({
  delivery,
  isSelected,
  onClick,
}: {
  delivery: WebhookDelivery;
  onClick: () => void;
  isSelected: boolean;
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { event } = useEnvironmentEvent(
    workspaceId,
    environmentId,
    delivery.event
  );
  if (!event) {
    return <LoadingDelivery />;
  }

  return (
    <button
      onClick={onClick}
      className={classNames(
        "py-2 px-4 flex items-center justify-between rounded",
        "transition duration-200 ease-in-out",
        {
          "bg-neutral-100": isSelected,
          "hover:bg-neutral-100 active:bg-neutral-200": !isSelected,
        }
      )}
    >
      <div className={"flex items-center space-x-2"}>
        <RenderDeliveryStatusPill status={delivery.status} />
        <span className={"text-neutral-600 text-sm font-mono"}>
          {event.kind}
        </span>
      </div>

      <div className={"text-neutral-500"}>
        <DateTimeRendererWithFormats value={delivery.created_at} />
      </div>
    </button>
  );
}

function DeleteWebhookAlert({ onClick }: { onClick: () => void }) {
  const [isOpen, setIsOpen] = useState(false);

  const dialogRef = useRef<HTMLDivElement>(null);

  return (
    <AlertDialog.Root
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
      }}
    >
      <AlertDialog.Trigger asChild>
        <ButtonWithRef
          role={"danger"}
          size={"small"}
          onClick={(e) => {
            setIsOpen(true);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          Delete
        </ButtonWithRef>
      </AlertDialog.Trigger>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="z-30" />
        <AlertDialog.Content asChild>
          <div
            className="z-30 fixed top-0 left-0 w-screen h-screen grid place-items-center bg-neutral-900/50"
            onClick={(e) => {
              // If clicked outside of immediate children, close
              if (!dialogRef.current?.contains(e.target as Node)) {
                setIsOpen(false);
              }
            }}
          >
            <div
              ref={dialogRef}
              className={
                "shadow-lg bg-white rounded-md border border-neutral-200 whitespace-nowrap select-none"
              }
            >
              <div className={"p-8 flex flex-col space-y-4"}>
                <AlertDialog.Title className="text-lg font-medium">
                  Are you sure?
                </AlertDialog.Title>
                <AlertDialog.Description className="text-sm">
                  Deleting a webhook is permanent and cannot be undone.
                </AlertDialog.Description>
              </div>
              <div className={"flex items-center justify-end space-x-4 p-4"}>
                <AlertDialog.Cancel asChild>
                  <ButtonWithRef
                    size={"small"}
                    role={"secondary"}
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </ButtonWithRef>
                </AlertDialog.Cancel>
                <AlertDialog.Action asChild>
                  <ButtonWithRef
                    size={"small"}
                    role={"danger"}
                    onClick={onClick}
                  >
                    Delete Webhook
                  </ButtonWithRef>
                </AlertDialog.Action>
              </div>
            </div>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
