import React, {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import * as Popover from "@radix-ui/react-popover";
import { Link } from "react-router-dom";
import { useHotkeys } from "../../hooks/keypress";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { ButtonWithRef } from "../../components/basics/button";

function DropdownContainer({
  children,
  noSizing,
}: PropsWithChildren<{ noSizing?: boolean }>) {
  return (
    <div
      className={classNames(
        "flex flex-col space-y-1 backdrop-blur-xl bg-white/60 rounded-md border border-neutral-200 text-sm font-light shadow-xl select-none",
        {
          "w-64 max-h-64": !noSizing,
        }
      )}
    >
      {children}
    </div>
  );
}

export function DropdownListContainer({ children }: PropsWithChildren) {
  return (
    <div
      className={"flex h-full w-full overflow-hidden"}
      style={{
        maxHeight: "calc(256px - 38px)",
      }}
    >
      <div className={"flex flex-col space-y-2 p-2 overflow-auto w-full"}>
        {children}
      </div>
    </div>
  );
}

export function Dropdown({
  open,
  setOpen,
  children,
  activator,
  noSizing,
  collisionPadding,
  side,
}: PropsWithChildren<{
  activator: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;

  noSizing?: boolean;
  collisionPadding?: number;

  side?: "top" | "right" | "bottom" | "left";
}>) {
  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>{activator}</Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side={side}
          asChild
          sideOffset={-20}
          collisionPadding={collisionPadding}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.075, ease: "easeInOut" }}
            className={"z-40"}
          >
            <DropdownContainer noSizing={noSizing}>
              {children}
            </DropdownContainer>
          </motion.div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export function useContainerDimensions(myRef: React.RefObject<any>) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: (myRef && myRef.current.offsetWidth) || 0,
      height: (myRef && myRef.current.offsetHeight) || 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
}

export function FullWidthDropdown({
  open,
  setOpen,
  children,
  anchor,
  collisionPadding,
}: PropsWithChildren<{
  anchor: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;

  noSizing?: boolean;
  collisionPadding?: number;
}>) {
  const anchorRef = useRef(null);
  const dimensions = useContainerDimensions(anchorRef);

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Anchor ref={anchorRef} asChild>
        {anchor}
      </Popover.Anchor>
      <Popover.Portal>
        <Popover.Content
          asChild
          sideOffset={6}
          collisionPadding={collisionPadding}
          avoidCollisions={false}
          align={"start"}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.075, ease: "easeInOut" }}
            className={classNames("z-20")}
          >
            <div
              className={classNames(
                "flex flex-col space-y-1 backdrop-blur-xl bg-white/60 rounded-md border border-neutral-200 text-sm font-light shadow-xl select-none w-full max-h-72"
              )}
              style={{ width: dimensions.width }}
            >
              {children}
            </div>
          </motion.div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export function DropdownTextInput({
  value,
  setValue,
  placeholder,
  type,
}: {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  type?: string;
}) {
  return (
    <input
      autoFocus
      placeholder={placeholder}
      className={
        "rounded-t-md border-b border-neutral-200 px-4 py-2 focus:outline-none bg-transparent"
      }
      value={value}
      onChange={(e) => setValue(e.currentTarget.value)}
      type={type}
    />
  );
}

export function DropdownItemButton({
  children,
  onClick,
  active,
}: PropsWithChildren<{ onClick: () => void; active?: boolean }>) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        "p-2 text-left hover:bg-neutral-100 active:bg-neutral-200 w-full rounded-md transition duration-150 flex items-center space-x-4",
        {
          "bg-neutral-100": active,
        }
      )}
    >
      {children}
    </button>
  );
}

export const DropdownItemButtonWithRef = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<{ onClick: () => void; disabled?: boolean }>
>(({ children, onClick, disabled }, ref) => {
  return (
    <button
      ref={ref}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        "p-2 text-left w-full rounded-md transition duration-150 flex items-center grow space-x-4",
        {
          "hover:bg-neutral-100/60 active:bg-neutral-200/60": !disabled,
          "bg-neutral-100/60 cursor-not-allowed": disabled,
        }
      )}
    >
      {children}
    </button>
  );
});

export function DropdownItemLink({
  children,
  to,
  onClick,
  close,
}: PropsWithChildren<{
  to: string;
  onClick?: () => void;
  close?: () => void;
}>) {
  return (
    <Link
      to={to}
      onClick={(e) => {
        if (close) {
          close();
        }
        if (onClick) {
          onClick();
        }
      }}
      className={
        "p-2 text-left hover:bg-neutral-100 active:bg-neutral-200 w-full rounded-md transition duration-150 flex items-center grow space-x-4"
      }
    >
      {children}
    </Link>
  );
}

export function DropdownConfirmButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      onClick={onClick}
      className={
        "p-2 text-center bg-neutral-50 hover:bg-neutral-100 active:bg-neutral-200 w-full rounded-md transition duration-150 space-x-4 w-full"
      }
    >
      Confirm
    </button>
  );
}

export function DropdownItemButtonWithAlertDialog({
  children,
  onClick,
  confirmLabel,
  message,
  title,
  onOpenChange,
  disabled,
}: PropsWithChildren<{
  onClick: () => void;
  title: string;
  message: string;
  confirmLabel?: string;
  onOpenChange?: (isOpen: boolean) => void;
  disabled?: boolean;
}>) {
  const [isOpen, setIsOpen] = useState(false);

  const dialogRef = useRef<HTMLDivElement>(null);

  useHotkeys(
    "esc",
    () => {
      setIsOpen(false);
      onOpenChange?.(false);
    },
    [isOpen],
    isOpen
  );

  return (
    <AlertDialog.Root
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
        onOpenChange?.(open);
      }}
    >
      <AlertDialog.Trigger asChild>
        <DropdownItemButtonWithRef
          disabled={disabled}
          onClick={() => {
            setIsOpen(true);
            onOpenChange?.(true);
          }}
        >
          {children}
        </DropdownItemButtonWithRef>
      </AlertDialog.Trigger>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="z-30" />
        <AlertDialog.Content asChild>
          <div
            className="z-40 fixed top-0 left-0 w-screen h-screen grid place-items-center bg-gradient-to-tr from-red-50/10 to-red-200/10"
            onClick={(e) => {
              // If clicked outside of immediate children, close
              if (!dialogRef.current?.contains(e.target as Node)) {
                setIsOpen(false);
              }
            }}
          >
            <div
              ref={dialogRef}
              className={
                "shadow-lg bg-white/60 backdrop-blur-xl rounded-md border border-neutral-200 whitespace-nowrap select-none"
              }
            >
              <div className={"p-8"}>
                <AlertDialog.Title className="text-lg font-medium">
                  {title}
                </AlertDialog.Title>
                <AlertDialog.Description className="text-sm">
                  {message}
                </AlertDialog.Description>
              </div>
              <div className={"flex items-center justify-end space-x-4 p-4"}>
                <AlertDialog.Cancel asChild>
                  <ButtonWithRef
                    size={"small"}
                    role={"primary"}
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </ButtonWithRef>
                </AlertDialog.Cancel>
                <AlertDialog.Action asChild>
                  <ButtonWithRef
                    size={"small"}
                    role={"danger"}
                    onClick={onClick}
                  >
                    {confirmLabel || "Confirm"}
                  </ButtonWithRef>
                </AlertDialog.Action>
              </div>
            </div>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
