import { useMemo } from "react";
import { useInsightsSettingsConfig } from "./script/page";

export function useInsightsSettingsSections() {
  const { insightsConfig } = useInsightsSettingsConfig();
  return useMemo(() => {
    return [
      {
        name: "Script",
        id: "script",
      },
      {
        name: "Allowed Origins",
        id: "allowed-origins",
        warning:
          insightsConfig?.config.allowed_origins.length === 0
            ? "No allowed origins configured"
            : undefined,
      },
    ];
  }, [insightsConfig]);
}
