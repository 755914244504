import {
  useCRMContact,
  useCRMContactSchema,
  useEnvironmentEventsForResource,
  useEnvironmentInsightsEventsCountTimeline,
  useEnvironmentInsightsUsageDuration,
} from "../../../../../../../../data";
import {
  toEnvironmentInsightsEvents,
  useContactId,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../../../routes";
import {
  AllEvents,
  CRMCommentKind,
  EventResourceKind,
  ICRMContact,
  InsightsRelativeTimeRange,
} from "@anzuhq/backend";
import { CountHeatmap } from "./heatmap";
import { ParentSize } from "@visx/responsive";
import { ButtonLink } from "../../../../../../../../components/basics/button";
import { ArrowRight } from "../../../../../../../../icons";
import { formatActivityDuration } from "../../../../insights/overview/page";
import { ActivitySection } from "../../../activity";
import { CommentSection } from "../../../comment";

export function CRMContactOverviewPage() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const contactId = useContactId();
  const { contact } = useCRMContact(workspaceId, environmentId, contactId);

  const { schema } = useCRMContactSchema(workspaceId, environmentId);

  // TODO render loading indicator
  if (!schema || !contact) {
    return <div className={"animate-pulse"}>loading...</div>;
  }

  return (
    <div className={"grid grid-cols-12 grow"}>
      <div className={"col-span-1"} />
      <div className={"col-span-10"}>
        <div className={"p-2 grid grid-cols-12 gap-8"}>
          <div className={"col-span-7"}>
            <ActivityHeatmap contact={contact} />
          </div>
          <div className={"col-span-5"}>
            <SmartInsights contact={contact} />
          </div>
        </div>

        <CRMActivitySection contact={contact} />
      </div>
      <div className={"col-span-1"} />
    </div>
  );
}

function ActivityHeatmap({ contact }: { contact: ICRMContact }) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { buckets } = useEnvironmentInsightsEventsCountTimeline(
    workspaceId,
    environmentId,
    { relative: InsightsRelativeTimeRange.Last90Days },
    contact && contact.user_identity
      ? [
          {
            field: "user_identity",
            value: contact.user_identity,
            operator: "eq",
          },
        ]
      : [],
    true
  );

  return (
    <div className={"flex flex-col space-y-1 bg-neutral-50 rounded-md p-4"}>
      <div className={"flex items-start"}>
        <div className={"flex flex-col"}>
          <h2 className={"text-neutral-700"}>Recent Activity</h2>

          <span className={"text-neutral-400 text-xs"}>
            Insights events for the last 90 days.
          </span>
        </div>

        <div className={"ml-auto"}>
          <ButtonLink
            role={"secondary"}
            size={"small"}
            icon={ArrowRight}
            to={toEnvironmentInsightsEvents(workspaceId, environmentId)}
          >
            All Events
          </ButtonLink>
        </div>
      </div>

      <div className={"h-48"}>
        {contact.user_identity ? (
          <ParentSize>
            {({ width, height }) => (
              <CountHeatmap
                width={width}
                height={height}
                data={buckets || []}
              />
            )}
          </ParentSize>
        ) : (
          <div className={"flex items-center justify-center h-full"}>
            <p className={"text-sm text-neutral-500"}>
              No user identity linked.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

function SmartInsights({ contact }: { contact: ICRMContact }) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { usageDuration, isLoading } = useEnvironmentInsightsUsageDuration(
    workspaceId,
    environmentId,
    { relative: InsightsRelativeTimeRange.Last90Days },
    contact.user_identity
  );

  if (isLoading || !usageDuration) {
    return (
      <div
        className={
          "flex flex-col items-center justify-center h-full bg-neutral-50 rounded-md p-4"
        }
      >
        <span
          className={
            "mt-auto text-2xl tracking-tight font-medium text-neutral-800"
          }
        >
          N/A
        </span>

        <span className={"text-xs text-neutral-400 mt-auto"}>
          Usage in the last 90 days
        </span>
      </div>
    );
  }

  return (
    <div
      className={
        "flex flex-col items-center justify-center h-full bg-neutral-50 rounded-md p-4"
      }
    >
      <span
        className={
          "mt-auto text-2xl tracking-tight font-medium text-neutral-800"
        }
      >
        {formatActivityDuration(usageDuration.sumSessionDuration)}
      </span>

      <span className={"text-xs text-neutral-400 mt-auto"}>
        Usage in the last 90 days
      </span>
    </div>
  );
}

function CRMActivitySection({ contact }: { contact: ICRMContact }) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { events, mutate: mutateActivity } = useEnvironmentEventsForResource(
    workspaceId,
    environmentId,
    EventResourceKind.CRMContact,
    contact.id
  );

  if (!events) {
    return <p>loading</p>;
  }

  return (
    <div className={"p-2"}>
      <div className={"border-t border-neutral-100"} />
      <CommentSection
        mutateActivity={mutateActivity}
        kind={CRMCommentKind.Contact}
        parentId={contact.id}
      />
      <ActivitySection events={events.map((e) => e.data as AllEvents)} />
    </div>
  );
}
