import { useMatch } from "react-router-dom";
import {
  toEnvironmentInsights,
  toEnvironmentInsightsEvents,
  // toEnvironmentInsightsExplore,
  toEnvironmentInsightsOverview,
  toEnvironmentInsightsSettings,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../routes";
import {
  BreadcrumbItem,
  ContextualNavigationItem,
} from "../../../../navigation";

export const useContextualNavigation = () => {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const overview = toEnvironmentInsightsOverview(workspaceId, environmentId);
  const events = toEnvironmentInsightsEvents(workspaceId, environmentId);
  // const explore = toEnvironmentInsightsExplore(workspaceId, environmentId);
  const settings = toEnvironmentInsightsSettings(workspaceId, environmentId);

  // const onExplore = useMatch(explore);
  const onOverview = useMatch(overview);
  const onEvents = useMatch(events);
  const onSettings = useMatch({
    end: false,
    path: settings,
  });

  const items: ContextualNavigationItem[] = [
    // {
    //   to: explore,
    //   active: onExplore !== null,
    //   label: "Explore",
    // },
    {
      to: overview,
      active: onOverview !== null,
      label: "Overview",
    },
    {
      to: events,
      active: onEvents !== null,
      label: "Events",
    },
    {
      to: settings,
      active: onSettings !== null,
      label: "Settings",
    },
  ];

  return items;
};

export function useBreadcrumbItems() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const items: BreadcrumbItem[] = [
    {
      to: toEnvironmentInsights(workspaceId, environmentId),
      label: "Insights",
    },
  ];

  return items;
}
