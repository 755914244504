import { PropsWithChildren, useState } from "react";
import * as Tooltip from "@radix-ui/react-tooltip";

export function WithTooltip({
  children,
  text,
  disabled,
  side,
}: PropsWithChildren<{
  text: string;
  disabled?: boolean;
  side?: "top" | "right" | "left" | "bottom";
}>) {
  const [open, setOpen] = useState(false);
  return (
    <Tooltip.Provider>
      <Tooltip.Root open={disabled ? false : open} onOpenChange={setOpen}>
        <Tooltip.Trigger asChild>
          <button className="appearance-none">{children}</button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side={side}
            className="rounded p-2 bg-white/60 backdrop-blur-2xl shadow-md select-none z-30"
            sideOffset={5}
          >
            <Tooltip.Arrow className="fill-white" />
            <span className={"text-sm"}>{text}</span>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
