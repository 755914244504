import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getApi, postApi, withErrorToast } from "../data";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useHotkeys } from "../hooks/keypress";
import { TextInput } from "./basics/input";
import { Button } from "./basics/button";
import { toLoginPage } from "../app/routes";
import { Spinner } from "./basics/spinner";
import { motion } from "framer-motion";
import { useApiEndpointContext } from "../env";

export interface User {
  token: string;
  accountId: string;
}

interface AuthContextType {
  user: User | null | undefined;
  signIn: (user: User, callback: VoidFunction) => void;
  signOut: (callback?: VoidFunction) => void;
}

const localStorageKey = "anzu:auth:user";

let AuthContext = React.createContext<AuthContextType>(null!);

export function resetSession() {
  localStorage.removeItem(localStorageKey);
  window.location.reload();
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [apiEndpoint] = useApiEndpointContext();
  let [user, setUser] = useState<User | null | undefined>(undefined);

  useEffect(() => {
    let storedUser = localStorage.getItem(localStorageKey);
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      setUser(null);
    }
  }, []);

  let signIn = (user: User, callback: VoidFunction) => {
    setUser(user);
    localStorage.setItem(localStorageKey, JSON.stringify(user));
    callback();
  };

  let signOut = (callback?: VoidFunction) => {
    (async () => {
      if (!user) {
        return;
      }
      await postApi(apiEndpoint, `/auth/signout`, {}, user.token);
      setUser(null);
      localStorage.removeItem(localStorageKey);
      if (callback) {
        callback();
      }
    })();
  };

  let value = { user, signIn, signOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}

export function useToken() {
  const auth = useAuth();
  const token = auth.user?.token;
  if (!token) {
    throw new Error("No token found");
  }
  return token;
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  // In case user is not loaded from localStorage yet
  if (auth.user === undefined) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.1 }}
        className={"grid place-items-center h-full"}
      >
        <Spinner color={"text-neutral-800"} size={"regular"} />
      </motion.div>
    );
  }

  if (auth.user === null || !auth.user.token) {
    // Redirect them to the /auth/login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={toLoginPage()} state={{ from: location }} replace />;
  }

  return children;
}

function usePreviousLocation() {
  const location = useLocation();

  // @ts-ignore
  const from = location.state?.from?.pathname || "/";

  return from;
}

/**
 * Sends user back to previous page if they're signed in and visit the login page.
 * @param param0
 * @returns
 */
export function BounceIfSignedIn({ children }: PropsWithChildren<{}>) {
  const auth = useAuth();
  const previous = usePreviousLocation();

  // If user is already logged in (localStorage is evaluated when LoginPage is already rendered),
  // redirect them to the page they were trying to access before they logged in.
  if (auth.user) {
    return <Navigate to={previous} replace />;
  }

  return <>{children}</>;
}

export function useSignInAndRedirectToPrevious() {
  const auth = useAuth();
  const navigate = useNavigate();
  const from = usePreviousLocation();

  return function signIn(user: User) {
    auth.signIn(user, () => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true });
    });
  };
}

export function useStartAuth(
  email: string,
  setAttemptId: (attemptId: string) => void
) {
  const [apiEndpoint] = useApiEndpointContext();
  return useCallback(async () => {
    if (!email) {
      return;
    }

    await withErrorToast(async () => {
      const { attemptId } = await postApi<{ attemptId: string }>(
        apiEndpoint,
        `/auth/start`,
        { email }
      );
      setAttemptId(attemptId);
    });
  }, [email, setAttemptId]);
}

export function CodeStep({
  attemptId,
  setCode,
  email,
}: {
  attemptId: string;
  email: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [apiEndpoint] = useApiEndpointContext();

  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const { code } = await getApi<{ code: string }>(
          apiEndpoint,
          `/auth/magic/${attemptId}`
        );
        setCode(code);
        clearInterval(interval);
      } catch (error) {
        // ignore
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [attemptId, setCode]);

  useHotkeys("enter", () => setCode(tempCode));

  const [tempCode, setTempCode] = useState("");
  return (
    <>
      <p>
        We sent a temporary login link.
        <br />
        Please check your inbox at <b>{email}</b>.
      </p>

      {showInput ? (
        <>
          <TextInput
            value={tempCode}
            onChange={setTempCode}
            label="Code"
            autoFocus
            name="code"
            type="text"
          />
          <Button
            role={"secondary"}
            disabled={!tempCode}
            onClick={() => setCode(tempCode)}
          >
            Continue
          </Button>
        </>
      ) : (
        <Button role="secondary" onClick={() => setShowInput(true)}>
          Enter code manually
        </Button>
      )}
    </>
  );
}
