import {
  useEnvironmentId,
  useUserIdentityId,
  useWorkspaceId,
} from "../../../../../../../routes";
import {
  useDeleteAPI,
  usePatchAPI,
  useUserManagementUserIdentityAccessTokens,
  withErrorToast,
} from "../../../../../../../../data";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
  HeaderContext,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { IUserAccessToken } from "@anzuhq/backend";
import classNames from "classnames";
import { MoreVertical, Pause, Trash2 } from "../../../../../../../../icons";
import { idRenderer } from "../../../../../../../database/renderer";
import { Checkbox } from "../../../../../../../../components/basics/checkbox";
import {
  Dropdown,
  DropdownItemButton,
  DropdownListContainer,
} from "../../../../../../../database/dropdown";
import toast from "react-hot-toast";
import { LightTable } from "../../../../../../../components/table/light-table";
import { DateTimeRendererWithFormats } from "../../../../../../../database/datetime";

function AllRowsActions({
  table,
  mutate,
}: HeaderContext<IUserAccessToken, any> & { mutate: () => void }) {
  const [isOpen, setIsOpen] = useState(false);

  const deleteApi = useDeleteAPI();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const deleteTokens = async () => {
    setIsOpen(false);

    const selected = table.getSelectedRowModel().rows.map((r) => r.original.id);

    await withErrorToast(async () => {
      for (const id of selected) {
        await deleteApi(
          `/workspaces/${workspaceId}/environments/${environmentId}/user_management/access_tokens/${id}`
        );
      }
      toast.success("Deleted tokens");
      mutate();
    });
  };

  return (
    <Dropdown
      activator={
        <button
          className={"w-full flex items-center"}
          disabled={table.getSelectedRowModel().rows.length === 0}
        >
          <span
            className={classNames(
              "w-8 px-2 py-px border border-neutral-200 rounded text-xs",
              table.getSelectedRowModel().rows.length === 0
                ? "invisible"
                : "visible"
            )}
          >
            {table.getSelectedRowModel().rows.length || 0}
          </span>

          <MoreVertical
            className={
              table.getSelectedRowModel().rows.length === 0
                ? "text-neutral-500"
                : "text-neutral-800"
            }
            size={16}
          />
        </button>
      }
      open={isOpen}
      setOpen={setIsOpen}
    >
      <DropdownListContainer>
        <DropdownItemButton onClick={deleteTokens}>
          <div className={"flex items-center space-x-2"}>
            <Trash2 size={14} />
            <span>Delete</span>
          </div>
        </DropdownItemButton>
      </DropdownListContainer>
    </Dropdown>
  );
}

function SomeRowsActions({
  row,
  mutate,
}: CellContext<IUserAccessToken, any> & { mutate: () => void }) {
  const token = row.original;

  const patchApi = usePatchAPI();
  const deleteApi = useDeleteAPI();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const [isOpen, setIsOpen] = useState(false);

  const toggleEnabled = async () => {
    setIsOpen(false);
    await withErrorToast(async () => {
      await patchApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/user_management/access_tokens/${token.id}`,
        {
          is_enabled: !token.is_enabled,
        }
      );
      toast.success(token.is_enabled ? "Disabled token" : "Enabled token");
      mutate();
    });
  };

  const deleteToken = async () => {
    setIsOpen(false);

    await withErrorToast(async () => {
      await deleteApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/user_management/access_tokens/${token.id}`
      );
      toast.success("Deleted token");
      mutate();
    });
  };

  return (
    <Dropdown
      activator={
        <button className={"w-full flex items-center"}>
          <span
            className={classNames(
              "w-8 px-2 py-px border border-neutral-200 rounded text-xs invisible"
            )}
          />

          <MoreVertical size={16} />
        </button>
      }
      open={isOpen}
      setOpen={setIsOpen}
    >
      <DropdownListContainer>
        <DropdownItemButton onClick={toggleEnabled}>
          <div className={"flex items-center space-x-2"}>
            <Pause size={14} />
            <span>{token.is_enabled ? "Disable" : "Enable"}</span>
          </div>
        </DropdownItemButton>
        <DropdownItemButton onClick={deleteToken}>
          <div className={"flex items-center space-x-2"}>
            <Trash2 size={14} />
            <span>Delete</span>
          </div>
        </DropdownItemButton>
      </DropdownListContainer>
    </Dropdown>
  );
}

export function UserIdentityAccessTokensView() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const userIdentityId = useUserIdentityId();

  const { tokens, isLoading, mutate } =
    useUserManagementUserIdentityAccessTokens(
      workspaceId,
      environmentId,
      userIdentityId
    );

  const columns = useMemo<ColumnDef<IUserAccessToken, any>[]>(() => {
    const columnHelper = createColumnHelper<IUserAccessToken>();

    const actionsDef: ColumnDef<IUserAccessToken, any> = {
      id: "actions",
      enableResizing: false,
      size: 10,
      maxSize: 10,
      minSize: 10,
      header: (c) => <AllRowsActions {...c} mutate={mutate} />,
      cell: (c) => <SomeRowsActions {...c} mutate={mutate} />,
    };

    const defs: ColumnDef<IUserAccessToken, any>[] = [
      {
        id: "select",
        enableResizing: false,
        header: ({ table }) => (
          <Checkbox
            id={"select-all"}
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={(c) => table.toggleAllRowsSelected()}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              id={"select-" + row.id}
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={(c) => row.toggleSelected()}
            />
          </div>
        ),
      },
      columnHelper.accessor("id", {
        cell: idRenderer as any,
        header: "ID",
      }),
      columnHelper.accessor("is_enabled", {
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        header: "Is Enabled",
      }),
      columnHelper.accessor("created_at", {
        cell: (info) => <DateTimeRendererWithFormats value={info.getValue()} />,
        header: "Created At",
      }),
      columnHelper.accessor("expires_at", {
        cell: (info) => <DateTimeRendererWithFormats value={info.getValue()} />,
        header: "Expires At",
      }),
      columnHelper.accessor("description", {
        cell: (info) => info.getValue(),
        header: "Description",
      }),
      actionsDef,
    ];

    return defs;
  }, []);

  return (
    <LightTable
      data={tokens || null}
      isLoading={isLoading}
      columns={columns}
      emptyMessage={"No access tokens created."}
    />
  );
}
