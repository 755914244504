import {
  toEnvironmentUserManagementUserIdentity,
  useEnvironmentId,
  useUserIdentityId,
  useWorkspaceId,
} from "../../../../../../../routes";
import {
  useDeleteAPI,
  usePatchAPI,
  useUserManagementUserIdentityRelatedIdentities,
  useUserManagementUserIdentitySchema,
  withErrorToast,
} from "../../../../../../../../data";
import { useMemo, useState } from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
  HeaderContext,
} from "@tanstack/react-table";
import { Checkbox } from "../../../../../../../../components/basics/checkbox";
import { getCellRenderer } from "../../../../../../../database/renderer";
import toast from "react-hot-toast";
import {
  Dropdown,
  DropdownItemButtonWithAlertDialog,
  DropdownListContainer,
} from "../../../../../../../database/dropdown";
import classNames from "classnames";
import {
  Eye,
  MoreVertical,
  PauseCircle,
  Trash2,
} from "../../../../../../../../icons";
import { LightTable } from "../../../../../../../components/table/light-table";
import { ButtonLink } from "../../../../../../../../components/basics/button";
import { IUserIdentity } from "@anzuhq/backend";

function AllRowsActions({
  table,
  mutate,
}: HeaderContext<IUserIdentity, any> & { mutate: () => void }) {
  const [isOpen, setIsOpen] = useState(false);

  const patchApi = usePatchAPI();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const suspendIdentities = async () => {
    setIsOpen(false);

    const selected = table.getSelectedRowModel().rows.map((r) => r.original.id);

    await withErrorToast(async () => {
      for (const id of selected) {
        await patchApi(
          `/workspaces/${workspaceId}/environments/${environmentId}/user_management/user_identities/${id}`,
          {
            is_suspended: true,
          }
        );
      }
      toast.success("Suspended user identities");
      mutate();
    });
  };

  return (
    <Dropdown
      activator={
        <button
          disabled={table.getSelectedRowModel().rows.length === 0}
          className={"w-full flex items-center"}
        >
          <span
            className={classNames(
              "w-8 px-2 py-px border border-neutral-200 rounded text-xs",
              table.getSelectedRowModel().rows.length === 0
                ? "invisible"
                : "visible"
            )}
          >
            {table.getSelectedRowModel().rows.length || 0}
          </span>

          <MoreVertical
            className={
              table.getSelectedRowModel().rows.length === 0
                ? "text-neutral-500"
                : "text-neutral-800"
            }
            size={16}
          />
        </button>
      }
      open={isOpen}
      setOpen={setIsOpen}
    >
      <DropdownListContainer>
        <DropdownItemButtonWithAlertDialog
          onOpenChange={(o) => (!o ? setIsOpen(false) : null)}
          title={"Danger"}
          message={`Are you sure you want to suspend multiple user identities?`}
          confirmLabel={"Suspend multiple identities"}
          onClick={suspendIdentities}
        >
          <div className={"flex items-center space-x-2"}>
            <PauseCircle size={14} />
            <span>Suspend All</span>
          </div>
        </DropdownItemButtonWithAlertDialog>
      </DropdownListContainer>
    </Dropdown>
  );
}

function SomeRowsActions({
  row,
  mutate,
}: CellContext<IUserIdentity, any> & { mutate: () => void }) {
  const identity = row.original;

  const patchApi = usePatchAPI();
  const deleteApi = useDeleteAPI();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const [isOpen, setIsOpen] = useState(false);

  const toggleSuspendUserIdentity = async () => {
    setIsOpen(false);

    await withErrorToast(async () => {
      await patchApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/user_management/user_identities/${identity.id}`,
        {
          is_suspended: !identity.is_suspended,
        }
      );
      toast.success(identity.is_suspended ? "Unsuspended" : "Suspended");
      mutate();
    });
  };

  const deleteUserIdentity = async () => {
    setIsOpen(false);

    await withErrorToast(async () => {
      await deleteApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/user_management/user_identities/${identity.id}`
      );
      toast.success("Deleted user identity");
      mutate();
    });
  };

  return (
    <Dropdown
      activator={
        <button className={"w-full flex items-center"}>
          <span
            className={classNames(
              "w-8 px-2 py-px border border-neutral-200 rounded text-xs invisible"
            )}
          />

          <MoreVertical size={16} />
        </button>
      }
      open={isOpen}
      setOpen={setIsOpen}
    >
      <DropdownListContainer>
        <DropdownItemButtonWithAlertDialog
          onOpenChange={(o) => (!o ? setIsOpen(false) : null)}
          title={"Danger"}
          message={`Are you sure you want to ${
            identity.is_suspended ? "unsuspend" : "suspend"
          } this user identity?`}
          confirmLabel={identity.is_suspended ? "Unsuspend" : "Suspend"}
          onClick={toggleSuspendUserIdentity}
        >
          <div className={"flex items-center space-x-2"}>
            <PauseCircle size={14} />
            <span>{identity.is_suspended ? "Unsuspend" : "Suspend"}</span>
          </div>
        </DropdownItemButtonWithAlertDialog>

        <DropdownItemButtonWithAlertDialog
          onOpenChange={(o) => (!o ? setIsOpen(false) : null)}
          title={"Danger"}
          message={
            "Are you sure you want to delete this user identity? This cannot be undone."
          }
          confirmLabel={"Delete identity irrevocably"}
          onClick={deleteUserIdentity}
        >
          <div className={"flex items-center space-x-2 text-red-500"}>
            <Trash2 size={14} />
            <span>Delete</span>
          </div>
        </DropdownItemButtonWithAlertDialog>
      </DropdownListContainer>
    </Dropdown>
  );
}

export function UserIdentityRelatedIdentitiesView() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const userIdentityId = useUserIdentityId();

  const { identities, isLoading, mutate } =
    useUserManagementUserIdentityRelatedIdentities(
      workspaceId,
      environmentId,
      userIdentityId
    );

  const { schema } = useUserManagementUserIdentitySchema(
    workspaceId,
    environmentId
  );

  const columns = useMemo<ColumnDef<IUserIdentity, any>[]>(() => {
    if (!schema) {
      return [];
    }

    const columnHelper = createColumnHelper<IUserIdentity>();

    const actionsDef: ColumnDef<IUserIdentity, any> = {
      id: "actions",
      enableResizing: false,
      size: 10,
      maxSize: 10,
      minSize: 10,
      header: (c) => <AllRowsActions {...c} mutate={mutate} />,
      cell: (c) => <SomeRowsActions {...c} mutate={mutate} />,
    };

    const defs: ColumnDef<IUserIdentity, any>[] = [
      {
        id: "select",
        enableResizing: false,
        header: ({ table }) => (
          <Checkbox
            id={"select-all"}
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={(c) => table.toggleAllRowsSelected()}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              id={"select-" + row.id}
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={(c) => row.toggleSelected()}
            />
          </div>
        ),
      },
      {
        id: "actions",
        header: () => <span className={"font-normal text-sm"}>Actions</span>,
        enableResizing: false,
        cell: (info) => (
          <div className={"flex flex-row items-center space-x-2"}>
            <ButtonLink
              to={toEnvironmentUserManagementUserIdentity(
                workspaceId,
                environmentId,
                info.row.original.id
              )}
              size={"small"}
              role={"ghost"}
              weight={"regular"}
              icon={Eye}
            />
          </div>
        ),
      },
    ];

    for (const field of schema.fields) {
      defs.push(
        columnHelper.accessor(field.name as any, {
          header: field.label,
          cell: getCellRenderer<IUserIdentity>(field, (id) =>
            toEnvironmentUserManagementUserIdentity(
              workspaceId,
              environmentId,
              id
            )
          ),
          footer: (props) => props.column.id,
          enableHiding: true,
          enableResizing: true,
        })
      );
    }

    defs.push(actionsDef);

    return defs;
  }, [schema]);

  return (
    <LightTable
      data={identities || null}
      isLoading={isLoading}
      columns={columns}
      emptyMessage={"No related user identities."}
    />
  );
}
