import {
  ContentNode,
  ParagraphNode,
  RichTextNodeKind,
  RootNode,
  TextNode,
} from "@anzuhq/backend";

export const emptyRichText = () => {
  const rt: RootNode = {
    version: "2023.03",
    kind: RichTextNodeKind.Root,
    content: [
      {
        content: [
          {
            kind: RichTextNodeKind.Text,
            content: "",
          },
        ],
        kind: RichTextNodeKind.Paragraph,
      },
    ],
  };
  return JSON.parse(JSON.stringify(rt));
};

export function FakeRichTextRenderer({
  content,
  setContent,
  editingDisabled,
  placeholder,
  fullHeight,
}: {
  content: RootNode;
  setContent: (node: RootNode) => void;
  editingDisabled?: boolean;
  placeholder?: string;
  fullHeight?: boolean;
}) {
  const firstParagraph = content.content[0];
  if (!firstParagraph) {
    return null;
  }

  const firstText = firstParagraph.content[0];
  if (!firstText) {
    return null;
  }

  return (
    <textarea
      className={
        "p-3 appearance-none resize-none w-full h-full bg-transparent outline-none placeholder:text-neutral-300 text-sm"
      }
      rows={fullHeight ? firstText.content.split("\n").length : 6}
      value={firstText.content}
      disabled={editingDisabled}
      placeholder={placeholder}
      onChange={(e) => {
        setContent({
          ...content,
          content: [
            {
              kind: RichTextNodeKind.Paragraph,
              content: [
                {
                  content: e.target.value,
                  kind: RichTextNodeKind.Text,
                },
              ],
            },
          ],
        });
      }}
    ></textarea>
  );
}

export function RichTextContentRenderer({
  content,
  setContent,
  editingDisabled,
  placeholder,
  preview,
}: {
  content: RootNode;
  setContent: (node: RootNode) => void;
  editingDisabled?: boolean;
  placeholder?: string;
  preview?: boolean;
}) {
  return (
    <div>
      {(preview ? content.content.slice(0, 1) : content.content).map(
        (c, idx) => (
          <RichTextContentNodeRenderer
            preview={preview}
            key={idx}
            node={c}
            placeholder={placeholder}
            editingDisabled={editingDisabled}
            setNode={(node: ContentNode) => {
              setContent({
                version: content.version,
                kind: content.kind,
                content: content.content.map((c, i) => (i === idx ? node : c)),
              });
            }}
          />
        )
      )}
    </div>
  );
}

function RichTextContentNodeRenderer({
  node,
  setNode,
  editingDisabled,
  placeholder,
  preview,
}: {
  node: ContentNode;
  setNode: (node: ContentNode) => void;
  editingDisabled?: boolean;
  placeholder?: string;
  preview?: boolean;
}) {
  switch (node.kind) {
    case RichTextNodeKind.Paragraph:
      return (
        <ParagraphRenderer
          preview={preview}
          placeholder={placeholder}
          editingDisabled={editingDisabled}
          node={node}
          setNode={setNode}
        />
      );
  }
}

function ParagraphRenderer({
  node,
  setNode,
  editingDisabled,
  placeholder,
  preview,
}: {
  node: ParagraphNode;
  setNode: (node: ContentNode) => void;
  editingDisabled?: boolean;
  placeholder?: string;
  preview?: boolean;
}) {
  return (
    <div
      className={"appearance-none outline-none"}
      suppressContentEditableWarning={true}
      contentEditable={!editingDisabled}
      // TODO This does not respect different styling within a paragraph
      onInput={(e) => {
        console.log(e);
        const targetEl = e.target as HTMLDivElement;
        setNode({
          ...node,
          content: [
            { kind: RichTextNodeKind.Text, content: targetEl.innerText },
          ],
        });
      }}
    >
      {node.content.map((c, idx) => (
        <TextNodeRenderer preview={preview} key={idx} node={c} />
      ))}
    </div>
  );
}

function TextNodeRenderer({
  node,
  preview,
}: {
  node: TextNode;
  preview?: boolean;
}) {
  const hasMultipleLines = node.content.includes("\n");
  const excerptLongerThan256 = node.content.length > 256;

  const firstLine = node.content.split("\n")[0];
  const firstLineExcerpt = firstLine.slice(0, 256);

  return (
    <>
      <span>{preview ? firstLineExcerpt : node.content}</span>
      {hasMultipleLines || excerptLongerThan256 ? (
        <span className={"text-neutral-400"}>...</span>
      ) : null}
    </>
  );
}
