import { PropsWithChildren } from "react";
import { Link, Outlet, useMatch, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { AlertTriangle } from "../../icons";
import { WithTooltip } from "../../components/basics/tooltip";

export function SettingsPageLayout(props: PropsWithChildren<{}>) {
  return (
    <div className={"my-16 max-w-3xl flex-col space-y-12"}>
      {props.children}
    </div>
  );
}

export function SettingsSection(s: {
  id: string;
  name: string;
  sectionUrl: (sectionId: string) => string;
  warning?: string;
}) {
  const sectionUrl = s.sectionUrl(s.id);
  const match = useMatch(sectionUrl);
  const navigate = useNavigate();

  return (
    <Link
      to={sectionUrl}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        navigate(sectionUrl, { replace: true });

        const el = document.getElementById(s.id);
        if (el) {
          el.scrollIntoView({
            behavior: "smooth",
          });
        }
      }}
      className={classNames(
        "pl-2 w-48 py-2 rounded-md text-sm whitespace-nowrap text-left",
        "flex items-center space-x-2",
        {
          "hover:bg-neutral-100 active:bg-neutral-200 text-neutral-600 hover:text-black font-regular":
            match === null,
          "text-black font-medium": match !== null,
        }
      )}
    >
      <WithTooltip disabled={!s.warning} text={s.warning || ""}>
        <AlertTriangle
          size={14}
          className={classNames("text-amber-600", {
            visible: s.warning,
            invisible: !s.warning,
          })}
        />
      </WithTooltip>

      <span>{s.name}</span>
    </Link>
  );
}

export function ConfigLoadErrorPage() {
  return (
    <SettingsPageLayout>
      <div className={"rounded-md border border-red-200"}>
        <div className={"p-8 text-sm flex flex-col space-y-2"}>
          <div className={"flex items-center"}>
            <h2 className={"pb-2 text-xl font-medium"}>
              Could not load configureation
            </h2>
          </div>
          <p>
            We tried loading your user management configuration, but something
            on our end failed. Please refresh your page and try again and reach
            out to our support if this issue persists.
          </p>
        </div>

        <div
          className={
            "bg-red-50 rounded-b-md border-t border-red-200 px-6 py-4 text-sm text-red-800"
          }
        >
          This should not happen.
        </div>
      </div>
    </SettingsPageLayout>
  );
}

export function LayoutWithStickySidebar({
  sections,
  sectionUrl,
}: {
  sections: {
    id: string;
    name: string;
    warning?: string;
  }[];
  sectionUrl: (sectionId: string) => string;
}) {
  return (
    <div className={"grid grid-cols-12"}>
      {/* Sidebar */}
      <div className={"col-span-1"} />
      <div className={"col-span-3 relative"}>
        <div className={"mt-24 scroll-mt-24 top-48 sticky flex"}>
          <div className={"flex flex-col space-y-2"}>
            {sections.map((s) => (
              <SettingsSection
                key={s.id}
                id={s.id}
                name={s.name}
                sectionUrl={sectionUrl}
                warning={s.warning}
              />
            ))}
          </div>
        </div>
      </div>

      <div className={"col-span-7 flex flex-col space-y-4 p-2"}>
        <Outlet />
      </div>

      <div className={"col-span-1"} />
    </div>
  );
}
