import {
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  DatabaseField,
  DatabaseFieldKind,
  DatabaseFilter,
  DatabaseGroupBy,
  DatabaseSchema,
  DatabaseSearch,
  DatabaseSort,
  PageInfo,
  PaginationArgs,
} from "@anzuhq/backend";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  useDraggable,
  useDroppable,
} from "@dnd-kit/core";
import classNames from "classnames";
import { IdRenderer, RenderIcon, TData } from "./renderer";
import { navigationHeight } from "../navigation";
import { headerHeight } from "../[workspaceId]/environments/[environmentId]/developers/webhooks/[webhookId]/page";
import { createPortal } from "react-dom";
import { Columns, MoreVertical } from "../../icons";
import { filterHeight } from "./database";
import { Button } from "../../components/basics/button";

export type RenderGroupsFn = (args: {
  groups: Array<string | null>;
  isLoading?: boolean;
}) => ReactElement;

export type BoardGroupFetcherComponent = FC<
  PropsWithChildren<{
    schema: DatabaseSchema;
    filters?: DatabaseFilter[];
    search?: DatabaseSearch | null;
    groupByField: DatabaseField;
    renderGroups: RenderGroupsFn;
  }>
>;

export type BoardGroupDataFetcherComponent<T extends TData> = FC<
  PropsWithChildren<{
    filters?: DatabaseFilter[];
    sort?: DatabaseSort | null;
    search?: DatabaseSearch | null;
    groupBy?: DatabaseGroupBy | null;
    renderItems: (args: {
      pageInfo: PageInfo | null;
      items: T[];
      paginationArgs: PaginationArgs;
      setPaginationArgs: (args: PaginationArgs) => void;
      isLoading?: boolean;
      mutate: () => void;
      countTotal?: number;
      countFiltered?: number;
    }) => ReactElement;
  }>
>;

export type BoardItemRendererComponent<T extends TData> = FC<{
  schema: DatabaseSchema;
  item: T;
}>;

function BoardGroup<T extends TData>({
  groupId,
  filters,
  sort,
  groupBy,
  search,
  paginationArgs,
  groupDataFetcher: GroupDataFetcher,
  itemRenderer,
  schema,
  field,

  onOpenSplitView,
}: {
  groupId: string | null;
  field: DatabaseField;

  paginationArgs?: PaginationArgs;
  filters?: DatabaseFilter[];
  sort?: DatabaseSort | null;
  search?: DatabaseSearch | null;
  groupBy?: DatabaseGroupBy | null;

  schema: DatabaseSchema;

  groupDataFetcher: BoardGroupDataFetcherComponent<T>;
  itemRenderer: BoardItemRendererComponent<T>;

  onOpenSplitView?: (id: string) => void;
}) {
  const groupByCurrent = useMemo(() => {
    return groupBy
      ? {
          field: groupBy.field,
          currentGroup: groupId,
        }
      : groupBy;
  }, [groupBy, groupId]);

  // check to remove unnecessary rerenders and make draggable work on first render
  if (!groupByCurrent) {
    return null;
  }

  return (
    <GroupDataFetcher
      filters={filters}
      sort={sort}
      groupBy={groupByCurrent}
      search={search}
      renderItems={({
        items,
        isLoading,
        mutate,
        paginationArgs,
        setPaginationArgs,
        pageInfo,
        countTotal,
        countFiltered,
      }) => (
        <ItemList
          itemRenderer={itemRenderer}
          groupId={groupId}
          mutate={mutate}
          items={items}
          schema={schema}
          field={field}
          isLoading={isLoading}
          paginationArgs={paginationArgs}
          setPaginationArgs={setPaginationArgs}
          pageInfo={pageInfo}
          countTotal={countTotal}
          countFiltered={countFiltered}
          onOpenSplitView={onOpenSplitView}
        />
      )}
    />
  );
}

export function useWhyDidYouUpdate(
  name: string,
  props: Record<string, unknown>
) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef<Record<string, unknown>>();

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj: Record<string, unknown> = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current![key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current![key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.log("[why-did-you-update]", name, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

function GroupLabelRenderer({
  groupId,
  field,
}: {
  groupId: string | null;
  field: DatabaseField;
}) {
  if (groupId === null) {
    return (
      <span className={"text-sm text-neutral-700 select-none"}>Empty</span>
    );
  }

  switch (field.kind) {
    case DatabaseFieldKind.Enum:
      const enumValue = field.enumValues.find((v) => v.value === groupId);
      if (!enumValue) {
        return null;
      }

      return (
        <div className={"flex items-center space-x-2"}>
          <RenderIcon icon={enumValue.icon} />
          <span className={"text-sm text-neutral-700 select-none"}>
            {enumValue.label}
          </span>
        </div>
      );
    case DatabaseFieldKind.ID:
      return <IdRenderer value={groupId} field={field} />;
    default:
      return null;
  }
}

function ItemList<T extends TData>({
  groupId,
  mutate,
  itemRenderer,
  isLoading,
  schema,
  items,
  field,
  paginationArgs,
  setPaginationArgs,
  pageInfo,
  countTotal,
  countFiltered,
  onOpenSplitView,
}: {
  items: T[];
  groupId: string | null;
  field: DatabaseField;
  isLoading?: boolean;

  mutate: () => void;
  schema: DatabaseSchema;

  itemRenderer: BoardItemRendererComponent<T>;

  pageInfo: PageInfo | null;
  paginationArgs: PaginationArgs;
  setPaginationArgs: (args: PaginationArgs) => void;

  countTotal?: number;
  countFiltered?: number;

  onOpenSplitView?: (id: string) => void;
}) {
  const { isOver, setNodeRef } = useDroppable({
    id: groupId || "empty",
    data: {
      mutate,
    },
    disabled: isLoading,
  });

  return (
    <div
      className={classNames("relative flex flex-col space-y-2", {
        "bg-neutral-100": isOver,
        "bg-neutral-50": !isOver,
      })}
      style={{
        // Dynamically size between 350px and 500px based on showing 4 columns on screen
        width: "min(max(350px, calc(100vw / 4)), 500px)",
      }}
    >
      <div
        className={
          "sticky top-0 bg-neutral-50 z-10 p-4 rounded flex items-center space-x-2"
        }
      >
        <GroupLabelRenderer groupId={groupId} field={field} />

        <div
          className={classNames(
            "flex items-center rounded bg-neutral-100 text-xs px-2 py-1 font-medium",
            {
              "animate-pulse": isLoading,
            }
          )}
        >
          <span>{countFiltered || 0}</span>
          <span>/</span>
          <span>{countTotal || 0}</span>
        </div>
      </div>
      <div
        className={classNames("p-4 flex flex-col space-y-2 h-full", {
          "animate-pulse": isLoading,
        })}
        ref={setNodeRef}
      >
        {items.map((i) => {
          return (
            <BoardItem
              key={i.id}
              item={i}
              schema={schema}
              itemRenderer={itemRenderer}
              onOpenSplitView={onOpenSplitView}
            />
          );
        })}

        {pageInfo && pageInfo.hasNextPage ? (
          <Button
            key={"load-more"}
            role={"secondary"}
            onClick={() =>
              setPaginationArgs({
                first: 10,
                after: pageInfo?.endCursor,
                last: null,
                before: null,
              })
            }
          >
            Load More
          </Button>
        ) : null}
      </div>
    </div>
  );
}

function BoardItem<T extends TData>({
  item,
  itemRenderer: ItemRenderer,
  onOpenSplitView,
  schema,
}: PropsWithChildren<{
  item: T;
  schema: DatabaseSchema;
  itemRenderer: BoardItemRendererComponent<T>;
  onOpenSplitView?: (id: string) => void;
}>) {
  const { attributes, listeners, setNodeRef, active } = useDraggable({
    id: item.id,
    data: item,
  });

  return (
    <div
      className={classNames(
        "w-full group relative flex bg-white rounded shadow p-2 h-24 active:shadow-md transition-shadow duration-200",
        {
          "bg-neutral-50 border border-dashed border-neutral-500":
            active?.id === item.id,
        }
      )}
      ref={setNodeRef}
    >
      <ItemRenderer schema={schema} item={item} />
      <div
        className={classNames(
          "opacity-0 group-hover:opacity-100 transition duration-200",
          "absolute right-0 top-0 h-full flex items-stretch rounded"
        )}
      >
        {onOpenSplitView ? (
          <button
            onClick={() => onOpenSplitView(item.id)}
            className={classNames(
              "hidden md:flex items-center justify-center px-2 w-12",
              "bg-neutral-50 hover:bg-neutral-100 active:bg-neutral-200 text-neutral-700"
            )}
          >
            <Columns size={16} />
          </button>
        ) : null}
        <button
          {...listeners}
          {...attributes}
          className={classNames(
            "flex items-center justify-center w-12 px-2",
            "bg-neutral-50 hover:bg-neutral-100 text-neutral-700",
            {
              "cursor-grab": active?.id !== item.id,
              "cursor-grabbing": active?.id === item.id,
            }
          )}
        >
          <MoreVertical size={16} className={"-mr-2.5"} />
          <MoreVertical size={16} />
        </button>
      </div>
    </div>
  );
}

export function BoardView<T extends TData>({
  isLoading,

  visibleFields,
  schema,

  toDetailPage,

  updateItemGroup,

  groupDataFetcher,
  itemRenderer: ItemRenderer,
  groupFetcher: GroupFetcher,

  filters,
  sort,
  groupBy,
  search,
  paginationArgs,

  onOpenSplitView,
}: {
  isLoading?: boolean;

  visibleFields: string[];
  schema: DatabaseSchema;

  toDetailPage: (id: string) => string;

  updateItemGroup: (data: T, nextGroupId: string | null) => void;

  paginationArgs?: PaginationArgs;
  filters?: DatabaseFilter[];
  sort?: DatabaseSort | null;
  search?: DatabaseSearch | null;

  groupBy?: DatabaseGroupBy | null;

  groupFetcher: BoardGroupFetcherComponent;
  groupDataFetcher: BoardGroupDataFetcherComponent<T>;
  itemRenderer: BoardItemRendererComponent<T>;

  onOpenSplitView?: (id: string) => void;
}) {
  const groupByField = useMemo(() => {
    if (!groupBy) {
      return null;
    }
    return schema.fields.find((f) => f.name === groupBy?.field);
  }, [groupBy, schema]);

  const [dragging, setDragging] = useState<T | null>(null);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { over } = event;

      setDragging(null);

      // If the item is dropped over a container, set it as the parent
      // otherwise reset the parent to `null`
      if (
        !over ||
        typeof over.id !== "string" ||
        typeof event.active.id !== "string"
      ) {
        return;
      }

      const data = event.active.data.current as unknown as T;

      let newGroup: string | null = over.id;
      if (newGroup === "empty") {
        newGroup = null;
      }

      updateItemGroup(data, newGroup);
    },
    [updateItemGroup]
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    const data = event.active.data.current as unknown as T;
    setDragging(data);
  }, []);

  if (!groupByField) {
    return <div className={"bg-neutral-50 h-full"} />;
  }

  return (
    <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <div
        className={
          "bg-neutral-50 grid justify-start overflow-auto gap-4 grid-flow-col"
        }
        style={{
          height: `calc(100vh - ${navigationHeight} - ${headerHeight} ${
            sort || (filters && filters.length > 0) ? `- ${filterHeight}` : ""
          })`,
        }}
      >
        <GroupFetcher
          schema={schema}
          filters={filters}
          groupByField={groupByField}
          search={search}
          renderGroups={({ groups, isLoading }) => {
            if (!groupByField || isLoading) {
              return <p>loading</p>;
            }

            const finalGroups = groupByField.isRequired
              ? groups
              : [...groups, null];

            return (
              <>
                {finalGroups.map((groupId) => (
                  // We updated the BoardGroup component so it would accept an `id`
                  // prop and pass it to `useDroppable`
                  <BoardGroup
                    onOpenSplitView={onOpenSplitView}
                    key={groupId || "empty"}
                    groupId={groupId}
                    groupDataFetcher={groupDataFetcher}
                    groupBy={groupBy}
                    search={search}
                    paginationArgs={paginationArgs}
                    filters={filters}
                    sort={sort}
                    field={groupByField}
                    itemRenderer={ItemRenderer}
                    schema={schema}
                  />
                ))}
              </>
            );
          }}
        />
      </div>
      {createPortal(
        <DragOverlay
          className={
            "relative flex w-full h-full bg-white border-2 border-blue-500 rounded shadow-xl p-2 h-24 active:shadow-md transition-shadow duration-200 cursor-grabbing"
          }
        >
          {dragging ? <ItemRenderer item={dragging} schema={schema} /> : null}
          <button
            className={classNames(
              "absolute right-0 top-0 h-full flex items-center rounded px-2",
              "bg-neutral-50 hover:bg-neutral-100 text-neutral-700",
              "cursor-grabbing"
            )}
          >
            <MoreVertical size={16} className={"-mr-2.5"} />
            <MoreVertical size={16} />
          </button>
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
}
