import {
  SettingsPageLayout,
  useUserManagementSettingsConfig,
} from "../general/page";
import {
  UserManagementConfig,
  UserManagementConfigResp,
} from "@anzuhq/backend";
import { useState } from "react";
import { Button } from "../../../../../../../components/basics/button";
import { TextInput } from "../../../../../../../components/basics/input";
import { FieldSet } from "../../../../../../components/fieldset";
import { ConfigLoadErrorPage } from "../../../../../../components/settings";
import { LearnMoreLink } from "../../../../../../components/docs";

export function UserManagementSettingsHostedAuthPage() {
  const { userManagementConfig, isLoading, saveConfig } =
    useUserManagementSettingsConfig();

  if (isLoading) {
    return <FieldSet footer={<></>} title={""} loading />;
  }

  if (!userManagementConfig) {
    return <ConfigLoadErrorPage />;
  }

  return (
    <SettingsPageLayout>
      <Appearance config={userManagementConfig} setConfig={saveConfig} />
    </SettingsPageLayout>
  );
}

function Appearance({
  config,
  setConfig,
}: {
  config: UserManagementConfigResp;
  setConfig: (
    config: Partial<UserManagementConfig>,
    message?: string
  ) => Promise<void>;
}) {
  const [appearance, setAppearance] = useState(
    config.config.hosted_page_appearance
  );

  return (
    <FieldSet
      footer={
        <div className={"flex items-center justify-between"}>
          <LearnMoreLink
            to={"https://anzuhq.com/docs/blocks/user-management/hosted-page"}
          >
            Hosted Page
          </LearnMoreLink>

          <Button
            onClick={() => {
              setConfig(
                { hosted_page_appearance: appearance },
                "Updated hosted page appearance"
              );
            }}
            size={"small"}
            disabled={
              JSON.stringify(appearance) ===
              JSON.stringify(config.config.hosted_page_appearance)
            }
          >
            Save
          </Button>
        </div>
      }
      title={"Appearance"}
    >
      <p className={"pb-2"}>Change what your users see when they sign in.</p>

      <TextInput
        label={"Application Name"}
        placeholder={"My application"}
        value={appearance?.applicationName || ""}
        onChange={(v) => {
          setAppearance(
            appearance
              ? { ...appearance, applicationName: v }
              : { applicationName: v, logoUrl: null, subtitle: null }
          );
        }}
      />

      <TextInput
        label={"Greeting Text"}
        placeholder={"Sign in to my application"}
        value={appearance?.subtitle || ""}
        onChange={(v) => {
          setAppearance(
            appearance
              ? { ...appearance, subtitle: v }
              : { subtitle: v, logoUrl: null, applicationName: null }
          );
        }}
      />

      <TextInput
        label={"Logo URL"}
        placeholder={"https://example.com/logo.png"}
        value={appearance?.logoUrl || ""}
        onChange={(v) => {
          setAppearance(
            appearance
              ? { ...appearance, logoUrl: v }
              : { logoUrl: v, applicationName: null, subtitle: null }
          );
        }}
      />
    </FieldSet>
  );
}
