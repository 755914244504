import React, { useEffect, useMemo, useState } from "react";
import { Eye, EyeOff, Plus, Search, Trash2, X } from "../../icons";
import { Button, CopyButton, DeleteButton, UpdateButton } from "./button";
import classNames from "classnames";

export function TextInput({
  label,
  onChange,
  value,
  inputRef,
  type,
  placeholder,
  autoFocus,
  onBlur,
  onFocus,
  name,
  isSecret,
  readOnly,
  labelHorizontal,
  size = "regular",
  noInitialBorder,
}: {
  label?: string;
  placeholder?: string;
  value: string;
  type?: string;
  onChange: (value: string) => void;
  inputRef?: React.LegacyRef<HTMLInputElement> | undefined;
  autoFocus?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  name?: string;
  isSecret?: boolean;
  readOnly?: boolean;
  labelHorizontal?: boolean;
  size?: "small" | "regular";
  noInitialBorder?: boolean;
}) {
  const [showText, setShowText] = useState<boolean>(!isSecret);

  return (
    <div
      className={classNames({
        "flex flex-col space-y-1": labelHorizontal,
        "grid grid-cols-12 items-center space-x-1 ": !labelHorizontal,
      })}
    >
      {label ? (
        <p className="text-sm font-medium col-span-3 whitespace-nowrap shrink-0 grow-0 truncate">
          {label}
        </p>
      ) : null}
      <div
        className={classNames("gap-2 flex flex-row items-stretch grow", {
          "col-span-9": label,
          "col-span-12": !label,
        })}
      >
        <div
          onClick={(e) => {
            const currentEl = e.target as HTMLElement;
            const inputEl = currentEl.children[0] as HTMLInputElement;
            if (inputEl) {
              inputEl.focus();
            }
          }}
          className={classNames(
            "transition-colors border focus-within:border-black focus-within:shadow-sm rounded-md w-full flex items-center",
            {
              "p-2": size === "regular",
              "p-1": size === "small",
              "border-gray-200": !noInitialBorder,
              "border-transparent": noInitialBorder,
            }
          )}
        >
          <input
            ref={inputRef}
            className={classNames(
              "bg-transparent appearance-none outline-none w-full placeholder:text-neutral-300",
              {
                "text-sm": size === "regular",
                "text-xs": size === "small",
                "px-1": size === "regular",
                "px-px": size === "small",
              }
            )}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            type={showText ? type : "password"}
            placeholder={placeholder}
            autoFocus={autoFocus}
            onFocus={onFocus}
            onBlur={onBlur}
            name={name}
            readOnly={readOnly}
            disabled={readOnly}
          />
          {isSecret ? (
            <div className={"ml-auto flex items-stretch space-x-1"}>
              <Button
                size={"small"}
                role={"secondary"}
                onClick={() => setShowText(!showText)}
              >
                {showText ? <EyeOff size={16} /> : <Eye size={16} />}
              </Button>

              <CopyButton text={value} size={"small"} role={"secondary"} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function SearchInput({
  label,
  onChange,
  value,
  inputRef,
  placeholder,
  autoFocus,
  onBlur,
  onFocus,
  name,
}: {
  label?: string;
  placeholder?: string;
  value: string;

  onChange: (value: string) => void;
  inputRef?: React.LegacyRef<HTMLInputElement> | undefined;
  autoFocus?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  name?: string;
}) {
  return (
    <div
      className={classNames(
        "w-full",
        "grid grid-cols-12 items-center space-x-1 "
      )}
    >
      {label ? (
        <p className="text-sm font-medium col-span-3 whitespace-nowrap shrink-0 grow-0 truncate">
          {label}
        </p>
      ) : null}
      <div
        className={classNames("flex flex-row items-stretch gap-2 grow", {
          "col-span-9": label,
          "col-span-12": !label,
        })}
      >
        <div className="p-2 border border-gray-200 focus-within:border-black focus-within:shadow-sm rounded-md w-full flex items-center text-neutral-600 focus-within:text-black transition duration-75">
          <Search size={16} />
          <input
            ref={inputRef}
            className="peer placeholder:text-neutral-300 px-1 appearance-none outline-none w-full text-sm bg-transparent"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            type={"search"}
            placeholder={placeholder}
            autoFocus={autoFocus}
            onFocus={onFocus}
            onBlur={onBlur}
            name={name}
          />
          {value.length > 0 ? (
            <div className={"ml-auto flex items-stretch space-x-1"}>
              <Button
                size={"small"}
                role={"ghost"}
                icon={X}
                onClick={() => onChange("")}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function TextArea({
  label,
  onChange,
  value,
  rows,
  inputRef,
  autoFocus,
  onFocus,
  onBlur,
  placeholder,
  readOnly,
  noInitialBorder,
}: {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  rows?: number;
  inputRef?: React.LegacyRef<HTMLTextAreaElement> | undefined;
  autoFocus?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
  placeholder?: string;
  readOnly?: boolean;
  noInitialBorder?: boolean;
}) {
  return (
    <div className="space-y-1 h-full w-full">
      {label ? <p className="text-sm font-semibold">{label}</p> : null}
      <div
        className={classNames(
          "border focus-within:border-black focus-within:shadow-sm rounded-md h-full w-full flex p-2",
          {
            "border-gray-200": !noInitialBorder,
            "border-transparent": noInitialBorder,
          }
        )}
        onClick={(e) => {
          const currentEl = e.target as HTMLElement;
          const inputEl = currentEl.children[0] as HTMLTextAreaElement;
          if (inputEl) {
            inputEl.focus();
          }
        }}
      >
        <textarea
          readOnly={readOnly}
          autoFocus={autoFocus}
          ref={inputRef}
          rows={rows}
          className={classNames(
            "bg-transparent appearance-none outline-none w-full text-sm px-1 resize-none placeholder:text-neutral-300"
          )}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}

export function BufferedTextInput({
  onSave,
  value: managedValue,
  noDelete,
  button: ButtonComp = UpdateButton,
  placeholder,
  emptyOnSave,
  size,
  autoFocus,
  onBlur,
  hideButton,
  type,
  isMultiline,
}: {
  onSave: (value: string | null) => void;
  value: string;
  noDelete?: boolean;
  button?: typeof Button;
  placeholder?: string;
  emptyOnSave?: boolean;
  size?: "small" | "regular";
  autoFocus?: boolean;
  onBlur?: () => void;
  hideButton?: boolean;
  type?: "text" | "password" | "email" | "number" | "tel" | "url";
  isMultiline?: boolean;
}) {
  const [value, setValue] = useState(managedValue);

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <form
      className={"flex space-x-2 items-end w-full"}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          e.preventDefault();
          e.stopPropagation();
          setValue(managedValue);
          onBlur?.();
          return;
        }

        if (e.key === "Enter") {
          e.preventDefault();
          e.stopPropagation();
          onSave(value);
          if (emptyOnSave) {
            setValue("");
          }
        }
      }}
    >
      {isMultiline ? (
        <TextArea
          autoFocus={autoFocus}
          placeholder={placeholder}
          value={value}
          onChange={setValue}
          rows={6}
          onBlur={(e) => {
            if (value !== managedValue) {
              onSave(value);
              if (emptyOnSave) {
                setValue("");
              }
            }
            onBlur?.();
          }}
        />
      ) : (
        <TextInput
          autoFocus={autoFocus}
          size={size}
          placeholder={placeholder}
          value={value}
          onChange={setValue}
          type={type}
          onBlur={(e) => {
            if (value !== managedValue) {
              onSave(value);
              if (emptyOnSave) {
                setValue("");
              }
            }
            onBlur?.();
          }}
        />
      )}

      {!hideButton && value !== managedValue ? (
        <>
          {
            <ButtonComp
              size={size}
              onClick={() => {
                onSave(value);
                if (emptyOnSave) {
                  setValue("");
                }
              }}
            />
          }
        </>
      ) : null}
      {!noDelete ? (
        <DeleteButton
          onClick={() => {
            onSave(null);
            if (emptyOnSave) {
              setValue("");
            }
          }}
        />
      ) : null}
    </form>
  );
}

export function DomainListInput({
  onChange,
  value,
}: {
  value: string[];
  onChange: (v: string[]) => void;
}) {
  const [inputValue, setInputValue] = useState("");

  const isValid = useMemo(() => {
    if (value.includes(inputValue)) {
      return false;
    }
    try {
      new URL(inputValue);
      return true;
    } catch (e) {
      return false;
    }
  }, [inputValue]);

  const submit = () => {
    onChange([...value, inputValue]);
    setInputValue("");
  };

  return (
    <div className={"flex flex-col space-y-2 w-full"}>
      <div key={value.length} className={"flex flex-col space-y-2 w-full"}>
        {value.map((v, idx) => (
          <div key={idx} className={"flex items-center space-x-2"}>
            <span
              className={
                "text-xs font-mono grow p-2 bg-neutral-50 rounded truncate"
              }
            >
              {v}
            </span>
            <Button
              role={"secondary"}
              size={"small"}
              onClick={() => onChange(value.filter((_, i) => i !== idx))}
              icon={Trash2}
            >
              Remove
            </Button>
          </div>
        ))}
      </div>

      <form
        className={"flex items-end space-x-4"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          submit();
        }}
      >
        <TextInput
          labelHorizontal={true}
          value={inputValue}
          onChange={setInputValue}
          label={"Domain"}
          placeholder={"https://example.com"}
        />

        <Button
          type={"submit"}
          onClick={() => {}}
          icon={Plus}
          disabled={!isValid}
        >
          Add
        </Button>
      </form>
    </div>
  );
}
