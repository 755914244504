import { Page } from "../../../../../../components/page/page";
import {
  PageHeaderTitle,
  TabbedHeader,
} from "../../../../../../components/header";
import { Outlet } from "react-router-dom";
import { useBreadcrumbItems, useContextualNavigation } from "../../nav";
import {
  useCRMCompany,
  useCRMCompanySchema,
  useEnvironmentEventsForResource,
  usePatchAPI,
  withErrorToast,
} from "../../../../../../../data";
import {
  toEnvironmentCRMCompany,
  toEnvironmentCRMCompanyNotes,
  useCompanyId,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../../routes";
import toast from "react-hot-toast";
import { CRMSidebar } from "../../sidebar";
import { CRMNotesPrimaryResource, EventResourceKind } from "@anzuhq/backend";
import { navigationHeight } from "../../../../../../navigation";
import { headerHeight } from "../../../developers/webhooks/[webhookId]/page";
import { CreateNotes } from "../../notes";

export function CRMCompanyPage() {
  const navItems = useContextualNavigation();
  const breadcrumbItems = useBreadcrumbItems();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const companyId = useCompanyId();

  const { company, mutate } = useCRMCompany(
    workspaceId,
    environmentId,
    companyId
  );

  const { mutate: mutateActivity } = useEnvironmentEventsForResource(
    workspaceId,
    environmentId,
    EventResourceKind.CRMCompany,
    companyId
  );

  const { schema } = useCRMCompanySchema(workspaceId, environmentId);
  const patchApi = usePatchAPI();

  const updateCompany = async (id: string, data: Record<string, unknown>) => {
    if (!company) {
      return;
    }
    await withErrorToast(async () => {
      await mutate(
        {
          ...company,
          ...data,
        },
        {
          revalidate: false,
        }
      );
      await patchApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/crm/companies/${id}`,
        data
      );
      await mutate();
      await mutateActivity();
      toast.success("Company updated");
    });
  };

  return (
    <Page
      title={"Company - CRM"}
      contextualNavigationItems={navItems}
      breadcrumbItems={breadcrumbItems}
    >
      <div
        className={
          "w-full h-full flex justify-start overflow-auto grid-flow-col"
        }
      >
        <div className={"w-full grow flex flex-col overflow-hidden"}>
          <TabbedHeader
            tabs={[
              {
                label: "Overview",
                path: toEnvironmentCRMCompany(
                  workspaceId,
                  environmentId,
                  companyId
                ),
              },
              {
                label: "Notes",
                path: toEnvironmentCRMCompanyNotes(
                  workspaceId,
                  environmentId,
                  companyId
                ),
              },
            ]}
          >
            <>
              <PageHeaderTitle>{company?.name}</PageHeaderTitle>

              <div className={"ml-auto flex items-center space-x-2"}>
                <CreateNotes
                  primaryResource={CRMNotesPrimaryResource.Company}
                  company={companyId}
                  mutateActivity={mutateActivity}
                />
              </div>
            </>
          </TabbedHeader>

          <div
            className={"overflow-auto flex"}
            style={{
              height: `calc(100vh - ${navigationHeight} - ${headerHeight})`,
            }}
          >
            <Outlet />
          </div>
        </div>

        <CRMSidebar
          schema={schema || null}
          entity={company}
          updateEntity={updateCompany}
          sidebarFieldOrder={sidebarFieldOrder}
        />
      </div>
    </Page>
  );
}

const sidebarFieldOrder = ["assigned_to", "stage", "industry", "domain"];
