import { DatabaseColor } from "@anzuhq/backend";

export function getIconColor(color: DatabaseColor): string {
  switch (color) {
    case DatabaseColor.Green:
      return "text-green-500";

    case DatabaseColor.Orange:
      return "text-orange-500";

    case DatabaseColor.Black:
      return "text-neutral-800";

    case DatabaseColor.Red:
      return "text-red-500";
  }
}

export function getTextColor(color: DatabaseColor): string {
  switch (color) {
    case DatabaseColor.Green:
      return "text-green-700";

    case DatabaseColor.Orange:
      return "text-orange-700";

    case DatabaseColor.Black:
      return "text-neutral-700";

    case DatabaseColor.Red:
      return "text-red-700";
  }
}
