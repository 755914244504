import { Page } from "./components/page/page";
import { useClearCache } from "../data";
import {
  buildCommitSha,
  disableCache,
  useApiEndpointContext,
  apiEndpoint as defaultApiEndpoint,
} from "../env";
import { DeleteButton } from "../components/basics/button";
import { BufferedTextInput } from "../components/basics/input";

export function useIsDevelopment() {
  return buildCommitSha === "development";
}

export function useIsProductionApi() {
  const [apiEndpoint] = useApiEndpointContext();
  return apiEndpoint === "https://api.anzuhq.com";
}

export function DebugPage() {
  const clearCache = useClearCache();
  const [apiEndpoint, setApiEndpoint] = useApiEndpointContext();

  return (
    <Page
      title={"Debug"}
      contextualNavigationItems={[
        {
          active: false,
          to: "/",
          label: "Overview",
        },
        {
          active: true,
          to: "/debug",
          label: "Debug",
        },
      ]}
      contextualNavigationItemsLoading={false}
      breadcrumbItems={[]}
    >
      <div className={"grid grid-cols-12"}>
        <div className={"col-span-1"} />

        <div className={"pt-24 col-span-10 flex flex-col space-y-2"}>
          <p>Version: {buildCommitSha}</p>
          <p>API Endpint: {apiEndpoint}</p>

          <BufferedTextInput
            onSave={(v) => setApiEndpoint(v || defaultApiEndpoint)}
            value={apiEndpoint}
          />

          <p>Disable Cache: {disableCache ? "true" : "false"}</p>

          <div>
            <DeleteButton onClick={clearCache}>Clear cache</DeleteButton>
          </div>
        </div>

        <div className={"col-span-10"} />
      </div>
    </Page>
  );
}
