import { Page } from "../../../../../components/page/page";
import { useBreadcrumbItems, useContextualNavigation } from "../nav";
import {
  LightTable,
  selectAction,
} from "../../../../../components/table/light-table";
import { useMemo, useState } from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
  HeaderContext,
} from "@tanstack/react-table";
import { Webhook } from "@anzuhq/backend";
import { ButtonLink } from "../../../../../../components/basics/button";
import {
  toEnvironmentDevelopersWebhook,
  toEnvironmentDevelopersWebhookCreate,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../routes";
import {
  renderBoolean,
  renderDateTime,
  renderNameLink,
  renderUrl,
} from "../../../../../database/renderer";
import {
  useDeleteAPI,
  useEnvironmentWebhooks,
  usePatchAPI,
  withErrorToast,
} from "../../../../../../data";
import toast from "react-hot-toast";
import {
  Dropdown,
  DropdownItemButton,
  DropdownItemButtonWithAlertDialog,
  DropdownListContainer,
} from "../../../../../database/dropdown";
import classNames from "classnames";
import {
  MoreVertical,
  PauseCircle,
  PlayCircle,
  Plus,
  Trash2,
} from "../../../../../../icons";
import {
  PageHeaderTitle,
  SimpleHeader,
} from "../../../../../components/header";

function AllRowsActions({
  table,
  mutate,
}: HeaderContext<Webhook, any> & { mutate: () => void }) {
  const [isOpen, setIsOpen] = useState(false);

  const patchApi = usePatchAPI();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const disableWebhooks = async () => {
    setIsOpen(false);

    const selected = table.getSelectedRowModel().rows.map((r) => r.original.id);

    await withErrorToast(async () => {
      for (const id of selected) {
        await patchApi(
          `/workspaces/${workspaceId}/environments/${environmentId}/webhooks/${id}`,
          {
            is_enabled: false,
          }
        );
      }
      toast.success("Disabled webhooks");
      mutate();
    });
  };

  return (
    <Dropdown
      activator={
        <button
          disabled={table.getSelectedRowModel().rows.length === 0}
          className={"w-full flex items-center"}
        >
          <span
            className={classNames(
              "w-8 px-2 py-px border border-neutral-200 rounded text-xs",
              table.getSelectedRowModel().rows.length === 0
                ? "invisible"
                : "visible"
            )}
          >
            {table.getSelectedRowModel().rows.length || 0}
          </span>

          <MoreVertical
            className={
              table.getSelectedRowModel().rows.length === 0
                ? "text-neutral-500"
                : "text-neutral-800"
            }
            size={16}
          />
        </button>
      }
      open={isOpen}
      setOpen={setIsOpen}
    >
      <DropdownListContainer>
        <DropdownItemButtonWithAlertDialog
          onOpenChange={(o) => (!o ? setIsOpen(false) : null)}
          title={"Danger"}
          message={`Are you sure you want to disable multiple webhooks?`}
          confirmLabel={"Disable multiple webhooks"}
          onClick={disableWebhooks}
        >
          <div className={"flex items-center space-x-2"}>
            <PauseCircle size={14} />
            <span>Disable All</span>
          </div>
        </DropdownItemButtonWithAlertDialog>
      </DropdownListContainer>
    </Dropdown>
  );
}

function SomeRowsActions({
  row,
  mutate,
}: CellContext<Webhook, any> & { mutate: () => void }) {
  const webhook = row.original;

  const patchApi = usePatchAPI();
  const deleteApi = useDeleteAPI();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const [isOpen, setIsOpen] = useState(false);

  const toggleWebhookEnabled = async () => {
    setIsOpen(false);

    await withErrorToast(async () => {
      await patchApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/webhooks/${webhook.id}`,
        {
          is_enabled: !webhook.is_enabled,
        }
      );
      toast.success(
        webhook.is_enabled ? "Disabled webhook" : "Enabled webhook"
      );
      mutate();
    });
  };

  const deleteWebhook = async () => {
    setIsOpen(false);

    await withErrorToast(async () => {
      await deleteApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/webhooks/${webhook.id}`
      );
      toast.success("Deleted webhook");
      mutate();
    });
  };

  return (
    <Dropdown
      activator={
        <button className={"w-full flex items-center"}>
          <span
            className={classNames(
              "w-8 px-2 py-px border border-neutral-200 rounded text-xs invisible"
            )}
          />

          <MoreVertical size={16} />
        </button>
      }
      open={isOpen}
      setOpen={setIsOpen}
    >
      <DropdownListContainer>
        {webhook.is_enabled ? (
          <DropdownItemButtonWithAlertDialog
            onOpenChange={(o) => (!o ? setIsOpen(false) : null)}
            title={"Danger"}
            message={`Are you sure you want to disable this webhook`}
            confirmLabel={"Disable"}
            onClick={toggleWebhookEnabled}
          >
            <div className={"flex items-center space-x-2"}>
              <PauseCircle size={14} />
              <span>Disable</span>
            </div>
          </DropdownItemButtonWithAlertDialog>
        ) : (
          <DropdownItemButton onClick={toggleWebhookEnabled}>
            <div className={"flex items-center space-x-2"}>
              <PlayCircle size={14} />
              <span>Enable</span>
            </div>
          </DropdownItemButton>
        )}

        <DropdownItemButtonWithAlertDialog
          onOpenChange={(o) => (!o ? setIsOpen(false) : null)}
          title={"Danger"}
          message={
            "Are you sure you want to delete this webhook? This cannot be undone."
          }
          confirmLabel={"Delete webhook irrevocably"}
          onClick={deleteWebhook}
        >
          <div className={"flex items-center space-x-2 text-red-500"}>
            <Trash2 size={14} />
            <span>Delete</span>
          </div>
        </DropdownItemButtonWithAlertDialog>
      </DropdownListContainer>
    </Dropdown>
  );
}

export function WebhooksList() {
  const navItems = useContextualNavigation();
  const breadcrumbItems = useBreadcrumbItems();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { mutate, webhooks, isLoading } = useEnvironmentWebhooks(
    workspaceId,
    environmentId
  );

  const columns = useMemo<ColumnDef<Webhook, any>[]>(() => {
    const columnHelper = createColumnHelper<Webhook>();

    const dropdownActions: ColumnDef<Webhook, any> = {
      id: "actions",
      enableResizing: false,
      size: 10,
      maxSize: 10,
      minSize: 10,
      header: (c) => <AllRowsActions {...c} mutate={mutate} />,
      cell: (c) => <SomeRowsActions {...c} mutate={mutate} />,
    };

    const defs: ColumnDef<Webhook, any>[] = [
      selectAction<Webhook>(),

      columnHelper.accessor("name", {
        cell: renderNameLink((id: string) =>
          toEnvironmentDevelopersWebhook(workspaceId, environmentId, id)
        ),
        header: "Name",
        enableResizing: true,
        enableHiding: true,
      }),
      columnHelper.accessor("is_enabled", {
        cell: renderBoolean(),
        header: "Enabled",
        enableResizing: true,
        enableHiding: true,
      }),
      columnHelper.accessor("created_at", {
        cell: renderDateTime(),
        header: "Created At",
        enableResizing: true,
        enableHiding: true,
      }),
      columnHelper.accessor("endpoint", {
        cell: renderUrl(),
        header: "Endpoint",
        enableResizing: true,
        enableHiding: true,
      }),
    ];

    defs.push(dropdownActions);

    return defs;
  }, [workspaceId, environmentId, mutate]);

  return (
    <Page
      title={"Webhooks"}
      contextualNavigationItems={navItems}
      breadcrumbItems={breadcrumbItems}
    >
      <div className={"flex flex-col h-full"}>
        <SimpleHeader>
          <PageHeaderTitle>Webhooks</PageHeaderTitle>

          <div className={"ml-auto"}>
            <ButtonLink
              weight={"regular"}
              to={toEnvironmentDevelopersWebhookCreate(
                workspaceId,
                environmentId
              )}
              icon={Plus}
            >
              Create Webhook
            </ButtonLink>
          </div>
        </SimpleHeader>

        <LightTable
          data={webhooks || null}
          isLoading={isLoading}
          columns={columns}
          emptyMessage={"No webhooks created."}
        />
      </div>
    </Page>
  );
}
