import { useMatch } from "react-router-dom";
import {
  toEnvironmentDevelopers,
  toEnvironmentDevelopersWebhooks,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../routes";
import {
  BreadcrumbItem,
  ContextualNavigationItem,
} from "../../../../navigation";

export const useContextualNavigation = () => {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const webhooksPath = toEnvironmentDevelopersWebhooks(
    workspaceId,
    environmentId
  );

  const onWebhooks = useMatch({
    path: webhooksPath,
    end: false,
  });

  const items: ContextualNavigationItem[] = [
    {
      to: webhooksPath,
      active: onWebhooks !== null,
      label: "Webhooks",
    },
  ];

  return items;
};

export function useBreadcrumbItems() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const items: BreadcrumbItem[] = [
    {
      to: toEnvironmentDevelopers(workspaceId, environmentId),
      label: "Developers",
    },
  ];

  return items;
}
