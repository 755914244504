import { useNavigate, useParams } from "react-router-dom";
import {
  isNotFound,
  useAccountInvite,
  usePostAPI,
  withErrorToast,
} from "../../../data";
import { useState } from "react";
import toast from "react-hot-toast";
import { confirmHotkey, useHotkeys } from "../../../hooks/keypress";
import { IconTextLink } from "../../../components/basics/link";
import { ArrowLeft } from "../../../icons";
import { Button } from "../../../components/basics/button";
import { Page } from "../../components/page/page";
import { toWorkspaceDashboard } from "../../routes";
import { motion } from "framer-motion";

export function InvitePage() {
  const { inviteId } = useParams();
  const navigate = useNavigate();
  const postApi = usePostAPI();

  const { invite, error, isLoading } = useAccountInvite(inviteId!);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const acceptInvite = async () => {
    if (!invite || isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await withErrorToast(async () => {
      await postApi(`/invites/${invite.id}/accept`, {});

      toast.success("Invite accepted");
      navigate(toWorkspaceDashboard(invite.workspace.id));
    });
    setIsSubmitting(false);
  };

  useHotkeys(confirmHotkey, acceptInvite);

  if (isLoading) {
    return null;
  } else {
    if (isNotFound(error) || !invite) {
      return (
        <Page title={"Invite Not Found"}>
          <motion.div className="grid place-items-center grow w-full h-full">
            <div className="w-1/3 h-1/3 flex flex-col space-y-8">
              <IconTextLink icon={ArrowLeft} size={16} to="/">
                Back
              </IconTextLink>
              <div className={"flex flex-col space-y-2 leading-normal"}>
                <h2 className={"text-lg font-medium text-neutral-700"}>
                  Invite not found
                </h2>
                <p className={"text-neutral-500"}>
                  This invite could not found, or it was sent to another account
                  than the one you&apos;re currently using. Please verify that
                  you are logged in with the correct account and try again.
                </p>
              </div>
            </div>
          </motion.div>
        </Page>
      );
    }
  }

  return (
    <Page title={"Accept Invite"}>
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2, delay: 0.1 }}
        className="grid place-items-center grow w-full h-full"
      >
        <div className="w-1/3 h-1/3 flex flex-col space-y-8">
          <IconTextLink icon={ArrowLeft} size={16} to="/">
            Back
          </IconTextLink>
          {invite.invited_by ? (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2, delay: 0.2 }}
              className="flex flex-row items-center space-x-2"
            >
              <img
                alt="profile"
                src={invite.invited_by.picture}
                className="rounded-md w-8 h-8"
              />
              <p>
                {invite.invited_by.name} invited you to join{" "}
                <b>{invite.workspace.name}</b>
              </p>
            </motion.div>
          ) : (
            <p>You are invited to join {invite.workspace.name}</p>
          )}

          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2, delay: 0.4 }}
            className="flex"
          >
            <Button grow isLoading={isSubmitting} onClick={acceptInvite}>
              Accept Invite
            </Button>
          </motion.div>
        </div>
      </motion.div>
    </Page>
  );
}
