import classNames from "classnames";
import { PropsWithChildren } from "react";
import {
  Link as RouterLink,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import { BookOpen, Icon } from "../../icons";

export function Link({
  to,
  children,
  ref,
  ...props
}: PropsWithChildren<{ to: string } & React.HTMLProps<HTMLAnchorElement>>) {
  if (to.startsWith("http") || to.startsWith("mailto")) {
    return (
      <a {...props} href={to}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink to={to} {...(props as any)}>
      {children}
    </RouterLink>
  );
}

export function BoxLink({
  to,
  children,
  className,
}: PropsWithChildren<{ to: string; className?: string }>) {
  return (
    <Link to={to} className="w-full block">
      <div
        className={classNames(
          "rounded-md hover:bg-neutral-100 active:bg-neutral-200 px-2 py-1",
          className
        )}
      >
        {children}
      </div>
    </Link>
  );
}

export function TextLink({
  to,
  children,
  className,
}: PropsWithChildren<{ to: string; className?: string }>) {
  const isActive = useIsActive(to);

  return (
    <Link
      to={to}
      className={classNames(
        "rounded-md p-1 text-neutral-500 hover:text-neutral-800 active:text-black text-sm",
        {
          "text-black": isActive,
        },
        className
      )}
    >
      {children}
    </Link>
  );
}

export function IconBoxLink({
  to,
  icon: IconComp,
  children,
  size,
}: PropsWithChildren<{ to: string; icon: Icon; size: number }>) {
  const isActive = useIsActive(to);
  return (
    <BoxLink to={to}>
      <div className="flex flex-row items-center">
        <IconComp
          size={size}
          className={classNames("mr-2", {
            "stroke-sky-500": isActive,
          })}
        />
        {children}
      </div>
    </BoxLink>
  );
}

export function IconTextLink({
  to,
  icon: IconComp,
  children,
  size,
  className,
}: PropsWithChildren<{
  to: string;
  icon: Icon;
  size: number;
  className?: string;
}>) {
  const isActive = useIsActive(to);
  return (
    <TextLink to={to} className={className}>
      <span className="flex flex-row items-center">
        <IconComp
          size={size}
          className={classNames("mr-2", {
            "stroke-sky-500": isActive,
          })}
        />
        {children}
      </span>
    </TextLink>
  );
}

export function useIsActive(path: string) {
  const resolved = useResolvedPath(path);
  const match = useMatch({ path: resolved.pathname, end: true });

  const isActive = match !== null;

  return isActive;
}

export function DocsLink({
  to,
  children,
  className,
}: PropsWithChildren<{ to: string; className?: string }>) {
  return (
    <a
      className={classNames(
        "inline text-neutral-700 hover:text-neutral-800 pointer-events-auto",
        className
      )}
      href={`https://anzuhq.com/docs${to}`}
      target={"_blank"}
      rel={"noreferrer"}
    >
      {/* Links within hover cards are not clickable, so we add a button as a workaround while keeping the link accessibility */}
      <button
        className={"inline-flex items-center space-x-2 appearance-none"}
        onClick={() => {
          window.open(`https://anzuhq.com/docs${to}`, "_blank");
        }}
      >
        <BookOpen size={14} />
        <span>{children}</span>
      </button>
    </a>
  );
}
