import {
  useEnvironmentId,
  useUserIdentityId,
  useWorkspaceId,
} from "../../../../../../../routes";
import {
  useUserManagementUserIdentity,
  useUserManagementUserIdentityAuthAttempts,
  useUserManagementUserIdentitySchema,
} from "../../../../../../../../data";
import {
  DatabaseFieldKind,
  UserAuthAttemptStatus,
  IUserIdentity,
  IUserAuthAttempt,
  DatabaseSchema,
} from "@anzuhq/backend";
import { CheckCircle, Circle, XCircle } from "../../../../../../../../icons";
import classNames from "classnames";
import { retrieveUserIdentityName } from "../page";
import { useMemo } from "react";
import { RenderIcon } from "../../../../../../../database/renderer";
import { DateTimeRendererWithFormats } from "../../../../../../../database/datetime";

function ProviderInfo({
  attempt,
  schema,
}: {
  attempt: IUserAuthAttempt;
  schema: DatabaseSchema;
}) {
  const value = useMemo(() => {
    const providerField = schema.fields.find((f) => f.name === "provider");
    if (!providerField || providerField.kind !== DatabaseFieldKind.Enum) {
      return null;
    }
    const provider = providerField.enumValues.find(
      (o) => o.value === attempt.provider
    );
    return provider;
  }, [attempt.provider]);
  if (!value) {
    return null;
  }
  return (
    <>
      <span>using</span>
      <div
        className={
          "flex items-center space-x-2 px-2 py-px rounded bg-neutral-100"
        }
      >
        <RenderIcon icon={value.icon} />
        <span>{value.label}</span>
      </div>
    </>
  );
}

function AuthAttemptItem({
  authAttempt: t,
  identity,
  schema,
}: {
  identity: IUserIdentity;
  authAttempt: IUserAuthAttempt;
  schema: DatabaseSchema;
}) {
  return (
    <li key={t.id} className="mb-10 ml-6">
      <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-200 rounded-full ring-8 ring-white dark:ring-neutral-900 dark:bg-blue-900">
        <div
          className={classNames(
            "rounded-full w-6 h-6 flex items-center justify-center",
            {
              "bg-green-600": t.status === UserAuthAttemptStatus.Accepted,
              "bg-red-600": t.status === UserAuthAttemptStatus.Failed,
              "bg-amber-600": t.status === UserAuthAttemptStatus.Pending,
            }
          )}
        >
          {t.status === UserAuthAttemptStatus.Accepted ? (
            <CheckCircle size={14} color={"white"} />
          ) : t.status === UserAuthAttemptStatus.Pending ? (
            <Circle size={14} color={"white"} />
          ) : (
            <XCircle size={14} color={"white"} />
          )}
        </div>
      </span>

      <div className="justify-between items-center p-4 bg-white rounded border border-neutral-200 xl:flex dark:bg-neutral-700 dark:border-neutral-600">
        <div
          className={
            "mb-1 text-xs font-normal text-neutral-400 sm:order-last sm:mb-0 whitespace-nowrap"
          }
        >
          <DateTimeRendererWithFormats relative value={t.created_at} />
        </div>

        <div className="text-sm font-normal text-neutral-800 flex space-x-1 whitespace-nowrap flex-wrap">
          <span>
            {identity ? retrieveUserIdentityName(identity) : "Unknown"}
          </span>

          <span>attempted to log in</span>
          {t.ip_address ? (
            <>
              <span>from</span>
              <span className={"px-2 py-px rounded bg-neutral-100"}>
                {t.ip_address}
              </span>
            </>
          ) : null}

          <ProviderInfo schema={schema} attempt={t} />
          {t.user_agent ? (
            <>
              <span>with user agent</span>
              <pre
                className={
                  "px-2 py-px rounded bg-neutral-100 truncate w-48 shrink"
                }
                title={t.user_agent}
              >
                {t.user_agent}
              </pre>
            </>
          ) : null}
        </div>
      </div>
    </li>
  );
}

function LoadingItem() {
  return (
    <li className="mb-10 ml-6">
      <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-200 rounded-full ring-8 ring-white dark:ring-neutral-900 dark:bg-blue-900">
        <div
          className={
            "rounded-full w-6 h-6 flex items-center justify-center animate-pulse bg-neutral-600"
          }
        >
          <CheckCircle size={14} color={"white"} />
        </div>
      </span>

      <div className="justify-between items-center p-4 bg-white rounded-lg border border-neutral-200 xl:flex dark:bg-neutral-700 dark:border-neutral-600">
        <time className="mb-1 text-xs font-normal text-neutral-400 sm:order-last sm:mb-0 whitespace-nowrap animate-pulse">
          00:00:00
        </time>
        <div className="text-sm font-normal text-neutral-800 flex space-x-1 whitespace-nowrap flex-wrap animate-pulse">
          <span>Unknown</span>
        </div>
      </div>
    </li>
  );
}

export function UserIdentityAuthAttemptsView() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const userIdentityId = useUserIdentityId();

  const { identity, isLoading: isLoadingIdentity } =
    useUserManagementUserIdentity(workspaceId, environmentId, userIdentityId);

  const { attempts, isLoading: isLoadingAttempts } =
    useUserManagementUserIdentityAuthAttempts(
      workspaceId,
      environmentId,
      userIdentityId
    );

  const isLoading = isLoadingIdentity || isLoadingAttempts;

  const { schema } = useUserManagementUserIdentitySchema(
    workspaceId,
    environmentId
  );

  return (
    <div className={"grid grid-cols-12 grow"}>
      <div className={"col-span-1"} />

      <div className={"col-span-10 py-8"}>
        {isLoading ? (
          <>
            <LoadingItem />
            <LoadingItem />
            <LoadingItem />
            <LoadingItem />
            <LoadingItem />
          </>
        ) : (
          <>
            {identity && attempts && attempts.length > 0 ? (
              <ol className="relative border-l border-neutral-200 dark:border-neutral-700">
                {attempts.map((t) => (
                  <AuthAttemptItem
                    key={t.id}
                    identity={identity}
                    authAttempt={t}
                    schema={schema || { fields: [] }}
                  />
                ))}
              </ol>
            ) : (
              <div
                className={
                  "w-full h-24 flex flex-col bg-neutral-50 rounded items-center justify-center border border-neutral-200 text-sm text-neutral-800"
                }
              >
                No recent auth attempts found.
              </div>
            )}
          </>
        )}
      </div>

      <div className={"col-span-1"} />
    </div>
  );
}
