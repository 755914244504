import React, { PropsWithChildren, useMemo, useState } from "react";

import {
  Dropdown,
  DropdownItemButton,
  DropdownListContainer,
  DropdownTextInput,
} from "./dropdown";
import { RenderIcon } from "./renderer";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { ArrowDown, ArrowUp, X } from "../../icons";
import classNames from "classnames";
import {
  DatabaseField,
  DatabaseSchema,
  DatabaseSort,
  DatabaseSortDirection,
} from "@anzuhq/backend";
import { useHotkeys } from "../../hooks/keypress";

const DirectionToggle = ({
  setDirection,
  direction,
}: {
  direction: DatabaseSortDirection;
  setDirection: (dir: DatabaseSortDirection) => void;
}) => (
  <ToggleGroup.Root
    type="single"
    value={direction}
    onValueChange={(v) => {
      if (v === "") {
        return;
      }
      setDirection(v as DatabaseSortDirection);
    }}
    className={
      "border border-neutral-300 rounded-md flex items-center divide-x divide-neutral-300"
    }
  >
    <ToggleGroup.Item
      className={classNames("flex items-center space-x-2 py-1 px-2 grow", {
        "bg-neutral-300": direction === "asc",
      })}
      value="asc"
    >
      <ArrowUp size={16} />
      <span>Ascending</span>
    </ToggleGroup.Item>
    <ToggleGroup.Item
      className={classNames("flex items-center space-x-2 py-1 px-2 grow", {
        "bg-neutral-300": direction === "desc",
      })}
      value="desc"
    >
      <ArrowDown size={16} />
      <span>Descending</span>
    </ToggleGroup.Item>
  </ToggleGroup.Root>
);

function SortSelectField({
  setSort,
  schema,
}: {
  schema: DatabaseSchema;
  setSort: (sort: DatabaseSort) => void;
}) {
  const [search, setSearch] = useState("");
  const filteredFields = useMemo(() => {
    return schema.fields.filter(
      (field) =>
        field.isSortable &&
        (field.label.toLowerCase().includes(search.toLowerCase()) ||
          field.name.toLowerCase().includes(search.toLowerCase()))
    );
  }, [schema, search]);

  return (
    <>
      <DropdownTextInput
        value={search}
        setValue={setSearch}
        placeholder={"Search..."}
      />

      <DropdownListContainer>
        {filteredFields.map((f) => (
          <DropdownItemButton
            key={f.name}
            onClick={() => {
              setSort({
                field: f.name,
                direction:
                  f.defaultSortDirection || DatabaseSortDirection.Ascending,
              });
            }}
          >
            <div className={"group flex items-center space-x-4 w-full"}>
              {f.icon ? <RenderIcon icon={f.icon} /> : null}
              <span>{f.label}</span>
            </div>
          </DropdownItemButton>
        ))}
      </DropdownListContainer>
    </>
  );
}

export function SortDialog({
  children,
  schema,
  sort,
  setSort,
  enableHotkey,
}: PropsWithChildren<{
  schema: DatabaseSchema;
  sort: DatabaseSort | null;
  setSort: (sort: DatabaseSort | null) => void;
  enableHotkey?: boolean;
}>) {
  const [open, setOpen] = useState(false);
  const sortedField = useMemo(() => {
    return schema.fields.find((f) => f.name === sort?.field);
  }, [sort, schema]);

  useHotkeys("s", () => setOpen(true), [enableHotkey], true);

  return (
    <Dropdown activator={children} open={open} setOpen={setOpen}>
      {sort && sortedField ? (
        <SortEdit setSort={setSort} sort={sort} sortedField={sortedField} />
      ) : (
        <SortSelectField
          schema={schema}
          setSort={(sort) => {
            setSort(sort);
            setOpen(false);
          }}
        />
      )}
    </Dropdown>
  );
}

export function SortEdit({
  sortedField,
  sort,
  setSort,
}: {
  sort: DatabaseSort;
  setSort: (sort: DatabaseSort | null) => void;
  sortedField: DatabaseField;
}) {
  return (
    <div className={"flex flex-col space-y-2 p-2"}>
      <DropdownItemButton onClick={() => setSort(null)}>
        <X size={16} />
        <span>Clear Sort</span>
      </DropdownItemButton>

      <div className={"flex items-center space-x-1"}>
        <span>sorting by</span>
        {sortedField.icon ? <RenderIcon icon={sortedField.icon} /> : null}
        <span>{sortedField.label}</span>
      </div>

      <DirectionToggle
        direction={sort.direction}
        setDirection={(d) => setSort({ field: sort.field, direction: d })}
      />
    </div>
  );
}

export function Sort({
  setSort,
  sort,
  schema,
}: {
  sort: DatabaseSort;
  setSort: (sort: DatabaseSort | null) => void;
  schema: DatabaseSchema;
}) {
  const sortedField = useMemo(() => {
    return schema.fields.find((f) => f.name === sort.field);
  }, [sort, schema]);

  if (!sortedField) {
    return null;
  }

  return (
    <SortDialog
      enableHotkey={false}
      schema={schema}
      sort={sort}
      setSort={setSort}
    >
      <button
        className={
          "flex items-center space-x-2 border border-neutral-200 rounded-full py-px pl-1 pr-2 text-sm font-regular"
        }
      >
        <div className={"p-px bg-neutral-300 rounded-full"}>
          {sort.direction === "asc" ? (
            <ArrowUp size={14} />
          ) : (
            <ArrowDown size={14} />
          )}
        </div>
        {sortedField.icon ? (
          <RenderIcon size={14} icon={sortedField.icon} />
        ) : null}
        <span>{sortedField.label}</span>
      </button>
    </SortDialog>
  );
}
