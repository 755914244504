import * as HoverCard from "@radix-ui/react-hover-card";
import { useMemo } from "react";
import { DatabaseMonetaryValue } from "@anzuhq/backend";
import { useEnvironmentId, useWorkspaceId } from "../routes";
import {
  useEnvironment,
  useEnvironmentCurrencyConversionRates,
} from "../../data";

export function getCurrentLocale() {
  return navigator.language || "en-US";
}

export function formatMonetaryValue(value: DatabaseMonetaryValue) {
  return new Intl.NumberFormat(getCurrentLocale(), {
    style: "currency",
    currency: value.currency,
  }).format(value.value);
}

export function MonetaryValueRendererWithConversion({
  value,
}: {
  value: DatabaseMonetaryValue;
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { environment } = useEnvironment(workspaceId, environmentId);

  const { data } = useEnvironmentCurrencyConversionRates(
    workspaceId,
    environmentId
  );

  const formattedValue = useMemo(() => {
    if (!value) {
      return null;
    }

    return formatMonetaryValue(value);
  }, [value]);

  const formattedValueInBaseCurrency = useMemo(() => {
    if (!value) {
      return null;
    }

    if (!data || !environment) {
      return null;
    }

    if (environment.currency === value.currency) {
      return formattedValue;
    }

    const exchangeRate = data.find(
      (item) => item.to_currency === value.currency
    );
    if (!exchangeRate) {
      return null;
    }

    return formatMonetaryValue({
      value: value.value / exchangeRate.rate,
      currency: environment.currency,
    });
  }, [environment, formattedValue, value, data]);

  return (
    <HoverCard.Root>
      <HoverCard.Trigger asChild>
        <span
          className={
            "font-normal text-sm text-underline underline underline-offset-2 decoration-neutral-300"
          }
        >
          {formattedValue}
        </span>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content sideOffset={5}>
          <HoverCard.Arrow className="fill-neutral-200" />
          <div className={"bg-white shadow flex flex-col rounded"}>
            <div
              className={
                "rounded-t p-2 bg-neutral-100 text-xs text-neutral-700 uppercase border-b border-neutral-200"
              }
            >
              Currency conversion
            </div>
            <div className={"flex flex-col space-y-2 p-2 bg-white rounded-b"}>
              <div className={"flex items-center justify-between"}>
                <span className="text-neutral-800 text-xs">Currency</span>
                <span className={"text-sm text-neutral-600"}>
                  {formattedValue}
                </span>
              </div>
              <div className={"flex items-center justify-between"}>
                <span className="text-neutral-800 text-xs">
                  Local currency (your account)
                </span>
                <span className={"pl-4 text-sm text-neutral-600"}>
                  {formattedValueInBaseCurrency}
                </span>
              </div>
            </div>
          </div>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
}
