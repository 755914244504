import classNames from "classnames";

import { ArrowDown, Filter as FilterIcon, Grid, Table } from "../../icons";
import { FilterDialog, Filters } from "./filters";

import { useSearchParams } from "react-router-dom";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ButtonWithRef, TabButton } from "../../components/basics/button";
import { TData } from "./renderer";
import { TableView } from "./table";
import { VisibleFieldsDialog } from "./fields";
import { Sort, SortDialog } from "./sort";
import {
  DatabaseFilter,
  DatabaseGroupBy,
  DatabaseSchema,
  DatabaseSearch,
  DatabaseSort,
  DatabaseView,
  DatabaseViewLayout,
  PageInfo,
  PaginationArgs,
} from "@anzuhq/backend";
import { PageHeaderTitle } from "../components/header";
import { DatabaseSearchInput } from "./search";
import { useDebounce } from "../../hooks/debounce";
import {
  BoardGroupDataFetcherComponent,
  BoardGroupFetcherComponent,
  BoardItemRendererComponent,
  BoardView,
} from "./board";
import { GroupByDialog } from "./group_by";

export function useCurrentView(views: DatabaseView[]) {
  const [queryParams, setQueryParams] = useSearchParams();
  const currentViewId = useMemo(
    () => queryParams.get("view") || null,
    [queryParams]
  );

  useEffect(() => {
    if (views.length > 0 && currentViewId === null) {
      const nextParams = new URLSearchParams(queryParams);
      nextParams.set("view", views[0].id);
      setQueryParams(nextParams, { replace: true });
    }
  }, [currentViewId, queryParams, views]);

  const currentView = useMemo(
    () => views.find((v) => v.id === currentViewId),
    [views, currentViewId]
  );

  return currentView;
}

export function useDatabaseSearch() {
  const [search, setSearch] = useState<DatabaseSearch | null>(null);

  const debouncedSearch = useDebounce(search, 500);

  return {
    search,
    setSearch,
    debouncedSearch,
  };
}

export function useViewState(currentView?: DatabaseView) {
  const [filters, setFilters] = useState<DatabaseFilter[]>([]);
  const [visibleFields, setVisibleFields] = useState<string[]>([]);
  const [sort, setSort] = useState<DatabaseSort | null>(null);
  const [groupBy, setGroupBy] = useState<DatabaseGroupBy | null>(null);

  const [previousId, setPreviousId] = useState<string | null>(null);

  useEffect(() => {
    if (currentView && currentView.id !== previousId) {
      setFilters(currentView.filters || []);
      setVisibleFields(
        currentView.fields.filter((f) => f.isHidden !== true).map((f) => f.name)
      );
      setSort(currentView.sort || null);
      setGroupBy(currentView.group_by || null);

      setPreviousId(currentView.id);
    }
  }, [previousId, currentView]);

  return {
    filters,
    setFilters,

    visibleFields,
    setVisibleFields,

    sort,
    setSort,

    groupBy,
    setGroupBy,
  };
}

export const filterHeight = "40px";

export function Database<T extends TData>({
  schema,
  views,
  data,
  toDetailPage,
  title,
  isLoading,
  actions,

  pageInfo,
  currentView,

  paginationArgs,
  setPaginationArgs,

  setFilters,
  filters,

  setVisibleFields,
  visibleFields,

  setSort,
  sort,

  countFiltered,
  countTotal,

  emptyState,

  toDatabaseView,

  search,
  setSearch,

  updateBoardGroup,
  boardGroupDataFetcher,
  boardItemRenderer,
  boardGroupFetcher,

  groupBy,
  setGroupBy,
  onOpenSplitView,
}: {
  schema: DatabaseSchema;

  views: DatabaseView[];
  currentView?: DatabaseView;

  visibleFields: string[];
  setVisibleFields: (fields: string[]) => void;

  filters: DatabaseFilter[];
  setFilters: (filters: DatabaseFilter[]) => void;

  sort: DatabaseSort | null;
  setSort: (sort: DatabaseSort | null) => void;

  groupBy: DatabaseGroupBy | null;
  setGroupBy: (groupBy: DatabaseGroupBy | null) => void;

  data: T[];

  toDetailPage: (id: string) => string;
  toDatabaseView: (viewId: string) => string;

  title: string;
  actions?: ReactNode;
  isLoading?: boolean;

  pageInfo: PageInfo;

  paginationArgs: PaginationArgs;
  setPaginationArgs: (args: PaginationArgs) => void;

  countTotal?: number;
  countFiltered?: number;

  emptyState?: React.ReactNode;

  search: DatabaseSearch | null;
  setSearch: (search: DatabaseSearch | null) => void;

  updateBoardGroup?: (data: T, nextGroupId: string | null) => void;
  boardGroupFetcher?: BoardGroupFetcherComponent;
  boardGroupDataFetcher?: BoardGroupDataFetcherComponent<T>;
  boardItemRenderer?: BoardItemRendererComponent<T>;

  onOpenSplitView?: (id: string) => void;
}) {
  const showFilters = useMemo(() => filters && filters.length > 0, [filters]);

  const hasFilterableFields = useMemo(
    () =>
      schema.fields.some(
        (f) => f.allowedFilterOperators && f.allowedFilterOperators.length > 0
      ),
    [schema]
  );
  const hasSortableFields = useMemo(
    () => schema.fields.some((f) => f.isSortable),
    [schema]
  );
  const hasSearchableFields = useMemo(
    () => schema.fields.some((f) => f.isSearchable),
    [schema]
  );
  const hasGroupableFields = useMemo(
    () => schema.fields.some((f) => f.isGroupable),
    [schema]
  );

  return (
    <div className={"flex flex-col h-full w-full"}>
      <div className={"pt-8 shrink-0"}>
        <div className={"grid grid-cols-12 border-b border-neutral-200"}>
          <div className={"col-span-1"} />
          <div className={"flex flex-col col-span-10"}>
            <div className={"p-2 flex items-center"}>
              <PageHeaderTitle isLoading={isLoading}>{title}</PageHeaderTitle>

              <div className={"ml-auto flex flex-row items-center space-x-2"}>
                {actions}
              </div>
            </div>
            <div className={"flex items-center"}>
              <div
                className={classNames(
                  "col-span-12 flex items-end space-x-2 overflow-y-hidden overflow-x-auto"
                )}
              >
                {views
                  ? views.map((v) => (
                      <TabButton
                        key={v.id}
                        loading={isLoading}
                        active={currentView ? currentView.id === v.id : false}
                        to={toDatabaseView(v.id)}
                      >
                        {v.name}
                      </TabButton>
                    ))
                  : null}
              </div>
              <div className={"ml-auto px-1 flex items-center space-x-1"}>
                {hasSearchableFields ? (
                  <DatabaseSearchInput search={search} setSearch={setSearch} />
                ) : null}

                {hasFilterableFields ? (
                  <FilterDialog
                    enableHotkey
                    schema={schema}
                    filters={filters}
                    addFilter={(f) => setFilters([...filters, f])}
                  >
                    <ButtonWithRef
                      onClick={() => {}}
                      role={"ghost"}
                      size={"small"}
                      icon={FilterIcon}
                      weight={"regular"}
                    >
                      Filter
                    </ButtonWithRef>
                  </FilterDialog>
                ) : null}

                {hasGroupableFields ? (
                  <GroupByDialog
                    schema={schema}
                    setGroupBy={setGroupBy}
                    groupBy={groupBy}
                    enableHotkey
                  >
                    <ButtonWithRef
                      onClick={() => {}}
                      role={groupBy ? "secondary" : "ghost"}
                      size={"small"}
                      icon={Grid}
                      weight={"regular"}
                    >
                      Group By
                    </ButtonWithRef>
                  </GroupByDialog>
                ) : null}

                <VisibleFieldsDialog
                  schema={schema}
                  visibleFields={visibleFields}
                  setVisibleFields={setVisibleFields}
                >
                  <ButtonWithRef
                    onClick={() => {}}
                    role={"ghost"}
                    size={"small"}
                    icon={Table}
                    weight={"regular"}
                  >
                    Fields
                  </ButtonWithRef>
                </VisibleFieldsDialog>

                {hasSortableFields ? (
                  <SortDialog
                    enableHotkey
                    schema={schema}
                    sort={sort}
                    setSort={setSort}
                  >
                    <ButtonWithRef
                      onClick={() => {}}
                      role={"ghost"}
                      size={"small"}
                      icon={ArrowDown}
                      weight={"regular"}
                    >
                      Sort
                    </ButtonWithRef>
                  </SortDialog>
                ) : null}
              </div>
            </div>
          </div>
          <div className={"col-span-1"} />
        </div>
      </div>

      <AnimatePresence>
        {sort || showFilters ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15 }}
            className={`shrink-0 grid grid-cols-12 bg-neutral-50 h-[${filterHeight}]`}
          >
            <div className={"col-span-1"} />
            <div
              className={
                "flex items-end space-x-2 overflow-x-auto p-2 col-span-11"
              }
            >
              {sort ? (
                <div className={"flex items-center space-x-2"}>
                  <Sort schema={schema} sort={sort} setSort={setSort} />
                  <span className={"text-neutral-200 text-sm"}>|</span>
                </div>
              ) : null}
              <Filters
                schema={schema}
                filters={filters}
                setFilters={setFilters}
              />
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>

      {currentView ? (
        <>
          {currentView.layout === DatabaseViewLayout.Table ? (
            <TableView
              toDetailPage={toDetailPage}
              visibleFields={visibleFields}
              schema={schema}
              data={data}
              isLoading={isLoading}
              pageInfo={pageInfo}
              pagination={paginationArgs}
              setPagination={setPaginationArgs}
              emptyState={emptyState}
              countFiltered={countFiltered}
              countTotal={countTotal}
              onOpenSplitView={onOpenSplitView}
            />
          ) : null}
          {boardGroupFetcher &&
          updateBoardGroup &&
          boardGroupDataFetcher &&
          boardItemRenderer &&
          currentView.layout === DatabaseViewLayout.Board ? (
            <BoardView
              toDetailPage={toDetailPage}
              visibleFields={visibleFields}
              schema={schema}
              updateItemGroup={updateBoardGroup}
              isLoading={isLoading}
              groupDataFetcher={boardGroupDataFetcher}
              filters={filters}
              groupBy={groupBy}
              search={search}
              paginationArgs={paginationArgs}
              itemRenderer={boardItemRenderer}
              sort={sort}
              groupFetcher={boardGroupFetcher}
              onOpenSplitView={onOpenSplitView}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
}
