import {
  Database,
  useCurrentView,
  useDatabaseSearch,
  useViewState,
} from "../../../../../database/database";
import {
  toEnvironmentCRMCompaniesView,
  toEnvironmentCRMCompany,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../routes";
import { Page } from "../../../../../components/page/page";
import { useBreadcrumbItems, useContextualNavigation } from "../nav";
import {
  useCountCRMCompanies,
  useCRMCompanies,
  useCRMCompany,
  useCRMCompanySchema,
  useDatabaseViews,
  usePatchAPI,
  withErrorToast,
} from "../../../../../../data";
import { useState } from "react";
import { DatabaseViewKind, PaginationArgs } from "@anzuhq/backend";
import { CRMCreateCompanyDialog } from "./create";
import toast from "react-hot-toast";
import classNames from "classnames";
import { SeparateLink } from "../../../../../database/renderer";
import { CRMSidebar } from "../sidebar";
import { SplitViewMainContainer, SplitViewSideBar } from "../contacts/page";
import { AnimatePresence } from "framer-motion";

export function CRMCompaniesList() {
  const navItems = useContextualNavigation();
  const breadcrumbItems = useBreadcrumbItems();
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { schema } = useCRMCompanySchema(workspaceId, environmentId);

  const { views, isLoading: isLoadingViews } = useDatabaseViews(
    workspaceId,
    environmentId,
    DatabaseViewKind.CRMCompanies
  );

  const currentView = useCurrentView(views || []);
  const {
    setVisibleFields,
    visibleFields,
    filters,
    setFilters,
    sort,
    setSort,
    groupBy,
    setGroupBy,
  } = useViewState(currentView);

  const { debouncedSearch, search, setSearch } = useDatabaseSearch();

  const [paginationArgs, setPaginationArgs] = useState<PaginationArgs>({
    last: null,
    first: 25,
    before: null,
    after: null,
  });

  const {
    companies: data,
    isLoading: isLoadingCompanies,
    mutate: mutateCompanies,
  } = useCRMCompanies(
    workspaceId,
    environmentId,
    paginationArgs,
    filters,
    sort,
    debouncedSearch
  );

  const { data: countAll, mutate: mutateCountAll } = useCountCRMCompanies(
    workspaceId,
    environmentId
  );
  const { data: countFiltered, mutate: mutateCountFiltered } =
    useCountCRMCompanies(workspaceId, environmentId, filters, debouncedSearch);

  const mutate = async () => {
    await mutateCompanies();
    await mutateCountAll();
    await mutateCountFiltered();
  };

  const isLoading = isLoadingCompanies || isLoadingViews;

  const [splitViewOpen, setSplitViewOpen] = useState<string | null>(null);

  return (
    <Page
      title={"Companies - CRM"}
      contextualNavigationItems={navItems}
      breadcrumbItems={breadcrumbItems}
    >
      <div className={"flex h-full w-full"}>
        <SplitViewMainContainer splitViewOpen={splitViewOpen}>
          <Database
            title={"Companies"}
            toDetailPage={(id) =>
              toEnvironmentCRMCompany(workspaceId, environmentId, id)
            }
            actions={<CRMCreateCompanyDialog mutate={mutate} />}
            toDatabaseView={(viewId) =>
              toEnvironmentCRMCompaniesView(workspaceId, environmentId, viewId)
            }
            countTotal={countAll?.count}
            countFiltered={countFiltered?.count}
            data={data?.data || []}
            onOpenSplitView={setSplitViewOpen}
            isLoading={isLoading}
            views={views || []}
            schema={schema || { fields: [] }}
            pageInfo={
              data?.pageInfo || {
                hasPreviousPage: false,
                hasNextPage: false,
                startCursor: null,
                endCursor: null,
              }
            }
            setSort={setSort}
            sort={sort}
            setFilters={setFilters}
            currentView={currentView}
            filters={filters}
            setPaginationArgs={setPaginationArgs}
            paginationArgs={paginationArgs}
            setVisibleFields={setVisibleFields}
            visibleFields={visibleFields}
            search={search}
            setSearch={setSearch}
            emptyState={
              <p className={"text-neutral-800 whitespace-nowrap text-sm"}>
                No companies added.
              </p>
            }
            groupBy={groupBy}
            setGroupBy={setGroupBy}
          />
        </SplitViewMainContainer>

        <AnimatePresence>
          {splitViewOpen ? (
            <CompanySplitView
              companyId={splitViewOpen}
              onClose={() => setSplitViewOpen(null)}
            />
          ) : null}
        </AnimatePresence>
      </div>
    </Page>
  );
}

function CompanySplitView({
  companyId,
  onClose,
}: {
  companyId: string;
  onClose: () => void;
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { company, mutate } = useCRMCompany(
    workspaceId,
    environmentId,
    companyId
  );

  const { schema } = useCRMCompanySchema(workspaceId, environmentId);
  const patchApi = usePatchAPI();

  const updateCompany = async (id: string, data: Record<string, unknown>) => {
    if (!company) {
      return;
    }
    await withErrorToast(async () => {
      await mutate(
        {
          ...company,
          ...data,
        },
        {
          revalidate: false,
        }
      );
      await patchApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/crm/companies/${id}`,
        data
      );
      await mutate();
      toast.success("Company updated");
    });
  };

  return (
    <SplitViewSideBar onClose={onClose}>
      <div className={"flex items-stretch justify-between w-full p-4"}>
        <div className={"flex flex-col justify-start"}>
          <span
            className={classNames("overflow-hidden min-w-0 truncate", {
              "animate-pulse": !company,
            })}
          >
            {company?.name || "loading"}
          </span>
        </div>

        <SeparateLink
          permanent
          to={toEnvironmentCRMCompany(workspaceId, environmentId, companyId)}
        />
      </div>

      <CRMSidebar
        schema={schema || null}
        updateEntity={updateCompany}
        onlyInner
        entity={company}
        sidebarFieldOrder={["domain", "linkedin_url", "assigned_to", "stage"]}
      />
    </SplitViewSideBar>
  );
}
