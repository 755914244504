import { useMatch } from "react-router-dom";
import {
  toEnvironmentCRM,
  toEnvironmentCRMCompanies,
  toEnvironmentCRMContacts,
  toEnvironmentCRMDealsBase,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../routes";
import {
  BreadcrumbItem,
  ContextualNavigationItem,
} from "../../../../navigation";

export const useContextualNavigation = () => {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const contactsPath = toEnvironmentCRMContacts(workspaceId, environmentId);
  const companiesPath = toEnvironmentCRMCompanies(workspaceId, environmentId);
  const dealsPath = toEnvironmentCRMDealsBase(workspaceId, environmentId);

  const onContacts = useMatch({
    path: contactsPath,
    end: false,
  });
  const onCompanies = useMatch({
    path: companiesPath,
    end: false,
  });
  const onDeals = useMatch({
    path: dealsPath,
    end: false,
  });

  const items: ContextualNavigationItem[] = [
    {
      to: contactsPath,
      active: onContacts !== null,
      label: "Contacts",
    },
    {
      to: companiesPath,
      active: onCompanies !== null,
      label: "Companies",
    },
    {
      to: dealsPath,
      active: onDeals !== null,
      label: "Deals",
    },
  ];

  return items;
};

export function useBreadcrumbItems() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const items: BreadcrumbItem[] = [
    {
      to: toEnvironmentCRM(workspaceId, environmentId),
      label: "CRM",
    },
  ];

  return items;
}
