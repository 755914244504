import { Page } from "../../../components/page/page";
import { useContextualNavigation } from "./nav";
import { ArrowRight } from "../../../../icons";
import { Link, Navigate } from "react-router-dom";
import {
  toEnvironmentCRM,
  toEnvironmentDevelopers,
  toEnvironmentInsights,
  toEnvironmentUserManagement,
  toWorkspaceDashboard,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../routes";
import { isNotFound, useEnvironment, useOwnProfile } from "../../../../data";

export function OverviewPage() {
  const contextualItems = useContextualNavigation();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { error } = useEnvironment(workspaceId, environmentId);

  const { profile } = useOwnProfile();

  const firstName = profile?.name?.split(" ")[0] || profile?.name;

  if (isNotFound(error)) {
    return <Navigate to={toWorkspaceDashboard(workspaceId)} />;
  }

  const relatedLinks = [
    {
      title: "User Management",
      description: "Setting up auth for your application",
      url: toEnvironmentUserManagement(workspaceId, environmentId),
    },
    {
      title: "Product Insights",
      description: "Understand how your product is used",

      url: toEnvironmentInsights(workspaceId, environmentId),
    },
    {
      title: "CRM",
      description: "Track all your relationships in one place",
      url: toEnvironmentCRM(workspaceId, environmentId),
    },
    {
      title: "Developers",
      description: "Configure webhooks",
      url: toEnvironmentDevelopers(workspaceId, environmentId),
    },
  ];

  return (
    <Page title={"Overview"} contextualNavigationItems={contextualItems}>
      <div className={"grid grid-cols-12"}>
        <div className={"col-span-1"}></div>
        <div
          className={
            "col-span-10 h-full w-full flex flex-col space-y-4 p-4 pt-24"
          }
        >
          <h1 className={"text-xl"}>Welcome back, {firstName}</h1>

          <div
            className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"}
          >
            {relatedLinks.map((l) => (
              <Link
                to={l.url}
                key={l.url}
                className={
                  "col-span-1 bg-white rounded-md flex flex-col space-y-1 transition group hover:-translate-y-1 border border-neutral-300 hover:border-black"
                }
              >
                <div
                  className={
                    "h-16 bg-gradient-to-tr from-white to-neutral-200 transition group-hover:to-black rounded-t-md"
                  }
                />
                <div className={"px-4 pt-4 flex items-center space-x-2"}>
                  <ArrowRight size={16} />
                  <span className={"text-sm font-medium whitespace-nowrap"}>
                    {l.title}
                  </span>
                </div>
                <span
                  className={
                    "px-4 pb-4 md:w-48 lg:w-64 xl:w-72 text-xs text-neutral-500"
                  }
                >
                  {l.description}
                </span>
              </Link>
            ))}
          </div>
          <div className={"col-span-1"}></div>
        </div>
      </div>
    </Page>
  );
}
