import { PropsWithChildren, useState } from "react";
import {
  UserManagementConfig,
  UserManagementConfigResp,
} from "@anzuhq/backend";
import toast from "react-hot-toast";
import {
  usePatchAPI,
  useUserManagementConfig,
  withErrorToast,
} from "../../../../../../../data";
import { useEnvironmentId, useWorkspaceId } from "../../../../../../routes";
import {
  Button,
  CopyButton,
} from "../../../../../../../components/basics/button";
import { FieldSet } from "../../../../../../components/fieldset";
import { LearnMoreLink } from "../../../../../../components/docs";
import { ConfigLoadErrorPage } from "../../../../../../components/settings";

export function useUserManagementSettingsConfig() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { userManagementConfig, isLoading, mutate } = useUserManagementConfig(
    workspaceId,
    environmentId
  );

  const patchApi = usePatchAPI();

  // save changes to backend
  const saveConfig = async (
    newConfig: Partial<UserManagementConfig>,
    message?: string
  ) => {
    if (!newConfig) {
      return;
    }

    await withErrorToast(async () => {
      await patchApi<{}>(
        `/workspaces/${workspaceId}/environments/${environmentId}/user_management/config`,
        newConfig
      );

      toast.success(message || "Updated configuration successfully!");

      mutate();
    });
  };

  return { userManagementConfig, isLoading, saveConfig };
}

export function SettingsPageLayout(props: PropsWithChildren<{}>) {
  return <div className={"my-16 flex-col space-y-12"}>{props.children}</div>;
}

export function UserManagementSettingsGeneral() {
  const { userManagementConfig, isLoading, saveConfig } =
    useUserManagementSettingsConfig();

  if (isLoading) {
    return <FieldSet footer={<></>} title={""} loading />;
  }

  if (!userManagementConfig) {
    return <ConfigLoadErrorPage />;
  }

  return (
    <SettingsPageLayout>
      <FieldSet
        footer={
          <LearnMoreLink
            to={"https://anzuhq.com/docs/blocks/user-management/hosted-page"}
          >
            Hosted Page
          </LearnMoreLink>
        }
        title={"Hosted Page URL"}
      >
        <p>
          Make sure to redirect users to this URL to sign in or create their
          account.
        </p>

        <div
          className={
            "bg-neutral-100 rounded-md px-2 py-1 flex space-x-2 items-center max-w-fit"
          }
        >
          <pre className={'overflow-auto max-w-full"'}>
            {userManagementConfig.hostedPageUrl}
          </pre>

          <CopyButton
            size={"small"}
            text={userManagementConfig.hostedPageUrl}
          />
        </div>
      </FieldSet>

      <CustomAlias config={userManagementConfig} setConfig={saveConfig} />
    </SettingsPageLayout>
  );
}

function CustomAlias({
  config,
  setConfig,
}: {
  config: UserManagementConfigResp;
  setConfig: (
    config: Partial<UserManagementConfig>,
    message?: string
  ) => Promise<void>;
}) {
  const [alias, setAlias] = useState(config.config.tenant_custom_alias);

  return (
    <FieldSet
      footer={
        <div className={"flex items-center justify-between"}>
          <LearnMoreLink
            to={"https://anzuhq.com/docs/blocks/user-management/hosted-page"}
          >
            Hosted Page
          </LearnMoreLink>

          <Button
            onClick={() => {
              setConfig({ tenant_custom_alias: alias }, "Updated custom alias");
            }}
            size={"small"}
            disabled={alias === config.config.tenant_custom_alias}
          >
            Save
          </Button>
        </div>
      }
      title={"Custom Alias"}
    >
      <p>Choose a custom subdomain for the hosted auth page.</p>

      <AliasInput
        value={alias}
        onChange={setAlias}
        hostedPageDomain={config.hosted_page_domain}
      />
    </FieldSet>
  );
}

function AliasInput({
  onChange,
  value,
  hostedPageDomain,
}: {
  value: string | null;
  onChange: (value: string | null) => void;
  hostedPageDomain: string;
}) {
  return (
    <div
      className={
        "flex items-center space-x-1 rounded-md border border-neutral-200 max-w-fit"
      }
    >
      <span
        className={
          "bg-neutral-50 p-2 border-r border-neutral-200 rounded-l-md grow-0 shrink-0 font-medium"
        }
      >
        https://
      </span>
      <input
        className={"px-3 py-2"}
        value={value || ""}
        placeholder={"custom-alias"}
        onChange={(e) => onChange(e.target.value || null)}
      />
      <span
        className={
          "bg-neutral-50 p-2 border-l border-neutral-200 rounded-r-md grow-0 shrink-0 font-medium"
        }
      >
        .{hostedPageDomain}
      </span>
    </div>
  );
}
