import {
  Database,
  useCurrentView,
  useDatabaseSearch,
  useViewState,
} from "../../../../../../../database/database";
import {
  toEnvironmentCRMDeal,
  toEnvironmentCRMDeals,
  toEnvironmentCRMDealsView,
  useDealPipelineId,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../../../routes";
import { Page } from "../../../../../../../components/page/page";
import { useBreadcrumbItems, useContextualNavigation } from "../../../nav";
import {
  buildCrmDealsCountKey,
  buildCrmDealsKey,
  useCountCRMDeals,
  useCRMDeal,
  useCRMDealFieldValues,
  useCRMDealPipelines,
  useCRMDeals,
  useCRMDealSchema as useDataCRMDealSchema,
  useCRMDealsInfinite,
  useDatabaseViews,
  usePatchAPI,
  withErrorToast,
} from "../../../../../../../../data";
import { useMemo, useState } from "react";
import {
  DatabaseEnumField,
  DatabaseField,
  DatabaseFieldKind,
  DatabaseFilter,
  DatabaseIDField,
  DatabaseSchema,
  DatabaseSearch,
  DatabaseStringField,
  DatabaseViewKind,
  DatabaseViewLayout,
  ICRMDeal,
  PageInfo,
  PaginationArgs,
} from "@anzuhq/backend";
import { Link, Navigate } from "react-router-dom";
import {
  BoardGroupDataFetcherComponent,
  BoardGroupFetcherComponent,
  RenderGroupsFn,
} from "../../../../../../../database/board";
import {
  EnumRenderer,
  IdRenderer,
  MonetaryValueRenderer,
  SeparateLink,
  TextRenderer,
} from "../../../../../../../database/renderer";
import toast from "react-hot-toast";
import { useSWRConfig } from "swr";
import { useToken } from "../../../../../../../../components/auth";
import { CRMCreateDealDialog } from "../../create";
import {
  SplitViewMainContainer,
  SplitViewSideBar,
} from "../../../contacts/page";
import { AnimatePresence } from "framer-motion";
import classNames from "classnames";
import { CRMSidebar } from "../../../sidebar";

export function RedirectToCRMDeals() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { dealPipelines } = useCRMDealPipelines(workspaceId, environmentId);

  if (!dealPipelines || dealPipelines.length === 0) {
    return null;
  }

  return (
    <Navigate
      to={toEnvironmentCRMDeals(
        workspaceId,
        environmentId,
        dealPipelines[0].id
      )}
    />
  );
}

export function useCRMDealSchema() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const dealPipelineId = useDealPipelineId();

  const { schema } = useDataCRMDealSchema(
    workspaceId,
    environmentId,
    dealPipelineId
  );
  return schema;
}

export function CRMDealsList() {
  const navItems = useContextualNavigation();
  const breadcrumbItems = useBreadcrumbItems();
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const dealPipelineId = useDealPipelineId();

  const patchApi = usePatchAPI();
  const { mutate } = useSWRConfig();
  const token = useToken();

  const schema = useCRMDealSchema();

  const { views, isLoading: isLoadingViews } = useDatabaseViews(
    workspaceId,
    environmentId,
    DatabaseViewKind.CRMDeals
  );

  const currentView = useCurrentView(views || []);

  const {
    setVisibleFields,
    visibleFields,
    filters,
    setFilters,
    sort,
    setSort,
    groupBy,
    setGroupBy,
  } = useViewState(currentView);

  const { debouncedSearch, search, setSearch } = useDatabaseSearch();

  const [paginationArgs, setPaginationArgs] = useState<PaginationArgs>({
    last: null,
    first: 25,
    before: null,
    after: null,
  });

  const { deals: data, isLoading: isLoadingDeals } = useCRMDeals(
    workspaceId,
    environmentId,
    dealPipelineId,
    paginationArgs,
    filters,
    sort,
    debouncedSearch,
    null,
    !currentView || currentView.layout === DatabaseViewLayout.Board
  );

  const { data: countAll, mutate: mutateCountTotalDeals } = useCountCRMDeals(
    workspaceId,
    environmentId,
    dealPipelineId
  );
  const { data: countFiltered, mutate: mutateCountFilteredDeals } =
    useCountCRMDeals(
      workspaceId,
      environmentId,
      dealPipelineId,
      filters,
      debouncedSearch
    );

  const isLoading = isLoadingDeals || isLoadingViews;
  const groupByField = useMemo(() => groupBy?.field || "stage", [groupBy]);

  const [splitViewOpen, setSplitView] = useState<string | null>(null);

  return (
    <Page
      title={"Deals - CRM"}
      contextualNavigationItems={navItems}
      breadcrumbItems={breadcrumbItems}
    >
      <div className={"flex h-full w-full"}>
        <SplitViewMainContainer splitViewOpen={splitViewOpen}>
          <Database
            title={"Deals"}
            actions={<CRMCreateDealDialog pipelineId={dealPipelineId} />}
            toDetailPage={(id) =>
              toEnvironmentCRMDeal(workspaceId, environmentId, id)
            }
            toDatabaseView={(viewId) =>
              toEnvironmentCRMDealsView(
                workspaceId,
                environmentId,
                dealPipelineId,
                viewId
              )
            }
            countTotal={countAll?.count}
            countFiltered={countFiltered?.count}
            data={data?.data || []}
            isLoading={isLoading}
            views={views || []}
            schema={schema || { fields: [] }}
            onOpenSplitView={(id) => setSplitView(id)}
            pageInfo={
              data?.pageInfo || {
                hasPreviousPage: false,
                hasNextPage: false,
                startCursor: null,
                endCursor: null,
              }
            }
            setSort={setSort}
            sort={sort}
            setFilters={setFilters}
            currentView={currentView}
            filters={filters}
            setPaginationArgs={setPaginationArgs}
            paginationArgs={paginationArgs}
            setVisibleFields={setVisibleFields}
            visibleFields={visibleFields}
            search={search}
            setSearch={setSearch}
            emptyState={
              <p className={"text-neutral-800 whitespace-nowrap text-sm"}>
                No deals added.
              </p>
            }
            updateBoardGroup={async (deal, newGroup) => {
              const oldGroup = (deal as unknown as Record<string, unknown>)[
                groupByField
              ];

              if (oldGroup === newGroup) {
                return;
              }

              const oldGroupKey = [
                buildCrmDealsKey(
                  workspaceId,
                  environmentId,
                  dealPipelineId,
                  paginationArgs,
                  filters,
                  sort,
                  search,
                  {
                    field: groupByField,
                    currentGroup: oldGroup as string | null,
                  }
                ),
                token,
              ];
              const oldGroupCountFilteredKey = [
                buildCrmDealsCountKey(
                  workspaceId,
                  environmentId,
                  dealPipelineId,
                  filters,
                  search,
                  {
                    field: groupByField,
                    currentGroup: oldGroup as string | null,
                  }
                ),
                token,
              ];
              const oldGroupCountTotalKey = [
                buildCrmDealsCountKey(
                  workspaceId,
                  environmentId,
                  dealPipelineId,
                  undefined,
                  undefined,
                  {
                    field: groupByField,
                    currentGroup: oldGroup as string | null,
                  }
                ),
                token,
              ];
              const newGroupKey = [
                buildCrmDealsKey(
                  workspaceId,
                  environmentId,
                  dealPipelineId,
                  paginationArgs,
                  filters,
                  sort,
                  search,
                  {
                    field: groupByField,
                    currentGroup: newGroup as string | null,
                  }
                ),
                token,
              ];
              const newGroupCountFilteredKey = [
                buildCrmDealsCountKey(
                  workspaceId,
                  environmentId,
                  dealPipelineId,
                  filters,
                  search,
                  {
                    field: groupByField,
                    currentGroup: newGroup as string | null,
                  }
                ),
                token,
              ];
              const newGroupCountTotalKey = [
                buildCrmDealsCountKey(
                  workspaceId,
                  environmentId,
                  dealPipelineId,
                  undefined,
                  undefined,
                  {
                    field: groupByField,
                    currentGroup: newGroup as string | null,
                  }
                ),
                token,
              ];

              await withErrorToast(async () => {
                // Optimistically remove deal from old group (don't re-fetch yet)
                await mutate(
                  oldGroupKey,
                  (
                    data: { data: ICRMDeal[]; pageInfo: PageInfo } | undefined
                  ) => ({
                    data: data ? data.data.filter((d) => d.id !== deal.id) : [],
                    pageInfo: data
                      ? data.pageInfo
                      : {
                          hasNextPage: false,
                          hasPreviousPage: false,
                          startCursor: null,
                          endCursor: null,
                        },
                  }),
                  {
                    revalidate: false,
                  }
                );

                // Optimistically add value to new group (try to match sorting so we don't have jumping results once we re-fetch later)
                const sortField = sort?.field || "id";
                const sortDirection = sort?.direction || "asc";

                await mutate(
                  newGroupKey,
                  (
                    data: { data: ICRMDeal[]; pageInfo: PageInfo } | undefined
                  ) => ({
                    data: [...(data?.data || []), deal].sort((a, b) => {
                      const aValue = (a as unknown as Record<string, unknown>)[
                        sortField
                      ];
                      const bValue = (b as unknown as Record<string, unknown>)[
                        sortField
                      ];

                      if (
                        typeof aValue === "string" &&
                        typeof bValue === "string"
                      ) {
                        return sortDirection === "asc"
                          ? aValue.localeCompare(bValue)
                          : bValue.localeCompare(aValue);
                      }

                      if (
                        typeof aValue === "number" &&
                        typeof bValue === "number"
                      ) {
                        return sortDirection === "asc"
                          ? aValue - bValue
                          : bValue - aValue;
                      }

                      if (aValue !== null && bValue === null) {
                        return sortDirection === "asc" ? 1 : -1;
                      }

                      return 0;
                    }),
                    pageInfo: data
                      ? data.pageInfo
                      : {
                          hasNextPage: false,
                          hasPreviousPage: false,
                          startCursor: null,
                          endCursor: null,
                        },
                  }),
                  {
                    revalidate: false,
                  }
                );

                await mutate(
                  oldGroupCountTotalKey,
                  (data: { count: number } | undefined) =>
                    data ? { count: data.count - 1 } : undefined,
                  {
                    revalidate: false,
                  }
                );
                if (filters.length > 0 || search) {
                  await mutate(
                    oldGroupCountFilteredKey,
                    (data: { count: number } | undefined) =>
                      data ? { count: data.count - 1 } : undefined,
                    {
                      revalidate: false,
                    }
                  );
                }
                await mutate(
                  newGroupCountTotalKey,
                  (data: { count: number } | undefined) =>
                    data ? { count: data.count + 1 } : undefined,
                  {
                    revalidate: false,
                  }
                );
                if (filters.length > 0 || search) {
                  await mutate(
                    newGroupCountFilteredKey,
                    (data: { count: number } | undefined) =>
                      data ? { count: data.count + 1 } : undefined,
                    {
                      revalidate: false,
                    }
                  );
                }

                await patchApi(
                  `/workspaces/${workspaceId}/environments/${environmentId}/crm/deals/${deal.id}`,
                  {
                    [groupByField]: newGroup,
                  }
                );
                await Promise.all([
                  // Invalidate current deal
                  mutate([
                    `/workspaces/${workspaceId}/environments/${environmentId}/crm/deals/${deal.id}`,
                    token,
                  ]),
                  // Invalidate old group (now re-fetch)
                  mutate(oldGroupKey),
                  // Invalidate new group
                  mutate(newGroupKey),

                  // update counts
                  mutateCountFilteredDeals(),
                  mutateCountTotalDeals(),
                  mutate(oldGroupCountFilteredKey),
                  mutate(oldGroupCountTotalKey),
                  mutate(newGroupCountFilteredKey),
                  mutate(newGroupCountTotalKey),
                ]);

                toast.success("Deal updated");
              });
            }}
            boardGroupDataFetcher={DealBoardGroupDataFetcher}
            groupBy={groupBy}
            setGroupBy={setGroupBy}
            boardItemRenderer={DealBoardItemRenderer}
            boardGroupFetcher={DealBoardGroupFetcher}
          />
        </SplitViewMainContainer>

        <AnimatePresence>
          {splitViewOpen ? (
            <DealSplitView
              dealId={splitViewOpen}
              onClose={() => setSplitView(null)}
            />
          ) : null}
        </AnimatePresence>
      </div>
    </Page>
  );
}

function DealSplitView({
  dealId,
  onClose,
}: {
  dealId: string;
  onClose: () => void;
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { deal, mutate } = useCRMDeal(workspaceId, environmentId, dealId);

  const schema = useCRMDealSchema();
  const patchApi = usePatchAPI();

  const updateDeal = async (id: string, data: Record<string, unknown>) => {
    if (!deal) {
      return;
    }
    await withErrorToast(async () => {
      await mutate(
        {
          ...deal,
          ...data,
        },
        {
          revalidate: false,
        }
      );
      await patchApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/crm/deals/${id}`,
        data
      );
      await mutate();
      toast.success("Deal updated");
    });
  };

  return (
    <SplitViewSideBar onClose={onClose}>
      <div className={"flex items-stretch justify-between w-full p-4"}>
        <div className={"flex flex-col justify-start"}>
          <span
            className={classNames("overflow-hidden min-w-0 truncate", {
              "animate-pulse": !deal,
            })}
          >
            {deal?.name || "loading"}
          </span>
        </div>

        <SeparateLink
          permanent
          to={toEnvironmentCRMDeal(workspaceId, environmentId, dealId)}
        />
      </div>

      <CRMSidebar
        schema={schema || null}
        updateEntity={updateDeal}
        onlyInner
        entity={deal}
        sidebarFieldOrder={["value", "assigned_to", "stage"]}
      />
    </SplitViewSideBar>
  );
}

const DealBoardItemRenderer = ({
  item,
  schema,
}: {
  item: ICRMDeal;
  schema: DatabaseSchema;
}) => {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const dealPipelineId = useDealPipelineId();

  const nameField = useMemo(() => {
    return schema.fields.find(
      (f) => f.name === "name"
    ) as DatabaseStringField | null;
  }, [schema]);

  const assignedToField = useMemo(() => {
    return schema.fields.find(
      (f) => f.name === "assigned_to"
    ) as DatabaseIDField | null;
  }, [schema]);

  const priorityField = useMemo(() => {
    return schema.fields.find(
      (f) => f.name === "priority"
    ) as DatabaseEnumField | null;
  }, [schema]);

  if (!nameField || !assignedToField || !priorityField) {
    return null;
  }

  return (
    <Link
      to={toEnvironmentCRMDeal(workspaceId, environmentId, item.id)}
      className={
        "flex w-full h-full flex-row justify-start items-start justify-between"
      }
    >
      <div className={"flex flex-col items-start text-left space-y-1"}>
        <TextRenderer value={item.name} field={nameField} id={item.id} />
        <MonetaryValueRenderer value={item.value} />
        <EnumRenderer
          size={"small"}
          field={priorityField}
          value={item.priority}
        />
      </div>

      <IdRenderer value={item.assigned_to} field={assignedToField} />
    </Link>
  );
};

const DealBoardGroupFetcher: BoardGroupFetcherComponent = ({
  renderGroups,
  groupByField,
  schema,
  filters,
  search,
}) => {
  if (!groupByField) {
    return null;
  }

  if (groupByField.kind === DatabaseFieldKind.Enum) {
    return renderGroups({
      groups: groupByField.enumValues.map((v) => v.value),
    });
  }

  return (
    <RenderNonEnumGroups
      field={groupByField}
      renderGroups={renderGroups}
      filters={filters}
      search={search}
    />
  );
};

const RenderNonEnumGroups = ({
  renderGroups,
  field,
  filters,
  search,
}: {
  renderGroups: RenderGroupsFn;
  field: DatabaseField;
  filters?: DatabaseFilter[];
  search?: DatabaseSearch | null;
}) => {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const dealPipelineId = useDealPipelineId();

  const { data } = useCRMDealFieldValues(
    workspaceId,
    environmentId,
    dealPipelineId,
    field.name,
    filters,
    search
  );

  return renderGroups({ groups: data || [] });
};

const DealBoardGroupDataFetcher: BoardGroupDataFetcherComponent<ICRMDeal> = ({
  groupBy,
  sort,
  search,
  filters,
  renderItems,
}) => {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const dealPipelineId = useDealPipelineId();

  const [paginationArgs, setPaginationArgs] = useState<PaginationArgs>({
    first: 10,
    before: null,
    after: null,
    last: null,
  });

  const { data: countAll } = useCountCRMDeals(
    workspaceId,
    environmentId,
    dealPipelineId,
    undefined,
    undefined,
    groupBy
  );
  const { data: countFiltered } = useCountCRMDeals(
    workspaceId,
    environmentId,
    dealPipelineId,
    filters,
    search,
    groupBy
  );
  const { deals, isLoading, mutate } = useCRMDealsInfinite(
    workspaceId,
    environmentId,
    dealPipelineId,
    paginationArgs,
    filters,
    sort,
    search,
    groupBy
  );

  // const allPages = deals
  //   ? (deals
  //       .filter((d) => d !== undefined)
  //       .flatMap((d) => d?.data) as ICRMDeal[])
  //   : [];

  return renderItems({
    items: deals ? deals.data : [] || [],
    pageInfo: deals ? deals?.pageInfo || null : null,
    isLoading,
    mutate,
    paginationArgs,
    setPaginationArgs: (args) => {
      // TODO allow loading more data
    },
    countTotal: countAll?.count,
    countFiltered: countFiltered?.count,
  });
};
