import { ButtonWithRef } from "../../components/basics/button";
import { Plus, X } from "../../icons";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { formatDateTime, formatNumber, RenderIcon } from "./renderer";
import {
  Dropdown,
  DropdownConfirmButton,
  DropdownItemButton,
  DropdownListContainer,
  DropdownTextInput,
} from "./dropdown";
import {
  DatabaseField,
  DatabaseFieldKind,
  DatabaseFilter,
  DatabaseFilterOperator,
  DatabaseIDField,
  DatabaseRelatedEntity,
  DatabaseSchema,
} from "@anzuhq/backend";
import { useHotkeys } from "../../hooks/keypress";
import { Checkbox } from "../../components/basics/checkbox";
import { format, parseISO } from "date-fns";
import { useEnvironment, useWorkspaceMemberProfile } from "../../data";
import { useEnvironmentId, useWorkspaceId } from "../routes";
import { formatMonetaryValue } from "./monetary_value";
import {
  CRMCompanyInputRenderer,
  CRMContactInputRenderer,
  CRMDealInputRenderer,
  UserIdentityInputRenderer,
  WorkspaceMemberInputRenderer,
} from "./input_renderer";
import { DateTimeInputContentRenderer } from "./datetime";

function getOperatorLabel(op: DatabaseFilterOperator) {
  switch (op) {
    case DatabaseFilterOperator.Equals:
      return "is";
    case DatabaseFilterOperator.NotEquals:
      return "is not";
    case DatabaseFilterOperator.Contains:
      return "contains";
    case DatabaseFilterOperator.StartsWith:
      return "starts with";
    case DatabaseFilterOperator.EndsWith:
      return "ends with";
    case DatabaseFilterOperator.IsBefore:
      return "is before";
    case DatabaseFilterOperator.IsAfter:
      return "is after";
    case DatabaseFilterOperator.IsEmpty:
      return "is empty";
    case DatabaseFilterOperator.IsNotEmpty:
      return "is not empty";
    case DatabaseFilterOperator.GreaterThan:
      return "is greater than";
    case DatabaseFilterOperator.GreaterThanOrEqual:
      return "is greater than or equal to";
    case DatabaseFilterOperator.LessThan:
      return "is less than";
    case DatabaseFilterOperator.LessThanOrEqual:
      return "is less than or equal to";
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const x: never = op;
      throw new Error(`Unknown operator: ${op}`);
  }
}

function usePopOver(
  activator: JSX.Element,
  content: (setOpen: (open: boolean) => void) => JSX.Element,
  noSizing?: boolean
): [JSX.Element, boolean, (open: boolean) => void] {
  const [open, setOpen] = useState(false);
  return [
    <Dropdown
      noSizing={noSizing}
      activator={activator}
      open={open}
      setOpen={setOpen}
    >
      {content(setOpen)}
    </Dropdown>,
    open,
    setOpen,
  ];
}

function IdLabelResolver({
  field,
  value,
}: {
  field: DatabaseIDField;
  value: string;
}) {
  switch (field.relatedEntity) {
    case DatabaseRelatedEntity.WorkspaceMember:
      if (value === "@anzu_current_workspace_member") {
        return <span className="text-neutral-500">me</span>;
      }
      return <WorkspaceMemberLabelResolver workspaceMemberId={value} />;
    default:
      return null;
  }
}

function WorkspaceMemberLabelResolver({
  workspaceMemberId,
}: {
  workspaceMemberId: string;
}) {
  const workspaceId = useWorkspaceId();
  const { profile } = useWorkspaceMemberProfile(workspaceId, workspaceMemberId);
  return <>{profile?.name || null}</>;
}

function FilterRenderer({
  setFilter,
  filter,
  field,
  filters,
}: {
  field: DatabaseField;
  filter: DatabaseFilter;
  setFilter: (filter: DatabaseFilter | null) => void;
  filters: DatabaseFilter[];
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { environment } = useEnvironment(workspaceId, environmentId);

  const operatorText = useMemo(() => {
    return getOperatorLabel(filter.operator);
  }, [filter.operator]);

  const styles = classNames(
    "bg-neutral-50 hover:bg-neutral-100 active:bg-neutral-200",
    "py-1 px-2",
    "flex items-center",
    "transition duration-100",
    "text-2sm"
  );

  const valueText = useMemo(() => {
    if (!environment) {
      return;
    }
    if (filter.value === "") {
      return "empty";
    }
    switch (field.kind) {
      case DatabaseFieldKind.ID:
        return <IdLabelResolver field={field} value={filter.value} />;
      case DatabaseFieldKind.String:
        return filter.value;
      case DatabaseFieldKind.Number:
        return formatNumber(parseInt(filter.value, 10));
      case DatabaseFieldKind.Boolean:
        return filter.value === "true" ? "True" : "False";
      case DatabaseFieldKind.Enum:
        return field.enumValues.find((v) => v.value === filter.value)?.label;
      case DatabaseFieldKind.DateTime:
        return formatDateTime(filter.value);
      case DatabaseFieldKind.MonetaryValue:
        return formatMonetaryValue({
          value: parseInt(filter.value, 10),
          currency: environment.currency,
        });
    }
  }, [environment, filter, field]);

  const valueIcon = useMemo(() => {
    switch (field.kind) {
      case DatabaseFieldKind.Enum:
        return field.enumValues.find((v) => v.value === filter.value)?.icon;
      default:
        return undefined;
    }
  }, [filter, field]);

  const [operatorPopover] = usePopOver(
    <button className={classNames(styles, "font-light")}>
      {operatorText}
    </button>,
    (setOpen) => (
      <FilterSelectOperator
        setDraftFilter={(f) => {
          setFilter({ ...filter, ...f });
          setOpen(false);
        }}
        draftFilter={filter}
        chosenField={field}
      />
    )
  );

  const [valuePopover] = usePopOver(
    <button
      className={classNames(styles, "font-light flex items-center space-x-2")}
    >
      {valueIcon ? <RenderIcon icon={valueIcon} /> : null}
      <span className={"whitespace-nowrap"}>{valueText}</span>
    </button>,
    (setOpen) => (
      <FilterSelectValue
        setDraftFilter={(f) => {
          setFilter({ ...filter, ...f });
          setOpen(false);
        }}
        draftFilter={filter}
        chosenField={field}
        filters={filters}
      />
    ),
    true
  );

  return (
    <div className={"flex items-stretch border border-neutral-200 rounded"}>
      <div
        className={
          "py-1 px-2 bg-neutral-50 font-light flex items-center space-x-2"
        }
      >
        {field.icon ? <RenderIcon icon={field.icon} /> : null}
        <span className={"whitespace-nowrap text-2sm"}>{field.label}</span>
      </div>

      {operatorPopover}

      {filter.operator !== DatabaseFilterOperator.IsEmpty &&
      filter.operator !== DatabaseFilterOperator.IsNotEmpty
        ? valuePopover
        : null}

      <button
        onClick={() => setFilter(null)}
        className={classNames(
          styles,
          "font-light text-neutral-600 hover:text-neutral-700 active:text-neutral-800"
        )}
      >
        <X size={14} />
      </button>
    </div>
  );
}

export function Filters({
  filters,
  setFilters,
  schema,
}: {
  schema: DatabaseSchema;
  filters: DatabaseFilter[];
  setFilters: (filters: DatabaseFilter[]) => void;
}) {
  return (
    <div className={"flex items-center space-x-2 h-6 max-h-6"}>
      {filters.length === 0 ? (
        <span className={"font-normal text-sm"}>No filters added</span>
      ) : (
        <>
          {filters.map((filter, index) => (
            <FilterRenderer
              key={index}
              field={schema.fields.find((f) => f.name === filter.field)!}
              filter={filter}
              setFilter={(f) => {
                if (!f) {
                  setFilters(filters.filter((_, i) => i !== index));
                  return;
                }
                setFilters(filters.map((f2, i) => (i === index ? f : f2)));
              }}
              filters={filters}
            />
          ))}
        </>
      )}

      <FilterDialog
        enableHotkey={false}
        schema={schema}
        filters={filters}
        addFilter={(f) => setFilters([...filters, f])}
      >
        <ButtonWithRef
          role={"secondary"}
          size={"small"}
          onClick={() => {}}
          icon={Plus}
        >
          Filter
        </ButtonWithRef>
      </FilterDialog>
    </div>
  );
}

interface DraftFilter {
  field?: string;
  operator?: DatabaseFilterOperator;
  value?: string;
}

export function FilterDialog({
  children,
  addFilter,
  schema,
  filters,
  enableHotkey,
}: PropsWithChildren<{
  addFilter: (f: DatabaseFilter) => void;
  schema: DatabaseSchema;
  filters: DatabaseFilter[];
  enableHotkey?: boolean;
}>) {
  const [open, setOpen] = useState(false);
  const [draftFilter, setDraftFilter] =
    useState<Partial<DatabaseFilter> | null>(null);
  const chosenField = schema.fields.find((f) => f.name === draftFilter?.field);

  useHotkeys("f", () => setOpen(true), [setOpen], enableHotkey);

  useEffect(() => {
    if (
      draftFilter &&
      draftFilter.field &&
      draftFilter.operator &&
      typeof draftFilter.value === "string"
    ) {
      addFilter(draftFilter as DatabaseFilter);
      setDraftFilter(null);
      setOpen(false);
    }
  }, [draftFilter]);

  return (
    <Dropdown
      activator={children}
      open={open}
      setOpen={(open) => {
        if (!open) {
          setDraftFilter(null);
        }
        setOpen(open);
      }}
      noSizing={draftFilter?.operator && !draftFilter?.value}
    >
      {!draftFilter || !chosenField ? (
        <FilterSelectField
          schema={schema}
          filters={filters}
          setDraftFilter={setDraftFilter}
        />
      ) : !draftFilter.operator ? (
        <FilterSelectOperator
          chosenField={chosenField}
          setDraftFilter={setDraftFilter}
          draftFilter={draftFilter}
        />
      ) : (
        <FilterSelectValue
          setDraftFilter={setDraftFilter}
          draftFilter={draftFilter}
          chosenField={chosenField}
          filters={filters}
        />
      )}
    </Dropdown>
  );
}

function FilterSelectField({
  setDraftFilter,
  filters,
  schema,
}: {
  setDraftFilter: (filter: DraftFilter) => void;
  schema: DatabaseSchema;
  filters: DatabaseFilter[];
}) {
  const [search, setSearch] = useState("");

  const filteredFields = useMemo(() => {
    return schema.fields.filter(
      (f) =>
        // only allow filtering on fields that are filterable
        getPossibleOperators(f).length > 0 &&
        // only allow filtering on fields that match the search
        (f.name.toLowerCase().includes(search.toLowerCase()) ||
          f.label.toLowerCase().includes(search.toLowerCase())) &&
        // only allow filtering on fields that are not already filtered
        !filters.some((f2) => f2.field === f.name)
    );
  }, [search]);

  return (
    <>
      <DropdownTextInput
        placeholder={"Search..."}
        value={search}
        setValue={setSearch}
      />

      <DropdownListContainer>
        {filteredFields.map((f) => (
          <DropdownItemButton
            key={f.name}
            onClick={() => {
              if (f.kind === DatabaseFieldKind.ID) {
                setDraftFilter({
                  field: f.name,
                  operator: DatabaseFilterOperator.Equals,
                });
                return;
              }
              setDraftFilter({ field: f.name });
            }}
          >
            {f.icon ? <RenderIcon icon={f.icon} /> : null}
            <span>{f.label}</span>
          </DropdownItemButton>
        ))}
      </DropdownListContainer>
    </>
  );
}

function FilterSelectValue({
  chosenField,
  setDraftFilter,
  draftFilter,
  filters,
}: {
  chosenField: DatabaseField;
  setDraftFilter: (draftFilter: DraftFilter) => void;
  draftFilter: DraftFilter;
  filters: DatabaseFilter[];
}) {
  const [tempValue, setTempValue] = useState(draftFilter.value || "");

  const submit = () => {
    setDraftFilter({
      ...draftFilter,
      value: tempValue,
    });
  };

  const ops = useMemo(() => getPossibleOperators(chosenField), [chosenField]);
  if (ops.length === 0) {
    return null;
  }

  switch (chosenField.kind) {
    case DatabaseFieldKind.String:
    case DatabaseFieldKind.Number:
    case DatabaseFieldKind.MonetaryValue:
      let inputValue = tempValue;

      let inputType;
      switch (chosenField.kind) {
        case DatabaseFieldKind.String:
          inputType = "text";
          break;
        case DatabaseFieldKind.Number:
          inputType = "number";
          break;
        case DatabaseFieldKind.MonetaryValue:
          inputType = "number";
          break;
      }

      return (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();

              submit();
            }}
            className={"w-full flex flex-col"}
          >
            <DropdownTextInput
              placeholder={"Filter..."}
              value={inputValue}
              setValue={(v) => {
                switch (chosenField.kind) {
                  case DatabaseFieldKind.String:
                    setTempValue(v);
                    break;
                  case DatabaseFieldKind.Number:
                    if (v === "" || !isNaN(Number(v))) {
                      setTempValue(v);
                    }
                    break;
                  case DatabaseFieldKind.MonetaryValue:
                    if (v === "" || !isNaN(Number(v))) {
                      setTempValue(v);
                    }
                    break;
                  default:
                    break;
                }
              }}
              type={inputType}
            />

            <div className={"p-2"}>
              <DropdownConfirmButton onClick={() => submit()} />
            </div>
          </form>
        </>
      );
    case DatabaseFieldKind.Boolean:
      return (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();

              submit();
            }}
            className={"w-full flex flex-col"}
          >
            <div className={"p-4"}>
              <Checkbox
                checked={tempValue === "true"}
                onChange={(c) => setTempValue(c ? "true" : "false")}
                id={"boolean-input"}
                label={chosenField.label}
              />
            </div>

            <div className={"p-2"}>
              <DropdownConfirmButton onClick={() => submit()} />
            </div>
          </form>
        </>
      );
    case DatabaseFieldKind.ID:
      if (!chosenField.relatedEntity) {
        return <p>id field but no related entity</p>;
      }

      return (
        <IDRelatedEntityFilterInput
          field={chosenField}
          setValue={(v) =>
            setDraftFilter({
              ...draftFilter,
              operator: DatabaseFilterOperator.Equals,
              value: v,
            })
          }
        />
      );
    case DatabaseFieldKind.DateTime:
      return (
        <DateTimeInputContentRenderer
          tempValue={tempValue}
          setDirty={() => {}}
          setTempValue={(v) => (v ? setTempValue(v) : null)}
          setValue={(v) =>
            v
              ? setDraftFilter({
                  ...draftFilter,
                  value: v,
                })
              : null
          }
        />
      );

    case DatabaseFieldKind.Enum:
      if (!chosenField.enumValues) {
        return <p>enum field but no enum values</p>;
      }

      return (
        <DropdownListContainer>
          {chosenField.enumValues.map((v) => (
            <DropdownItemButton
              key={v.value}
              onClick={() =>
                setDraftFilter({
                  ...draftFilter,
                  operator: DatabaseFilterOperator.Equals,
                  value: v.value,
                })
              }
            >
              {v.icon ? <RenderIcon icon={v.icon} /> : null}
              <span>{v.label}</span>
            </DropdownItemButton>
          ))}
        </DropdownListContainer>
      );
  }
}

function FilterSelectOperator({
  chosenField,
  setDraftFilter,
  draftFilter,
}: {
  chosenField: DatabaseField;
  setDraftFilter: (draftFilter: DraftFilter) => void;
  draftFilter: DraftFilter;
}) {
  const ops = useMemo(() => getPossibleOperators(chosenField), [chosenField]);
  if (ops.length === 0) {
    return null;
  }

  return (
    <div className={"flex flex-col space-y-2 p-2 overflow-auto"}>
      {ops.map((v) => (
        <DropdownItemButton
          key={v}
          onClick={() =>
            setDraftFilter({
              ...draftFilter,
              operator: v,
              value: [
                DatabaseFilterOperator.IsEmpty,
                DatabaseFilterOperator.IsNotEmpty,
              ].includes(v)
                ? ""
                : draftFilter.value,
            })
          }
        >
          {getOperatorLabel(v)}
        </DropdownItemButton>
      ))}
    </div>
  );
}

function getPossibleOperators(field: DatabaseField) {
  const ops: DatabaseFilterOperator[] = [];
  if (field.allowedFilterOperators) {
    ops.push(...field.allowedFilterOperators);
  }
  if (!field.isRequired) {
    ops.push(DatabaseFilterOperator.IsEmpty, DatabaseFilterOperator.IsNotEmpty);
  }
  return ops;
}

function createDateTimeLocalInput(value: string) {
  const parsed = parseISO(value);
  return format(parsed, "yyyy-MM-dd'T'HH:mm");
}

function convertDateTimeLocalToISO(value: string) {
  const parsed = parseISO(value);
  return parsed.toISOString();
}

function IDRelatedEntityFilterInput({
  field,
  setValue,
}: {
  field: DatabaseIDField;
  setValue: (v: string) => void;
}) {
  if (!field.relatedEntity) {
    return null;
  }
  switch (field.relatedEntity) {
    case DatabaseRelatedEntity.WorkspaceMember:
      return (
        <WorkspaceMemberInputRenderer
          value={null}
          field={field}
          updateValue={(v) => (v ? setValue(v) : null)}
        />
      );
    case DatabaseRelatedEntity.CRMDeal:
      return (
        <CRMDealInputRenderer
          value={null}
          field={field}
          updateValue={(v) => (v ? setValue(v) : null)}
        />
      );
    case DatabaseRelatedEntity.CRMCompany:
      return (
        <CRMCompanyInputRenderer
          value={null}
          field={field}
          updateValue={(v) => (v ? setValue(v) : null)}
        />
      );
    case DatabaseRelatedEntity.CRMContact:
      return (
        <CRMContactInputRenderer
          value={null}
          field={field}
          updateValue={(v) => (v ? setValue(v) : null)}
        />
      );
    case DatabaseRelatedEntity.UserManagementUserIdentity:
      return (
        <UserIdentityInputRenderer
          value={null}
          field={field}
          updateValue={(v) => (v ? setValue(v) : null)}
        />
      );
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const x: never = field.relatedEntity;
      return null;
  }
}
