import React, { PropsWithChildren } from "react";
import * as Select from "@radix-ui/react-select";
import classnames from "classnames";
import { Check, ChevronDown, ChevronUp } from "../../../../../../../../icons";
import { getStackName, Stack } from "./stack";

export const StackPicker = ({
  setStack,
  stacks,
  stack,
}: {
  stacks: Stack[];
  stack: Stack | null;
  setStack: (stack: Stack | null) => void;
}) => (
  <Select.Root
    value={stack as string}
    onValueChange={(s) => setStack(s as Stack)}
  >
    <Select.Trigger
      className="flex items-center space-x-2 justify-center text-sm px-2 py-px bg-neutral-100 hover:bg-neutral-200 rounded"
      aria-label="Stack"
    >
      <Select.Value placeholder="Select a stack…" />
      <Select.Icon className="SelectIcon">
        <ChevronDown size={16} />
      </Select.Icon>
    </Select.Trigger>
    <Select.Portal>
      <Select.Content
        position={"popper"}
        className="overflow-hidden bg-white/60 backdrop-blur-xl border border-neutral-200 rounded z-20"
      >
        <Select.ScrollUpButton className="SelectScrollButton">
          <ChevronUp />
        </Select.ScrollUpButton>
        <Select.Viewport className="p-2 flex flex-col space-y-1">
          {stacks.map((stack) => (
            <SelectItem key={stack} value={stack}>
              {getStackName(stack)}
            </SelectItem>
          ))}
        </Select.Viewport>
        <Select.ScrollDownButton className="flex items-center justify-center h-12 bg-white">
          <ChevronDown />
        </Select.ScrollDownButton>
      </Select.Content>
    </Select.Portal>
  </Select.Root>
);

const SelectItem = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ className?: string; value: string }>
>(({ children, className, value, ...props }, forwardedRef) => {
  return (
    <Select.Item
      className={classnames(
        "flex items-center px-2 py-1 text-sm space-x-4 hover:bg-neutral-100 rounded focused:bg-neutral-100 select-none cursor-pointer",
        className
      )}
      value={value}
      {...props}
      ref={forwardedRef}
    >
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator className="SelectItemIndicator">
        <Check size={16} />
      </Select.ItemIndicator>
    </Select.Item>
  );
});
