import { Page } from "../../../../../../components/page/page";
import {
  PageHeaderTitle,
  TabbedHeader,
} from "../../../../../../components/header";
import { Outlet } from "react-router-dom";
import { useBreadcrumbItems, useContextualNavigation } from "../../nav";
import {
  useCRMDeal,
  useCRMDealSchema,
  useEnvironmentEventsForResource,
  usePatchAPI,
  withErrorToast,
} from "../../../../../../../data";
import {
  toEnvironmentCRMDeal,
  toEnvironmentCRMDealNotes,
  useDealId,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../../routes";
import toast from "react-hot-toast";
import { CRMSidebar } from "../../sidebar";
import { CRMNotesPrimaryResource, EventResourceKind } from "@anzuhq/backend";
import { navigationHeight } from "../../../../../../navigation";
import { headerHeight } from "../../../developers/webhooks/[webhookId]/page";
import { CreateNotes } from "../../notes";

export function CRMDealPage() {
  const navItems = useContextualNavigation();
  const breadcrumbItems = useBreadcrumbItems();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const dealId = useDealId();

  const { deal, mutate } = useCRMDeal(workspaceId, environmentId, dealId);

  const { mutate: mutateActivity } = useEnvironmentEventsForResource(
    workspaceId,
    environmentId,
    EventResourceKind.CRMDeal,
    dealId
  );

  const { schema } = useCRMDealSchema(
    workspaceId,
    environmentId,
    deal?.pipeline
  );
  const patchApi = usePatchAPI();

  const updateDeal = async (id: string, data: Record<string, unknown>) => {
    if (!deal) {
      return;
    }
    await withErrorToast(async () => {
      await mutate(
        {
          ...deal,
          ...data,
        },
        {
          revalidate: false,
        }
      );
      await patchApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/crm/deals/${id}`,
        data
      );
      await mutate();
      await mutateActivity();
      toast.success("Deal updated");
    });
  };

  return (
    <Page
      title={"Deal - CRM"}
      contextualNavigationItems={navItems}
      breadcrumbItems={breadcrumbItems}
    >
      <div
        className={
          "w-full h-full flex justify-start overflow-auto grid-flow-col"
        }
      >
        <div className={"w-full grow flex flex-col overflow-hidden"}>
          <TabbedHeader
            tabs={[
              {
                label: "Overview",
                path: toEnvironmentCRMDeal(workspaceId, environmentId, dealId),
              },
              {
                label: "Notes",
                path: toEnvironmentCRMDealNotes(
                  workspaceId,
                  environmentId,
                  dealId
                ),
              },
            ]}
          >
            <>
              <PageHeaderTitle>{deal?.name}</PageHeaderTitle>

              <div className={"ml-auto flex items-center space-x-2"}>
                <CreateNotes
                  primaryResource={CRMNotesPrimaryResource.Deal}
                  deal={dealId}
                  mutateActivity={mutateActivity}
                />
              </div>
            </>
          </TabbedHeader>

          <div
            className={"overflow-auto flex"}
            style={{
              height: `calc(100vh - ${navigationHeight} - ${headerHeight})`,
            }}
          >
            <Outlet />
          </div>
        </div>

        <CRMSidebar
          schema={schema || null}
          entity={deal}
          updateEntity={updateDeal}
          sidebarFieldOrder={sidebarFieldOrder}
        />
      </div>
    </Page>
  );
}

const sidebarFieldOrder = ["assigned_to", "stage"];
