import React, { PropsWithChildren } from "react";

export function WithGridLayout({ children }: PropsWithChildren<{}>) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-12">
      <div className="hidden md:block col-span-1 lg:col-span-2" />
      <div className="col-span-1 md:col-span-4 lg:col-span-8">{children}</div>
      <div className="hidden md:block col-span-1 lg:col-span-2" />
    </div>
  );
}

export function WithNarrowGridLayout({ children }: PropsWithChildren<{}>) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-12">
      <div className="hidden md:block col-span-1 lg:col-span-4" />
      <div className="col-span-1 md:col-span-4 lg:col-span-4">{children}</div>
      <div className="hidden md:block col-span-1 lg:col-span-4" />
    </div>
  );
}

export function WithTopSpace({ children }: PropsWithChildren<{}>) {
  return <div className="space-y-2 my-24">{children}</div>;
}

export function CenteredLayout({
  children,
  heading,
}: PropsWithChildren<{ heading: string }>) {
  return (
    <WithNarrowGridLayout>
      <div className="flex flex-col space-y-4 w-full h-full px-16 py-32 bg-white">
        <div className="flex flex-row items-center space-x-2">
          <h1 className="text-3xl font-semibold">{heading}</h1>
        </div>
        {children}
      </div>
    </WithNarrowGridLayout>
  );
}
