import { DatabaseField, DatabaseSchema } from "@anzuhq/backend";
import { useMemo, useState } from "react";
import classNames from "classnames";
import { navigationHeight } from "../../../../navigation";
import { Button } from "../../../../../components/basics/button";
import { Cpu, Trash2 } from "../../../../../icons";
import { AnimatePresence, motion } from "framer-motion";
import { useEnvironmentId, useWorkspaceId } from "../../../../routes";
import {
  ClearButton,
  getInputRenderer,
} from "../../../../database/input_renderer";
import { getFieldRenderer, TData } from "../../../../database/renderer";

export const sidebarWidth = "w-[350px] md:w-[375px] lg:w-[400px] xl:w-[425px]";

// 100% refers to parent container which is full width
export const nonSidebarWidth =
  "w-[calc(100%-350px)] md:w-[calc(100%-375px)] lg:w-[calc(100%-400px)] xl:w-[calc(100%-425px)]";

export function CRMSidebar<T extends TData>({
  entity,
  schema,
  updateEntity,
  sidebarFieldOrder,
  onlyInner,
}: {
  schema: DatabaseSchema | null;
  entity?: T | null;
  updateEntity: (id: string, data: Record<string, unknown>) => void;
  sidebarFieldOrder: string[];
  onlyInner?: boolean;
}) {
  const [search, setSearch] = useState("");

  const fieldOrder = useMemo(() => {
    if (!schema) {
      return null;
    }
    const priorityFields = sidebarFieldOrder.map((name) => {
      const f = schema.fields.find((field) => field.name === name);
      if (!f) {
        throw new Error(`field ${name} not found`);
      }
      return f;
    });

    const remainingFields = schema.fields.filter(
      (field) => !sidebarFieldOrder.includes(field.name)
    );

    return [...priorityFields, ...remainingFields].filter(
      (field) =>
        field.name.toLowerCase().includes(search.toLowerCase()) ||
        field.label.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, schema]);

  if (!entity || !schema || !fieldOrder) {
    return (
      <div
        className={classNames("overflow-auto relative flex flex-col", {
          [classNames(
            "h-full border-l border-neutral-200 bg-neutral-50",
            sidebarWidth
          )]: !onlyInner,
        })}
        style={
          !onlyInner
            ? {
                height: `calc(100vh - ${navigationHeight})`,
              }
            : undefined
        }
      ></div>
    );
  }

  return (
    <div
      className={classNames("h-full overflow-auto relative flex flex-col ", {
        [classNames(
          "border-l border-neutral-200 bg-neutral-50 shrink-0",
          sidebarWidth
        )]: !onlyInner,
      })}
      style={
        !onlyInner
          ? {
              height: `calc(100vh - ${navigationHeight})`,
            }
          : undefined
      }
    >
      {fieldOrder.length === 0 ? (
        <div
          className={"flex flex-col items-center justify-center grow space-y-2"}
        >
          <p className={"text-sm text-neutral-500"}>No fields found.</p>
          <Button
            icon={Trash2}
            size={"medium"}
            role={"ghost"}
            onClick={() => setSearch("")}
          >
            Reset
          </Button>
        </div>
      ) : null}

      <div className={classNames("grow pb-6 flex flex-col space-y-4 p-12")}>
        <AnimatePresence>
          {fieldOrder.map((field) => {
            const fieldVal = (entity as unknown as Record<string, unknown>)[
              field.name
            ];
            return (
              <RenderSidebarField
                key={field.name}
                entity={entity}
                field={field}
                value={fieldVal}
                updateEntity={updateEntity}
              />
            );
          })}
        </AnimatePresence>
      </div>

      <div
        className={classNames(
          "bottom-0 left-0 sticky w-full",
          "bg-neutral-50/60 backdrop-filter backdrop-blur-xl py-4 px-12"
        )}
      >
        <input
          className={
            "bg-transparent backdrop-filter backdrop-blur-xl hover:bg-neutral-100 focus:bg-neutral-100/60 border border-neutral-200 rounded-md p-1 w-full text-sm placeholder:text-neutral-400 outline-none focus:border-neutral-700"
          }
          placeholder={"Search..."}
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
        />
      </div>
    </div>
  );
}

function RenderSidebarField<T extends TData>({
  field,
  entity,
  value,
  updateEntity,
}: {
  field: DatabaseField;
  entity: T;
  value: unknown;
  updateEntity: (id: string, data: Record<string, unknown>) => void;
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const renderer = useMemo(() => {
    if (field.isUserEditable) {
      return getInputRenderer(
        field,
        value,
        (v) =>
          updateEntity(entity.id, {
            [field.name]: v,
          }),
        undefined,
        undefined,
        "separate"
      );
    }

    return (
      <div className={"p-2"}>{getFieldRenderer(field, entity, value)}</div>
    );
  }, [field, entity, value, workspaceId, environmentId, updateEntity]);

  return (
    <motion.div
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={"flex flex-col space-y-0.5 group"}
    >
      <div className={"flex items-center"}>
        <p className={"text-sm text-neutral-500 select-none"}>{field.label}</p>
        {field.isComputed ? (
          <div
            className={
              "mx-2 rounded-full bg-neutral-200 flex items-center space-x-1 px-1.5 py-px text-neutral-500"
            }
          >
            <Cpu size={12} />
            <span className={"text-xs"}>Computed</span>
          </div>
        ) : null}

        <div className={"ml-auto"}>
          <ClearButton
            field={field}
            value={value}
            updateValue={(v) =>
              updateEntity(entity.id, {
                [field.name]: v,
              })
            }
          />
        </div>
      </div>
      {renderer}
    </motion.div>
  );
}
