import { Page } from "../../../../../components/page/page";
import { useBreadcrumbItems, useContextualNavigation } from "../nav";
import {
  PageHeaderTitle,
  SimpleHeader,
} from "../../../../../components/header";
import {
  useEnvironmentInsightsEventAttributeNames,
  useEnvironmentInsightsEventFieldValues,
  useEnvironmentInsightsEvents,
  useEnvironmentInsightsEventsCountTimeline,
  useEnvironmentInsightsSchema,
  useFetchEnvironmentInsightsEvents,
} from "../../../../../../data";
import {
  InsightsEvent,
  InsightsEventFilter,
  InsightsRelativeTimeRange,
  InsightsTimeRange,
} from "@anzuhq/backend";
import { useEnvironmentId, useWorkspaceId } from "../../../../../routes";
import React, { useEffect, useMemo, useState } from "react";
import { BrushChart } from "./chart";
import { ParentSize } from "@visx/responsive";
import { format, parseISO } from "date-fns";
import { DateTimeRendererWithFormats } from "../../../../../database/datetime";
import { EmptyCell, UserIdentityLink } from "../../../../../database/renderer";
import {
  Button,
  ButtonWithRef,
} from "../../../../../../components/basics/button";
import { Calendar, Cast, ChevronLeft, Plus, X } from "../../../../../../icons";
import {
  Dropdown,
  DropdownItemButton,
  DropdownListContainer,
  DropdownTextInput,
  FullWidthDropdown,
} from "../../../../../database/dropdown";
import classNames from "classnames";
import * as Popover from "@radix-ui/react-popover";
import { UserIdentityInputRenderer } from "../../../../../database/input_renderer";

interface InsightsEventField {
  name: string;
  displayName: string;
  type: string;
}

export function InsightsEventsPage() {
  const items = useContextualNavigation();
  const breadcrumbs = useBreadcrumbItems();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const [timeRange, setTimeRange] = useState<InsightsTimeRange>({
    relative: InsightsRelativeTimeRange.Last24Hours,
  });
  const [filters, setFilters] = useState<InsightsEventFilter[]>([]);

  const [isLive, setIsLive] = useState(false);

  const { events, isLoading } = useEnvironmentInsightsEvents(
    workspaceId,
    environmentId,
    timeRange,
    filters
  );

  const [before, setBefore] = useState<string | null>(null);
  const [after, setAfter] = useState<string | null>(null);

  const [newerEvents, setNewerEvents] = useState<InsightsEvent[]>([]);

  const [noOlderEvents, setNoOlderEvents] = useState(false);

  const [olderEvents, setOlderEvents] = useState<InsightsEvent[]>([]);
  useEffect(() => {
    setNoOlderEvents(false);
  }, [timeRange]);

  const fetchEvents = useFetchEnvironmentInsightsEvents(
    workspaceId,
    environmentId,
    timeRange,
    filters
  );

  useEffect(() => {
    if (!before) {
      return;
    }
    (async () => {
      const events = await fetchEvents(before, null);
      if (!events) {
        return;
      }
      if (events.length === 0) {
        setNoOlderEvents(true);
        return;
      }
      setOlderEvents((o) => [...o, ...events]);
    })();
  }, [fetchEvents, before]);

  useEffect(() => {
    if (!isLive) {
      return;
    }

    const i = setInterval(async () => {
      if (!isLive) {
        return;
      }
      const events = await fetchEvents(null, after);
      if (!events || events.length === 0) {
        return;
      }
      setNewerEvents((n) => [...events, ...n]);
      setAfter(events[0].created_at);
    }, 5000);
    return () => clearInterval(i);
  }, [after, isLive, fetchEvents]);

  const dedupedEvents = useMemo(() => {
    const deduped = new Set<string>();

    const mergedEvents = [...newerEvents, ...(events || []), ...olderEvents];

    return mergedEvents.filter((e) => {
      if (deduped.has(e.event_id)) {
        return false;
      }
      deduped.add(e.event_id);
      return true;
    });
  }, [newerEvents, events, olderEvents]);

  return (
    <Page
      contextualNavigationItems={items}
      breadcrumbItems={breadcrumbs}
      title={"Events"}
    >
      <div className={"flex flex-col h-full"}>
        <SimpleHeader>
          <div className={"flex items-center w-full"}>
            <PageHeaderTitle>Events</PageHeaderTitle>

            <div className={"ml-auto flex items-center space-x-2"}>
              <Button
                role={"secondary"}
                onClick={() => setIsLive((l) => !l)}
                icon={Cast}
              >
                {isLive ? "Streaming..." : "Live-Tail"}
              </Button>

              <TimeRangePicker
                timeRange={timeRange}
                setTimeRange={setTimeRange}
              />
            </div>
          </div>
        </SimpleHeader>

        <div className={"grid grid-cols-12 grow bg-neutral-50"}>
          <div className={"col-span-1"} />

          <div className={"col-span-10 px-2 py-4 flex flex-col space-y-4"}>
            <FilterEditor
              timeRange={timeRange}
              filters={filters}
              setFilters={setFilters}
            />

            <TimelineView
              timeRange={timeRange}
              filters={filters}
              setTimeRange={setTimeRange}
              isLive={isLive}
            />

            <EventList
              events={dedupedEvents}
              isLoading={isLoading}
              setBefore={setBefore}
              noOlderEvents={noOlderEvents}
            />
          </div>

          <div className={"col-span-1"} />
        </div>
      </div>
    </Page>
  );
}

function FilterEditor({
  setFilters,
  filters,
  timeRange,
}: {
  timeRange: InsightsTimeRange;
  filters: InsightsEventFilter[];
  setFilters: (filters: InsightsEventFilter[]) => void;
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { attributeNames } = useEnvironmentInsightsEventAttributeNames(
    workspaceId,
    environmentId,
    timeRange,
    filters
  );

  const { schema } = useEnvironmentInsightsSchema(workspaceId, environmentId);

  const allFields = useMemo(() => {
    return [
      ...(schema?.insightsEventFilterableFields || []),
      ...(attributeNames || []).map((a) => ({
        name: a,
        type: "String",
        displayName: a,
      })),
    ];
  }, [schema, attributeNames]);

  return (
    <FilterBar
      filters={filters}
      timeRange={timeRange}
      addFilter={(f) => setFilters([...filters, f])}
      allFields={allFields}
      anchor={
        <div
          className={
            "p-2 bg-white shadow-lg rounded flex items-center space-x-2"
          }
        >
          <span className={"text-xs font-medium shrink-0"}>Filters</span>

          <div className={"grow flex items-center space-x-2"}>
            {filters.map((filter) => (
              <div
                key={`${filter.field}-${filter.operator}-${filter.value}`}
                className={
                  "flex space-x-2 items-center border rounded border-neutral-100 text-xs py-1 px-2 bg-neutral-100"
                }
              >
                <span>{filter.field}</span>
                <span>{filter.operator}</span>
                {filter.field === "user_identity" ? (
                  <UserIdentityLink value={filter.value as string} />
                ) : (
                  <span>{JSON.stringify(filter.value)}</span>
                )}
                <button
                  onClick={() =>
                    setFilters(filters.filter((f) => f !== filter))
                  }
                >
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>

          <div className={"shrink-0"}>
            <Popover.Trigger asChild>
              <ButtonWithRef
                size={"small"}
                role={"secondary"}
                onClick={() => {}}
                icon={Plus}
              >
                Filter
              </ButtonWithRef>
            </Popover.Trigger>
          </div>
        </div>
      }
    />
  );
}

function TimelineView({
  timeRange,
  setTimeRange,

  filters,
  isLive,
}: {
  timeRange: InsightsTimeRange;
  filters: InsightsEventFilter[];
  setTimeRange: (timeRange: InsightsTimeRange) => void;
  isLive?: boolean;
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { buckets } = useEnvironmentInsightsEventsCountTimeline(
    workspaceId,
    environmentId,
    timeRange,
    filters,
    isLive
  );

  return (
    <div className={"p-4 bg-white shadow-lg rounded h-48"}>
      <ParentSize>
        {({ width, height }) => (
          <BrushChart
            timeRange={timeRange}
            width={width}
            height={height}
            buckets={buckets || []}
          />
        )}
      </ParentSize>
    </div>
  );
}

function EventList({
  events,
  isLoading,
  setBefore,
  noOlderEvents,
}: {
  events: InsightsEvent[];
  isLoading?: boolean;
  setBefore: (before: string | null) => void;
  noOlderEvents?: boolean;
}) {
  return (
    <div
      className={classNames("p-2 bg-white shadow-lg rounded", {
        "animate-pulse": isLoading,
      })}
    >
      {events && events.length > 0 ? (
        <div className={"flex flex-col "}>
          <div className={"border-b border-neutral-50 p-2"}>
            <div className={"grid grid-cols-12 items-center"}>
              <div className={"col-span-2"}>
                <span className={"text-sm font-medium"}>Timestamp</span>
              </div>

              <div className={"col-span-2"}>
                <span className={"text-sm font-medium"}>User</span>
              </div>

              <div className={"col-span-1"}>
                <span className={"text-sm font-medium"}>Category</span>
              </div>

              <div className={"col-span-2"}>
                <span className={"text-sm font-medium"}>Kind</span>
              </div>

              <div className={"col-span-1"}>
                <span className={"text-sm font-medium"}>Country</span>
              </div>

              <div className={"col-span-2"}>
                <span className={"text-sm font-medium"}>User Agent</span>
              </div>
            </div>
          </div>

          {events.map((event, idx) => (
            <div
              key={event.event_id}
              className={classNames("border-b border-neutral-50 p-2 rounded", {
                "bg-neutral-50/50": idx % 2 === 0,
              })}
            >
              <div className={"grid grid-cols-12 items-center"}>
                <div className={"col-span-2"}>
                  <DateTimeRendererWithFormats value={event.created_at} />
                </div>

                <div className={"col-span-2 text-sm truncate"}>
                  <UserIdentityLink value={event.user_identity} />
                </div>

                <div className={"col-span-1"}>
                  <span
                    className={"text-xs p-1 bg-neutral-100 rounded font-mono"}
                  >
                    {event.category}
                  </span>
                </div>

                <div className={"col-span-2"}>
                  <span
                    className={"text-xs p-1 bg-neutral-100 rounded font-mono"}
                  >
                    {event.kind}
                  </span>
                </div>

                <div className={"col-span-1"}>
                  <span className={"text-sm"}>
                    {event.country || <EmptyCell />}
                  </span>
                </div>

                <div className={"col-span-4 text-sm truncate"}>
                  {event.user_agent || <EmptyCell />}
                </div>
              </div>
            </div>
          ))}

          <button
            disabled={noOlderEvents}
            className={
              "flex justify-center p-2 bg-neutral-50 hover:bg-neutral-100 active:bg-neutral-200 disabled:bg-white disabled:text-neutral-500 text-sm font-medium rounded w-full h-full"
            }
            onClick={() => setBefore(events[events.length - 1].created_at)}
          >
            Load Older Events
          </button>
        </div>
      ) : (
        <div
          className={
            "flex justify-center items-center text-sm text-neutral-700 p-8"
          }
        >
          {isLoading ? (
            <p>Loading your events.</p>
          ) : (
            <p>No events found in the current time range.</p>
          )}
        </div>
      )}
    </div>
  );
}

export function TimeRangePicker({
  timeRange,
  setTimeRange,
}: {
  timeRange: InsightsTimeRange;
  setTimeRange: (range: InsightsTimeRange) => void;
}) {
  const [open, setOpen] = useState(false);

  const formattedRange = useMemo(() => {
    if (timeRange.relative) {
      switch (timeRange.relative) {
        case InsightsRelativeTimeRange.Last30Days:
          return "Last 30 days";
        case InsightsRelativeTimeRange.Last7Days:
          return "Last 7 days";
        case InsightsRelativeTimeRange.Last24Hours:
          return "Last 24 hours";
        case InsightsRelativeTimeRange.Last90Days:
          return "Last 90 days";
        default:
          const _: never = timeRange.relative;
      }
    }

    if (timeRange.from && timeRange.to) {
      const from = format(parseISO(timeRange.from), "yyyy-MM-dd HH:mm:ss");
      const to = format(parseISO(timeRange.to), "yyyy-MM-dd HH:mm:ss");

      return `${from} to ${to}`;
    }

    return "Custom";
  }, [timeRange]);

  const changeRange = (range: InsightsTimeRange) => {
    setTimeRange(range);
    setOpen(false);
  };

  return (
    <Dropdown
      activator={
        <ButtonWithRef role={"ghost"} icon={Calendar} onClick={() => {}}>
          {formattedRange}
        </ButtonWithRef>
      }
      open={open}
      setOpen={setOpen}
      noSizing
      collisionPadding={40}
    >
      <div className={"grid grid-cols-2 w-[512px] p-4"}>
        <div className={"col-span-1 flex flex-col"}>
          <span className={"text-xs uppercase font-medium text-neutral-700"}>
            Absolute
          </span>

          <div className={"flex items-center justify-center grow"}>
            <p className={"text-xs text-neutral-700 font-normal"}>
              Absolute picker coming soon.
            </p>
          </div>
        </div>

        <div className={"col-span-1"}>
          <span className={"text-xs uppercase font-medium text-neutral-700"}>
            Relative
          </span>
          <DropdownListContainer>
            <DropdownItemButton
              onClick={() =>
                changeRange({
                  relative: InsightsRelativeTimeRange.Last24Hours,
                })
              }
            >
              Last 24 hours
            </DropdownItemButton>
            <DropdownItemButton
              onClick={() =>
                changeRange({ relative: InsightsRelativeTimeRange.Last7Days })
              }
            >
              Last 7 days
            </DropdownItemButton>
            <DropdownItemButton
              onClick={() =>
                changeRange({ relative: InsightsRelativeTimeRange.Last30Days })
              }
            >
              Last 30 days
            </DropdownItemButton>
            <DropdownItemButton
              onClick={() =>
                changeRange({ relative: InsightsRelativeTimeRange.Last90Days })
              }
            >
              Last 90 days
            </DropdownItemButton>
          </DropdownListContainer>
        </div>
      </div>
    </Dropdown>
  );
}

type FilterField = { name: string; type: string; displayName: string };

function FilterBar({
  addFilter,
  filters,
  timeRange,
  allFields,
  anchor,
}: {
  addFilter: (filter: InsightsEventFilter) => void;
  filters: InsightsEventFilter[];
  timeRange: InsightsTimeRange;
  allFields: InsightsEventField[];
  anchor: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const [searchText, setSearchText] = useState("");
  const [selectedField, setSelectedField] = useState<{
    name: string;
    type: string;
    displayName: string;
  } | null>(null);

  const filteredFields = useMemo<FilterField[]>(() => {
    return allFields.filter(
      (f) =>
        f.name.toLowerCase().includes(searchText.toLowerCase()) ||
        f.displayName.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, allFields]);

  const { fieldValues } = useEnvironmentInsightsEventFieldValues(
    workspaceId,
    environmentId,
    timeRange,
    filters,
    selectedField?.name
  );

  const filteredValues = useMemo<string[]>(() => {
    if (!fieldValues) return [];
    return fieldValues.filter((f) =>
      f.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, fieldValues]);

  const reset = () => {
    setSelectedField(null);
    resetFilter();
  };

  const resetFilter = () => {
    setSearchText("");
  };

  const setField = (field: FilterField) => {
    setSelectedField(field);
    resetFilter();
  };

  const resetField = () => {
    setSelectedField(null);
    resetFilter();
  };

  const saveFilter = (value: unknown) => {
    if (!selectedField) {
      return;
    }
    addFilter({
      value,
      field: selectedField.name,
      operator: "eq",
    });
    setIsOpen(false);
    reset();
  };

  return (
    <FullWidthDropdown
      open={isOpen}
      setOpen={(o) => {
        setIsOpen(o);
        reset();
      }}
      anchor={anchor}
    >
      {selectedField ? (
        <>
          <div className={"flex items-center p-4"}>
            <Button
              role={"secondary"}
              icon={ChevronLeft}
              onClick={() => resetField()}
            ></Button>

            <div className={"explainer ml-2"}>
              Choose Value for{" "}
              <span className={"font-bold"}>"{selectedField.displayName}"</span>
            </div>
          </div>

          <DropdownTextInput
            placeholder={"Search..."}
            value={searchText}
            setValue={setSearchText}
          />
          {fieldValues ? (
            <DropdownListContainer>
              {filteredValues.map((f) => (
                <DropdownItemButton key={f} onClick={() => saveFilter(f)}>
                  <FilterValue fieldName={selectedField.name} value={f} />
                </DropdownItemButton>
              ))}
            </DropdownListContainer>
          ) : null}
        </>
      ) : (
        <>
          <div className={"explainer p-4"}>Choose Field</div>
          <DropdownTextInput
            placeholder={"Search..."}
            value={searchText}
            setValue={setSearchText}
          />
          <DropdownListContainer>
            {filteredFields.map((f) => (
              <DropdownItemButton key={f.name} onClick={() => setField(f)}>
                <div className="truncate w-full">{f.displayName}</div>
              </DropdownItemButton>
            ))}
          </DropdownListContainer>
        </>
      )}
    </FullWidthDropdown>
  );
}

function FilterValue({
  value,
  fieldName,
}: {
  value: string;
  fieldName: string;
}) {
  if (fieldName === "user_identity") {
    return <UserIdentityLink value={value} />;
  }

  if (value.length === 0) {
    return (
      <div className={"truncate w-full text-neutral-500"}>Empty String</div>
    );
  }
  return <div className="truncate w-full">{value}</div>;
}
