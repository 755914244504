import { useEffect } from "react";
import { useAuth } from "./components/auth";
import { useCrispIdentity } from "./data";
import { buildCommitSha, enableCrisp } from "./env";
import { useMatch } from "react-router-dom";

// Docs for Crisp JS API can be found here: https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/dollar-crisp/

// add $crisp to the global object
declare global {
  interface Window {
    $crisp: any;
  }
}
export function isCrispAvailable() {
  return typeof window.$crisp !== "undefined";
}

export function useShowCrispChatbox() {
  return () => {
    if (!isCrispAvailable()) {
      return;
    }
    window.$crisp.push(["do", "chat:show"]);
    window.$crisp.push(["do", "chat:open"]);
  };
}

export function useCrispWorkspace(workspaceId: string) {
  useEffect(() => {
    if (!isCrispAvailable()) {
      return;
    }
    window.$crisp.push([
      "set",
      "session:data",
      [[["workspaceId", workspaceId]]],
    ]);
  }, [workspaceId]);
}

export function useCrisp() {
  const auth = useAuth();

  const isAuthPage = useMatch("/auth/*") !== null;

  // In case Crisp hasn't been loaded, prepare script for it to run
  useEffect(() => {
    if (enableCrisp) {
      const setupCrispScript = document.createElement("script");
      if (isAuthPage) {
        setupCrispScript.innerHTML = `
        window.CRISP_READY_TRIGGER = function() {
          window.$crisp.push(["do", "chat:show"]);
          window.$crisp.push(["do", "chat:close"]);
        }
        `;
      } else {
        setupCrispScript.innerHTML = `
        window.CRISP_READY_TRIGGER = function() {
          window.$crisp.push(["do", "chat:hide"]);
          // Automatically hide chat button when chatbox is closed
          window.$crisp.push([
            "on",
            "chat:closed",
            () => {
              window.$crisp.push(["do", "chat:hide"]);
            },
          ]);
        }
        `;
      }
      document.body.appendChild(setupCrispScript);
    }
  }, [isAuthPage]);

  // Inject Crisp
  useEffect(() => {
    if (!enableCrisp) {
      return;
    }

    const addToBody = `window.$crisp=[];window.CRISP_WEBSITE_ID="e0453268-8d02-4241-9561-7ded27d42d1b";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`;
    const script = document.createElement("script");
    script.innerHTML = addToBody;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // In case Crisp is available and we switch pages, make sure to show or hide the button
  useEffect(() => {
    if (!isCrispAvailable()) {
      return;
    }

    if (isAuthPage) {
      window.$crisp.push(["do", "chat:show"]);
      window.$crisp.push(["do", "chat:close"]);
    } else {
      window.$crisp.push(["do", "chat:hide"]);
      // Automatically hide chat button when chatbox is closed
      window.$crisp.push([
        "on",
        "chat:closed",
        () => {
          window.$crisp.push(["do", "chat:hide"]);
        },
      ]);
    }
  }, [isAuthPage]);

  // Load additional information to supply to Crisp only if user is logged in and crisp should be enabled
  const { crispIdentity } = useCrispIdentity(
    auth.user?.token || null,
    enableCrisp
  );

  useEffect(() => {
    if (!isCrispAvailable()) {
      return;
    }

    window.$crisp.push([
      "set",
      "session:data",
      [[["version", buildCommitSha]]],
    ]);
  }, []);

  useEffect(() => {
    if (!auth.user) {
      return;
    }
    if (!isCrispAvailable()) {
      return;
    }
    window.$crisp.push([
      "set",
      "session:data",
      [[["accountId", auth.user.accountId]]],
    ]);
  }, [auth]);

  useEffect(() => {
    if (!isCrispAvailable()) {
      return;
    }
    if (!crispIdentity) {
      return;
    }

    window.$crisp.push([
      "set",
      "user:email",
      [crispIdentity.email, crispIdentity.signature],
    ]);
    window.$crisp.push(["set", "user:avatar", [crispIdentity.image]]);
  }, [crispIdentity]);
}
