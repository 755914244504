import {
  SettingsPageLayout,
  useUserManagementSettingsConfig,
} from "../general/page";
import {
  UserManagementConfig,
  UserManagementConfigResp,
} from "@anzuhq/backend";
import { useState } from "react";
import { Button } from "../../../../../../../components/basics/button";
import {
  DomainListInput,
  TextInput,
} from "../../../../../../../components/basics/input";
import { FieldSet } from "../../../../../../components/fieldset";
import { ConfigLoadErrorPage } from "../../../../../../components/settings";
import { LearnMoreLink } from "../../../../../../components/docs";
import { AlertTriangle } from "../../../../../../../icons";
import { WithTooltip } from "../../../../../../../components/basics/tooltip";
import classNames from "classnames";

export function UserManagementSettingsRedirectUri() {
  const { userManagementConfig, isLoading, saveConfig } =
    useUserManagementSettingsConfig();

  if (isLoading) {
    return <FieldSet footer={<></>} title={""} loading />;
  }

  if (!userManagementConfig) {
    return <ConfigLoadErrorPage />;
  }

  return (
    <SettingsPageLayout>
      <DefaultRedirectURI
        config={userManagementConfig}
        setConfig={saveConfig}
      />
      <AllowedRedirectUris
        config={userManagementConfig}
        setConfig={saveConfig}
      />
    </SettingsPageLayout>
  );
}

function DefaultRedirectURI({
  config,
  setConfig,
}: {
  config: UserManagementConfigResp;
  setConfig: (
    config: Partial<UserManagementConfig>,
    message?: string
  ) => Promise<void>;
}) {
  const [defaultRedirectUri, setDefaultRedirectUri] = useState(
    config.config.default_redirect_uri
  );

  return (
    <FieldSet
      footer={
        <div className={"flex items-center justify-between"}>
          <LearnMoreLink
            to={"https://anzuhq.com/docs/blocks/user-management/hosted-page"}
          >
            Hosted Page
          </LearnMoreLink>

          <Button
            onClick={() => {
              setConfig(
                { default_redirect_uri: defaultRedirectUri },
                "Updated default redirect URI"
              );
            }}
            size={"small"}
            disabled={defaultRedirectUri === config.config.default_redirect_uri}
          >
            Save
          </Button>
        </div>
      }
      action={
        <div className={"flex items-center"}>
          {defaultRedirectUri ? null : (
            <WithTooltip text={"Provide a default redirect URI"}>
              <AlertTriangle
                size={16}
                className={classNames("text-amber-600")}
              />
            </WithTooltip>
          )}
        </div>
      }
      title={"Default Redirect URI"}
    >
      <p className={"pb-2"}>
        Configure where your users are redirected after authenticating, if no
        redirect URI is passed.
      </p>

      <TextInput
        label={"Redirect URI"}
        placeholder={"https://example.com"}
        value={defaultRedirectUri || ""}
        onChange={(v) => setDefaultRedirectUri(v || null)}
      />
    </FieldSet>
  );
}

function AllowedRedirectUris({
  config,
  setConfig,
}: {
  config: UserManagementConfigResp;
  setConfig: (
    config: Partial<UserManagementConfig>,
    message?: string
  ) => Promise<void>;
}) {
  const [allowedRedirectUris, setAllowedRedirecUris] = useState(
    config.config.allowed_redirect_uris
  );

  return (
    <FieldSet
      footer={
        <div className={"flex items-center justify-between"}>
          <LearnMoreLink
            to={"https://anzuhq.com/docs/blocks/user-management/hosted-page"}
          >
            Hosted Page
          </LearnMoreLink>

          <Button
            onClick={() => {
              setConfig(
                { allowed_redirect_uris: allowedRedirectUris },
                "Updated allowed redirect URIs"
              );
            }}
            size={"small"}
            disabled={
              JSON.stringify(allowedRedirectUris) ===
              JSON.stringify(config.config.allowed_redirect_uris)
            }
          >
            Save
          </Button>
        </div>
      }
      title={"Allowed Redirect URIs"}
    >
      <p className={"pb-2"}>
        Configure which redirect URLs are allowed. The supplied redirect URI
        must exactly match one of the values entered below.
      </p>

      <DomainListInput
        value={allowedRedirectUris || []}
        onChange={(v) => setAllowedRedirecUris(v)}
      />
    </FieldSet>
  );
}
