import { useBreadcrumbItems, useContextualNavigation } from "../nav";

import {
  toEnvironmentUserManagementUserIdentitiesView,
  toEnvironmentUserManagementUserIdentity,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../routes";
import {
  useCountUserManagementUserIdentities,
  useDatabaseViews,
  useUserManagementUserIdentities,
  useUserManagementUserIdentitySchema,
} from "../../../../../../data";
import { Page } from "../../../../../components/page/page";

import {
  Database,
  useCurrentView,
  useDatabaseSearch,
  useViewState,
} from "../../../../../database/database";
import { useState } from "react";
import { DatabaseViewKind, PaginationArgs } from "@anzuhq/backend";

export const pendingProvisioningViewId = "pending-provisioning";

export function UserIdentityList() {
  const navItems = useContextualNavigation();
  const breadcrumbItems = useBreadcrumbItems();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { schema } = useUserManagementUserIdentitySchema(
    workspaceId,
    environmentId
  );

  const { views, isLoading: isLoadingViews } = useDatabaseViews(
    workspaceId,
    environmentId,
    DatabaseViewKind.UserManagementUserIdentities
  );

  const currentView = useCurrentView(views || []);
  const {
    setVisibleFields,
    visibleFields,
    filters,
    setFilters,
    sort,
    setSort,
    groupBy,
    setGroupBy,
  } = useViewState(currentView);

  const { debouncedSearch, search, setSearch } = useDatabaseSearch();

  const [paginationArgs, setPaginationArgs] = useState<PaginationArgs>({
    last: null,
    first: 25,
    before: null,
    after: null,
  });

  const { identities: data, isLoading: isLoadingIdentities } =
    useUserManagementUserIdentities(
      workspaceId,
      environmentId,
      paginationArgs,
      filters,
      sort,
      debouncedSearch
    );

  const { data: countAll } = useCountUserManagementUserIdentities(
    workspaceId,
    environmentId
  );
  const { data: countFiltered } = useCountUserManagementUserIdentities(
    workspaceId,
    environmentId,
    filters,
    debouncedSearch
  );

  const isLoading = isLoadingViews || isLoadingIdentities;

  return (
    <Page
      title={"User Identities - User Management"}
      contextualNavigationItems={navItems}
      breadcrumbItems={breadcrumbItems}
    >
      <Database
        title={"User Identities"}
        toDetailPage={(id) =>
          toEnvironmentUserManagementUserIdentity(
            workspaceId,
            environmentId,
            id
          )
        }
        toDatabaseView={(id) =>
          toEnvironmentUserManagementUserIdentitiesView(
            workspaceId,
            environmentId,
            id
          )
        }
        countTotal={countAll?.count}
        countFiltered={countFiltered?.count}
        data={data?.data || []}
        isLoading={isLoading}
        views={views || []}
        schema={schema || { fields: [] }}
        pageInfo={
          data?.pageInfo || {
            hasPreviousPage: false,
            hasNextPage: false,
            startCursor: null,
            endCursor: null,
          }
        }
        setSort={setSort}
        sort={sort}
        setFilters={setFilters}
        currentView={currentView}
        filters={filters}
        setPaginationArgs={setPaginationArgs}
        paginationArgs={paginationArgs}
        setVisibleFields={setVisibleFields}
        visibleFields={visibleFields}
        search={search}
        setSearch={setSearch}
        emptyState={
          <p className={"text-neutral-800 whitespace-nowrap text-sm"}>
            No user identities added.
          </p>
        }
        groupBy={groupBy}
        setGroupBy={setGroupBy}
      />
    </Page>
  );
}
