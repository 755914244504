import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Button, ButtonLink } from "../../../components/basics/button";
import {
  BounceIfSignedIn,
  CodeStep,
  User,
  useSignInAndRedirectToPrevious,
  useStartAuth,
} from "../../../components/auth";
import { Link, useSearchParams } from "react-router-dom";
import { postApi, withErrorToast } from "../../../data";
import { TextInput } from "../../../components/basics/input";
import { IconAndLogoText } from "../../../components/basics/icon";
import { useHotkeys } from "../../../hooks/keypress";
import { Check } from "../../../icons";
import { Page } from "../../components/page/page";
import { useApiEndpointContext } from "../../../env";

export function RegisterPage() {
  const [name, setName] = useState("");
  const [organization, setOrganization] = useState<string | null>(null);
  const [email, setEmail] = useState("");

  const [code, setCode] = useState("");
  const [attemptId, setAttemptId] = useState<string | null>(null);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const paramEmail = searchParams.get("email");
    if (paramEmail) {
      setEmail(paramEmail);
    }

    const paramCode = searchParams.get("code");
    if (paramCode) {
      setCode(paramCode);
    }

    const paramAttemptId = searchParams.get("attemptId");
    if (paramAttemptId) {
      setAttemptId(paramAttemptId);
    }
  }, [searchParams]);

  const [stage, setStage] = useState<"email" | "code" | "complete">("email");

  const startAuth = useStartAuth(email, (attemptId: string) => {
    setAttemptId(attemptId);
    setStage("code");
  });

  let view: JSX.Element;
  switch (stage) {
    case "email":
      view = (
        <SignupForm
          email={email}
          name={name}
          organization={organization}
          onContinue={() => {
            if (code) {
              setStage("complete");
            } else {
              startAuth();
            }
          }}
          setEmail={setEmail}
          setName={setName}
          setOrganization={setOrganization}
        />
      );
      break;
    case "code":
      if (!attemptId) {
        return <p>Please refresh the page</p>;
      }
      view = (
        <div className="space-y-4 max-w-lg">
          <CodeStep
            email={email}
            setCode={(code) => {
              setCode(code);
              setStage("complete");
            }}
            attemptId={attemptId}
          />
        </div>
      );
      break;
    case "complete":
      view = (
        <CompleteSignUpStep
          email={email}
          code={code}
          organization={organization}
          name={name}
        />
      );
      break;
  }

  return (
    <BounceIfSignedIn>
      <Page
        title={"Sign up"}
        contextualNavigationItemsLoading={false}
        breadcrumbItems={[]}
        contextualNavigationItems={[]}
      >
        <RegistrationSkeleton>{view}</RegistrationSkeleton>
      </Page>
    </BounceIfSignedIn>
  );
}

function SignupForm({
  email,
  name,
  organization,
  onContinue,
  setEmail,
  setName,
  setOrganization,
}: {
  email: string;
  name: string;
  organization: string | null;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setOrganization: React.Dispatch<React.SetStateAction<string | null>>;
  onContinue: () => void;
}) {
  const isValid = useMemo(() => {
    if (!email || !email.includes("@")) {
      return false;
    }

    if (name.length < 1) {
      return false;
    }

    return true;
  }, [email, name]);

  const continueFlow = () => {
    if (isValid) {
      onContinue();
    }
  };

  useHotkeys("enter", continueFlow);

  return (
    <form
      className="space-y-4 max-w-lg"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        continueFlow();
      }}
    >
      <TextInput
        labelHorizontal={true}
        autoFocus
        value={name}
        onChange={setName}
        label="Name"
      />

      <TextInput
        labelHorizontal={true}
        value={email}
        onChange={setEmail}
        label="Email"
        type="email"
      />

      <TextInput
        labelHorizontal={true}
        value={organization || ""}
        onChange={setOrganization}
        label="Organization (optional)"
      />

      <div className="submit space-y-4 flex flex-col">
        <p className="text-neutral-400 text-sm">
          By clicking 'Continue', you agree to our Terms of Service and Privacy
          Policy.
        </p>
        <div className="flex flex-row-reverse gap-2">
          <Button
            disabled={!isValid}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              continueFlow();
            }}
          >
            Continue
          </Button>
          <ButtonLink role={"secondary"} to="/auth/login">
            Sign In
          </ButtonLink>
        </div>
      </div>
    </form>
  );
}

function CompleteSignUpStep({
  code,
  name,
  organization,
  email,
}: {
  email: string;
  code: string;
  name: string;
  organization: string | null;
}) {
  const [apiEndpoint] = useApiEndpointContext();
  const signIn = useSignInAndRedirectToPrevious();

  const signUp = async () => {
    await withErrorToast(async () => {
      const user = await postApi<User>(apiEndpoint, `/auth/signup`, {
        email,
        name,
        code,
        analytics: { organization },
      });
      signIn(user);
    });
  };

  useEffect(() => {
    signUp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export function AuthNavigation() {
  return (
    <nav className="nav p-4 shrink-0 grow-0">
      <Link to="/">
        <IconAndLogoText />
      </Link>
    </nav>
  );
}

function SplitLayout({
  left,
  right,
}: PropsWithChildren<{ left: JSX.Element; right: JSX.Element }>) {
  return (
    <div className="flex flex-col h-full pt-24">
      <div className="grow grid gap-4 grid-cols-1 sm:grid-cols-3 h-full p-3">
        <div className="col-span-1 p-4">{left}</div>
        <div className="col-span-2 p-4">{right}</div>
      </div>
    </div>
  );
}

function ListCheck() {
  return (
    <span className="inline-block rounded-full p-1 bg-green-200 mr-3">
      <Check size={14} strokeWidth={3} />
    </span>
  );
}

function ListItem({ children }: PropsWithChildren<{}>) {
  return (
    <li className="flex items-center leading-tight">
      <ListCheck />
      {children}
    </li>
  );
}

function ValuesSection() {
  return (
    <div className="flex items-center py-8">
      <ul className="space-y-3">
        <ListItem>
          Everything you need to build your product, all in one place
        </ListItem>
        <ListItem>No time sunk into integrating third-party products</ListItem>
      </ul>
    </div>
  );
}

function RegistrationSkeleton({ children }: PropsWithChildren<{}>) {
  return (
    <SplitLayout
      left={<ValuesSection />}
      right={
        <div className={"h-full"}>
          <div className="signup-heading mb-5">
            <h1 className="text-3xl font-semibold">Sign up to Anzu</h1>

            <p className="text-neutral-500">
              Create your account now to set up product insights, user
              management, <br />
              and your CRM <span className="font-bold">in under an hour</span>
            </p>
          </div>

          {children}
        </div>
      }
    />
  );
}
