import { Page } from "../../../../../../components/page/page";
import { useBreadcrumbItems, useContextualNavigation } from "../../nav";
import {
  useCRMNotes,
  useCRMNotesSchema,
  useEnvironmentEventsForResource,
  usePatchAPI,
  withErrorToast,
} from "../../../../../../../data";
import {
  useEnvironmentId,
  useNotesId,
  useWorkspaceId,
} from "../../../../../../routes";
import { FakeRichTextRenderer } from "../../richtext";
import {
  PageHeaderTitle,
  SimpleHeader,
} from "../../../../../../components/header";
import { CommentSection } from "../../comment";
import {
  AllEvents,
  CRMCommentKind,
  CRMNotesPrimaryResource,
  EventResourceKind,
  ICRMNotes,
} from "@anzuhq/backend";
import { ActivitySection } from "../../activity";
import {
  CRMCompanyLink,
  CRMContactLink,
  CRMDealLink,
} from "../../../../../../database/renderer";
import React, { useMemo } from "react";
import { CRMSidebar } from "../../sidebar";
import { navigationHeight } from "../../../../../../navigation";
import toast from "react-hot-toast";

const headerHeight = "150px";

export function CRMNotesPage() {
  const navItems = useContextualNavigation();
  const breadcrumbItems = useBreadcrumbItems();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const notesId = useNotesId();

  const { notes, mutate } = useCRMNotes(workspaceId, environmentId, notesId);

  const { events, mutate: mutateActivity } = useEnvironmentEventsForResource(
    workspaceId,
    environmentId,
    EventResourceKind.CRMNotes,
    notesId
  );

  const { schema } = useCRMNotesSchema(workspaceId, environmentId, notes?.kind);

  const filteredSchema = useMemo(() => {
    if (!schema) {
      return null;
    }

    const ignoredFields = ["id", "title", "company", "contact", "deal"];

    return {
      ...schema,
      fields: schema.fields.filter((f) => !ignoredFields.includes(f.name)),
    };
  }, [schema]);

  const patchApi = usePatchAPI();

  const updateNotes = async (id: string, data: Record<string, unknown>) => {
    if (!notes) {
      return;
    }
    await withErrorToast(async () => {
      await mutate(
        {
          ...notes,
          ...data,
        },
        {
          revalidate: false,
        }
      );
      await patchApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/crm/notes/${id}`,
        data
      );
      await mutate();
      await mutateActivity();
      toast.success("Notes updated");
    });
  };
  return (
    <Page
      title={"Notes - CRM"}
      contextualNavigationItems={navItems}
      breadcrumbItems={breadcrumbItems}
    >
      <div
        className={
          "w-full h-full flex justify-start overflow-auto grid-flow-col"
        }
      >
        <div className={"w-full grow flex flex-col overflow-hidden"}>
          <SimpleHeader height={headerHeight}>
            <div className={"flex flex-col"}>
              {notes ? <NotesOrigin notes={notes} /> : null}
              <PageHeaderTitle>{notes?.title || "Notes"}</PageHeaderTitle>
            </div>
          </SimpleHeader>

          <div
            className={"overflow-auto grid grid-cols-12"}
            style={{
              height: `calc(100vh - ${navigationHeight} - ${headerHeight})`,
            }}
          >
            <div className={"col-span-1"}></div>

            <div className={"col-span-10 p-2"}>
              {notes ? (
                <>
                  <div className={"p-2 rounded-md bg-neutral-50"}>
                    <FakeRichTextRenderer
                      content={notes.content}
                      setContent={() => {}}
                      fullHeight
                      editingDisabled={true}
                    />
                  </div>

                  <CommentSection
                    mutateActivity={mutateActivity}
                    kind={CRMCommentKind.Notes}
                    parentId={notes.id}
                  />
                </>
              ) : null}

              <ActivitySection
                events={(events || []).map((e) => e.data) as AllEvents[]}
              />
            </div>

            <div className={"col-span-1"}></div>
          </div>
        </div>

        <CRMSidebar
          schema={filteredSchema || null}
          entity={notes || null}
          updateEntity={updateNotes}
          sidebarFieldOrder={["kind", "created_by", "created_at"]}
        />
      </div>
    </Page>
  );
}

function NotesOrigin({ notes }: { notes: ICRMNotes }) {
  switch (notes.primary_resource) {
    case CRMNotesPrimaryResource.Company:
      return (
        <div className={"flex items-center space-x-1"}>
          <span className={"text-sm text-neutral-500"}>Companies /</span>
          <CRMCompanyLink value={notes.company} />
        </div>
      );
    case CRMNotesPrimaryResource.Contact:
      return (
        <div className={"flex items-center space-x-1"}>
          <span className={"text-sm text-neutral-500"}>Contacts /</span>{" "}
          <CRMContactLink value={notes.contact} />
        </div>
      );
    case CRMNotesPrimaryResource.Deal:
      return (
        <div className={"flex items-center space-x-1"}>
          <span className={"text-sm text-neutral-500"}>Deals /</span>
          <CRMDealLink value={notes.deal} />
        </div>
      );
  }
}
