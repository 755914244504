import { useEnvironmentId, useWorkspaceId } from "../../../../../routes";
import {
  useCRMContactSchema,
  usePostAPI,
  withErrorToast,
} from "../../../../../../data";
import { useMemo, useState } from "react";
import { ChevronRight, Plus } from "../../../../../../icons";
import {
  Button,
  ButtonWithRef,
} from "../../../../../../components/basics/button";
import { createHotkey, useHotkeys } from "../../../../../../hooks/keypress";
import { TextArea, TextInput } from "../../../../../../components/basics/input";
import { getInputRenderer } from "../../../../../database/input_renderer";
import { DatabaseSchema, ICRMContact } from "@anzuhq/backend";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Toggle } from "../../../../../../components/basics/checkbox";
import { Dialog, DialogHeader } from "../../../../../components/dialog";

export function getRendererForField(
  schema: DatabaseSchema,
  field: string,
  value: string | null,
  setValue: (value: string | null) => void
) {
  const fieldSchema = schema.fields.find((f) => f.name === field);
  if (!fieldSchema) {
    return null;
  }

  return getInputRenderer(
    fieldSchema,
    value,
    (v) => {
      setValue(v);
    },
    "small",
    "bottom",
    "none"
  );
}

export function CRMCreateContactDialog({ mutate }: { mutate: () => void }) {
  const navigate = useNavigate();
  const postApi = usePostAPI();
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { schema } = useCRMContactSchema(workspaceId, environmentId);

  const [open, setOpen] = useState(false);

  useHotkeys(createHotkey, () => setOpen(true), [setOpen]);

  const [fullName, setFullName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [email, setEmail] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [assignedTo, setAssignedTo] = useState<string | null>(null);
  const [stage, setStage] = useState<string | null>(null);
  const [company, setCompany] = useState<string | null>(null);
  const [linkedInUrl, setLinkedInUrl] = useState<string | null>(null);

  const isValid = useMemo(() => {
    return fullName.length > 0;
  }, [fullName]);

  const [createMore, setCreateMore] = useState(false);

  const reset = () => {
    setFullName("");
    setDescription("");
    setEmail(null);
    setPhoneNumber(null);
    setAssignedTo(null);
    setStage(null);
    setCompany(null);
    setLinkedInUrl(null);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const createContact = async () => {
    if (!isValid || isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await withErrorToast(async () => {
      const { id } = await postApi<ICRMContact>(
        `/workspaces/${workspaceId}/environments/${environmentId}/crm/contacts`,
        {
          full_name: fullName,
          description,
          assigned_to: assignedTo,
          stage,
          email,
          phone_number: phoneNumber,
          company,
          linkedin_url: linkedInUrl,
        }
      );
      reset();
      mutate();
      if (!createMore) {
        await navigate(
          `/${workspaceId}/environments/${environmentId}/crm/contacts/${id}`
        );
      }
      toast.success("Contact created");
    });
    setIsSubmitting(false);
  };

  if (!schema) {
    return null;
  }

  return (
    <Dialog
      activator={
        <ButtonWithRef
          icon={Plus}
          role={"primary"}
          size={"regular"}
          onClick={() => setOpen(true)}
        >
          Create Contact
        </ButtonWithRef>
      }
      open={open}
      setOpen={setOpen}
    >
      <DialogHeader>
        <div
          className={
            "bg-white/60 backdrop-blur-2xl shadow text-xs font-medium px-4 py-1 rounded text-neutral-700"
          }
        >
          CRM
        </div>

        <ChevronRight size={14} className={"text-neutral-300"} />

        <div className={"text-xs text-neutral-700"}>New contact</div>
      </DialogHeader>

      <form
        className={"flex grow flex-col p-2 text-sm"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          createContact();
        }}
      >
        <div className={"grow-0 shrink-0"}>
          <TextInput
            autoFocus
            value={fullName}
            onChange={setFullName}
            placeholder={"Full Name"}
            noInitialBorder
          />
        </div>

        <div className={"grow-0 shrink-0"}>
          <TextInput
            value={email || ""}
            onChange={(v) => (v.length > 0 ? setEmail(v) : setEmail(null))}
            placeholder={"Email"}
            noInitialBorder
            type={"email"}
          />
        </div>

        <div className={"grow-0 shrink-0"}>
          <TextInput
            value={phoneNumber || ""}
            onChange={(v) =>
              v.length > 0 ? setPhoneNumber(v) : setPhoneNumber(null)
            }
            placeholder={"Phone Number"}
            noInitialBorder
            type={"tel"}
          />
        </div>

        <div className={"grow-0 shrink-0"}>
          <TextInput
            value={linkedInUrl || ""}
            onChange={(v) =>
              v.length > 0 ? setLinkedInUrl(v) : setLinkedInUrl(null)
            }
            placeholder={"LinkedIn URL"}
            noInitialBorder
            type={"url"}
          />
        </div>

        <div className={"grow shrink-0"}>
          <TextArea
            value={description}
            onChange={setDescription}
            placeholder={"Add description..."}
            noInitialBorder
          />
        </div>
      </form>

      <div className={"mt-auto flex items-center justify-between space-x-2"}>
        <div
          className={
            "overflow-auto p-2 text-xs text-neutral-700 space-x-2 whitespace-nowrap w-full flex items-center select-none"
          }
        >
          <span className={"text-neutral-500"}>Assigned To</span>
          {getRendererForField(
            schema,
            "assigned_to",
            assignedTo,
            setAssignedTo
          )}

          <span className={"text-neutral-500"}>Stage</span>
          {getRendererForField(schema, "stage", stage, setStage)}

          <span className={"text-neutral-500"}>Company</span>
          {getRendererForField(schema, "company", company, setCompany)}
        </div>

        <div className={"flex items-center space-x-2 p-2"}>
          <Toggle
            checked={createMore}
            label={"Create more?"}
            onChange={setCreateMore}
            id={"create-more"}
            size={"small"}
            labelLeading
          />

          <Button
            isLoading={isSubmitting}
            onClick={createContact}
            disabled={!isValid}
            icon={Plus}
            role={"primary"}
            size={"small"}
          >
            Create contact
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
