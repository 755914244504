import { useWorkspaceId } from "../../../../routes";
import { useWorkspaceMemberProfile } from "../../../../../data";
import { ImageWithFallback } from "../../../../database/renderer";
import { AnimatePresence, motion } from "framer-motion";
import {
  AllEvents,
  CRMCompanyAssignedEvent,
  CRMCompanyUnassignedEvent,
  CRMContactAssignedEvent,
  CRMContactUnassignedEvent,
  CRMDealAssignedEvent,
  CRMDealUnassignedEvent,
  EventActorKind,
  EventKind,
  IEvent,
} from "@anzuhq/backend";
import { CRMCommentRenderer } from "./comment";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { CRMNotesRenderer } from "./notes";

export function ActivitySection({ events }: { events: AllEvents[] }) {
  return (
    <div className={"pt-8 flex flex-col space-y-4"}>
      <div>
        <p className={"text-neutral-800 select-none"}>Activity</p>
      </div>

      {events.length > 0 ? (
        <div className={"flex flex-col space-y-1"}>
          <AnimatePresence>
            {events.map((a) => (
              <ActivityRenderer key={a.id} activity={a} />
            ))}

            <div key="bottom-end" className={"flex flex-col space-y-1 text-sm"}>
              <div className={"ml-4 w-2 h-2 rounded-full bg-neutral-200"}></div>
            </div>
          </AnimatePresence>
        </div>
      ) : (
        <div
          className={
            "flex items-center justify-center h-24 bg-neutral-50 rounded-md"
          }
        >
          <p className={"text-sm text-neutral-600 select-none"}>
            No activity yet.
          </p>
        </div>
      )}
    </div>
  );
}

function ActivityRenderer({ activity }: { activity: AllEvents }) {
  let render;
  switch (activity.kind) {
    case EventKind.CRMCommentCreated:
      render = <CRMCommentRenderer activity={activity} />;
      break;
    case EventKind.CRMNotesCreated:
    case EventKind.CRMNotesUpdated:
      render = <CRMNotesRenderer activity={activity} />;
      break;
    default:
      render = (
        <div className={"px-3 flex items-start"}>
          <div className={"mr-2"}>
            <ActorRenderer activity={activity} />
          </div>
          <div className={"flex items-center space-x-1"}>
            <ActivityText activity={activity} />
          </div>
          <div className={"ml-2 text-neutral-500"}>
            {formatDistanceToNowStrict(parseISO(activity.createdAt), {
              addSuffix: true,
            })}
          </div>
        </div>
      );
      break;
  }

  return (
    <motion.div
      initial={{ y: -20 }}
      animate={{ y: [-20, 0] }}
      exit={{ y: -20 }}
      className={"flex flex-col space-y-1 text-sm"}
    >
      {render}
      <div className={"border-l h-4 border-neutral-200 ml-5"} />
    </motion.div>
  );
}

function ActivityText({ activity }: { activity: AllEvents }) {
  switch (activity.kind) {
    case EventKind.CRMCompanyCreated:
      return <span className={"text-neutral-500"}>created company</span>;
    case EventKind.CRMContactCreated:
      return <span className={"text-neutral-500"}>created contact</span>;
    case EventKind.CRMDealCreated:
      return <span className={"text-neutral-500"}>created deal</span>;

    case EventKind.CRMContactUnassigned:
    case EventKind.CRMDealUnassigned:
    case EventKind.CRMCompanyUnassigned: {
      return <UnassignedTextRenderer activity={activity} />;
    }
    case EventKind.CRMContactAssigned:
    case EventKind.CRMDealAssigned:
    case EventKind.CRMCompanyAssigned: {
      return <AssignedToTextRenderer activity={activity} />;
    }
    default:
      return <p>unknown activity kind</p>;
  }
}

function UnassignedTextRenderer({
  activity,
}: {
  activity:
    | CRMContactUnassignedEvent
    | CRMDealUnassignedEvent
    | CRMCompanyUnassignedEvent;
}) {
  return (
    <>
      <span className={"text-neutral-500"}>unassigned</span>
      <WorkspaceMemberRenderer actorId={activity.payload.from} />
    </>
  );
}

function AssignedToTextRenderer({
  activity,
}: {
  activity:
    | CRMContactAssignedEvent
    | CRMDealAssignedEvent
    | CRMCompanyAssignedEvent;
}) {
  return (
    <>
      <span className={"text-neutral-500"}>assigned to</span>
      <WorkspaceMemberRenderer actorId={activity.payload.to} />
    </>
  );
}

export function ActorRenderer({ activity }: { activity: IEvent }) {
  switch (activity.actor.kind) {
    case EventActorKind.WorkspaceMember:
      if (!activity.actor) {
        return null;
      }
      return <WorkspaceMemberRenderer actorId={activity.actor.id} />;
    default:
      return <p>unkown actor kind</p>;
  }
}

function WorkspaceMemberRenderer({ actorId }: { actorId: string }) {
  const workspaceId = useWorkspaceId();
  const { profile } = useWorkspaceMemberProfile(workspaceId, actorId);

  if (!profile) {
    return (
      <div className={"flex items-center space-x-2"}>
        <div className={"rounded-full w-6 h-6 animate-pulse bg-neutral-200"} />
        <span className={"animate-pulse"}>loading</span>
      </div>
    );
  }

  return (
    <div className={"flex items-center space-x-2"}>
      {profile.gravatar_url ? (
        <ImageWithFallback
          src={profile.gravatar_url}
          alt={"profile"}
          height={"h-5"}
          width={"w-5"}
          round
        />
      ) : null}
      <span>{profile.name}</span>
    </div>
  );
}
