import { useBreadcrumbItems, useContextualNavigation } from "../nav";
import { Navigate } from "react-router-dom";
import { useInsightsSettingsSections } from "./nav";
import { Page } from "../../../../../components/page/page";
import {
  toEnvironmentInsightsSettingsSection,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../routes";
import { LayoutWithStickySidebar } from "../../../../../components/settings";
import { SimpleHeaderWithTitle } from "../../../../../components/header";

export function InsightsSettings() {
  const navItems = useContextualNavigation();
  const breadcrumbItems = useBreadcrumbItems();
  const sections = useInsightsSettingsSections();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  return (
    <Page
      title={"Settings - Insights"}
      contextualNavigationItems={navItems}
      breadcrumbItems={breadcrumbItems}
    >
      <SimpleHeaderWithTitle title={"Settings"} />
      <LayoutWithStickySidebar
        sections={sections}
        sectionUrl={(sectionId) =>
          toEnvironmentInsightsSettingsSection(
            workspaceId,
            environmentId,
            sectionId
          )
        }
      />
    </Page>
  );
}

export function InsightsSettingsOverview() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const sections = useInsightsSettingsSections();

  return (
    <Navigate
      to={toEnvironmentInsightsSettingsSection(
        workspaceId,
        environmentId,
        sections[0].id
      )}
      replace
    />
  );
}
