import { useUserManagementSettingsConfig } from "./general/page";
import { useMemo } from "react";
import { AuthProviderKind } from "@anzuhq/backend";

export function useUserManagementSettingsSections() {
  const { userManagementConfig } = useUserManagementSettingsConfig();

  return useMemo(() => {
    return [
      {
        name: "General",
        id: "general",
      },
      {
        name: "Redirect URI",
        id: "redirect-uri",
        warning:
          userManagementConfig?.config.default_redirect_uri === null
            ? "Default redirect URI is not set"
            : undefined,
      },
      {
        name: "Hosted Page",
        id: "hosted-auth-page",
        warning:
          userManagementConfig?.config.auth_providers.some(
            (a) => a.kind === AuthProviderKind.Email && a.isEnabled
          ) &&
          !userManagementConfig.config.hosted_page_appearance?.applicationName
            ? "Application Name is not set but required for email auth"
            : undefined,
      },
      {
        name: "Auth Providers",
        id: "auth-providers",
        warning:
          userManagementConfig?.config.auth_providers.length === 0 ||
          !userManagementConfig?.config.auth_providers.some((a) => a.isEnabled)
            ? "No auth providers are enabled"
            : undefined,
      },
      {
        name: "User Provisioning",
        id: "user-provisioning",
      },
      {
        name: "Access Tokens",
        id: "access-tokens",
      },
    ];
  }, [userManagementConfig]);
}
