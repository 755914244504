import { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../../hooks/localStorage";
import { toEnvironmentDashboard, useEnvironmentId } from "../../routes";
import { PageSkeleton } from "../../components/skeleton/page_skeleton";
import { Environment } from "@anzuhq/backend";

export const EnvironmentContext = createContext<{
  environmentId: string | null;
  setEnvironmentId: (environmentId: string | null) => void;
}>({
  environmentId: null,
  setEnvironmentId: (environmentId: string | null) => {},
});

export function EnvironmentContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const [environmentId, setEnvironmentId] = useLocalStorage<string | null>(
    "anzu:preferences:environmentId",
    null
  );

  return (
    <EnvironmentContext.Provider value={{ environmentId, setEnvironmentId }}>
      {children}
    </EnvironmentContext.Provider>
  );
}

export function useEnvironmentContext(): [
  string | null,
  (environmentId: string | null) => void
] {
  const { environmentId, setEnvironmentId } = useContext(EnvironmentContext);
  return [environmentId, setEnvironmentId];
}

// Whenever entering a environment route, remember the preference for next time
export function RememberEnvironment() {
  const environmentId = useEnvironmentId();
  const [, setEnvironmentId] = useEnvironmentContext();

  useEffect(
    () => {
      setEnvironmentId(environmentId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [environmentId]
  );

  return <Outlet />;
}

// Choose a suitable environment to redirect to
export function RedirectToFittingEnvironment({
  productionEnvironment,
  environments,
  workspaceId,
}: {
  environments: Environment[];
  productionEnvironment: Environment;
  workspaceId: string;
}) {
  const [environmentId] = useEnvironmentContext();
  const navigate = useNavigate();

  useEffect(
    () => {
      // If no environment was chosen, redirect to first environment
      if (!environmentId) {
        navigate(
          toEnvironmentDashboard(workspaceId, productionEnvironment.id),
          {
            replace: true,
          }
        );
        return;
      }

      // If environment does no longer exist, reset
      if (!environments.some((t) => t.id === environmentId)) {
        navigate(
          toEnvironmentDashboard(workspaceId, productionEnvironment.id),
          {
            replace: true,
          }
        );
        return;
      }

      // Otherwise redirect to environment
      navigate(toEnvironmentDashboard(workspaceId, environmentId), {
        replace: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [environments, environmentId]
  );

  return <PageSkeleton title={"Redirecting"} />;
}
