import { useHotkeys as useHotkeysHook } from "react-hotkeys-hook";

export const deleteHotkey = "cmd+backspace, del";
export const confirmHotkey = "cmd+enter, ctrl+enter";
export const createHotkey = "option+c,alt+c";

export function useHotkeys<T extends HTMLElement>(
  keys: string,
  handler: (e: KeyboardEvent) => void,
  deps?: any[],
  enabled?: boolean
) {
  return useHotkeysWithPropagation<T>(keys, handler, deps, enabled);
}

export function useHotkeysWithPropagation<T extends HTMLElement>(
  keys: string,
  handler: (e: KeyboardEvent) => void,
  deps?: any[],
  enabled: boolean = true
) {
  return useHotkeysHook<T>(
    keys,
    handler,
    {
      preventDefault: true,
      enabled,
    },
    deps
  );
}
