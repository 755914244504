import { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { toWorkspaceDashboard, useWorkspaceId } from "../routes";
import { Workspace } from "@anzuhq/backend";
import { PageSkeleton } from "../components/skeleton/page_skeleton";
import { useLocalStorage } from "../../hooks/localStorage";

export const WorkspaceContext = createContext<{
  workspaceId: string | null;
  setWorkspaceId: (workspaceId: string | null) => void;
}>({
  workspaceId: null,
  setWorkspaceId: (workspaceId: string | null) => {},
});

export function WorkspaceContextProvider({ children }: PropsWithChildren<{}>) {
  const [workspaceId, setWorkspaceId] = useLocalStorage<string | null>(
    "anzu:preferences:workspaceId",
    null
  );

  return (
    <WorkspaceContext.Provider value={{ workspaceId, setWorkspaceId }}>
      {children}
    </WorkspaceContext.Provider>
  );
}

export function useWorkspaceContext(): [
  string | null,
  (workspaceId: string | null) => void
] {
  const { workspaceId, setWorkspaceId } = useContext(WorkspaceContext);
  return [workspaceId, setWorkspaceId];
}

// Whenever entering a workspace route, remember the preference for next time
export function RememberWorkspace() {
  const workspaceId = useWorkspaceId();
  const [, setWorkspaceId] = useWorkspaceContext();

  useEffect(
    () => {
      setWorkspaceId(workspaceId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workspaceId]
  );

  return <Outlet />;
}

// Choose a suitable workspace to redirect to
export function RedirectToFittingWorkspace({
  workspaces,
}: {
  workspaces: Workspace[];
}) {
  const [workspaceId] = useWorkspaceContext();
  const navigate = useNavigate();

  useEffect(
    () => {
      // If no workspace was chosen, redirect to first workspace
      if (!workspaceId) {
        if (workspaces.length === 0) {
          navigate("/", { replace: true });
          return;
        }
        navigate(toWorkspaceDashboard(workspaces[0].id), { replace: true });
        return;
      }

      // If workspace does no longer exist, reset
      if (!workspaces.some((t) => t.id === workspaceId)) {
        if (workspaces.length === 0) {
          navigate("/", { replace: true });
          return;
        }
        navigate(toWorkspaceDashboard(workspaces[0].id), { replace: true });
        return;
      }

      // Otherwise redirect to workspace
      navigate(toWorkspaceDashboard(workspaceId), { replace: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workspaces, workspaceId]
  );

  return <PageSkeleton title={"Redirecting"} />;
}
