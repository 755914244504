import { Page } from "../../../../../components/page/page";
import { useBreadcrumbItems, useContextualNavigation } from "../nav";
import {
  PageHeaderTitle,
  SimpleHeader,
} from "../../../../../components/header";

export function InsightsExplorePage() {
  const items = useContextualNavigation();
  const breadcrumbs = useBreadcrumbItems();

  return (
    <Page
      contextualNavigationItems={items}
      breadcrumbItems={breadcrumbs}
      title={"Explore"}
    >
      <SimpleHeader>
        <PageHeaderTitle>Explore</PageHeaderTitle>
      </SimpleHeader>
    </Page>
  );
}
