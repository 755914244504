import { useMatch } from "react-router-dom";
import {
  toEnvironmentInsights,
  toEnvironmentCRM,
  toEnvironmentDevelopers,
  toEnvironmentOverview,
  toEnvironmentUserManagement,
  useWorkspaceId,
  toWorkspaceSettings,
} from "../../../routes";
import { ContextualNavigationItem } from "../../../navigation";
import { useEnvironmentContext } from "../context";

export const useContextualNavigation = () => {
  const workspaceId = useWorkspaceId();
  const [environmentId] = useEnvironmentContext();

  const overviewPath = toEnvironmentOverview(workspaceId, environmentId || "");
  const onOverview = useMatch(overviewPath);

  const insightsPath = toEnvironmentInsights(workspaceId, environmentId || "");
  const onInsights = useMatch(insightsPath);

  const crmPath = toEnvironmentCRM(workspaceId, environmentId || "");
  const onCrm = useMatch(crmPath);

  const userManagementPath = toEnvironmentUserManagement(
    workspaceId,
    environmentId || ""
  );
  const onUserManagement = useMatch(userManagementPath);

  const developersPath = toEnvironmentDevelopers(
    workspaceId,
    environmentId || ""
  );
  const onDevelopers = useMatch(developersPath);

  const settingsPath = toWorkspaceSettings(workspaceId);

  const onSettings = useMatch({
    path: settingsPath,
    end: false,
  });

  const items: ContextualNavigationItem[] = [
    {
      to: overviewPath,
      active: onOverview !== null,
      label: "Overview",
    },
    {
      to: crmPath,
      active: onCrm !== null,
      label: "CRM",
    },
    {
      to: insightsPath,
      active: onInsights !== null,
      label: "Insights",
    },

    {
      to: userManagementPath,
      active: onUserManagement !== null,
      label: "User Management",
    },
    {
      to: developersPath,
      active: onDevelopers !== null,
      label: "Developers",
    },
    {
      to: settingsPath,
      active: onSettings !== null,
      label: "Settings",
    },
  ];

  return items;
};
