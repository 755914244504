import React, { PropsWithChildren, useMemo, useState } from "react";
import { DatabaseGroupBy, DatabaseSchema } from "@anzuhq/backend";
import {
  Dropdown,
  DropdownItemButton,
  DropdownListContainer,
  DropdownTextInput,
} from "./dropdown";
import { RenderIcon } from "./renderer";
import { useHotkeys } from "../../hooks/keypress";

export function GroupByDialog({
  children,
  groupBy,
  setGroupBy,
  schema,
  enableHotkey,
}: PropsWithChildren<{
  schema: DatabaseSchema;
  setGroupBy: (g: DatabaseGroupBy | null) => void;
  groupBy: DatabaseGroupBy | null;
  enableHotkey?: boolean;
}>) {
  const [open, setOpen] = useState(false);

  useHotkeys("g", () => setOpen(true), [setOpen], enableHotkey);

  const [search, setSearch] = useState("");

  const filteredFields = useMemo(() => {
    return schema.fields.filter(
      (f) =>
        // only allow filtering on fields that are filterable
        f.isGroupable &&
        // only allow filtering on fields that match the search
        f.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [schema, search]);

  return (
    <Dropdown
      activator={children}
      open={open}
      setOpen={(open) => {
        setOpen(open);
      }}
    >
      <DropdownTextInput
        placeholder={"Search..."}
        value={search}
        setValue={setSearch}
      />

      <DropdownListContainer>
        {filteredFields.map((f) => (
          <DropdownItemButton
            active={groupBy?.field === f.name}
            key={f.name}
            onClick={() => {
              setGroupBy({ field: f.name });
              setOpen(false);
            }}
          >
            {f.icon ? <RenderIcon icon={f.icon} /> : null}
            <span>{f.label}</span>
          </DropdownItemButton>
        ))}
      </DropdownListContainer>
    </Dropdown>
  );
}
