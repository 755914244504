export enum Stack {
  NodeJS = "nodejs",
  Go = "go",
}

export enum Language {
  TypeScript = "typescript",
  JavaScript = "javascript",
  Go = "go",
}

export interface StackWithCode {
  stack: Stack;
  code: string;
  language: Language;
}

export function getStackName(stack: Stack) {
  switch (stack) {
    case Stack.NodeJS:
      return "Node.js";
    case Stack.Go:
      return "Go";
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _exhaustiveCheck: never = stack;
      throw new Error("Unreachable");
  }
}

export function getStackLanguage(stack: Stack) {
  switch (stack) {
    case Stack.NodeJS:
      return "javascript";
    case Stack.Go:
      return "go";
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _exhaustiveCheck: never = stack;
      throw new Error("Unreachable");
  }
}
