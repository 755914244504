import { PropsWithChildren } from "react";
import classNames from "classnames";

export function FieldSet({
  footer,
  title,
  children,
  loading,
  action,
}: PropsWithChildren<{
  footer: JSX.Element;
  title: string;
  loading?: boolean;
  action?: JSX.Element;
}>) {
  return (
    <div
      className={classNames("rounded-md border border-neutral-200", {
        "animate-pulse": loading,
      })}
    >
      <div className={"p-8 text-sm flex flex-col space-y-2"}>
        <div className={"pb-2 flex items-center"}>
          <h2 className={"text-xl font-medium select-none"}>{title}</h2>
          <div className={"ml-auto"}>{action}</div>
        </div>
        {children}
      </div>

      <div
        className={
          "bg-neutral-50 rounded-b-md border-t border-neutral-200 px-6 py-4 text-sm text-neutral-800 select-none"
        }
      >
        {footer}
      </div>
    </div>
  );
}
