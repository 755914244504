import React, { useMemo } from "react";
import { BarRounded, LinePath } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear, scaleTime } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { ParentSize } from "@visx/responsive";
import { default as tw } from "tailwindcss/colors";
import {
  InsightsCategoryCountBucket,
  InsightsCountBucket,
  InsightsTimeRange,
} from "@anzuhq/backend";
import { eachDayOfInterval, format, parseISO } from "date-fns";
import { getDomainFromTimeRangeRelative } from "../events/chart";
import { curveLinear } from "@visx/curve";
import stringToColor from "string-to-color";

const verticalMargin = 46;
const horizontalMargin = 60;

interface BarItem {
  value: number;
  label: string;
  key?: string;
}

const getLabel = (d: BarItem) => d.key || d.label;
const getValue = (d: BarItem) => d.value;

type BarsProps = {
  width: number;
  height: number;
  events?: boolean;
  data: BarItem[];
};

type TimelineLineChartProps = {
  width: number;
  height: number;
  data: InsightsCategoryCountBucket[];
  timeRange: InsightsTimeRange;
};

export function BarChart({ width, height, data }: BarsProps) {
  // bounds
  const xMax = width - horizontalMargin;
  const yMax = height - verticalMargin;

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: data.map(getLabel),
        padding: 0.4,
      }),
    [xMax, data]
  );
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...data.map(getValue))],
      }),
    [yMax, data]
  );

  return width < 10 ? null : (
    <svg width={width} height={height}>
      <Group top={verticalMargin / 2} left={horizontalMargin / 2}>
        {data.map((d) => {
          const label = getLabel(d);
          const barWidth = xScale.bandwidth();
          const barHeight = yMax - (yScale(getValue(d)) ?? 0);
          const barX = xScale(label);
          const barY = yMax - barHeight;
          return (
            <BarRounded
              top
              radius={2}
              key={`bar-${d.key || label}`}
              x={barX || 0}
              y={barY}
              width={barWidth}
              height={barHeight}
              fill={stringToColor(label)}
            />
          );
        })}

        <AxisBottom
          numTicks={data.length}
          top={yMax}
          scale={xScale}
          tickLabelProps={() => ({
            fontSize: 11,
            textAnchor: "middle",
            className: "fill-neutral-500",
          })}
          tickLineProps={{
            className: "stroke-neutral-400",
          }}
          stroke={tw.neutral[400]}
          tickClassName={"fill-neutral-400"}
        />
        <AxisLeft
          scale={yScale.nice()}
          numTicks={10}
          top={0}
          tickLabelProps={(e) => ({
            fontSize: 10,
            textAnchor: "end",
            x: -12,
            y: (yScale(e) ?? 0) + 3,
            className: "fill-neutral-500",
          })}
          tickLineProps={{
            className: "stroke-neutral-400",
          }}
          stroke={tw.neutral[400]}
        />
      </Group>
    </svg>
  );
}

export function BarChartResponsive({
  height,
  data,
}: Pick<BarsProps, "height" | "data">) {
  return (
    <>
      <ParentSize>
        {(parent) => (
          <BarChart width={parent.width} height={height} data={data} />
        )}
      </ParentSize>
    </>
  );
}

function fillWithEmptyDays(
  data: InsightsCategoryCountBucket[],
  domain: number[]
): InsightsCategoryCountBucket[] {
  const days = eachDayOfInterval({
    start: new Date(domain[0]),
    end: new Date(domain[1]),
  });

  const df = (date: Date) => {
    return format(date, "yyyy-MM-dd HH:mm:ss");
  };

  for (let day of days) {
    for (let category of data) {
      const hasDay = category.results.find((r) => r.bucket === df(day));
      if (!hasDay) {
        category.results.push({
          bucket: df(day),
          count: 0,
        });
        category.results = category.results.sort((a, b) => {
          return a.bucket.localeCompare(b.bucket);
        });
      }
    }
  }

  return data;
}

export function TimelineLineChart({
  width: outerWidth,
  height: outerHeight,
  data,
  timeRange,
}: TimelineLineChartProps) {
  const margin = {
    top: 40,
    right: 40,
    bottom: 40,
    left: 40,
  };

  const innerWidth = outerWidth - margin.left - margin.right;
  const innerHeight = outerHeight - margin.top - margin.bottom;

  const xMax = outerWidth - margin.right;
  const yMax = outerHeight - margin.bottom;

  const getDate = (d: InsightsCountBucket) => parseISO(d.bucket).getTime();
  const getDateCount = (d: InsightsCountBucket) => d.count;

  const relativeTo = new Date();
  relativeTo.setHours(-2);
  relativeTo.setMinutes(0);

  const prepared = useMemo(
    () =>
      fillWithEmptyDays(
        data,
        getDomainFromTimeRangeRelative(timeRange.relative!, new Date())
      ),
    [data, timeRange]
  );

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleTime({
        range: [0, innerWidth],
        domain: getDomainFromTimeRangeRelative(timeRange.relative!, relativeTo),
      }),
    [timeRange, xMax]
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [innerHeight, 0],
        domain: [
          0,
          Math.max(
            ...prepared
              .map((r) => r.results)
              .flat(1)
              .map(getDateCount)
          ),
        ],
      }),
    [prepared, yMax]
  );

  return outerWidth < 10 ? null : (
    <svg width={outerWidth} height={outerHeight}>
      <Group top={margin.top} left={margin.left}>
        {prepared.map((lineData, i) => {
          return (
            <Group key={`lines-${i}`}>
              {lineData.results.map((d, j) => (
                <circle
                  key={i + j}
                  r={3}
                  cx={xScale(getDate(d))}
                  cy={yScale(getDateCount(d)) ?? 0}
                  fill={stringToColor(lineData.category)}
                  stroke={stringToColor(lineData.category)}
                />
              ))}
              <LinePath
                curve={curveLinear}
                data={lineData.results}
                x={(d) => xScale(getDate(d)) ?? 0}
                y={(d) => yScale(getDateCount(d)) ?? 0}
                stroke={stringToColor(lineData.category)}
                strokeWidth={1}
                strokeOpacity={1}
                shapeRendering="geometricPrecision"
              />
            </Group>
          );
        })}

        <AxisBottom
          numTicks={7}
          top={innerHeight}
          scale={xScale}
          tickLabelProps={() => ({
            fontSize: 11,
            textAnchor: "middle",
            className: "fill-neutral-500",
          })}
          tickLineProps={{
            className: "stroke-neutral-400",
          }}
          stroke={tw.neutral[400]}
          tickClassName={"fill-neutral-400"}
        />
        <AxisLeft
          scale={yScale.nice()}
          numTicks={5}
          tickLabelProps={(e) => ({
            fontSize: 10,
            textAnchor: "end",
            x: -12,
            y: (yScale(e) ?? 0) + 3,
            className: "fill-neutral-500",
          })}
          tickLineProps={{
            className: "stroke-neutral-400",
          }}
          stroke={tw.neutral[400]}
        />
      </Group>
    </svg>
  );
}

export function TimelineLineChartResponsive({
  height,
  data,
  timeRange,
}: Pick<TimelineLineChartProps, "height" | "data" | "timeRange">) {
  return (
    <>
      <ParentSize>
        {(parent) => (
          <TimelineLineChart
            width={parent.width}
            height={height}
            data={data}
            timeRange={timeRange}
          />
        )}
      </ParentSize>
    </>
  );
}
