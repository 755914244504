import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { motion } from "framer-motion";
import * as RadixDialog from "@radix-ui/react-dialog";
import { X } from "../../icons";
import { RawButtonWithRef } from "../../components/basics/button";
import { useHotkeys } from "../../hooks/keypress";

export function Dialog({
  setOpen,
  open,
  activator,
  children,
  transparent,
}: PropsWithChildren<{
  activator: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  transparent?: boolean;
}>) {
  useHotkeys("esc", () => setOpen(false), [open], open);

  return (
    <RadixDialog.Root open={open} onOpenChange={setOpen} modal={true}>
      <RadixDialog.Trigger asChild>{activator}</RadixDialog.Trigger>

      <RadixDialog.Portal>
        {/* We use a regular div instead of RadixDialog.Overlay here because that blocks scrolling in nested Dropdowns */}
        <div key={"overlay"}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className={
              "fixed top-0 left-0 w-full h-full z-20 bg-neutral-100/70"
            }
          />
        </div>
        <RadixDialog.Content asChild key={"content"}>
          <motion.div
            // x and y must be used when using motion.div instead of -translate-x-1/2 -translate-y-1/2 to center modal
            /*
          can revert to if we don't want dialog to "jump out of the button":
          initial={{ opacity: 0, scale: 0.8, y: "-40%", x: "-50%" }}
          animate={{ opacity: 1, scale: [1.1, 1.0], y: "-50%", x: "-50%" }}
           */
            initial={{ opacity: 0, scale: 0, y: "-80%", x: "0%" }}
            animate={{
              opacity: 1,
              scale: [0.5, 0.8, 1.1, 1.0],
              y: "-50%",
              x: "-50%",
            }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.2 }}
            className={classNames(
              // positioning above and in the center
              "z-30 fixed top-1/2 left-1/2",

              // responsive sizing
              "w-[450px] h-[400px] md:w-[750px] md:h-[500px] lg:w-[900px] lg:h-[600px] xl:w-[900px] xl:h-[600px]",

              // styling of the dialog box
              !transparent
                ? "bg-white/60 backdrop-blur-2xl rounded-lg shadow-2xl"
                : "",

              // layout
              "flex flex-col"
            )}
          >
            {children}
          </motion.div>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}

export function DialogHeader({ children }: PropsWithChildren<{}>) {
  return (
    <div className={"flex items-center justify-between select-none"}>
      <RadixDialog.Title className="p-2 flex items-center space-x-2">
        {children}
      </RadixDialog.Title>

      <div className={"p-2"}>
        <RadixDialog.Close asChild>
          <RawButtonWithRef
            onClick={() => {}}
            aria-label="Close"
            className={
              "text-neutral-400 hover:text-neutral-500 active:text-neutral-700 p-2"
            }
          >
            <X size={16} />
          </RawButtonWithRef>
        </RadixDialog.Close>
      </div>
    </div>
  );
}
