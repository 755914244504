import { CellContext, ColumnDef } from "@tanstack/react-table";
import { Checkbox } from "../../components/basics/checkbox";
import {
  Box,
  Calendar,
  CheckCircle,
  ChevronRight,
  Circle,
  Columns,
  Database as DatabaseFeatherIcon,
  DollarSign,
  ExternalLink,
  Hash,
  Link as LinkIcon,
  Mail,
  MapPin,
  ToggleLeft,
  Type,
  User,
} from "../../icons";
import { format, parseISO } from "date-fns";

import classNames from "classnames";
import { useMemo, useState } from "react";

import { BsGithub, BsGoogle } from "react-icons/bs";
import { getIconColor, getTextColor } from "./color";
import {
  DatabaseEnumField,
  DatabaseField,
  DatabaseFieldKind,
  DatabaseIcon,
  DatabaseIDField,
  DatabaseMonetaryValue,
  DatabaseRelatedEntity,
  DatabaseStringField,
  DatabaseStringValueType,
  RenderableIcon,
} from "@anzuhq/backend";
import {
  useCRMCompany,
  useCRMContact,
  useCRMDeal,
  useUserManagementUserIdentity,
  useWorkspaceMember,
  useWorkspaceMemberProfile,
} from "../../data";
import {
  toEnvironmentCRMCompany,
  toEnvironmentCRMContact,
  toEnvironmentCRMDeal,
  toEnvironmentUserManagementUserIdentity,
  useEnvironmentId,
  useWorkspaceId,
} from "../routes";
import { Link } from "react-router-dom";
import { retrieveUserIdentityName } from "../[workspaceId]/environments/[environmentId]/user-management/user-identities/[userIdentityId]/page";
import { DateTimeRendererWithFormats } from "./datetime";
import {
  getCurrentLocale,
  MonetaryValueRendererWithConversion,
} from "./monetary_value";

export interface TData {
  id: string;
  [key: string]: any;
}

export const EmptyCell = () => (
  <span className={"text-neutral-300 select-none"}>-</span>
);

export function renderText<T extends TData>(
  field: DatabaseStringField,
  toDetailPage: (id: string) => string,
  linkMode?: "inline" | "none" | "separate",
  onOpenSplitView?: (id: string) => void
) {
  return (info: CellContext<T, any>) => {
    const value = info.getValue() as string | undefined;
    if (!value) {
      return <EmptyCell />;
    }

    const row = info.row.original;

    return (
      <TextRenderer
        field={field}
        linkMode={linkMode}
        toDetailPage={toDetailPage}
        value={value}
        id={row.id}
        onOpenSplitView={onOpenSplitView}
      />
    );
  };
}

export function TextRenderer({
  field,
  toDetailPage,
  value,
  id,
  linkMode,
  onOpenSplitView,
}: {
  id?: string;
  value: string | null;
  field: DatabaseStringField;
  toDetailPage?: (id: string) => string;
  linkMode?: "inline" | "none" | "separate";
  onOpenSplitView?: (id: string) => void;
}) {
  if (value === null) {
    return <EmptyCell />;
  }

  if (field.isTitle && toDetailPage && id) {
    return (
      <div className={"flex items-center space-x-1"}>
        {onOpenSplitView ? (
          <button
            className={
              "hidden md:block group-hover:opacity-100 active:bg-neutral-200 opacity-0 p-1 transition duration-100 bg-transparent hover:bg-neutral-100 rounded"
            }
            onClick={() => onOpenSplitView(id)}
          >
            <Columns size={16} />
          </button>
        ) : null}

        <Link
          to={toDetailPage(id)}
          className={
            "font-normal text-sm underline decoration-dashed underline-offset-4 decoration-neutral-400 hover:decoration-neutral-700 transition duration-100"
          }
        >
          {value}
        </Link>
      </div>
    );
  }

  if (value === "") {
    return (
      <span className={"font-normal text-sm text-neutral-600 font-italic"}>
        Empty
      </span>
    );
  }

  return (
    <span
      className={classNames(
        "block truncate min-w-0 max-w-full font-normal text-sm",
        {
          "text-left": !field.isMultiline,
          "text-justify": field.isMultiline,
        }
      )}
    >
      {value}
    </span>
  );
}

export function renderNameLink<T extends { id: string; name: string }>(
  toDetailPage: (id: string) => string
) {
  return function NameLink(info: CellContext<T, any>) {
    const row = info.row.original;
    return (
      <Link
        to={toDetailPage(row.id)}
        className={
          "font-normal text-sm underline decoration-dashed underline-offset-4 decoration-neutral-400 hover:decoration-neutral-700 transition duration-100"
        }
      >
        {row.name}
      </Link>
    );
  };
}

function SelectRowCell<T>({ info }: { info: CellContext<T, any> }) {
  const rowSelected = info.row.getIsSelected();

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        info.row.toggleSelected(!rowSelected);
      }}
      className={classNames("px-1 group-hover:visible w-full h-full", {
        visible: rowSelected,
        invisible: !rowSelected,
      })}
    >
      <Checkbox
        id={info.cell.id}
        checked={rowSelected}
        indeterminate={false}
        onChange={(v) => {
          info.row.toggleSelected(v);
        }}
      />
    </div>
  );
}

export function ImageWithFallback({
  src,
  alt,
  height = "h-4",
  width = "w-4",
  round,
}: {
  src: string;
  alt: string;
  height?: string;
  width?: string;
  round?: boolean;
}) {
  const [hasError, setHasError] = useState(false);

  if (hasError) {
    return (
      <div
        className={classNames(
          " bg-gradient-to-tr from-neutral-100 to-neutral-500",
          height,
          width,
          { "rounded-full": round, rounded: !round }
        )}
      />
    );
  }

  return (
    <img
      onError={(e) => setHasError(true)}
      alt={alt}
      src={src}
      className={classNames("", height, width, {
        "rounded-full": round,
        rounded: !round,
      })}
    />
  );
}

export function selectColumn<T>(): ColumnDef<T> {
  const def: ColumnDef<T> = {
    id: "select",

    enableResizing: false,

    header: ({ table }) => {
      const allRowsSelected = table.getIsAllRowsSelected();
      const someRowsSelected = table.getIsSomeRowsSelected();

      return (
        <div
          className={"flex items-center space-x-2"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            table.toggleAllRowsSelected();
          }}
        >
          <Checkbox
            id={"select-all"}
            checked={allRowsSelected}
            indeterminate={someRowsSelected}
            onChange={(v) => table.toggleAllRowsSelected()}
          />
          <span className={"font-normal text-sm whitespace-nowrap"}>
            Select all
          </span>
        </div>
      );
    },
    cell: (info) => <SelectRowCell info={info} />,
  };

  return def;
}

export function UserIdentityLink({
  value,
  size = "regular",
  linkMode = "inline",
}: {
  value: string | null;
  size?: "small" | "regular";
  linkMode?: "inline" | "none" | "separate";
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { identity } = useUserManagementUserIdentity(
    workspaceId,
    environmentId,
    value
  );

  if (linkMode === "separate") {
    if (!value || !identity) {
      return null;
    }

    return (
      <SeparateLink
        to={toEnvironmentUserManagementUserIdentity(
          workspaceId,
          environmentId,
          value
        )}
      />
    );
  }

  if (!value || !identity) {
    return <EmptyCell />;
  }

  if (linkMode === "none") {
    return (
      <div className={"flex items-center space-x-2"}>
        {identity.avatar_url ? (
          <ImageWithFallback src={identity.avatar_url} alt={"avatar"} />
        ) : null}
        <span
          className={classNames(
            "block whitespace-nowrap overflow-hidden truncate min-w-0",
            {
              "font-normal text-sm": size === "regular",
              "font-normal text-xs": size === "small",
            }
          )}
        >
          {retrieveUserIdentityName(identity)}
        </span>
      </div>
    );
  }

  return (
    <div className={"flex items-center space-x-2"}>
      {identity.avatar_url ? (
        <ImageWithFallback src={identity.avatar_url} alt={"avatar"} />
      ) : null}
      <Link
        to={toEnvironmentUserManagementUserIdentity(
          workspaceId,
          environmentId,
          value
        )}
        className={
          "flex items-center space-x-1 stroke-neutral-500 text-neutral-500 hover:stroke-black hover:text-black"
        }
      >
        <span
          className={classNames("block overflow-hidden truncate min-w-0", {
            "font-normal text-sm": size === "regular",
            "font-normal text-xs": size === "small",
          })}
        >
          {retrieveUserIdentityName(identity)}
        </span>
        <ExternalLink size={16} />
      </Link>
    </div>
  );
}

function UserIdentityRenderer<T>(info: CellContext<T, any>) {
  const value = info.getValue() as string | null;

  return <UserIdentityLink value={value} />;
}

export function WorkspaceMemberRenderer({
  value,
  size = "regular",
  linkMode = "none",
}: {
  value: string | null;
  size?: "small" | "regular";
  linkMode?: "inline" | "none" | "separate";
}) {
  const workspaceId = useWorkspaceId();
  const { member } = useWorkspaceMember(workspaceId, value);
  const { profile } = useWorkspaceMemberProfile(workspaceId, value);

  if (linkMode === "separate") {
    return null;
  } else {
    if (!value || !member || !profile) {
      return <EmptyCell />;
    }

    return (
      <div className={"flex items-center space-x-2 shrink-0 grow-0"}>
        {profile.gravatar_url ? (
          <ImageWithFallback src={profile.gravatar_url} alt={"avatar"} />
        ) : null}
        <span
          className={
            "flex items-center space-x-1 stroke-neutral-500 text-neutral-500 hover:stroke-black hover:text-black"
          }
        >
          <span
            className={classNames(
              "block overflow-hidden truncate min-w-0 font-normal",
              {
                "text-sm": size === "regular",
                "text-xs": size === "small",
              }
            )}
          >
            {profile.name}
          </span>
        </span>
      </div>
    );
  }
}

export function idRenderer<T>(
  field: DatabaseIDField,
  size?: "small" | "regular",
  linkMode?: "inline" | "none" | "separate"
) {
  return (info: CellContext<T, any>) => {
    return (
      <IdRenderer
        linkMode={linkMode}
        size={size}
        value={info.getValue() as string | null}
        field={field}
      />
    );
  };
}

export function IdRenderer({
  field,
  value,
  size,
  linkMode,
}: {
  value: string | null;
  field: DatabaseIDField;
  size?: "small" | "regular";
  linkMode?: "inline" | "none" | "separate";
}) {
  if (!value) {
    return <EmptyCell />;
  }
  if (!field.relatedEntity) {
    return (
      <span
        className={
          "p-1 font-mono bg-neutral-50 rounded-md truncate min-w-0 block text-xs"
        }
      >
        {value}
      </span>
    );
  }
  switch (field.relatedEntity) {
    case DatabaseRelatedEntity.CRMCompany: {
      return <CRMCompanyLink linkMode={linkMode} size={size} value={value} />;
    }
    case DatabaseRelatedEntity.WorkspaceMember: {
      return (
        <WorkspaceMemberRenderer
          linkMode={linkMode}
          size={size}
          value={value}
        />
      );
    }
    case DatabaseRelatedEntity.UserManagementUserIdentity: {
      return <UserIdentityLink linkMode={linkMode} size={size} value={value} />;
    }
    case DatabaseRelatedEntity.CRMContact: {
      return <CRMContactLink linkMode={linkMode} size={size} value={value} />;
    }
    case DatabaseRelatedEntity.CRMDeal: {
      return <CRMDealLink linkMode={linkMode} size={size} value={value} />;
    }
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _exhaustiveCheck: never = field.relatedEntity;
      return null;
  }
}

export function CRMContactLink({
  value,
  size = "regular",
  linkMode = "inline",
}: {
  value: string | null;
  size?: "small" | "regular";
  linkMode?: "inline" | "none" | "separate";
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { contact } = useCRMContact(workspaceId, environmentId, value);

  if (linkMode === "separate") {
    if (!value) {
      return null;
    }

    return (
      <SeparateLink
        to={toEnvironmentCRMContact(workspaceId, environmentId, value)}
      />
    );
  }

  if (!value || !contact) {
    return <EmptyCell />;
  }

  if (linkMode === "none") {
    return (
      <span
        className={"flex items-center space-x-1 stroke-neutral-500 text-black"}
      >
        <span
          className={classNames("block truncate min-w-0", {
            "text-sm font-normal": size === "regular",
            "text-xs font-normal": size === "small",
          })}
        >
          {contact.name}
        </span>
      </span>
    );
  }

  return (
    <Link
      to={toEnvironmentCRMContact(workspaceId, environmentId, value)}
      className={"flex items-center space-x-2"}
    >
      <span
        className={
          "flex items-center space-x-1 stroke-neutral-500 text-neutral-500 hover:stroke-black hover:text-black"
        }
      >
        <span
          className={classNames("block truncate min-w-0", {
            "text-sm font-normal": size === "regular",
            "text-xs font-normal": size === "small",
          })}
        >
          {contact.name}
        </span>
      </span>
    </Link>
  );
}

export function CRMCompanyLink({
  value,
  size = "regular",
  linkMode = "inline",
}: {
  value: string | null;
  size?: "regular" | "small";
  linkMode?: "inline" | "none" | "separate";
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { company } = useCRMCompany(workspaceId, environmentId, value);

  if (linkMode === "separate") {
    if (!value) {
      return null;
    }
    return (
      <SeparateLink
        to={toEnvironmentCRMCompany(workspaceId, environmentId, value)}
      />
    );
  } else {
    if (!value || !company) {
      return <EmptyCell />;
    }

    if (linkMode === "none") {
      return (
        <span
          className={classNames(
            "stroke-neutral-500 text-black min-w-0 truncate",
            {
              "text-sm font-normal": size === "regular",
              "text-xs font-normal": size === "small",
            }
          )}
        >
          {company.name}
        </span>
      );
    }

    return (
      <Link
        to={toEnvironmentCRMCompany(workspaceId, environmentId, value)}
        className={"flex items-center space-x-2"}
      >
        <span
          className={
            "flex items-center space-x-1 stroke-neutral-500 text-neutral-500 hover:stroke-black hover:text-black"
          }
        >
          <span
            className={classNames("block truncate min-w-0", {
              "text-sm font-normal": size === "regular",
              "text-xs font-normal": size === "small",
            })}
          >
            {company.name}
          </span>
        </span>
      </Link>
    );
  }
}

export function CRMDealLink({
  value,
  size = "regular",
  linkMode = "inline",
}: {
  value: string | null;
  size?: "small" | "regular";
  linkMode?: "inline" | "none" | "separate";
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { deal } = useCRMDeal(workspaceId, environmentId, value);

  if (linkMode === "separate") {
    if (!value || !deal) {
      return null;
    }

    return (
      <SeparateLink
        to={toEnvironmentCRMDeal(workspaceId, environmentId, value)}
      />
    );
  }

  if (!value || !deal) {
    return <EmptyCell />;
  }

  if (linkMode === "none") {
    return (
      <span
        className={"flex items-center space-x-1 stroke-neutral-500 text-black"}
      >
        <span
          className={classNames("block truncate min-w-0", {
            "text-sm font-normal": size === "regular",
            "text-xs font-normal": size === "small",
          })}
        >
          {deal.name}
        </span>
      </span>
    );
  }

  return (
    <Link
      to={toEnvironmentCRMDeal(workspaceId, environmentId, value)}
      className={"flex items-center space-x-2"}
    >
      <span
        className={
          "flex items-center space-x-1 stroke-neutral-500 text-neutral-500 hover:stroke-black hover:text-black"
        }
      >
        <span
          className={classNames("block truncate min-w-0", {
            "text-sm font-normal": size === "regular",
            "text-xs font-normal": size === "small",
          })}
        >
          {deal.name}
        </span>
      </span>
    </Link>
  );
}

function EmailRenderer({
  value,
  linkMode,
}: {
  value: string;
  linkMode?: "inline" | "none" | "separate";
}) {
  if (linkMode === "separate") {
    return <SeparateLink to={`mailto:${value}`} />;
  }

  if (linkMode === "none") {
    return (
      <span className={"text-sm font-normal block truncate min-w-0"}>
        {value}
      </span>
    );
  }

  return (
    <div className={"flex items-center space-x-2"}>
      <span className={"text-sm font-normal block truncate min-w-0"}>
        {value}
      </span>
      <a
        href={`mailto:${value}`}
        rel={"noreferrer"}
        target={"_blank"}
        className={
          "flex items-center space-x-1 stroke-neutral-500 text-neutral-500 hover:stroke-black hover:text-black"
        }
      >
        <ExternalLink size={16} />
      </a>
    </div>
  );
}

function getCustomStringValueTypeCellRenderer<T extends TData>(
  renderer: DatabaseStringValueType,
  linkMode?: "inline" | "none" | "separate"
) {
  switch (renderer) {
    case DatabaseStringValueType.Email:
      return (info: CellContext<T, any>) => {
        const value = info.getValue() as string;
        if (!value) {
          return <EmptyCell />;
        }
        return <EmailRenderer linkMode={linkMode} value={value} />;
      };
    case DatabaseStringValueType.Avatar:
      return (info: CellContext<T, any>) => {
        const value = info.getValue() as string;

        return <AvatarURLRenderer linkMode={linkMode} value={value} />;
      };
    case DatabaseStringValueType.URL:
      return renderUrl<T>(linkMode);
    case DatabaseStringValueType.PhoneNumber:
      return renderPhoneNumber<T>(linkMode);
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const x: never = renderer;
      return () => <EmptyCell />;
  }
}

export function AvatarURLRenderer({
  value,
  linkMode,
}: {
  value: string | null;
  linkMode?: "inline" | "none" | "separate";
}) {
  if (!value) {
    return <EmptyCell />;
  }

  return (
    <div className={"flex items-center space-x-2"}>
      <ImageWithFallback src={value} alt={"avatar"} />
      <a
        href={value}
        rel={"noreferrer"}
        target={"_blank"}
        className={
          "flex items-center space-x-1 stroke-neutral-500 text-neutral-500 hover:stroke-black hover:text-black"
        }
      >
        <span className={"text-sm font-normal"}>Open</span>{" "}
        <ExternalLink size={16} />
      </a>
    </div>
  );
}

export function RenderIcon({
  icon,
  size = 16,
}: {
  icon: RenderableIcon;
  size?: number;
}) {
  const color = getIconColor(icon.color);

  switch (icon.name) {
    case DatabaseIcon.Type:
      return <Type size={size} className={color} />;
    case DatabaseIcon.Database:
      return <DatabaseFeatherIcon size={size} className={color} />;
    case DatabaseIcon.Circle:
      return <Circle size={size} className={color} />;
    case DatabaseIcon.CheckCircle:
      return <CheckCircle size={size} className={color} />;
    case DatabaseIcon.Box:
      return <Box size={size} className={color} />;
    case DatabaseIcon.Email:
      return <Mail size={size} className={color} />;
    case DatabaseIcon.Calendar:
      return <Calendar size={size} className={color} />;
    case DatabaseIcon.BrandGoogle:
      return <BsGoogle size={size} className={color} />;
    case DatabaseIcon.BrandGitHub:
      return <BsGithub size={size} className={color} />;
    case DatabaseIcon.Toggle:
      return <ToggleLeft size={size} className={color} />;
    case DatabaseIcon.User:
      return <User size={size} className={color} />;
    case DatabaseIcon.Hash:
      return <Hash size={size} className={color} />;
    case DatabaseIcon.Currency:
      return <DollarSign size={size} className={color} />;
    case DatabaseIcon.Link:
      return <LinkIcon size={size} className={color} />;
    case DatabaseIcon.Map:
      return <MapPin size={size} className={color} />;
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const x: never = icon.name;
      return null;
  }
}

export function EnumRenderer({
  value,
  field,
  size,
}: {
  field: DatabaseEnumField;
  value: string | null;
  size?: "small" | "regular";
}) {
  if (!value) {
    return <EmptyCell />;
  }

  const enumValue = field.enumValues.find((v) => v.value === value);
  if (!enumValue) {
    return <EmptyCell />;
  }

  const textColour = enumValue.labelColor
    ? getTextColor(enumValue.labelColor)
    : "";

  return (
    <div className={"flex items-center space-x-2"}>
      <div className={"shrink-0"}>
        <RenderIcon size={size === "small" ? 14 : 16} icon={enumValue.icon!} />
      </div>
      <span
        className={classNames(
          size === "small" ? "text-xs" : "text-sm",
          "font-normal shrink",
          textColour
        )}
      >
        {enumValue.label}
      </span>
    </div>
  );
}

export function enumRenderer<T>(
  field: DatabaseEnumField,
  size?: "small" | "regular"
) {
  return (info: CellContext<T, any>) => {
    const value = info.getValue() as string;
    return <EnumRenderer size={size} value={value} field={field} />;
  };
}

export function getCellRenderer<T extends TData>(
  field: DatabaseField,
  toDetailPage: (id: string) => string,
  size?: "small" | "regular",
  linkMode?: "inline" | "none" | "separate",
  onOpenSplitView?: (id: string) => void
) {
  switch (field.kind) {
    case DatabaseFieldKind.ID:
      return idRenderer<T>(field, size, linkMode);
    case DatabaseFieldKind.String:
      if (field.valueType) {
        return getCustomStringValueTypeCellRenderer<T>(
          field.valueType,
          linkMode
        );
      }
      return renderText<T>(field, toDetailPage, linkMode, onOpenSplitView);
    case DatabaseFieldKind.Enum:
      return enumRenderer<T>(field, size);
    case DatabaseFieldKind.DateTime:
      return renderDateTime<T>();
    case DatabaseFieldKind.Boolean:
      return renderBoolean<T>();
    case DatabaseFieldKind.Number:
      return renderNumber<T>();
    case DatabaseFieldKind.MonetaryValue:
      return renderMonetaryValue<T>;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _: never = field;
}

export function getStringFieldValueTypeRenderer(
  renderer: DatabaseStringValueType,
  fieldValue: unknown,
  linkMode?: "inline" | "none" | "separate"
) {
  if (fieldValue === null) {
    if (linkMode === "separate") {
      return null;
    }
  }

  switch (renderer) {
    case DatabaseStringValueType.Email:
      if (typeof fieldValue !== "string") {
        return <EmptyCell />;
      }

      return <EmailRenderer linkMode={linkMode} value={fieldValue} />;

    case DatabaseStringValueType.Avatar:
      if (typeof fieldValue !== "string") {
        return <EmptyCell />;
      }

      return <AvatarURLRenderer linkMode={linkMode} value={fieldValue} />;

    case DatabaseStringValueType.URL:
      if (typeof fieldValue !== "string") {
        return <EmptyCell />;
      }
      return <URLRenderer linkMode={linkMode} value={fieldValue} />;
    case DatabaseStringValueType.PhoneNumber:
      if (typeof fieldValue !== "string") {
        return <EmptyCell />;
      }
      return <PhoneNumberRenderer linkMode={linkMode} value={fieldValue} />;
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const x: never = renderer;
      return <EmptyCell />;
  }
}

export function getFieldRenderer<T extends TData>(
  field: DatabaseField,
  entity: T,
  fieldValue: unknown,
  toDetailPage?: (id: string) => string,
  size?: "small" | "regular",
  linkMode?: "none" | "separate" | "inline"
) {
  switch (field.kind) {
    case DatabaseFieldKind.ID:
      if (typeof fieldValue !== "string" && fieldValue !== null) {
        return <EmptyCell />;
      }
      return (
        <IdRenderer
          linkMode={linkMode}
          size={size}
          value={fieldValue}
          field={field}
        />
      );
    case DatabaseFieldKind.String:
      if (typeof fieldValue !== "string" && fieldValue !== null) {
        return <EmptyCell />;
      }
      if (field.valueType) {
        return getStringFieldValueTypeRenderer(field.valueType, fieldValue);
      }
      return (
        <TextRenderer
          linkMode={linkMode}
          field={field}
          value={fieldValue}
          toDetailPage={toDetailPage}
          id={entity.id}
        />
      );
    case DatabaseFieldKind.Enum:
      if (typeof fieldValue !== "string" && fieldValue !== null) {
        return <EmptyCell />;
      }
      return <EnumRenderer size={size} field={field} value={fieldValue} />;
    case DatabaseFieldKind.DateTime:
      if (typeof fieldValue !== "string") {
        return <EmptyCell />;
      }
      return <DateTimeRenderer value={fieldValue} />;
    case DatabaseFieldKind.Boolean:
      if (typeof fieldValue !== "boolean") {
        return <EmptyCell />;
      }
      return <BooleanRenderer value={fieldValue} />;
    case DatabaseFieldKind.Number:
      if (typeof fieldValue !== "number") {
        return <EmptyCell />;
      }
      return <NumberRenderer value={fieldValue} />;
    case DatabaseFieldKind.MonetaryValue:
      if (typeof fieldValue !== "object" || fieldValue === null) {
        return <EmptyCell />;
      }
      return (
        <MonetaryValueRendererWithConversion
          value={fieldValue as DatabaseMonetaryValue}
        />
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _: never = field;
}

export function renderDateTime<T extends TData>() {
  return (info: CellContext<T, any>) => {
    const value = info.getValue() as string | null;
    if (!value) {
      return <EmptyCell />;
    }
    return <DateTimeRenderer value={value} />;
  };
}

export function DateTimeRenderer<T>({ value }: { value: string }) {
  return <DateTimeRendererWithFormats value={value} />;
}

export function formatDateTime(value: string) {
  return format(parseISO(value), "MMM d, yyyy HH:mm:ss");
}

export function renderBoolean<T extends TData>() {
  return (info: CellContext<T, any>) => {
    const value = info.getValue() as boolean | null;
    if (value === null) {
      return <EmptyCell />;
    }
    return <BooleanRenderer value={value} />;
  };
}

export function BooleanRenderer<T>({ value }: { value: boolean }) {
  return <span className={"font-normal text-sm"}>{value ? "Yes" : "No"}</span>;
}

function renderNumber<T extends TData>() {
  return (info: CellContext<T, any>) => {
    const value = info.getValue() as number | null;
    if (value === null) {
      return <EmptyCell />;
    }

    return <NumberRenderer value={value} />;
  };
}

export function NumberRenderer<T>({ value }: { value: number }) {
  // If value has decimals, show 2 decimals, otherwise show as integer
  const formatted = formatNumber(value);

  return <span className={"font-normal text-sm"}>{formatted}</span>;
}

function renderMonetaryValue<T>(info: CellContext<T, any>) {
  const value = info.getValue() as DatabaseMonetaryValue | null;
  return <MonetaryValueRenderer value={value} />;
}

export function MonetaryValueRenderer({
  value,
}: {
  value: DatabaseMonetaryValue | null;
}) {
  if (value === null) {
    return <EmptyCell />;
  }

  return (
    <span className={"font-normal text-sm"}>
      <MonetaryValueRendererWithConversion value={value} />
    </span>
  );
}

export function formatNumber(value: number) {
  return new Intl.NumberFormat(getCurrentLocale(), {
    maximumFractionDigits: value % 1 === 0 ? 0 : 2,
  }).format(value);
}

export function renderUrl<T extends TData>(
  linkMode?: "inline" | "none" | "separate"
) {
  return (info: CellContext<T, any>) => {
    const value = info.getValue() as string | null;
    if (!value) {
      return <EmptyCell />;
    }
    return <URLRenderer linkMode={linkMode} value={value} />;
  };
}

export function URLRenderer<T>({
  value,
  linkMode = "inline",
}: {
  value: string;
  linkMode?: "inline" | "none" | "separate";
}) {
  const normalizedLink = useMemo(() => {
    let temp = value;
    temp = temp.replace("http://", "");
    temp = temp.replace("https://", "");

    temp = temp.replace("www.", "");

    temp = decodeURIComponent(temp);

    if (temp.endsWith("/")) {
      temp = temp.substring(0, temp.length - 1);
    }

    temp = temp.length > 24 ? temp.substring(0, 24) + "..." : temp;

    return temp;
  }, [value]);

  if (linkMode === "none") {
    return (
      <pre className={"truncate min-w-0 text-sm font-normal font-mono"}>
        {normalizedLink}
      </pre>
    );
  } else if (linkMode === "inline") {
    return (
      <div className={"flex items-center space-x-2"}>
        <a
          href={value}
          rel={"noreferrer"}
          target={"_blank"}
          className={
            "flex items-center space-x-1 hover:stroke-black hover:text-black"
          }
        >
          <pre className={"text-sm font-normal font-mono"}>
            {normalizedLink}
          </pre>
          <ExternalLink size={16} />
        </a>
      </div>
    );
  } else {
    return <SeparateLink to={value} newTab />;
  }
}

export function renderPhoneNumber<T extends TData>(
  linkMode?: "inline" | "none" | "separate"
) {
  return (info: CellContext<T, any>) => {
    const value = info.getValue() as string | null;
    if (!value) {
      if (linkMode === "separate") {
        return null;
      }
      return <EmptyCell />;
    }
    return <PhoneNumberRenderer value={value} linkMode={linkMode} />;
  };
}

export function PhoneNumberRenderer({
  value,
  linkMode,
}: {
  value: string;
  linkMode?: "inline" | "none" | "separate";
}) {
  if (linkMode === "separate") {
    return <SeparateLink to={`tel:${value}`} newTab />;
  }

  if (linkMode === "none") {
    return (
      <pre className={"min-w-0 truncate text-sm font-normal font-mono"}>
        {value}
      </pre>
    );
  }

  return (
    <div className={"flex items-center space-x-2"}>
      <a
        className={
          "flex items-center space-x-1 hover:stroke-black hover:text-black"
        }
        href={`tel:${value}`}
      >
        <pre className={"min-w-0 truncate text-sm font-normal font-mono"}>
          {value}
        </pre>
      </a>
    </div>
  );
}

export function SeparateLink({
  to,
  newTab,
  permanent,
}: {
  to: string;
  newTab?: boolean;
  permanent?: boolean;
}) {
  const hasExternalScheme =
    to.startsWith("http://") || to.startsWith("https://");
  const isExternalLink = !to.startsWith("/") || hasExternalScheme;

  if (isExternalLink) {
    const validLink = hasExternalScheme ? to : `https://${to}`;
    return (
      <a
        className={classNames(
          "flex items-center p-2 rounded text-neutral-700",
          {
            "opacity-100 bg-neutral-100 hover:bg-neutral-200 active:bg-neutral-200":
              permanent,
            "opacity-0 group-hover:opacity-100 hover:bg-neutral-100 group-hover:bg-neutral-100 active:bg-neutral-200":
              !permanent,
          }
        )}
        href={validLink}
        target={newTab ? "_blank" : undefined}
        rel={"noreferrer noopener"}
      >
        <ExternalLink size={16} />
      </a>
    );
  }

  return (
    <Link
      className={classNames("flex items-center p-2 rounded text-neutral-700", {
        "opacity-100 bg-neutral-100 hover:bg-neutral-200 active:bg-neutral-200":
          permanent,
        "opacity-0 group-hover:opacity-100 hover:bg-neutral-100 group-hover:bg-neutral-100 active:bg-neutral-200":
          !permanent,
      })}
      to={to}
      target={newTab ? "_blank" : undefined}
    >
      <ChevronRight size={16} />
    </Link>
  );
}
