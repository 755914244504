import * as HoverCard from "@radix-ui/react-hover-card";
import { PropsWithChildren, useState } from "react";
import { DocsLink } from "../../../components/basics/link";
import { Tool } from "../../../icons";

export const StagingEnvironmentHint = ({
  children,
  disabled,
}: PropsWithChildren<{ disabled?: boolean }>) => {
  const [open, setOpen] = useState(false);
  return (
    <HoverCard.Root open={disabled ? false : open} onOpenChange={setOpen}>
      <HoverCard.Trigger asChild>{children}</HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          className="z-20 rounded border border-neutral-200 bg-white/60 backdrop-blur-xl shadow-lg"
          sideOffset={5}
        >
          <HoverCard.Arrow className="fill-neutral-200" />

          <div className={"p-4 flex flex-col space-y-4"}>
            <div className={"flex items-center justify-between"}>
              <div className={"flex items-center space-x-2"}>
                <Tool size={14} />
                <span>Staging Environments</span>
              </div>

              <div className={"text-sm"}>
                <DocsLink to={"/docs/platform/environments"}>
                  Learn more
                </DocsLink>
              </div>
            </div>
            <span className={"w-72 text-sm text-neutral-800"}>
              You are currently working in a staging environment. The
              environment picker will remain visible until you return to the
              production environment.
            </span>
          </div>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};
