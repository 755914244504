import { useNavigate } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";
import {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";
import {
  toEnvironmentInsights,
  toEnvironmentCRM,
  toEnvironmentOverview,
  toEnvironmentUserManagement,
  useOptionalEnvironmentId,
  useOptionalWorkspaceId,
  toWorkspaceSettings,
} from "./routes";
import { ArrowRight } from "../icons";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { RawButtonWithRef } from "../components/basics/button";
import { useHotkeys } from "../hooks/keypress";

export function WithKBar({ children }: PropsWithChildren) {
  const [open, setOpen] = useState(false);

  useHotkeys("meta+k,ctrl+k", () => setOpen(true), [setOpen]);
  useHotkeys("esc", () => setOpen(false), [setOpen]);

  return (
    <div>
      {children}
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Portal>
          <Dialog.Content aria-label={"Cmd+K"} asChild>
            <KBarDialog
              close={() => {
                setOpen(false);
                const wrapper = document.querySelector("#page-wrapper");
                if (wrapper) {
                  (wrapper as HTMLElement).focus();
                }
              }}
            />
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
}

const KBarDialog = forwardRef<HTMLDivElement, { close: () => void }>(
  ({ close }, forwardedRef) => {
    const navigate = useNavigate();
    const workspaceId = useOptionalWorkspaceId();
    const environmentId = useOptionalEnvironmentId();

    const [search, setSearch] = useState("");

    const items = useMemo(() => {
      const items = [];

      if (workspaceId && environmentId) {
        items.push(
          {
            label: "Go to overview",
            icon: ArrowRight,
            to: toEnvironmentOverview(workspaceId, environmentId),
          },
          {
            label: "Go to settings",
            icon: ArrowRight,
            to: toWorkspaceSettings(workspaceId),
          },
          {
            label: "Go to User Management",
            icon: ArrowRight,
            to: toEnvironmentUserManagement(workspaceId, environmentId),
          },
          {
            label: "Go to Insights",
            icon: ArrowRight,
            to: toEnvironmentInsights(workspaceId, environmentId),
          },
          {
            label: "Go to CRM",
            icon: ArrowRight,
            to: toEnvironmentCRM(workspaceId, environmentId),
          }
        );
      }

      return items.filter((i) =>
        i.label.toLowerCase().includes(search.toLowerCase())
      );
    }, [search, workspaceId, environmentId]);

    const inputRef = useRef<HTMLInputElement>(null);
    const resultContainerRef = useRef<HTMLDivElement>(null);

    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      // If results are focused and a key is pressed, focus the input
      const handleKeydown = (e: KeyboardEvent) => {
        if (resultContainerRef.current?.contains(e.target as HTMLElement)) {
          inputRef.current?.focus();
        }
      };

      document.addEventListener("keydown", handleKeydown);
      return () => {
        document.removeEventListener("keydown", handleKeydown);
      };
    }, [resultContainerRef]);

    return (
      <DropdownMenu.Root open={true}>
        <DropdownMenu.Content className="DropdownMenuContent" asChild loop>
          <motion.div
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
            className="fixed top-0 left-0 w-screen h-screen z-40 flex flex-col items-center select-none"
            onClick={(e) => {
              if (!contentRef.current?.contains(e.target as Node)) {
                close();
              }
            }}
          >
            <div
              ref={contentRef}
              className={classNames(
                "w-2/5 max-h-96 overflow-hidden mt-48 backdrop-blur-xl bg-white/80 z-40 flex flex-col border border-neutral-200 rounded-md drop-shadow-2xl whitespace-nowrap select-none"
              )}
            >
              <form
                className={"flex flex-col w-full"}
                onSubmit={(e) => {
                  if (items.length === 1) {
                    close();
                    navigate(items[0].to);
                    return;
                  }
                }}
              >
                <input
                  ref={inputRef}
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      close();
                      return;
                    }

                    // Only interrupt when arrow down key is pressed
                    if (e.key !== "ArrowDown") {
                      return;
                    }

                    // If the input is focused, focus the first result
                    if (inputRef.current !== document.activeElement) {
                      return;
                    }

                    const firstResult =
                      resultContainerRef.current?.querySelector(
                        "button"
                      ) as HTMLElement;
                    if (firstResult) {
                      firstResult.focus();
                    }
                  }}
                  value={search}
                  onChange={(e) => setSearch(e.currentTarget.value)}
                  className={
                    "bg-transparent placeholder:text-neutral-400 appearance-none p-4 border-b border-neutral-200 rounded-t-md outline-none font-light tracking-wide"
                  }
                  placeholder={"Type a command or search..."}
                />
              </form>
              <AnimatePresence>
                {items.length > 0 ? (
                  <motion.div
                    ref={resultContainerRef}
                    id={"kbar-items"}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.1 }}
                    className={"flex flex-col overflow-auto"}
                  >
                    {items.map(({ icon: Icon, ...item }) => (
                      <DropdownMenu.Item key={item.label} asChild>
                        <RawButtonWithRef
                          className={"p-1 appearance-none outline-none group"}
                          onClick={() => {
                            navigate(item.to);
                            close();
                          }}
                        >
                          <div
                            className={
                              "rounded-md p-3 text-left flex items-center space-x-2 text-sm font-normal text-neutral-700 group-hover:text-black group-focus:bg-neutral-200"
                            }
                          >
                            <Icon size={18} />
                            <span>{item.label}</span>
                          </div>
                        </RawButtonWithRef>
                      </DropdownMenu.Item>
                    ))}
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </div>
          </motion.div>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    );
  }
);
