import toast from "react-hot-toast";
import useSWR, { Fetcher, useSWRConfig } from "swr";
import { useAuth, useToken } from "./components/auth";
import { buildCommitSha, useApiEndpointContext } from "./env";
import useSWRMutation from "swr/mutation";
import {
  AccessToken,
  Environment,
  Workspace,
  WorkspaceInvite,
  WorkspaceMember,
  WorkspaceRole,
  UserManagementConfigResp,
  Webhook,
  WebhookDelivery,
  IUserIdentity,
  ProfileResponse,
  DatabaseFilter,
  DatabaseSort,
  InsightsConfigResp,
  IUserAccessToken,
  IUserAuthAttempt,
  Event,
  InsightsEvent,
  InsightsEventFilter,
  InsightsTimeRange,
  InsightsCategoryCountBucket,
  InsightsCountBucket,
  UserAuthAttemptStatus,
  ErrorCode,
  PageInfo,
  PaginationArgs,
  DatabaseSchema,
  ICRMContact,
  CRMConfigResp,
  DatabaseViewKind,
  DatabaseView,
  DatabaseSearch,
  InsightsSchemaResp,
  ICRMCompany,
  ICRMDeal,
  ICRMDealPipeline,
  DatabaseGroupBy,
  MonetaryValueExchangeRate,
  PaginatedResult,
  AccountResponse,
  EventResourceKind,
  ICRMComment,
  ICRMNotes,
  CRMNotesKind,
} from "@anzuhq/backend";
import { State } from "swr/_internal";
import { useMemo } from "react";

export class FetcherError extends Error {
  constructor(message: string, public res: Response) {
    super(message);
    this.name = "FetcherError";
  }
}

export class ApiError extends FetcherError {
  constructor(public code: ErrorCode, message: string, res: Response) {
    super(message, res);
    this.name = "ApiError";
  }
}

export function isNotFound(error: unknown) {
  return (
    (error instanceof ApiError && error.code === ErrorCode.NotFound) ||
    (error instanceof FetcherError && error.res.status === 404)
  );
}

export function isNotAllowed(error: unknown) {
  return error instanceof ApiError && error.code === ErrorCode.NotAllowed;
}

export function isFeatureNotEnabled(error: unknown) {
  return (
    error instanceof ApiError && error.code === ErrorCode.FeatureNotEnabled
  );
}

async function handleResponse<T>(res: Response): Promise<T> {
  if (!res.ok) {
    let body;
    try {
      body = await res.json();
    } catch (err) {
      throw new FetcherError("Failed to fetch", res);
    }

    if ("error" in body && "code" in body.error && "message" in body.error) {
      throw new ApiError(body.error.code, body.error.message, res);
    }

    throw new FetcherError("Failed to fetch", res);
  }

  const body: T = await res.json();
  return body;
}

export function getApiEndpoint(apiEndpoint: string, version = "2022.8") {
  return `${apiEndpoint}/${version}`;
}

export async function getApi<T>(
  apiEndpoint: string,
  route: string,
  token?: string
) {
  const headers = new Headers();
  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  const res = await fetch(`${getApiEndpoint(apiEndpoint)}${route}`, {
    method: "GET",
    headers,
  });

  return handleResponse<T>(res);
}

export const getFetcher =
  <T>(): Fetcher<T | null, [string, string, string]> =>
  async ([apiEndpoint, url, token]) =>
    getApi(apiEndpoint, url, token);

export const getFetcherNotNull =
  <T>(): Fetcher<T, [string, string, string]> =>
  async ([apiEndpoint, url, token]) =>
    getApi(apiEndpoint, url, token);

export async function postApi<T>(
  apiEndpoint: string,
  route: string,
  body: unknown,
  token?: string
) {
  const headers = new Headers();
  headers.set("Content-Type", "application/json");

  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  const res = await fetch(`${getApiEndpoint(apiEndpoint)}${route}`, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });
  return handleResponse<T>(res);
}

export async function withErrorToast<T>(p: () => Promise<T>) {
  try {
    return await p();
  } catch (error) {
    if (
      error instanceof Error &&
      error.name === "TypeError" &&
      error.message.startsWith("NetworkError")
    ) {
      toast.error("Failed to connect to API, check your internet connection.");
      return;
    }

    if (error instanceof ApiError) {
      toast.error(error.message);
      return;
    }

    if (error instanceof FetcherError) {
      toast.error(error.message);
      return;
    }

    if (error instanceof Error) {
      toast.error(error.message);
      return;
    }

    toast.error("Something went wrong");
  }
}

export const postFetcher =
  <T>(): Fetcher<T, [string, string, string, string]> =>
  async ([apiEndpoint, url, token, reqBody]) =>
    postApi(apiEndpoint, url, reqBody, token);

export async function deleteApi<T>(
  apiEndpoint: string,
  route: string,
  token: string
) {
  const res = await fetch(`${getApiEndpoint(apiEndpoint)}${route}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return handleResponse<T>(res);
}

export async function putAPI<T>(
  apiEndpoint: string,
  route: string,
  body: unknown,
  token: string
) {
  const res = await fetch(`${getApiEndpoint(apiEndpoint)}${route}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return handleResponse<T>(res);
}

export async function patchAPI<T>(
  apiEndpoint: string,
  route: string,
  body: unknown,
  token: string
) {
  const res = await fetch(`${getApiEndpoint(apiEndpoint)}${route}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return handleResponse<T>(res);
}

export function usePostAPI() {
  const token = useToken();
  const [apiEndpoint] = useApiEndpointContext();
  return async <T>(route: string, body: unknown) => {
    return await postApi<T>(apiEndpoint, route, body, token);
  };
}

export function usePutAPI() {
  const token = useToken();
  const [apiEndpoint] = useApiEndpointContext();

  return async <T>(route: string, body: unknown) => {
    return await putAPI<T>(apiEndpoint, route, body, token);
  };
}

export function usePatchAPI() {
  const token = useToken();
  const [apiEndpoint] = useApiEndpointContext();

  return async <T>(route: string, body: unknown) => {
    return await patchAPI<T>(apiEndpoint, route, body, token);
  };
}

export function useGetAPI() {
  const token = useToken();
  return async <T>(route: string) => {
    return await getApi<T>(route, token);
  };
}

export function useDeleteAPI() {
  const token = useToken();
  const [apiEndpoint] = useApiEndpointContext();

  return async <T>(route: string) => {
    return await deleteApi<T>(apiEndpoint, route, token);
  };
}

function getCacheKey() {
  return `anzu:appcache:${buildCommitSha}`;
}

export function useClearCache() {
  const cacheKey = getCacheKey();
  const { cache } = useSWRConfig();
  return () => {
    (cache as any).clear();
    localStorage.removeItem(cacheKey);
    document.location = "/";
  };
}

export function localStorageProvider() {
  const cacheKey = getCacheKey();
  // When initializing, we restore the data from `localStorage` into a map.
  const map = new Map<string, State>(
    JSON.parse(localStorage.getItem(cacheKey) || "[]")
  );

  // Before unloading the app, we write back all the data into `localStorage`.
  window.addEventListener("beforeunload", () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    localStorage.setItem(cacheKey, appCache);
  });

  // We still use the map for write & read for performance.
  return map;
}

function useBuildFetcherKey(route: string | null) {
  const token = useToken();
  const [apiEndpoint] = useApiEndpointContext();

  return useMemo(() => {
    return route ? [apiEndpoint, route, token] : null;
  }, [apiEndpoint, route, token]);
}

export function useWorkspaces() {
  const key = useBuildFetcherKey(`/workspaces`);

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    key,
    getFetcher<Workspace[]>()
  );

  return {
    workspaces: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useWorkspace(workspaceId: string) {
  const key = useBuildFetcherKey(`/workspaces/${workspaceId}`);

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    key,
    getFetcher<Workspace>()
  );

  return {
    workspace: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useWorkspaceEnvironments(workspaceId: string) {
  const key = useBuildFetcherKey(`/workspaces/${workspaceId}/environments`);

  const { data, error, isLoading, isValidating } = useSWR(
    key,
    getFetcher<Environment[]>()
  );

  return {
    environments: data,
    error,
    isLoading,
    isValidating,
  };
}

export function useOwnProfile() {
  const token = useToken();
  const [apiEndpoint] = useApiEndpointContext();
  const { user } = useAuth();

  const { data, error, isLoading, isValidating } = useSWR(
    user ? [apiEndpoint, `/profile`, token] : null,
    getFetcher<ProfileResponse>()
  );

  return {
    profile: data,
    error,
    isLoading,
    isValidating,
  };
}

export function useOwnAccount() {
  const token = useToken();
  const { user } = useAuth();
  const [apiEndpoint] = useApiEndpointContext();

  const { data, error, isLoading, isValidating } = useSWR(
    user ? [apiEndpoint, `/account`, token] : null,
    getFetcher<AccountResponse>()
  );

  return {
    account: data,
    error,
    isLoading,
    isValidating,
  };
}

export function useEnvironment(
  workspaceId: string,
  environmentId: string | null
) {
  const key = useBuildFetcherKey(
    environmentId
      ? `/workspaces/${workspaceId}/environments/${environmentId}`
      : null
  );

  const { data, error, isLoading, isValidating } = useSWR(
    key,
    getFetcher<Environment>()
  );

  return {
    environment: data,
    error,
    isLoading,
    isValidating,
  };
}

export function useProductionEnvironment(workspaceId: string) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/production`
  );

  const { data, error, isLoading, isValidating } = useSWR(
    key,
    getFetcher<Environment>()
  );

  return {
    environment: data,
    error,
    isLoading,
    isValidating,
  };
}

export function useWorkspaceAccessTokens(workspaceId: string) {
  const key = useBuildFetcherKey(`/workspaces/${workspaceId}/access_tokens`);

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    key,
    getFetcher<AccessToken[]>()
  );

  return {
    accessTokens: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useWorkspaceAccessToken(workspaceId: string, tokenId: string) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/access_tokens/${tokenId}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<AccessToken>()
  );

  return {
    accessToken: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useWorkspaceRoles(workspaceId: string) {
  const key = useBuildFetcherKey(`/workspaces/${workspaceId}/roles`);

  const { data, error, isLoading, isValidating } = useSWR(
    key,
    getFetcher<WorkspaceRole[]>()
  );

  return {
    roles: data,
    error,
    isLoading,
    isValidating,
  };
}

export function useWorkspaceRole(workspaceId: string, roleId: string) {
  const key = useBuildFetcherKey(`/workspaces/${workspaceId}/roles/${roleId}`);

  const { data, error, isLoading, isValidating } = useSWR(
    key,
    getFetcher<WorkspaceRole>()
  );

  return {
    role: data,
    error,
    isLoading,
    isValidating,
  };
}

export function useWorkspaceMembers(workspaceId: string) {
  const key = useBuildFetcherKey(`/workspaces/${workspaceId}/members`);

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    key,
    getFetcher<WorkspaceMember[]>()
  );

  return {
    members: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}
export function useWorkspaceMember(
  workspaceId: string,
  memberId: string | null
) {
  const key = useBuildFetcherKey(
    memberId ? `/workspaces/${workspaceId}/members/${memberId}` : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<WorkspaceMember>(),
    {
      keepPreviousData: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    member: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useWorkspaceMemberRoles(workspaceId: string, memberId: string) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/members/${memberId}/roles`
  );

  const { data, error, isLoading, isValidating } = useSWR(
    key,
    getFetcher<WorkspaceRole[]>()
  );

  return {
    roles: data,
    error,
    isLoading,
    isValidating,
  };
}

export function useWorkspaceMemberProfile(
  workspaceId: string,
  memberId: string | null
) {
  const key = useBuildFetcherKey(
    memberId ? `/workspaces/${workspaceId}/members/${memberId}/profile` : null
  );

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    key,
    getFetcher<ProfileResponse>()
  );

  return {
    profile: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useAccountInvites() {
  const key = useBuildFetcherKey(`/invites`);

  const { data, error, isLoading, isValidating } = useSWR(
    key,
    getFetcher<{ id: string; workspace: string; created_at: string }[]>()
  );

  return {
    invites: data,
    error,
    isLoading,
    isValidating,
  };
}

export function useAccountInvite(inviteId: string) {
  const key = useBuildFetcherKey(`/invites/${inviteId}`);

  const { data, error, isLoading, isValidating } = useSWR(
    key,
    getFetcher<{
      id: string;
      workspace: { id: string; name: string };
      created_at: string;
      invited_by: { name: string; picture: string } | null;
    }>(),
    {
      errorRetryCount: 0,
    }
  );

  return {
    invite: data,
    error,
    isLoading,
    isValidating,
  };
}

export function useWorkspaceInvites(workspaceId: string) {
  const key = useBuildFetcherKey(`/workspaces/${workspaceId}/invites`);

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<WorkspaceInvite[]>()
  );

  return {
    invites: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export interface CrispIdentity {
  email: string;
  signature: string;
  image: string;
}

export function useCrispIdentity(token: string | null, enable: boolean) {
  const key = useBuildFetcherKey(token && enable ? `/crisp/identity` : null);

  const { data, error, isLoading, isValidating } = useSWR(
    key,
    getFetcher<CrispIdentity>(),
    {
      errorRetryCount: 1,
      errorRetryInterval: 1000 * 60 * 5,
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    crispIdentity: data,
    error,
    isLoading,
    isValidating,
  };
}

export function useUserManagementConfig(
  workspaceId: string,
  environmentId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/user_management/config`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<UserManagementConfigResp>()
  );

  return {
    userManagementConfig: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function buildDatabaseQueryArgs(
  paginationArgs?: PaginationArgs,
  filters?: DatabaseFilter[],
  sort?: DatabaseSort | null,
  search?: DatabaseSearch | null,
  groupBy?: DatabaseGroupBy | null
) {
  const sp = new URLSearchParams();
  if (paginationArgs) {
    if (paginationArgs.before) {
      sp.append("before", paginationArgs.before);
    }
    if (paginationArgs.after) {
      sp.append("after", paginationArgs.after);
    }
    if (paginationArgs.first) {
      sp.append("first", paginationArgs.first.toString());
    }
    if (paginationArgs.last) {
      sp.append("last", paginationArgs.last.toString());
    }
  }
  if (search) {
    sp.append("search", JSON.stringify(search));
  }
  if (filters && filters.length > 0) {
    sp.append("filter", JSON.stringify(filters));
  }
  if (sort) {
    sp.append("sort", JSON.stringify(sort));
  }
  if (groupBy) {
    sp.append("group_by", JSON.stringify(groupBy));
  }
  return sp.toString();
}

export function buildGetUserManagementUserIdentitiesRequestUrl(
  workspaceId: string,
  environmentId: string,
  paginationArgs?: PaginationArgs,
  filters?: DatabaseFilter[],
  sort?: DatabaseSort | null,
  search?: DatabaseSearch | null
) {
  return `/workspaces/${workspaceId}/environments/${environmentId}/user_management/user_identities?${buildDatabaseQueryArgs(
    paginationArgs,
    filters,
    sort,
    search
  )}`;
}

export function useUserManagementUserIdentities(
  workspaceId: string,
  environmentId: string,
  paginationArgs?: PaginationArgs,
  filters?: DatabaseFilter[],
  sort?: DatabaseSort | null,
  search?: DatabaseSearch | null
) {
  const key = useBuildFetcherKey(
    paginationArgs && filters
      ? buildGetUserManagementUserIdentitiesRequestUrl(
          workspaceId,
          environmentId,
          paginationArgs,
          filters,
          sort,
          search
        )
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<{
      data: IUserIdentity[];
      pageInfo: PageInfo;
    }>()
  );

  return {
    identities: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCountUserManagementUserIdentities(
  workspaceId: string,
  environmentId: string,
  filters?: DatabaseFilter[],
  search?: DatabaseSearch | null
) {
  const sp = new URLSearchParams();

  if (filters && filters.length > 0) {
    sp.append("filter", JSON.stringify(filters));
  }

  if (!filters) {
    sp.append("all", "true");
  }

  if (search) {
    sp.append("search", JSON.stringify(search));
  }

  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/user_management/user_identities/count?${sp.toString()}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<{
      count: number;
    }>()
  );

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useUserManagementUserIdentity(
  workspaceId: string,

  environmentId: string,
  identityId: string | null
) {
  const key = useBuildFetcherKey(
    identityId
      ? `/workspaces/${workspaceId}/environments/${environmentId}/user_management/user_identities/${identityId}`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<IUserIdentity>()
  );

  return {
    identity: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useEnvironmentWebhooks(
  workspaceId: string,

  environmentId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/webhooks`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<Webhook[]>()
  );

  return {
    webhooks: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useEnvironmentWebhook(
  workspaceId: string,

  environmentId: string,
  webhookId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/webhooks/${webhookId}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<Webhook>()
  );

  return {
    mutate,
    webhook: data,
    error,
    isLoading,
    isValidating,
  };
}

export function useEnvironmentWebhookDeliveries(
  workspaceId: string,

  environmentId: string,
  webhookId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/webhooks/${webhookId}/deliveries`
  );

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    key,
    getFetcher<WebhookDelivery[]>(),
    {
      refreshInterval: 5000,
    }
  );

  return {
    webhookDeliveries: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useEnvironmentWebhookDelivery(
  workspaceId: string,

  environmentId: string,
  webhookId: string,
  deliveryId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/webhooks/${webhookId}/deliveries/${deliveryId}`
  );

  const { data, error, isLoading, isValidating } = useSWR(
    key,
    getFetcher<WebhookDelivery>()
  );

  return {
    webhookDelivery: data,
    error,
    isLoading,
    isValidating,
  };
}

export function useEnvironmentInsightsConfig(
  workspaceId: string,

  environmentId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/insights/config`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<InsightsConfigResp>()
  );

  return {
    insightsConfig: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export interface InsightsMostActiveUser {
  userId: string;
  sumSessionDuration: number;
  userIdentityId?: string;
}

export function useEnvironmentInsightsMostActiveUsers(
  workspaceId: string,

  environmentId: string,
  timeRange: InsightsTimeRange
) {
  const params = new URLSearchParams();
  params.append("time_range", JSON.stringify(timeRange));

  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/insights/users/most-active?${params.toString()}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<InsightsMostActiveUser[]>()
  );

  return {
    mostActiveUsers: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useEnvironmentInsightsUsageDuration(
  workspaceId: string,

  environmentId: string,
  timeRange: InsightsTimeRange,
  userIdentityId?: string | null
) {
  const params = new URLSearchParams();
  params.append("time_range", JSON.stringify(timeRange));

  const key = useBuildFetcherKey(
    userIdentityId
      ? `/workspaces/${workspaceId}/environments/${environmentId}/insights/usage/${userIdentityId}?${params.toString()}`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<Omit<InsightsMostActiveUser, "userId"> | null>()
  );

  return {
    usageDuration: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useUserManagementUserIdentityAuthAttempts(
  workspaceId: string,

  environmentId: string,
  identityId: string | null
) {
  const key = useBuildFetcherKey(
    identityId
      ? `/workspaces/${workspaceId}/environments/${environmentId}/user_management/user_identities/${identityId}/auth_attempts`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<IUserAuthAttempt[]>()
  );

  return {
    attempts: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useUserManagementUserIdentityRelatedIdentities(
  workspaceId: string,

  environmentId: string,
  identityId: string | null
) {
  const key = useBuildFetcherKey(
    identityId
      ? `/workspaces/${workspaceId}/environments/${environmentId}/user_management/user_identities/${identityId}/related_identities`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<IUserIdentity[]>()
  );

  return {
    identities: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useUserManagementUserIdentityAccessTokens(
  workspaceId: string,

  environmentId: string,
  identityId: string | null
) {
  const key = useBuildFetcherKey(
    identityId
      ? `/workspaces/${workspaceId}/environments/${environmentId}/user_management/user_identities/${identityId}/access_tokens`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<IUserAccessToken[]>()
  );

  return {
    tokens: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCountUserManagementAuthAttempts(
  workspaceId: string,
  environmentId: string,
  timeRange: InsightsTimeRange
) {
  const sp = new URLSearchParams();
  sp.append("time_range", JSON.stringify(timeRange));

  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/user_management/auth_attempts/count?${sp.toString()}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,

    getFetcher<Array<{ count: string; status: UserAuthAttemptStatus }>>()
  );

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export interface InsightsMostUsedCategory {
  category: string;
  count: number;
}

export function useEnvironmentInsightsMostUsedCategories(
  workspaceId: string,
  environmentId: string,
  timeRange: InsightsTimeRange
) {
  const params = new URLSearchParams();
  params.append("time_range", JSON.stringify(timeRange));

  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/insights/categories/most-used?${params.toString()}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<InsightsMostUsedCategory[]>()
  );

  return {
    mostUsedCategories: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useEnvironmentInsightsMostUsedCategoriesCountTimeline(
  workspaceId: string,

  environmentId: string,
  timeRange: InsightsTimeRange
) {
  const params = new URLSearchParams();
  params.append("time_range", JSON.stringify(timeRange));

  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/insights/categories/count?${params.toString()}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<InsightsCategoryCountBucket[]>()
  );

  return {
    buckets: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useUpdateWebhook(
  workspaceId: string,
  environmentId: string,
  webhookId: string
) {
  const token = useToken();
  const patchApi = usePatchAPI();
  const route = `/workspaces/${workspaceId}/environments/${environmentId}/webhooks/${webhookId}`;

  const fetcher = async (
    path: [string, string],
    {
      arg,
    }: {
      arg: {
        data: Record<string, unknown>;
        message?: string;
      };
    }
  ) => {
    await withErrorToast(async () => {
      await patchApi(route, arg.data);
      if (arg.message) {
        toast.success(arg.message);
      }
    });
  };

  const { isMutating, trigger } = useSWRMutation([route, token], fetcher);
  return {
    isUpdatingWebhook: isMutating,
    updateWebhook: trigger,
  };
}

export function useEnvironmentEvent(
  workspaceId: string,
  environmentId: string,
  eventId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/events/${eventId}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<Event>()
  );

  return {
    event: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useEnvironmentEventsForResource(
  workspaceId: string,
  environmentId: string,
  resourceKind: EventResourceKind,
  resourceId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/events?primary_resource_kind=${resourceKind}&primary_resource_id=${resourceId}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<Event[]>()
  );

  return {
    events: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useEnvironmentInsightsEvents(
  workspaceId: string,
  environmentId: string,
  timeRange: InsightsTimeRange,
  filters: InsightsEventFilter[]
) {
  const params = new URLSearchParams();
  params.append("time_range", JSON.stringify(timeRange));
  params.append("filters", JSON.stringify(filters));

  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/insights/events?${params.toString()}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<InsightsEvent[]>(),
    {
      revalidateOnFocus: false,
    }
  );

  return {
    events: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useFetchEnvironmentInsightsEvents(
  workspaceId: string,
  environmentId: string,
  timeRange: InsightsTimeRange,
  filters: InsightsEventFilter[]
) {
  const token = useToken();
  const [apiEndpoint] = useApiEndpointContext();

  return (before_ts: string | null, after_ts: string | null) => {
    const params = new URLSearchParams();
    params.append("time_range", JSON.stringify(timeRange));
    params.append("filters", JSON.stringify(filters));
    if (before_ts) {
      params.append("before_ts", before_ts);
    }
    if (after_ts) {
      params.append("after_ts", after_ts);
    }
    return getFetcher<InsightsEvent[]>()([
      apiEndpoint,
      `/workspaces/${workspaceId}/environments/${environmentId}/insights/events?${params.toString()}`,
      token,
    ]);
  };
}

export function useEnvironmentInsightsEventAttributeNames(
  workspaceId: string,

  environmentId: string,
  timeRange: InsightsTimeRange,
  filters: InsightsEventFilter[]
) {
  const params = new URLSearchParams();
  params.append("time_range", JSON.stringify(timeRange));
  params.append("filters", JSON.stringify(filters));

  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/insights/events/attributes/keys?${params.toString()}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<string[]>()
  );

  return {
    attributeNames: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useEnvironmentInsightsEventFieldValues(
  workspaceId: string,
  environmentId: string,
  timeRange: InsightsTimeRange,
  filters: InsightsEventFilter[],
  fieldName?: string
) {
  const params = new URLSearchParams();
  params.append("time_range", JSON.stringify(timeRange));
  params.append("filters", JSON.stringify(filters));

  const key = useBuildFetcherKey(
    fieldName
      ? `/workspaces/${workspaceId}/environments/${environmentId}/insights/events/fields/values/${fieldName}?${params.toString()}`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<string[]>()
  );

  return {
    fieldValues: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useEnvironmentInsightsEventsCountTimeline(
  workspaceId: string,

  environmentId: string,
  timeRange: InsightsTimeRange,
  filters: InsightsEventFilter[],
  isLive?: boolean
) {
  const params = new URLSearchParams();
  params.append("time_range", JSON.stringify(timeRange));
  params.append("filters", JSON.stringify(filters));

  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/insights/events/count?${params.toString()}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<InsightsCountBucket[]>(),
    {
      refreshInterval: isLive ? 5000 : 0,
    }
  );

  return {
    buckets: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useUserManagementUserIdentitySchema(
  workspaceId: string,

  environmentId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/user_management/user_identities/schema`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<DatabaseSchema>()
  );

  return {
    schema: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMConfig(workspaceId: string, environmentId: string) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/crm/config`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<CRMConfigResp>()
  );

  return {
    crmConfig: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMContacts(
  workspaceId: string,
  environmentId: string,
  paginationArgs?: PaginationArgs,
  filters?: DatabaseFilter[],
  sort?: DatabaseSort | null,
  search?: DatabaseSearch | null
) {
  const key = useBuildFetcherKey(
    paginationArgs && filters
      ? `/workspaces/${workspaceId}/environments/${environmentId}/crm/contacts?${buildDatabaseQueryArgs(
          paginationArgs,
          filters,
          sort,
          search
        )}`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<{
      data: ICRMContact[];
      pageInfo: PageInfo;
    }>(),
    {
      keepPreviousData: true,
    }
  );

  return {
    contacts: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCountCRMContacts(
  workspaceId: string,
  environmentId: string,
  filters?: DatabaseFilter[],
  search?: DatabaseSearch | null
) {
  const sp = new URLSearchParams();

  if (filters && filters.length > 0) {
    sp.append("filter", JSON.stringify(filters));
  }

  if (!filters) {
    sp.append("all", "true");
  }

  if (search) {
    sp.append("search", JSON.stringify(search));
  }

  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/crm/contacts/count?${sp.toString()}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<{
      count: number;
    }>(),
    {
      keepPreviousData: true,
    }
  );

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMContact(
  workspaceId: string,

  environmentId: string,
  contactId: string | null
) {
  const key = useBuildFetcherKey(
    contactId
      ? `/workspaces/${workspaceId}/environments/${environmentId}/crm/contacts/${contactId}`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<ICRMContact>()
  );

  return {
    contact: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMNotes(
  workspaceId: string,

  environmentId: string,
  notesId: string | null
) {
  const key = useBuildFetcherKey(
    notesId
      ? `/workspaces/${workspaceId}/environments/${environmentId}/crm/notes/${notesId}`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<ICRMNotes>()
  );

  return {
    notes: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useDatabaseViews(
  workspaceId: string,
  environmentId: string,
  kind: DatabaseViewKind
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/database_views?kind=${kind}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<DatabaseView[]>()
  );

  return {
    views: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useDatabaseView(
  workspaceId: string,
  environmentId: string,
  viewId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/database_views/${viewId}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<DatabaseView>()
  );

  return {
    view: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useEnvironmentInsightsSchema(
  workspaceId: string,

  environmentId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/insights/schema`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<InsightsSchemaResp>()
  );

  return {
    schema: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMCompanies(
  workspaceId: string,
  environmentId: string,
  paginationArgs?: PaginationArgs,
  filters?: DatabaseFilter[],
  sort?: DatabaseSort | null,
  search?: DatabaseSearch | null
) {
  const key = useBuildFetcherKey(
    paginationArgs && filters
      ? `/workspaces/${workspaceId}/environments/${environmentId}/crm/companies?${buildDatabaseQueryArgs(
          paginationArgs,
          filters,
          sort,
          search
        )}`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<{
      data: ICRMCompany[];
      pageInfo: PageInfo;
    }>(),
    {
      keepPreviousData: true,
    }
  );

  return {
    companies: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCountCRMCompanies(
  workspaceId: string,
  environmentId: string,
  filters?: DatabaseFilter[],
  search?: DatabaseSearch | null
) {
  const sp = new URLSearchParams();

  if (filters && filters.length > 0) {
    sp.append("filter", JSON.stringify(filters));
  }

  if (!filters) {
    sp.append("all", "true");
  }

  if (search) {
    sp.append("search", JSON.stringify(search));
  }

  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/crm/companies/count?${sp.toString()}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<{
      count: number;
    }>(),
    {
      keepPreviousData: true,
    }
  );

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMCompany(
  workspaceId: string,
  environmentId: string,
  companyId: string | null
) {
  const key = useBuildFetcherKey(
    companyId
      ? `/workspaces/${workspaceId}/environments/${environmentId}/crm/companies/${companyId}`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<ICRMCompany>()
  );

  return {
    company: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMDealPipelines(
  workspaceId: string,
  environmentId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/crm/deal_pipelines`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<ICRMDealPipeline[]>()
  );

  return {
    dealPipelines: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMDealPipeline(
  workspaceId: string,
  environmentId: string,
  dealPipelineId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/crm/deal_pipelines/${dealPipelineId}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<ICRMDealPipeline>()
  );

  return {
    dealPipeline: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function buildCrmDealsKey(
  workspaceId: string,
  environmentId: string,
  dealPipelineId: string,
  paginationArgs?: PaginationArgs,
  filters?: DatabaseFilter[],
  sort?: DatabaseSort | null,
  search?: DatabaseSearch | null,
  groupBy?: DatabaseGroupBy | null
) {
  return `/workspaces/${workspaceId}/environments/${environmentId}/crm/deal_pipelines/${dealPipelineId}/deals?${buildDatabaseQueryArgs(
    paginationArgs,
    filters,
    sort,
    search,
    groupBy
  )}`;
}

export function buildCrmDealsCountKey(
  workspaceId: string,
  environmentId: string,
  dealPipelineId: string,
  filters?: DatabaseFilter[],
  search?: DatabaseSearch | null,
  groupBy?: DatabaseGroupBy | null
) {
  return `/workspaces/${workspaceId}/environments/${environmentId}/crm/deal_pipelines/${dealPipelineId}/deals/count?${buildDatabaseQueryArgs(
    undefined,
    filters,
    null,
    search,
    groupBy
  )}`;
}

export function useCRMDeals(
  workspaceId: string,
  environmentId: string,
  dealPipelineId: string | null,
  paginationArgs?: PaginationArgs,
  filters?: DatabaseFilter[],
  sort?: DatabaseSort | null,
  search?: DatabaseSearch | null,
  groupBy?: DatabaseGroupBy | null,
  disabled?: boolean
) {
  const key = useBuildFetcherKey(
    dealPipelineId && !disabled
      ? buildCrmDealsKey(
          workspaceId,
          environmentId,
          dealPipelineId,
          paginationArgs,
          filters,
          sort,
          search,
          groupBy
        )
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<{
      data: ICRMDeal[];
      pageInfo: PageInfo;
    }>(),
    {
      keepPreviousData: true,
    }
  );

  return {
    deals: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMDealsInfinite(
  workspaceId: string,
  environmentId: string,
  dealPipelineId: string,
  paginationArgs?: PaginationArgs,
  filters?: DatabaseFilter[],
  sort?: DatabaseSort | null,
  search?: DatabaseSearch | null,
  groupBy?: DatabaseGroupBy | null,
  disabled?: boolean
) {
  const key = useBuildFetcherKey(
    dealPipelineId && !disabled
      ? buildCrmDealsKey(
          workspaceId,
          environmentId,
          dealPipelineId,
          paginationArgs,
          filters,
          sort,
          search,
          groupBy
        )
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR<
    PaginatedResult<ICRMDeal>
  >(
    key,
    getFetcherNotNull<{
      data: ICRMDeal[];
      pageInfo: PageInfo;
    }>(),
    {
      keepPreviousData: true,
    }
  );

  return {
    deals: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCountCRMDeals(
  workspaceId: string,
  environmentId: string,
  dealPipelineId: string,
  filters?: DatabaseFilter[],
  search?: DatabaseSearch | null,
  groupBy?: DatabaseGroupBy | null
) {
  const key = useBuildFetcherKey(
    buildCrmDealsCountKey(
      workspaceId,
      environmentId,
      dealPipelineId,
      filters,
      search,
      groupBy
    )
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<{
      count: number;
    }>(),
    {
      keepPreviousData: true,
    }
  );

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMDeal(
  workspaceId: string,
  environmentId: string,
  dealId: string | null
) {
  const key = useBuildFetcherKey(
    dealId
      ? `/workspaces/${workspaceId}/environments/${environmentId}/crm/deals/${dealId}`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<ICRMDeal>()
  );

  return {
    deal: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMDealSchema(
  workspaceId: string,
  environmentId: string,
  dealPipelineId?: string
) {
  const key = useBuildFetcherKey(
    dealPipelineId
      ? `/workspaces/${workspaceId}/environments/${environmentId}/crm/deal_pipelines/${dealPipelineId}/schema`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<DatabaseSchema>()
  );

  return {
    schema: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMDealFieldValues(
  workspaceId: string,
  environmentId: string,
  dealPipelineId: string,
  field?: string,

  filters?: DatabaseFilter[],
  search?: DatabaseSearch | null
) {
  const sp = new URLSearchParams();

  if (filters && filters.length > 0) {
    sp.append("filter", JSON.stringify(filters));
  }

  if (search) {
    sp.append("search", JSON.stringify(search));
  }

  const key = useBuildFetcherKey(
    field
      ? `/workspaces/${workspaceId}/environments/${environmentId}/crm/deal_pipelines/${dealPipelineId}/schema/values/${field}?${sp.toString()}`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<Array<string | null>>()
  );

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useEnvironmentCurrencyConversionRates(
  workspaceId: string,
  environmentId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/currency_conversion_rates`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<MonetaryValueExchangeRate[]>()
  );

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMComment(
  workspaceId: string,
  environmentId: string,
  commentId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/crm/comments/${commentId}`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<ICRMComment>()
  );

  return {
    comment: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMContactSchema(
  workspaceId: string,
  environmentId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/crm/contacts/schema`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<DatabaseSchema>()
  );

  return {
    schema: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMNotesSchema(
  workspaceId: string,
  environmentId: string,
  kind?: CRMNotesKind
) {
  const key = useBuildFetcherKey(
    kind
      ? `/workspaces/${workspaceId}/environments/${environmentId}/crm/notes/schema/${kind}`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<DatabaseSchema>()
  );

  return {
    schema: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMNotesBaseSchema(
  workspaceId: string,
  environmentId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/crm/notes/schema`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<DatabaseSchema>()
  );

  return {
    schema: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMCompanySchema(
  workspaceId: string,
  environmentId: string
) {
  const key = useBuildFetcherKey(
    `/workspaces/${workspaceId}/environments/${environmentId}/crm/companies/schema`
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<DatabaseSchema>()
  );

  return {
    schema: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMContactNotes(
  workspaceId: string,
  environmentId: string,
  contactId: string | null
) {
  const key = useBuildFetcherKey(
    contactId
      ? `/workspaces/${workspaceId}/environments/${environmentId}/crm/contacts/${contactId}/notes`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<ICRMNotes[]>()
  );

  return {
    notes: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMCompanyNotes(
  workspaceId: string,
  environmentId: string,
  companyId: string | null
) {
  const key = useBuildFetcherKey(
    companyId
      ? `/workspaces/${workspaceId}/environments/${environmentId}/crm/companies/${companyId}/notes`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<ICRMNotes[]>()
  );

  return {
    notes: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export function useCRMDealNotes(
  workspaceId: string,

  environmentId: string,
  dealId: string | null
) {
  const key = useBuildFetcherKey(
    dealId
      ? `/workspaces/${workspaceId}/environments/${environmentId}/crm/deals/${dealId}/notes`
      : null
  );

  const { data, error, mutate, isLoading, isValidating } = useSWR(
    key,
    getFetcher<ICRMNotes[]>()
  );

  return {
    notes: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}
