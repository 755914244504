import { Page } from "../../../../../../components/page/page";
import {
  PageHeaderTitle,
  TabbedHeader,
} from "../../../../../../components/header";
import { Outlet } from "react-router-dom";
import { useBreadcrumbItems, useContextualNavigation } from "../../nav";
import {
  useCRMContact,
  useCRMContactSchema,
  useEnvironmentEventsForResource,
  usePatchAPI,
  withErrorToast,
} from "../../../../../../../data";
import {
  toEnvironmentCRMContact,
  toEnvironmentCRMContactNotes,
  useContactId,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../../routes";
import { ButtonLink } from "../../../../../../../components/basics/button";
import { Mail } from "../../../../../../../icons";
import toast from "react-hot-toast";
import { CRMSidebar } from "../../sidebar";
import { CRMNotesPrimaryResource, EventResourceKind } from "@anzuhq/backend";
import { navigationHeight } from "../../../../../../navigation";
import { CreateNotes } from "../../notes";
import { headerHeight } from "../../../developers/webhooks/[webhookId]/page";

export function CRMContactPage() {
  const navItems = useContextualNavigation();
  const breadcrumbItems = useBreadcrumbItems();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const contactId = useContactId();

  const { contact, mutate } = useCRMContact(
    workspaceId,
    environmentId,
    contactId
  );

  const { mutate: mutateActivity } = useEnvironmentEventsForResource(
    workspaceId,
    environmentId,
    EventResourceKind.CRMContact,
    contactId
  );

  const { schema } = useCRMContactSchema(workspaceId, environmentId);
  const patchApi = usePatchAPI();

  const updateContact = async (id: string, data: Record<string, unknown>) => {
    if (!contact) {
      return;
    }
    await withErrorToast(async () => {
      await mutate(
        {
          ...contact,
          ...data,
        },
        {
          revalidate: false,
        }
      );
      await patchApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/crm/contacts/${id}`,
        data
      );
      await mutate();
      await mutateActivity();
      toast.success("Contact updated");
    });
  };

  return (
    <Page
      title={"Contact - CRM"}
      contextualNavigationItems={navItems}
      breadcrumbItems={breadcrumbItems}
    >
      <div
        className={
          "w-full h-full flex justify-start overflow-auto grid-flow-col"
        }
      >
        <div className={"w-full grow flex flex-col overflow-hidden"}>
          <TabbedHeader
            tabs={[
              {
                label: "Overview",
                path: toEnvironmentCRMContact(
                  workspaceId,
                  environmentId,
                  contactId
                ),
              },
              {
                label: "Notes",
                path: toEnvironmentCRMContactNotes(
                  workspaceId,
                  environmentId,
                  contactId
                ),
              },
            ]}
          >
            <>
              <PageHeaderTitle>{contact?.name}</PageHeaderTitle>

              {contact ? (
                <div className={"ml-auto flex items-center space-x-2"}>
                  <CreateNotes
                    primaryResource={CRMNotesPrimaryResource.Contact}
                    contact={contact.id}
                    mutateActivity={mutateActivity}
                  />

                  {contact?.email ? (
                    <ButtonLink
                      icon={Mail}
                      size={"medium"}
                      to={`mailto:${contact.email}`}
                    >
                      Send Email
                    </ButtonLink>
                  ) : null}
                </div>
              ) : null}
            </>
          </TabbedHeader>

          <div
            className={"overflow-auto flex"}
            style={{
              height: `calc(100vh - ${navigationHeight} - ${headerHeight})`,
            }}
          >
            <Outlet />
          </div>
        </div>

        <CRMSidebar
          schema={schema || null}
          entity={contact}
          updateEntity={updateContact}
          sidebarFieldOrder={sidebarFieldOrder}
        />
      </div>
    </Page>
  );
}

const sidebarFieldOrder = [
  "assigned_to",
  "stage",
  "lead_status",
  "company",
  "user_identity",
  "email",
  "phone_number",
  "linkedin_url",
];
