import {
  SettingsPageLayout,
  useUserManagementSettingsConfig,
} from "../general/page";
import {
  IEmailProviderConfig,
  IGitHubProviderConfig,
  ProviderConfig,
  UserManagementConfigResp,
} from "@anzuhq/backend";
import { AuthProviderKind, IGoogleProviderConfig } from "@anzuhq/backend";
import toast from "react-hot-toast";
import React, { useState } from "react";
import { Button } from "../../../../../../../components/basics/button";
import { Toggle } from "../../../../../../../components/basics/checkbox";
import { TextInput } from "../../../../../../../components/basics/input";
import { RedirectUriHint } from "../../../../../../../components/uri";
import { FieldSet } from "../../../../../../components/fieldset";
import { ConfigLoadErrorPage } from "../../../../../../components/settings";
import { LearnMoreLink } from "../../../../../../components/docs";
import { WithTooltip } from "../../../../../../../components/basics/tooltip";
import { AlertTriangle } from "../../../../../../../icons";
import classNames from "classnames";
import { Link } from "react-router-dom";
import {
  toUserManagementSettingsSection,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../../routes";

export function UserManagementSettingsAuthProviders() {
  const { userManagementConfig, isLoading, saveConfig } =
    useUserManagementSettingsConfig();

  if (isLoading) {
    return <FieldSet footer={<></>} title={""} loading />;
  }

  if (!userManagementConfig) {
    return <ConfigLoadErrorPage />;
  }

  const saveProvider = (
    kind: AuthProviderKind,
    data: ProviderConfig,
    message?: string
  ) => {
    const existingProviders = userManagementConfig.config.auth_providers;

    if (existingProviders.some((p) => p.kind === kind)) {
      const newProviders = existingProviders.map((p) => {
        if (p.kind === kind) {
          return data;
        }
        return p;
      });
      saveConfig({ auth_providers: newProviders }, message);
      return;
    }

    saveConfig({
      auth_providers: [...existingProviders, data],
    });
  };

  return (
    <SettingsPageLayout>
      <GoogleProvider
        config={userManagementConfig}
        saveProvider={saveProvider}
      />
      <GitHubProvider
        config={userManagementConfig}
        saveProvider={saveProvider}
      />
      <EmailProvider
        config={userManagementConfig}
        saveProvider={saveProvider}
      />
    </SettingsPageLayout>
  );
}

function GoogleProvider({
  config,
  saveProvider,
}: {
  config: UserManagementConfigResp;
  saveProvider: (
    kind: AuthProviderKind,
    data: ProviderConfig,
    message?: string
  ) => void;
}) {
  const provider = config.config.auth_providers.find(
    (p) => p.kind === AuthProviderKind.Google
  ) as IGoogleProviderConfig | undefined;

  const [clientId, setClientId] = useState(provider?.clientId || "");
  const [clientSecret, setClientSecret] = useState(
    provider?.clientSecret || ""
  );

  const [hostedDomain, setHostedDomain] = useState<string | null>(
    provider?.hostedDomain || null
  );

  const [enabled, setEnabled] = useState(provider?.isEnabled || false);

  const isValid = clientId.length > 0 && clientSecret.length > 0;

  const save = () => {
    if (!isValid) {
      toast.error("Not all necessary fields are filled out.");
      return;
    }

    const config: IGoogleProviderConfig = {
      kind: AuthProviderKind.Google,
      clientId,
      clientSecret,
      hostedDomain,
      isEnabled: enabled,
    };

    saveProvider(
      AuthProviderKind.Google,
      config,
      `Updated Google auth provider`
    );
  };

  return (
    <FieldSet
      footer={
        <div className={"flex items-center justify-between"}>
          <LearnMoreLink
            to={
              "https://anzuhq.com/docs/blocks/user-management/auth-providers/google"
            }
          >
            integrating Google authentication
          </LearnMoreLink>

          <Button onClick={save} size={"small"} disabled={!isValid}>
            Save
          </Button>
        </div>
      }
      title={"Google"}
      action={
        <Toggle
          id={"google-enabled-toggle"}
          label={"Enabled"}
          checked={enabled}
          onChange={setEnabled}
        />
      }
    >
      <RedirectUriHint
        redirectUri={
          config.providerRedirectUri.find(
            (p) => p.provider === AuthProviderKind.Google
          )!.redirectUri
        }
      >
        Make sure to enter this URI to the list of allowed redirect URIs when
        configuring your OAuth credentials.
      </RedirectUriHint>

      <TextInput value={clientId} onChange={setClientId} label={"Client ID"} />
      <TextInput
        value={clientSecret}
        onChange={setClientSecret}
        label={"Client Secret"}
        isSecret={true}
      />
      <TextInput
        value={hostedDomain || ""}
        onChange={setHostedDomain}
        label={"Hosted Domain"}
      />
    </FieldSet>
  );
}

function GitHubProvider({
  config,
  saveProvider,
}: {
  config: UserManagementConfigResp;
  saveProvider: (
    kind: AuthProviderKind,
    data: ProviderConfig,
    message?: string
  ) => void;
}) {
  const provider = config.config.auth_providers.find(
    (p) => p.kind === AuthProviderKind.GitHub
  ) as IGitHubProviderConfig | undefined;

  const [clientId, setClientId] = useState(provider?.clientId || "");
  const [clientSecret, setClientSecret] = useState(
    provider?.clientSecret || ""
  );
  const [enabled, setEnabled] = useState(provider?.isEnabled || false);

  const isValid = clientId.length > 0 && clientSecret.length > 0;

  const save = () => {
    if (!isValid) {
      toast.error("Not all necessary fields are filled out.");
      return;
    }

    const config: IGitHubProviderConfig = {
      kind: AuthProviderKind.GitHub,
      clientId,
      clientSecret,
      isEnabled: enabled,
    };

    saveProvider(
      AuthProviderKind.GitHub,
      config,
      `Updated GitHub auth provider`
    );
  };

  return (
    <FieldSet
      footer={
        <div className={"flex items-center justify-between"}>
          <LearnMoreLink
            to={
              "https://anzuhq.com/docs/blocks/user-management/auth-providers/github"
            }
          >
            integrating GitHub authentication
          </LearnMoreLink>

          <Button onClick={save} size={"small"} disabled={!isValid}>
            Save
          </Button>
        </div>
      }
      title={"GitHub"}
      action={
        <Toggle
          id={"github-enabled-toggle"}
          label={"Enabled"}
          checked={enabled}
          onChange={setEnabled}
        />
      }
    >
      <RedirectUriHint
        redirectUri={
          config.providerRedirectUri.find(
            (p) => p.provider === AuthProviderKind.GitHub
          )!.redirectUri
        }
      >
        Make sure to set this URI as Authorization callback URL when configuring
        the GitHub OAuth application.
      </RedirectUriHint>

      <TextInput value={clientId} onChange={setClientId} label={"Client ID"} />
      <TextInput
        value={clientSecret}
        onChange={setClientSecret}
        label={"Client Secret"}
        isSecret={true}
      />
    </FieldSet>
  );
}

function EmailProvider({
  config,
  saveProvider,
}: {
  config: UserManagementConfigResp;
  saveProvider: (
    kind: AuthProviderKind,
    data: ProviderConfig,
    message?: string
  ) => void;
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const provider = config.config.auth_providers.find(
    (p) => p.kind === AuthProviderKind.Email
  ) as IEmailProviderConfig | undefined;

  const [enabled, setEnabled] = useState(provider?.isEnabled || false);

  const isValid = true;

  const save = () => {
    if (!isValid) {
      toast.error("Not all necessary fields are filled out.");
      return;
    }

    const config: IEmailProviderConfig = {
      kind: AuthProviderKind.Email,

      isEnabled: enabled,
    };

    saveProvider(AuthProviderKind.Email, config, `Updated Email auth provider`);
  };

  const providerDisabled =
    !config.config.hosted_page_appearance?.applicationName;

  return (
    <FieldSet
      footer={
        <div className={"flex items-center justify-between"}>
          <LearnMoreLink
            to={
              "https://anzuhq.com/docs/blocks/user-management/auth-providers/email"
            }
          >
            integrating Email authentication
          </LearnMoreLink>

          <Button onClick={save} size={"small"} disabled={!isValid}>
            Save
          </Button>
        </div>
      }
      title={"Email (with Magic Links)"}
      action={
        <div className={"flex items-center space-x-2"}>
          <div className={"flex items-center"}>
            {!providerDisabled ? null : (
              <Link
                to={toUserManagementSettingsSection(
                  workspaceId,
                  environmentId,
                  "hosted-auth-page"
                )}
              >
                <WithTooltip
                  text={
                    "Mail auth will be disabled until Application name is set in hosted page appearance."
                  }
                >
                  <AlertTriangle
                    size={16}
                    className={classNames("text-amber-600")}
                  />
                </WithTooltip>
              </Link>
            )}
          </div>

          <Toggle
            id={"email-enabled-toggle"}
            label={"Enabled"}
            checked={enabled}
            onChange={setEnabled}
            disabled={providerDisabled}
          />
        </div>
      }
    >
      <p>For the time being, you do not need to configure anything else.</p>
    </FieldSet>
  );
}
