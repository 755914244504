import { Page } from "../components/page/page";
import { SimpleHeaderWithTitle } from "../components/header";
import { useAccountInvite, useAccountInvites } from "../../data";
import { Link } from "react-router-dom";
import { toWorkspaceInvite, toWorkspaceJoin } from "../routes";
import { ImageWithFallback } from "../database/renderer";

export function JoinPage() {
  const { invites } = useAccountInvites();
  return (
    <Page
      title={"Join"}
      contextualNavigationItems={[
        {
          active: true,
          to: toWorkspaceJoin(),
          label: "Join workspace",
        },
      ]}
    >
      <SimpleHeaderWithTitle title={"Join a workspace"} />
      <div className={"grid grid-cols-12"}>
        <div className={"col-span-1"} />

        <div className={"pt-24 col-span-10 grid grid-cols-3"}>
          {(invites || []).map((i) => (
            <InviteItem inviteId={i.id} key={i.id} />
          ))}
          {invites?.length === 0 ? (
            <span
              className={
                "col-span-3 self-center justify-self-center text-sm text-neutral-700 select-none"
              }
            >
              No pending invites.
            </span>
          ) : null}
        </div>

        <div className={"col-span-1"} />
      </div>
    </Page>
  );
}

function InviteItem({ inviteId }: { inviteId: string }) {
  const { invite } = useAccountInvite(inviteId);

  if (!invite) {
    return (
      <div className={"p-2 animate-pulse"}>
        <span>invite here</span>
      </div>
    );
  }

  return (
    <Link to={toWorkspaceInvite(inviteId)}>
      <div
        className={
          "flex flex-col space-y-8 p-4 items-center justify-center rounded border border-neutral-200 hover:border-black transition bg-gradient-to-b from-white to-neutral-50"
        }
      >
        <span className={"text-lg font-medium"}>{invite.workspace.name}</span>

        {invite.invited_by ? (
          <div className={"flex flex-col space-y-2"}>
            <span className={"text-sm text-neutral-500"}>invited by</span>

            <div className={"flex items-center space-x-1"}>
              <ImageWithFallback
                src={invite.invited_by.picture}
                alt={"profile"}
              />

              <span className={"text-sm"}>{invite.invited_by.name}</span>
            </div>
          </div>
        ) : null}
      </div>
    </Link>
  );
}
