import { useEffect, useMemo, useState } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import classNames from "classnames";

import {
  Check,
  ChevronDown,
  ChevronUp,
  PlusCircle,
  UserPlus,
  Info,
  X,
} from "../icons";
import { useAuth } from "../components/auth";
import {
  Button,
  RawButtonWithRef,
  TabButton,
} from "../components/basics/button";
import { useShowCrispChatbox } from "../crisp";
import { AnimatePresence, motion } from "framer-motion";
import {
  toEnvironmentDashboard,
  toEnvironmentDevelopers,
  toWorkspaceDashboard,
  toWorkspaceJoin,
  useOptionalEnvironmentId,
  useOptionalWorkspaceId,
} from "./routes";
import { DevelopmentIcon, Icon } from "../components/basics/icon";
import {
  getFetcher,
  useEnvironment,
  useOwnProfile,
  usePostAPI,
  useProductionEnvironment,
  useWorkspace,
  useWorkspaceEnvironments,
  useWorkspaces,
  withErrorToast,
} from "../data";
import {
  Dropdown,
  DropdownItemButton,
  DropdownItemButtonWithRef,
  DropdownItemLink,
  DropdownListContainer,
  DropdownTextInput,
} from "./database/dropdown";
import { Environment } from "@anzuhq/backend";
import { StagingEnvironmentHint } from "./[workspaceId]/environments/staging-hint";
import * as Dialog from "@radix-ui/react-dialog";
import { TextInput } from "../components/basics/input";
import toast from "react-hot-toast";
import { useDebounce } from "../hooks/debounce";
import { Tooltip } from "@radix-ui/react-tooltip";
import { WithTooltip } from "../components/basics/tooltip";
import { useIsDevelopment } from "./debug";
import { useApiEndpointContext } from "../env";
import useSWR from "swr";

export interface ContextualNavigationItem {
  to: string;
  active: boolean;
  label: string;
}

export interface BreadcrumbItem {
  to: string;
  label: string;
}

const DividerSlash = () => (
  <span
    className={
      "text-2xl font-light text-neutral-300 select-none rotate-6 h-6 w-[2px] bg-neutral-300 rounded"
    }
  />
);

// Whenever the navigation height changes, we need to update any part of the code
// that calculates the remaining page height for overflow calculations (e.g. the code editor on the webhook creation page)
export const navigationHeight = "100px";

export function Navigation({
  contextualItems,
  contextualItemsLoading,
  breadcrumbItems,
}: {
  contextualItems?: ContextualNavigationItem[];
  contextualItemsLoading?: boolean;
  breadcrumbItems?: BreadcrumbItem[];
}) {
  const workspaceId = useOptionalWorkspaceId();
  const environmentId = useOptionalEnvironmentId();

  const auth = useAuth();
  const isDevelopment = useIsDevelopment();

  const showCrispChatbox = useShowCrispChatbox();

  // The parent must have the same height (blocking) as the floating navigation
  return (
    <div
      className={"block shrink-0 grow-0"}
      style={{ height: navigationHeight }}
    >
      {/* Make sure the computed height matches the parent*/}
      <div className={"flex flex-col fixed top-0 z-40 bg-white w-full pt-4"}>
        {/* Top navigation bar */}
        <div className={"px-6 flex items-center"}>
          <div className={"flex items-center space-x-2"}>
            <Link to={"/"}>
              {isDevelopment ? <DevelopmentIcon /> : <Icon />}
            </Link>

            <AnimatePresence mode={"wait"}>
              <OnlineStatus />
            </AnimatePresence>

            {auth.user ? (
              <>
                <AnimatePresence>
                  {workspaceId ? (
                    <motion.div
                      key={"workspace"}
                      // Find out why opacity is not full
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.15 }}
                      className={"flex items-center space-x-4"}
                    >
                      <DividerSlash />
                      <WorkspaceItem workspaceId={workspaceId} />
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </>
            ) : null}

            {auth.user ? (
              <>
                <AnimatePresence>
                  {workspaceId && environmentId ? (
                    <motion.div
                      key={"environment-picker"}
                      // Find out why opacity is not full
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.15 }}
                      className={"flex items-center space-x-4"}
                    >
                      <EnvironmentPicker
                        workspaceId={workspaceId}
                        environmentId={environmentId}
                      />
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </>
            ) : null}

            <div className={"flex items-center space-x-2"}>
              <AnimatePresence>
                {breadcrumbItems && breadcrumbItems.length > 0
                  ? breadcrumbItems.map((item, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.15 }}
                        className={"flex items-center space-x-2"}
                      >
                        <DividerSlash />
                        <Link
                          to={item.to}
                          className={
                            "text-sm font-medium whitespace-nowrap select-none"
                          }
                        >
                          {item.label}
                        </Link>
                      </motion.div>
                    ))
                  : null}
              </AnimatePresence>
            </div>
          </div>

          <div
            className={
              "ml-auto items-center space-x-6 hidden md:flex h-full select-none"
            }
          >
            <a
              href={"mailto:hey@anzuhq.com"}
              className={
                "text-sm font-medium border border-neutral-200 hover:bg-black hover:border-black text-neutral-600 hover:text-white px-4 py-1 rounded-md transition duration-200 ease-in-out whitespace-nowrap"
              }
              onClick={showCrispChatbox}
            >
              Get Help
            </a>
            <a
              className={"text-sm text-neutral-600 hover:text-black"}
              href={"https://anzuhq.com/docs"}
            >
              Docs
            </a>
            {/*<a*/}
            {/*  className={"text-sm text-neutral-600 hover:text-black"}*/}
            {/*  href={"https://anzuhq.com/changelog"}*/}
            {/*>*/}
            {/*  Changelog*/}
            {/*</a>*/}
            <a
              className={"text-sm text-neutral-600 hover:text-black"}
              href={"https://anzuhq.com/blog"}
            >
              Blog
            </a>
            {auth.user ? <ProfileItem /> : null}
          </div>
        </div>

        {/* Contextual navigation bar */}
        <div
          className={classNames(
            "border-b border-neutral-200 px-4 pt-2 overflow-y-hidden overflow-x-auto select-none",
            {
              "h-4": !contextualItems || contextualItems.length < 1,
            }
          )}
        >
          <AnimatePresence mode={"wait"}>
            <div
              key={
                contextualItems
                  ? contextualItems.map((i) => i.label).join("")
                  : "contextual-items"
              }
              className={"flex items-end space-x-2"}
            >
              {contextualItems
                ? contextualItems.map((b) => (
                    <TabButton
                      key={b.to}
                      active={b.active}
                      to={b.to}
                      loading={contextualItemsLoading}
                    >
                      {b.label}
                    </TabButton>
                  ))
                : null}
            </div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

function OnlineStatus() {
  const status = useOnlineStatus();
  const debouncedStatus = useDebounce(status, 3000);

  if (status === "online") {
    if (debouncedStatus === "offline") {
      return (
        <motion.div
          key={"online"}
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: [0.8, 1.2, 1.0] }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.15 }}
        >
          <span className="relative flex h-3 w-3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
          </span>
        </motion.div>
      );
    }
    return null;
  }

  return (
    <WithTooltip
      text={
        "You are currently not connected to Anzu, please check your connection..."
      }
      side={"right"}
    >
      <motion.div
        key={"offline"}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: [0.8, 1.2, 1.0] }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.15 }}
      >
        <span className="relative flex h-3 w-3">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
        </span>
      </motion.div>
    </WithTooltip>
  );
}

function ProfileItem() {
  const { profile } = useOwnProfile();
  const { signOut } = useAuth();

  const [open, setOpen] = useState(false);

  if (!profile) {
    return (
      <div className="animate-pulse">
        <div className="rounded-full bg-neutral-200 h-7 w-7"></div>
      </div>
    );
  }

  return (
    <Dropdown
      open={open}
      setOpen={setOpen}
      activator={
        <RawButtonWithRef className="cursor-pointer w-7 h-7">
          {profile.gravatar_url ? (
            <img
              src={profile.gravatar_url}
              className={
                "w-full h-full outline outline-2 outline-neutral-200 rounded-full hover:opacity-80 active:scale-90 transition duration-75 ease-in-out"
              }
              alt={"profile"}
            />
          ) : (
            <div className="rounded-full bg-gradient-to-tr from-blue-800 to-pink-800 w-full h-full" />
          )}
        </RawButtonWithRef>
      }
    >
      <DropdownListContainer>
        <DropdownItemButton onClick={() => signOut()}>
          Sign Out
        </DropdownItemButton>
      </DropdownListContainer>
    </Dropdown>
  );
}

const ChevronUpDown = () => (
  <div
    className={
      "flex-col items-center hover:bg-neutral-100 active:bg-neutral-200 rounded-md px-2 py-1"
    }
  >
    <ChevronUp size={12} />
    <ChevronDown size={12} />
  </div>
);

function WorkspaceItem({ workspaceId }: { workspaceId: string }) {
  const { workspace, mutate: mutateWorkspace } = useWorkspace(workspaceId);
  const { workspaces, mutate: mutateWorkspaces } = useWorkspaces();

  const [open, setOpen] = useState(false);

  const [searchText, setSearchText] = useState("");
  const displayedResults = useMemo(() => {
    if (!workspaces) {
      return [];
    }
    return workspaces.filter((w) =>
      w.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [workspaces, searchText]);

  if (!workspace) {
    return (
      <div className="animate-pulse flex items-center space-x-2">
        <div className="rounded-full bg-neutral-200 h-6 w-6"></div>
        <div className="h-6 w-24 bg-neutral-200 rounded"></div>
      </div>
    );
  }

  return (
    <div className={"flex items-center space-x-1"}>
      <div
        className={
          "w-6 h-6 rounded-full bg-gradient-to-tr from-indigo-500 to-pink-500"
        }
      />
      <Link to={toWorkspaceDashboard(workspaceId)}>
        <span
          className={
            "text-sm hover:bg-neutral-100 active:bg-neutral-200 rounded-md px-2 py-1 font-medium whitespace-nowrap select-none"
          }
        >
          {workspace.name}
        </span>
      </Link>

      <Dropdown
        open={open}
        setOpen={setOpen}
        activator={
          <RawButtonWithRef className={"flex items-center space-x-1"}>
            <ChevronUpDown />
          </RawButtonWithRef>
        }
      >
        <DropdownTextInput
          placeholder={"Search..."}
          value={searchText}
          setValue={setSearchText}
        />

        {displayedResults.length > 0 ? (
          <DropdownListContainer>
            <span className={"px-2 py-2 text-xs text-neutral-500"}>
              Workspaces
            </span>

            {displayedResults.map((t) => (
              <DropdownItemLink
                key={t.id}
                to={toWorkspaceDashboard(t.id)}
                close={() => setOpen(false)}
              >
                <div
                  className={
                    "bg-gradient-to-tr from-indigo-500 to-pink-500 w-4 h-4 rounded-full"
                  }
                />
                <span className={"ml-2 text-sm whitespace-nowrap"}>
                  {t.name}
                </span>

                {t.id === workspaceId ? (
                  <div className={"flex items-center justify-end grow"}>
                    <Check size={16} />
                  </div>
                ) : null}
              </DropdownItemLink>
            ))}
          </DropdownListContainer>
        ) : (
          <DropdownListContainer>
            <p className={"text-sm font-regular text-center"}>
              No matching workspace found.
            </p>
          </DropdownListContainer>
        )}

        <div className={"flex flex-col space-y-1 p-2"}>
          <CreateWorkspaceDialog
            mutate={async () => {
              await mutateWorkspaces();
              await mutateWorkspace();
            }}
          />

          <DropdownItemLink to={toWorkspaceJoin()}>
            <UserPlus size={16} />
            <span className={"ml-2 text-sm"}>Join workspace</span>
          </DropdownItemLink>
        </div>
      </Dropdown>
    </div>
  );
}

export function CreateWorkspaceDialog({
  mutate,
  forceOpen,
}: {
  mutate: () => void;
  forceOpen?: boolean;
}) {
  const [open, setOpen] = useState(forceOpen || false);
  const navigate = useNavigate();

  const [name, setName] = useState("My Workspace");

  const postApi = usePostAPI();

  const isValid = name.length > 0;

  const createWorkspace = async () => {
    if (!isValid) {
      return;
    }
    await withErrorToast(async () => {
      const created = await postApi<{ id: string }>(`/workspaces`, { name });
      toast.success("Workspace created");
      await mutate();
      navigate(toWorkspaceDashboard(created.id));
    });
  };

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(o) => {
        if (!o && !forceOpen) {
          setOpen(false);
        }
      }}
      modal
    >
      <Dialog.Trigger asChild>
        <DropdownItemButtonWithRef
          onClick={() => {
            setOpen(true);
          }}
        >
          <PlusCircle size={16} />
          <span className={"ml-2 text-sm"}>Create workspace</span>
        </DropdownItemButtonWithRef>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay key={"overlay"} asChild>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed top-0 left-0 w-full h-full z-30 bg-neutral-100/70"
          />
        </Dialog.Overlay>
        <Dialog.Content asChild key={"content"}>
          <motion.div
            initial={{ opacity: 0, scale: 0, y: "-80%", x: "-50%" }}
            animate={{
              opacity: 1,
              scale: [0.5, 0.8, 1.1, 1.0],
              y: "-50%",
              x: "-50%",
            }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.2 }}
            className={classNames(
              "z-30 fixed top-1/2 left-1/2 w-[700px] h-[400px]",
              "bg-white/60 backdrop-blur-2xl rounded-lg shadow-2xl",
              "flex flex-col"
            )}
          >
            <div className={"flex justify-end"}>
              <Dialog.Close asChild>
                <button
                  disabled={forceOpen}
                  className="text-neutral-400 hover:text-neutral-700 p-4 disabled:opacity-0"
                  aria-label="Close"
                >
                  <X size={20} />
                </button>
              </Dialog.Close>
            </div>

            <div className={"grid grid-cols-12"}>
              <div className={"col-span-1"}></div>

              <div className={"col-span-10 p-4 flex flex-col space-y-8"}>
                <div>
                  <h2 className={"text-lg font-medium"}>Create Workspace</h2>
                  <span className={"text-neutral-400"}>
                    Create a new workspace for your team.
                  </span>
                </div>

                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    createWorkspace();
                  }}
                >
                  <TextInput
                    autoFocus
                    value={name}
                    onChange={setName}
                    label={"Name"}
                    labelHorizontal
                    type={"name"}
                  />
                </form>

                <Button disabled={!isValid} onClick={createWorkspace}>
                  Create Workspace
                </Button>
              </div>

              <div className={"col-span-1"} />
            </div>
          </motion.div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

function EnvironmentPicker({
  workspaceId,
  environmentId,
}: {
  workspaceId: string;
  environmentId: string;
}) {
  const { environments } = useWorkspaceEnvironments(workspaceId);
  const { environment: productionEnv } = useProductionEnvironment(workspaceId);
  const { environment } = useEnvironment(workspaceId, environmentId);

  const isInDeveloperSection =
    useMatch({
      path: toEnvironmentDevelopers(workspaceId, environmentId),
      end: false,
    }) !== null;

  const showPicker =
    isInDeveloperSection ||
    (productionEnv ? productionEnv.id !== environmentId : false);

  if (!environment || !environments || !productionEnv) {
    return (
      <div className="animate-pulse flex items-center space-x-2">
        <DividerSlash />
        <div className="rounded-full bg-neutral-200 h-6 w-6"></div>
        <div className="h-6 w-24 bg-neutral-200 rounded"></div>
      </div>
    );
  }

  if (!showPicker) {
    return null;
  }

  return (
    <EnvironmentItem
      isInDeveloperSection={isInDeveloperSection}
      workspaceId={workspaceId}
      environmentId={environmentId}
      environments={environments}
      environment={environment}
    />
  );
}

function EnvironmentItem({
  workspaceId,
  environmentId,
  environments,
  environment,
  isInDeveloperSection,
}: {
  workspaceId: string;
  environmentId: string;
  environments: Environment[];
  environment: Environment;
  isInDeveloperSection?: boolean;
}) {
  const [open, setOpen] = useState(false);

  const [searchText, setSearchText] = useState("");
  const displayedResults = useMemo(() => {
    if (!environments) {
      return [];
    }
    return environments.filter((w) =>
      w.display_name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [environments, searchText]);

  const linkTo = (envId: string) =>
    isInDeveloperSection
      ? toEnvironmentDevelopers(workspaceId, envId)
      : toEnvironmentDashboard(workspaceId, envId);

  return (
    <>
      <DividerSlash />
      <div className={"flex items-center space-x-1"}>
        <div
          className={classNames(
            "w-6 h-6 rounded-full bg-gradient-to-tr from-blue-500 to-cyan-500",
            {
              "from-blue-500 to-cyan-500": environment.is_production,
              "from-yellow-500 to-amber-500": !environment.is_production,
            }
          )}
        />
        <Link
          to={linkTo(environmentId)}
          className={classNames(
            "text-sm font-medium whitespace-nowrap rounded-md px-2 py-1 flex items-center space-x-2",
            {
              "hover:bg-neutral-100 active:bg-neutral-200 text-neutral-800":
                environment.is_production,
              "bg-amber-50 hover:bg-amber-100 active:bg-amber-200 text-amber-800":
                !environment.is_production,
            }
          )}
        >
          <span>{environment.display_name}</span>
          {environment.is_production ? null : (
            <StagingEnvironmentHint disabled={environment.is_production}>
              <Info size={14} />
            </StagingEnvironmentHint>
          )}
        </Link>

        <Dropdown
          open={open}
          setOpen={setOpen}
          activator={
            <RawButtonWithRef className={"flex items-center space-x-1"}>
              <ChevronUpDown />
            </RawButtonWithRef>
          }
        >
          <DropdownTextInput
            placeholder={"Search..."}
            value={searchText}
            setValue={setSearchText}
          />

          {displayedResults.length > 0 ? (
            <DropdownListContainer>
              <span className={"px-2 py-2 text-xs text-neutral-500"}>
                Environments
              </span>

              {displayedResults.map((t) => (
                <DropdownItemLink
                  key={t.id}
                  to={linkTo(t.id)}
                  close={() => setOpen(false)}
                >
                  <div
                    className={classNames(
                      "bg-gradient-to-tr w-4 h-4 rounded-full",
                      {
                        "from-blue-500 to-cyan-500": t.is_production,
                        "from-yellow-500 to-amber-500": !t.is_production,
                      }
                    )}
                  />
                  <span className={"ml-2 text-sm whitespace-nowrap"}>
                    {t.display_name}
                  </span>

                  {t.id === environmentId ? (
                    <div className={"flex items-center justify-end grow"}>
                      <Check size={16} />
                    </div>
                  ) : null}
                </DropdownItemLink>
              ))}
            </DropdownListContainer>
          ) : (
            <DropdownListContainer>
              <p className={"text-sm font-regular text-center"}>
                No matching environment found.
              </p>
            </DropdownListContainer>
          )}
        </Dropdown>
      </div>
    </>
  );
}

function useOnlineStatus() {
  const [status, setStatus] = useState<"online" | "offline">(
    navigator.onLine ? "online" : "offline"
  );

  // ping API continuously
  const [apiEndpoint] = useApiEndpointContext();
  const { data: isConnected } = useSWR(
    [apiEndpoint, "/"],
    async ([apiEndpoint, route]) => {
      try {
        const res = await fetch(`${apiEndpoint}${route}`);
        if (!res.ok) {
          return false;
        }

        const body = await res.json();
        if (!body || body.ok !== true) {
          return false;
        }

        return true;
      } catch (err) {
        return false;
      }
    },
    {
      refreshInterval: 10000,
      dedupingInterval: 10000,
      loadingTimeout: 10000,
      errorRetryInterval: 10000,
    }
  );

  // react to changes in navigator online
  useEffect(() => {
    function online() {
      setStatus("online");
    }

    function offline() {
      setStatus("offline");
    }

    window.addEventListener("online", online);
    window.addEventListener("offline", offline);

    return () => {
      window.removeEventListener("online", online);
      window.removeEventListener("offline", offline);
    };
  }, [setStatus]);

  // react to changes in connectivity
  useEffect(() => {
    setStatus(isConnected ? "online" : "offline");
  }, [isConnected, setStatus]);

  return status;
}
