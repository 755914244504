import { useEnvironmentId, useWorkspaceId } from "../../../../../routes";
import {
  useCRMDealSchema,
  usePostAPI,
  withErrorToast,
} from "../../../../../../data";
import { useEffect, useMemo, useState } from "react";
import { ChevronRight, Plus } from "../../../../../../icons";
import {
  Button,
  ButtonWithRef,
} from "../../../../../../components/basics/button";
import { createHotkey, useHotkeys } from "../../../../../../hooks/keypress";
import { TextArea, TextInput } from "../../../../../../components/basics/input";
import { DatabaseFieldKind, ICRMDeal } from "@anzuhq/backend";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Toggle } from "../../../../../../components/basics/checkbox";
import { Dialog, DialogHeader } from "../../../../../components/dialog";
import { getRendererForField } from "../contacts/create";

export function CRMCreateDealDialog({ pipelineId }: { pipelineId: string }) {
  const navigate = useNavigate();
  const postApi = usePostAPI();
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { schema } = useCRMDealSchema(workspaceId, environmentId, pipelineId);

  const [open, setOpen] = useState(false);

  useHotkeys(createHotkey, () => setOpen(true), [setOpen]);

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string | null>(null);

  const [assignedTo, setAssignedTo] = useState<string | null>(null);
  const [stage, setStage] = useState<string | null>(null);

  const [contact, setContact] = useState<string | null>(null);
  const [company, setCompany] = useState<string | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (schema && stage === null) {
      const stageField = schema.fields.find((f) => f.name === "stage");
      if (!stageField || stageField.kind !== DatabaseFieldKind.Enum) {
        return;
      }

      const defaultValue = stageField.enumValues.find((v) => v.isDefault);
      if (!defaultValue) {
        return;
      }

      setStage(defaultValue.value);
    }
  }, [stage, schema]);

  const isValid = useMemo(() => {
    return name.length > 0;
  }, [name]);

  const [createMore, setCreateMore] = useState(false);

  const reset = () => {
    setName("");
    setDescription(null);
    setAssignedTo(null);
    setStage(null);
    setCompany(null);
    setContact(null);
  };

  const createDeal = async () => {
    if (!isValid || isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await withErrorToast(async () => {
      const { id } = await postApi<ICRMDeal>(
        `/workspaces/${workspaceId}/environments/${environmentId}/crm/deals`,
        {
          name,
          description,
          assigned_to: assignedTo,
          stage,
          pipeline: pipelineId,
          contact,
          company,
        }
      );
      reset();
      if (!createMore) {
        await navigate(
          `/${workspaceId}/environments/${environmentId}/crm/deals/${id}`
        );
      }
      toast.success("Deal created");
    });
    setIsSubmitting(false);
  };

  if (!schema) {
    return null;
  }

  return (
    <Dialog
      open={open}
      setOpen={setOpen}
      activator={
        <ButtonWithRef
          icon={Plus}
          role={"primary"}
          size={"regular"}
          onClick={() => setOpen(true)}
        >
          Create Deal
        </ButtonWithRef>
      }
    >
      <DialogHeader>
        <div
          className={
            "bg-white/60 backdrop-blur-2xl shadow text-xs font-medium px-4 py-1 rounded text-neutral-700"
          }
        >
          CRM
        </div>

        <ChevronRight size={14} className={"text-neutral-300"} />

        <div className={"text-xs text-neutral-700"}>New deal</div>
      </DialogHeader>

      <form
        className={"flex grow flex-col p-2 text-sm"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          createDeal();
        }}
      >
        <div className={"grow-0 shrink-0"}>
          <TextInput
            autoFocus
            value={name}
            onChange={setName}
            placeholder={"Full Name"}
            noInitialBorder
          />
        </div>

        <div className={"grow shrink-0"}>
          <TextArea
            value={description || ""}
            onChange={setDescription}
            placeholder={"Add description..."}
            noInitialBorder
          />
        </div>
      </form>

      <div className={"mt-auto flex items-center justify-between space-x-2"}>
        <div
          className={
            "overflow-auto p-2 text-xs text-neutral-700 space-x-2 whitespace-nowrap w-full flex items-center select-none"
          }
        >
          <span className={"text-neutral-500"}>Assigned To</span>
          {getRendererForField(
            schema,
            "assigned_to",
            assignedTo,
            setAssignedTo
          )}

          <span className={"text-neutral-500"}>Stage</span>
          {getRendererForField(schema, "stage", stage, setStage)}

          <span className={"text-neutral-500"}>Contact</span>
          {getRendererForField(schema, "contact", contact, setContact)}

          <span className={"text-neutral-500"}>Company</span>
          {getRendererForField(schema, "company", company, setCompany)}
        </div>

        <div className={"flex items-center space-x-2 p-2"}>
          <Toggle
            checked={createMore}
            label={"Create more?"}
            onChange={setCreateMore}
            id={"create-more"}
            size={"small"}
            labelLeading
          />
          <Button
            onClick={createDeal}
            disabled={!isValid}
            icon={Plus}
            role={"primary"}
            size={"small"}
            isLoading={isSubmitting}
          >
            Create deal
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
