import { useBreadcrumbItems, useContextualNavigation } from "../../nav";
import { Page } from "../../../../../../components/page/page";
import {
  toEnvironmentUserManagementUserIdentity,
  toEnvironmentUserManagementUserIdentityAccessTokens,
  toEnvironmentUserManagementUserIdentityAuthAttempts,
  toEnvironmentUserManagementUserIdentityRelatedIdentities,
  useEnvironmentId,
  useUserIdentityId,
  useWorkspaceId,
} from "../../../../../../routes";
import { IUserIdentity, UserProvisioningStatus } from "@anzuhq/backend";
import {
  usePatchAPI,
  useUserManagementUserIdentity,
  withErrorToast,
} from "../../../../../../../data";
import classNames from "classnames";
import { Button } from "../../../../../../../components/basics/button";
import { Link, Outlet } from "react-router-dom";
import { useMemo, useState } from "react";
import { Check, PauseCircle, User, UserPlus } from "../../../../../../../icons";
import toast from "react-hot-toast";
import {
  PageHeaderTitle,
  TabbedHeader,
} from "../../../../../../components/header";

export function UserIdentityPage() {
  const navItems = useContextualNavigation();
  const breadcrumbItems = useBreadcrumbItems();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const userIdentityId = useUserIdentityId();

  const {
    identity,
    isLoading: isLoadingIdentity,
    mutate,
  } = useUserManagementUserIdentity(workspaceId, environmentId, userIdentityId);

  const isLoading = isLoadingIdentity;

  const subviews = useMemo(() => {
    return [
      {
        label: "Overview",
        path: toEnvironmentUserManagementUserIdentity(
          workspaceId,
          environmentId,
          userIdentityId
        ),
      },
      {
        label: "Access Tokens",
        path: toEnvironmentUserManagementUserIdentityAccessTokens(
          workspaceId,
          environmentId,
          userIdentityId
        ),
      },
      {
        label: "Auth Attempts",
        path: toEnvironmentUserManagementUserIdentityAuthAttempts(
          workspaceId,
          environmentId,
          userIdentityId
        ),
      },
      {
        label: "Related Identities",
        path: toEnvironmentUserManagementUserIdentityRelatedIdentities(
          workspaceId,
          environmentId,
          userIdentityId
        ),
      },
    ];
  }, [workspaceId, environmentId, userIdentityId]);

  const patchApi = usePatchAPI();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const markAsProvisioned = async () => {
    setIsSubmitting(true);
    await withErrorToast(async () => {
      await patchApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/user_management/user_identities/${userIdentityId}`,
        {
          provisioning_status: UserProvisioningStatus.Complete,
        }
      );
      toast.success("Marked as provisioned");
      mutate();
    });
    setIsSubmitting(false);
  };

  const toggleSuspend = async () => {
    if (!identity) {
      return;
    }
    setIsSubmitting(true);
    await withErrorToast(async () => {
      await patchApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/user_management/user_identities/${userIdentityId}`,
        {
          is_suspended: !identity.is_suspended,
        }
      );
      toast.success(
        identity.is_suspended ? "User unsuspended" : "User suspended"
      );
      mutate();
    });
    setIsSubmitting(false);
  };

  return (
    <Page
      title={"User Identity - User Management"}
      contextualNavigationItems={navItems}
      breadcrumbItems={breadcrumbItems}
    >
      <div className={"flex flex-col h-full"}>
        <TabbedHeader tabs={subviews}>
          <PageHeaderTitle isLoading={isLoading}>
            {identity ? retrieveUserIdentityName(identity) : "Loading"}
          </PageHeaderTitle>

          {identity ? (
            <>
              {identity.is_suspended ? (
                <div
                  className={
                    "ml-4 p-1 text-xs font-medium bg-amber-100 rounded-full flex items-center space-x-1"
                  }
                >
                  <PauseCircle className={"stroke-amber-800"} size={16} />
                  <span className={"pr-1 text-amber-800"}>Suspended</span>
                </div>
              ) : null}
            </>
          ) : null}

          {identity ? (
            <>
              {identity.primary_identity ? (
                <Link
                  to={toEnvironmentUserManagementUserIdentity(
                    workspaceId,
                    environmentId,
                    identity.primary_identity
                  )}
                  className={
                    "ml-4 py-1 px-2 text-xs font-medium bg-amber-100 rounded-full flex items-center space-x-1"
                  }
                >
                  <UserPlus className={"stroke-amber-800"} size={16} />
                  <span className={"text-amber-800"}>Secondary Identity</span>
                </Link>
              ) : (
                <div
                  className={
                    "ml-4 py-1 px-2 text-xs font-medium bg-neutral-100 rounded-full flex items-center space-x-1"
                  }
                >
                  <User className={"stroke-neutral-800"} size={16} />
                  <span className={"text-neutral-800"}>Primary Identity</span>
                </div>
              )}
            </>
          ) : null}

          <div className="ml-auto flex space-x-2">
            {identity?.provisioning_status ===
            UserProvisioningStatus.Pending ? (
              <Button
                disabled={isSubmitting}
                isLoading={isSubmitting}
                icon={Check}
                onClick={markAsProvisioned}
                role={"secondary"}
                size={"small"}
              >
                Mark as provisioned
              </Button>
            ) : null}

            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              icon={PauseCircle}
              onClick={toggleSuspend}
              role={"danger"}
              size={"small"}
            >
              {identity?.is_suspended ? "Unsuspend" : "Suspend"}
            </Button>
          </div>
        </TabbedHeader>

        <Outlet />
      </div>
    </Page>
  );
}

export function retrieveUserIdentityName(userIdentity: IUserIdentity) {
  if (userIdentity.full_name) {
    return userIdentity.full_name;
  }

  if (userIdentity.given_name && userIdentity.family_name) {
    return `${userIdentity.given_name} ${userIdentity.family_name}`;
  }

  if (userIdentity.email) {
    return userIdentity.email;
  }

  return userIdentity.id;
}
