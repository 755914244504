import { useOwnAccount } from "./data";
import { Navigate, Outlet } from "react-router-dom";
import { Page } from "./app/components/page/page";
import { Icon } from "./components/basics/icon";

export function CheckEarlyAccess() {
  const { account, isLoading } = useOwnAccount();

  if (isLoading) {
    return null;
  }

  if (!account) {
    return <Navigate to={"/auth/login"} replace={true} />;
  }

  if (account.earlyAccessEnabled) {
    return <Outlet />;
  }

  return (
    <Page
      title={"Early Access"}
      contextualNavigationItems={[
        {
          active: true,
          to: "/",
          label: "Waitlist",
        },
      ]}
      contextualNavigationItemsLoading={false}
      breadcrumbItems={[]}
    >
      <div className={"bg-neutral-50 h-full grid grid-cols-12"}>
        <div className={"col-span-1 md:col-span-2 lg:col-span-4"} />

        <div className={"col-span-10 md:col-span-8 lg:col-span-4 py-16"}>
          <div
            className={
              "p-4 md:p-8 lg:p-16 bg-white rounded-md border border-neutral-200 flex flex-col space-y-4"
            }
          >
            <h1 className={"text-lg font-semibold"}>You&apos;re All Set 🎉</h1>
            <p className={""}>
              Welcome aboard! As Anzu is currently in early access, we&apos;ll
              reach out to you shortly to understand your use case and give you
              a personalized onboarding experience.
            </p>

            <div className={"pt-16 flex flex-col"}>
              <Icon />

              <span className={"px-1 text-neutral-700 text-sm"}>
                Bruno and Tim from Anzu
              </span>
            </div>
          </div>
        </div>

        <div className={"col-span-1 md:col-span-2 lg:col-span-4"} />
      </div>
    </Page>
  );
}
