import { usePostAPI } from "../../../../../../../../data";
import { useEffect, useState } from "react";
import { Spinner } from "../../../../../../../../components/basics/spinner";
import { CopyButton } from "../../../../../../../../components/basics/button";

/**
 * Renders supplied code
 *
 * Must set absolute height in any parent element
 * for overflow to work.
 *
 * @param code
 * @param language
 * @param noCopy
 * @param noCache
 * @constructor
 */
export function RenderedCode({
  code,
  language,
  noCopy,
  noCache = false,
}: {
  code: string;
  language: string;
  noCopy?: boolean;
  noCache?: boolean;
}) {
  const postApi = usePostAPI();
  const [rendered, setRendered] = useState<{
    html: string;
    css: string;
  } | null>(null);

  useEffect(
    () => {
      (async () => {
        try {
          const res = await postApi<{ html: string; css: string }>(
            "/code-renderer",
            {
              code,
              language,
              noCache,
            }
          );
          setRendered(res);
        } catch (err) {}
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [code, language]
  );

  if (!rendered) {
    return (
      <div className={"animate-pulse flex justify-center items-center grow"}>
        <div className={"flex flex-col space-y-2 items-center"}>
          <Spinner size={"regular"} color={"text-neutral-800"} />
          <p className={"text-sm text-neutral-500"}>
            Preparing your code preview...
          </p>
        </div>
      </div>
    );
  }

  // only allow scrolling on the code preview, not the whole page
  return (
    <div className={"relative group grow overflow-hidden h-full"}>
      <style>{rendered.css}</style>

      <div
        className={"font-mono text-sm overflow-auto h-full"}
        dangerouslySetInnerHTML={{ __html: rendered.html }}
      />

      {!noCopy ? (
        <div
          className={
            "opacity-0 group-hover:opacity-100 flex absolute bottom-0 items-center justify-center w-full p-4 transition-all duration-200 ease-in-out"
          }
        >
          <CopyButton size={"medium"} text={code} />
        </div>
      ) : null}
    </div>
  );
}
