import { SettingsPageLayout } from "../../../components/settings";
import React, { useEffect, useMemo, useState } from "react";
import { FieldSet } from "../../../components/fieldset";
import { LearnMoreLink } from "../../../components/docs";
import {
  Button,
  ButtonWithRef,
  CopyButton,
} from "../../../../components/basics/button";
import {
  useDeleteAPI,
  usePatchAPI,
  usePostAPI,
  useWorkspaceAccessTokens,
  useWorkspaceEnvironments,
  withErrorToast,
} from "../../../../data";
import { useWorkspaceId } from "../../../routes";
import { Dialog, DialogHeader } from "../../../components/dialog";
import {
  CheckCircle,
  ChevronDown,
  Key,
  Link,
  MoreHorizontal,
  PauseCircle,
  Plus,
  RefreshCw,
  Trash2,
  XCircle,
} from "../../../../icons";
import toast from "react-hot-toast";
import {
  addMonths,
  formatDistanceToNowStrict,
  isAfter,
  isBefore,
  parseISO,
} from "date-fns";
import {
  AccessToken,
  EnvironmentScope,
  Scopes,
  WorkspaceScope,
} from "@anzuhq/backend";
import { SearchInput, TextInput } from "../../../../components/basics/input";
import {
  DateTimeInputDropdownRenderer,
  DateTimeRendererWithFormats,
} from "../../../database/datetime";
import { Toggle } from "../../../../components/basics/checkbox";
import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import {
  Dropdown,
  DropdownItemButtonWithAlertDialog,
  DropdownListContainer,
} from "../../../database/dropdown";
import { hi } from "date-fns/locale";

export function AccessTokenSettingsPage() {
  return (
    <SettingsPageLayout>
      <AccessTokenSettings />
    </SettingsPageLayout>
  );
}

function CreateTokenDialog({
  mutate,
  highlightToken,
}: {
  mutate: () => void;
  highlightToken: (token: string) => void;
}) {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const workspaceId = useWorkspaceId();

  const postApi = usePostAPI();

  const [description, setDescription] = useState(`access token for `);
  const [expiresAt, setExpiresAt] = useState<string>(
    addMonths(new Date(), 3).toISOString()
  );
  const [scopes, setScopes] = useState<Scopes>({
    environmentScopes: [],
    environments: [],
    workspaceScopes: [],
  });

  const isValid = useMemo(() => {
    if (!description) {
      return false;
    }

    if (isBefore(parseISO(expiresAt), new Date())) {
      return false;
    }

    if (
      scopes.environmentScopes.length > 0 &&
      scopes.environments.length === 0
    ) {
      return false;
    }

    if (
      scopes.environmentScopes.length === 0 &&
      scopes.workspaceScopes.length === 0
    ) {
      return false;
    }

    return true;
  }, [description, expiresAt, scopes]);

  const createToken = async () => {
    if (!isValid || isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await withErrorToast(async () => {
      const { token } = await postApi<{ id: string; token: string }>(
        `/workspaces/${workspaceId}/access_tokens`,
        {
          scopes,
          expiresAt,
          description,
        }
      );
      mutate();
      toast.success("Token created");
      highlightToken(token);

      try {
        await navigator.clipboard.writeText(token);
      } catch (e) {
        toast.error("Failed to copy token to clipboard");
      }

      setOpen(false);
    });
    setIsSubmitting(false);
  };

  return (
    <Dialog
      activator={
        <ButtonWithRef onClick={() => {}} size={"medium"} icon={Plus}>
          Create token
        </ButtonWithRef>
      }
      open={open}
      setOpen={setOpen}
    >
      <DialogHeader />
      <div className={"grid grid-cols-12 overflow-auto"}>
        <div className={"col-span-1"}></div>

        <div className={"col-span-10 p-4 flex flex-col space-y-8 select-none"}>
          <div>
            <h2 className={"text-lg font-medium"}>Create Access Token</h2>
            <span className={"text-neutral-400"}>
              Configure your access token
            </span>
          </div>

          <form
            className={"flex flex-col space-y-4"}
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
              createToken();
            }}
          >
            <TextInput
              value={description}
              onChange={setDescription}
              label={"Description"}
              labelHorizontal
              autoFocus
            />

            <div className={"flex flex-col space-y-1"}>
              <span className={"font-medium text-sm"}>Expires At</span>
              <div>
                <DateTimeInputDropdownRenderer
                  value={expiresAt}
                  updateValue={(v) =>
                    typeof v === "string" ? setExpiresAt(v) : null
                  }
                />
              </div>
            </div>

            <ScopeSelect scopes={scopes} setScopes={setScopes} />
          </form>

          <Button
            isLoading={isSubmitting}
            disabled={!isValid}
            onClick={createToken}
          >
            Create Access Token
          </Button>
        </div>

        <div className={"col-span-1"}></div>
      </div>
    </Dialog>
  );
}

const workspaceScopes = [
  {
    name: "Access Tokens",
    scopes: [
      {
        name: "Read Access Tokens",
        value: WorkspaceScope.AccessTokensRead,
        description: "Allows you to read access tokens.",
      },
      {
        name: "Write Access Tokens",
        value: WorkspaceScope.AccessTokensWrite,
        description:
          "Allows you to create, update, roll, and delete access tokens.",
      },
    ],
  },
  {
    name: "Members",
    scopes: [
      {
        name: "Write Members",
        value: WorkspaceScope.MembersWrite,
        description: "Allows you to invite and suspend members.",
      },
    ],
  },
];

const environmentScopes = [
  {
    name: "Webhooks",
    scopes: [
      {
        name: "Read Webhooks",
        value: EnvironmentScope.WebhooksRead,
        description: "Allows you to read webhooks.",
      },
      {
        name: "Write Webhooks",
        value: EnvironmentScope.WebhooksWrite,
        description: "Allows you to create, update, and delete webhooks.",
      },
    ],
  },
  {
    name: "Events",
    scopes: [
      {
        name: "Read Events",
        value: EnvironmentScope.EventsRead,
        description: "Allows you to read events.",
      },
    ],
  },
  {
    name: "CRM",
    scopes: [
      {
        name: "Write CRM Config",
        value: EnvironmentScope.CRMConfigWrite,
        description: "Allows you to update CRM configuration.",
      },
      {
        name: "Read Notes",
        value: EnvironmentScope.CRMNotesRead,
        description: "Allows you to read CRM notes.",
      },
      {
        name: "Write Notes",
        value: EnvironmentScope.CRMNotesWrite,
        description: "Allows you to create, update, and delete CRM notes.",
      },
      {
        name: "Read Contacts",
        value: EnvironmentScope.CRMContactsRead,
        description: "Allows you to read CRM contacts.",
      },
      {
        name: "Write Contacts",
        value: EnvironmentScope.CRMContactsWrite,
        description: "Allows you to create, update, and delete CRM contacts.",
      },
      {
        name: "Read Companies",
        value: EnvironmentScope.CRMCompaniesRead,
        description: "Allows you to read CRM companies.",
      },
      {
        name: "Write Companies",
        value: EnvironmentScope.CRMCompaniesWrite,
        description: "Allows you to create, update, and delete CRM companies.",
      },
      {
        name: "Read Deals",
        value: EnvironmentScope.CRMDealsRead,
        description: "Allows you to read CRM deals.",
      },
      {
        name: "Write Deals",
        value: EnvironmentScope.CRMDealsWrite,
        description: "Allows you to create, update, and delete CRM deals.",
      },
      {
        name: "Read Comments",
        value: EnvironmentScope.CRMCommentsRead,
        description: "Allows you to read CRM comments.",
      },
      {
        name: "Write Comments",
        value: EnvironmentScope.CRMCommentsWrite,
        description: "Allows you to create, update, and delete CRM comments.",
      },
    ],
  },
  {
    name: "Insights",
    scopes: [
      {
        name: "Write Insights Config",
        value: EnvironmentScope.InsightsConfigWrite,
        description: "Allows you to update Insights configuration.",
      },
      {
        name: "Read Usage",
        value: EnvironmentScope.InsightsUsageRead,
        description: "Allows you to read Insights usage.",
      },
      {
        name: "Read Insights Events",
        value: EnvironmentScope.InsightsEventsRead,
        description: "Allows you to read Insights events.",
      },
    ],
  },
  {
    name: "User Management",
    scopes: [
      {
        name: "Write User Management Config",
        value: EnvironmentScope.UserManagementConfigWrite,
        description: "Allows you to update User Management configuration.",
      },
      {
        name: "Read User Identities",
        value: EnvironmentScope.UserManagementUserIdentitiesRead,
        description: "Allows you to read User Management identities.",
      },
      {
        name: "Write User Identities",
        value: EnvironmentScope.UserManagementUserIdentitiesWrite,
        description:
          "Allows you to create, update, and delete User Management identities.",
      },
      {
        name: "Read Access Tokens",
        value: EnvironmentScope.UserManagementAccessTokensRead,
        description: "Allows you to read User Management access tokens.",
      },
      {
        name: "Write Access Tokens",
        value: EnvironmentScope.UserManagementAccessTokensWrite,
        description:
          "Allows you to create, update, and delete User Management access tokens.",
      },
      {
        name: "Read Auth Attempts",
        value: EnvironmentScope.UserManagementAuthAttemptsRead,
        description:
          "Allows you to read User Management authentication attempts.",
      },
    ],
  },
];

export function ScopeSelect({
  scopes,
  setScopes,
}: {
  scopes: Scopes;
  setScopes: (scopes: Scopes) => void;
}) {
  const workspaceId = useWorkspaceId();
  const { environments } = useWorkspaceEnvironments(workspaceId);

  const [search, setSearch] = useState("");

  const [currentMode, setCurrentMode] = useState<"workspace" | "environment">(
    "workspace"
  );

  const allSelected = useMemo(
    () =>
      currentMode === "workspace"
        ? scopes.workspaceScopes.length === Object.values(WorkspaceScope).length
        : scopes.environmentScopes.length ===
          Object.values(EnvironmentScope).length,
    [scopes, currentMode]
  );

  const currentlySelectedWorkspaceScopes = useMemo(
    () => new Set(scopes.workspaceScopes),
    [scopes]
  );

  const currentlySelectedEnvironmentScopes = useMemo(
    () => new Set(scopes.environmentScopes),
    [scopes]
  );

  const filteredKinds = useMemo(() => {
    if (search.length < 1) {
      return null;
    }

    return (currentMode === "workspace" ? workspaceScopes : environmentScopes)
      .map((e) => e.scopes)
      .flat()
      .filter((e) => e.name.toLowerCase().includes(search.toLowerCase()));
  }, [search, currentMode]);

  return (
    <div className={"flex flex-col space-y-4"}>
      <div className={"flex flex-col space-y-2"}>
        <div className={"flex items-center space-x-2"}>
          <div>
            <div
              className={
                "bg-neutral-100 rounded p-1 flex items-center space-x-1 max-w-max shadow-inner text-sm"
              }
            >
              <button
                type={"button"}
                onClick={() => setCurrentMode("workspace")}
                className={classNames(
                  "appearance-none py-1 px-4 rounded hover:bg-neutral-200 active:bg-neutral-300",
                  {
                    "bg-white hover:bg-white active:bg-white shadow":
                      currentMode === "workspace",
                  }
                )}
              >
                Workspace
              </button>

              <button
                type={"button"}
                onClick={() => setCurrentMode("environment")}
                className={classNames(
                  "appearance-none block py-1 px-4 rounded hover:bg-neutral-200 active:bg-neutral-300",
                  {
                    "bg-white hover:bg-white active:bg-white shadow":
                      currentMode === "environment",
                  }
                )}
              >
                Environment
              </button>
            </div>
          </div>

          <SearchInput
            value={search}
            onChange={setSearch}
            placeholder={"Search events"}
          />
        </div>
      </div>

      {currentMode === "environment" ? (
        <div className={"flex flex-col space-y-2 py-4"}>
          <span className={"text-sm font-medium"}>Allowed environments</span>

          {(environments || []).map((e) => (
            <div className={"p-1 flex items-center space-x-2"} key={e.id}>
              <Toggle
                label={""}
                checked={scopes.environments.includes(e.id)}
                onChange={(c) =>
                  setScopes({
                    ...scopes,
                    environments: c
                      ? [...scopes.environments, e.id]
                      : scopes.environments.filter((f) => f !== e.id),
                  })
                }
                id={`select-env-${e.id}`}
              />

              <span className={"text-sm select-none"}>{e.display_name}</span>
            </div>
          ))}
        </div>
      ) : null}

      <span className={"font-medium text-sm"}>Select scopes to authorize</span>

      {filteredKinds ? (
        <>
          {filteredKinds.length > 0 ? (
            <div className={"flex flex-col space-y-2"}>
              {filteredKinds.map((scope) => (
                <div key={scope.value}>
                  <Toggle
                    size={"regular"}
                    label={scope.name}
                    checked={
                      currentMode === "workspace"
                        ? currentlySelectedWorkspaceScopes.has(
                            scope.value as WorkspaceScope
                          )
                        : currentlySelectedEnvironmentScopes.has(
                            scope.value as EnvironmentScope
                          )
                    }
                    onChange={(s) =>
                      s
                        ? setScopes({
                            workspaceScopes:
                              currentMode === "workspace"
                                ? [
                                    ...scopes.workspaceScopes,
                                    scope.value as WorkspaceScope,
                                  ]
                                : scopes.workspaceScopes,
                            environmentScopes:
                              currentMode === "environment"
                                ? [
                                    ...scopes.environmentScopes,
                                    scope.value as EnvironmentScope,
                                  ]
                                : scopes.environmentScopes,
                            environments: scopes.environments,
                          })
                        : setScopes({
                            workspaceScopes:
                              currentMode === "workspace"
                                ? scopes.workspaceScopes.filter(
                                    (s) => s !== scope.value
                                  )
                                : scopes.workspaceScopes,
                            environmentScopes:
                              currentMode === "environment"
                                ? scopes.environmentScopes.filter(
                                    (s) => s !== scope.value
                                  )
                                : scopes.environmentScopes,
                            environments: scopes.environments,
                          })
                    }
                    id={`select-event-${scope.value}`}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className={"text-neutral-700 text-sm"}>No results found.</div>
          )}
        </>
      ) : (
        <>
          <Toggle
            size={"regular"}
            label={"Select all events"}
            checked={allSelected}
            onChange={() =>
              allSelected
                ? setScopes({
                    workspaceScopes:
                      currentMode === "workspace" ? [] : scopes.workspaceScopes,
                    environmentScopes:
                      currentMode === "environment"
                        ? []
                        : scopes.environmentScopes,
                    environments: scopes.environments,
                  })
                : setScopes({
                    workspaceScopes:
                      currentMode === "workspace"
                        ? Object.values(WorkspaceScope)
                        : scopes.workspaceScopes,
                    environmentScopes:
                      currentMode === "environment"
                        ? Object.values(EnvironmentScope)
                        : scopes.environmentScopes,
                    environments: scopes.environments,
                  })
            }
            id={"select-all-events"}
          />

          <Accordion.Root
            className="w-full flex flex-col space-y-4"
            type="multiple"
          >
            {(currentMode === "workspace"
              ? workspaceScopes
              : environmentScopes
            ).map((kindSection) => (
              <Accordion.Item value={kindSection.name} key={kindSection.name}>
                <Accordion.Header className="w-full border-b border-neutral-100">
                  <div className={"flex items-center space-x-2 py-2"}>
                    <Toggle
                      size={"regular"}
                      label={""}
                      checked={kindSection.scopes
                        .map((x) => x)
                        .every((e) =>
                          currentMode === "workspace"
                            ? currentlySelectedWorkspaceScopes.has(
                                e.value as WorkspaceScope
                              )
                            : currentlySelectedEnvironmentScopes.has(
                                e.value as EnvironmentScope
                              )
                        )}
                      onChange={(s) =>
                        s
                          ? setScopes({
                              workspaceScopes:
                                currentMode === "workspace"
                                  ? Array.from(
                                      new Set([
                                        ...scopes.workspaceScopes,
                                        ...(kindSection.scopes.map(
                                          (s) => s.value
                                        ) as WorkspaceScope[]),
                                      ])
                                    )
                                  : scopes.workspaceScopes,
                              environmentScopes:
                                currentMode === "environment"
                                  ? Array.from(
                                      new Set([
                                        ...scopes.environmentScopes,
                                        ...(kindSection.scopes.map(
                                          (s) => s.value
                                        ) as EnvironmentScope[]),
                                      ])
                                    )
                                  : scopes.environmentScopes,
                              environments: scopes.environments,
                            })
                          : setScopes({
                              workspaceScopes:
                                currentMode === "workspace"
                                  ? scopes.workspaceScopes.filter(
                                      (s) =>
                                        !kindSection.scopes
                                          .map((s) => s.value)
                                          .includes(s)
                                    )
                                  : scopes.workspaceScopes,
                              environmentScopes:
                                currentMode === "environment"
                                  ? scopes.environmentScopes.filter(
                                      (s) =>
                                        !kindSection.scopes
                                          .map((s) => s.value)
                                          .includes(s)
                                    )
                                  : scopes.environmentScopes,
                              environments: scopes.environments,
                            })
                      }
                      id={`select-section-${kindSection.name}`}
                    />
                    <Accordion.Trigger
                      className={classNames(
                        "flex items-center justify-between w-full"
                      )}
                    >
                      <p className={"text-sm"}>{kindSection.name}</p>

                      <div className={"flex items-center space-x-2"}>
                        <span className={"text-xs text-neutral-500"}>
                          {
                            kindSection.scopes
                              .map((s) => s)
                              .filter((e) =>
                                currentMode === "workspace"
                                  ? currentlySelectedWorkspaceScopes.has(
                                      e.value as WorkspaceScope
                                    )
                                  : currentlySelectedEnvironmentScopes.has(
                                      e.value as EnvironmentScope
                                    )
                              ).length
                          }{" "}
                          selected
                        </span>

                        <ChevronDown
                          className="AccordionChevron"
                          aria-hidden
                          size={16}
                        />
                      </div>
                    </Accordion.Trigger>
                  </div>
                </Accordion.Header>

                <Accordion.Content asChild>
                  <div
                    className={classNames(
                      "flex flex-col space-y-4 bg-neutral-50 rounded-b",
                      "data-[state=closed]:p-0 data-[state=open]:p-4"
                    )}
                  >
                    {kindSection.scopes.map((scope) => (
                      <div
                        key={scope.value}
                        className={"flex items-start space-x-2"}
                      >
                        <Toggle
                          size={"regular"}
                          label={""}
                          checked={
                            currentMode === "workspace"
                              ? currentlySelectedWorkspaceScopes.has(
                                  scope.value as WorkspaceScope
                                )
                              : currentlySelectedEnvironmentScopes.has(
                                  scope.value as EnvironmentScope
                                )
                          }
                          onChange={(s) =>
                            s
                              ? setScopes({
                                  workspaceScopes:
                                    currentMode === "workspace"
                                      ? Array.from(
                                          new Set([
                                            ...scopes.workspaceScopes,
                                            scope.value as WorkspaceScope,
                                          ])
                                        )
                                      : scopes.workspaceScopes,
                                  environmentScopes:
                                    currentMode === "environment"
                                      ? Array.from(
                                          new Set([
                                            ...scopes.environmentScopes,
                                            scope.value as EnvironmentScope,
                                          ])
                                        )
                                      : scopes.environmentScopes,
                                  environments: scopes.environments,
                                })
                              : setScopes({
                                  workspaceScopes:
                                    currentMode === "workspace"
                                      ? scopes.workspaceScopes.filter(
                                          (s) => s !== scope.value
                                        )
                                      : scopes.workspaceScopes,
                                  environmentScopes:
                                    currentMode === "environment"
                                      ? scopes.environmentScopes.filter(
                                          (s) => s !== scope.value
                                        )
                                      : scopes.environmentScopes,
                                  environments: scopes.environments,
                                })
                          }
                          id={`select-event-${scope.value}`}
                        />
                        <div className={"flex flex-col"}>
                          <span className={"text-sm font-medium"}>
                            {scope.name}
                          </span>
                          <span className={"text-xs text-neutral-500"}>
                            {scope.description}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </Accordion.Content>
              </Accordion.Item>
            ))}
          </Accordion.Root>

          <div></div>
        </>
      )}
    </div>
  );
}

function AccessTokenSettings() {
  const workspaceId = useWorkspaceId();
  const { accessTokens, mutate } = useWorkspaceAccessTokens(workspaceId);

  const [highlightedToken, setHighlightedToken] = useState<string | null>(null);

  return (
    <FieldSet
      footer={
        <LearnMoreLink
          to={"https://anzuhq.com/docs/platform/teams/access-tokens"}
        >
          Access Tokens
        </LearnMoreLink>
      }
      title={"Access Tokens"}
      action={
        <CreateTokenDialog
          highlightToken={setHighlightedToken}
          mutate={mutate}
        />
      }
    >
      {highlightedToken ? (
        <div
          className={
            "bg-white rounded flex flex-col p-2 space-y-2 border border-neutral-200"
          }
        >
          <div className={"flex items-center space-x-2"}>
            <Key size={24} className={"rounded p-1 bg-neutral-100"} />
            <h3 className={"font-semibold"}>Copy your access token</h3>
          </div>
          <span className={"text-neutral-800"}>
            This access token will only be visible once, so make sure to copy it
            now.
          </span>

          <div
            className={
              "bg-neutral-100 rounded-md p-1 flex items-center space-x-2 grow"
            }
          >
            <span
              className={
                "pl-2 font-mono text-sm grow whitespace-nowrap truncate"
              }
            >
              {highlightedToken}
            </span>
            <CopyButton size={"small"} text={highlightedToken} />
          </div>
        </div>
      ) : null}

      <div className={"divide-y divide-neutral-200 flex flex-col"}>
        {(accessTokens || []).map((token) => (
          <TokenItem
            highlightToken={setHighlightedToken}
            token={token}
            key={token.id}
            mutate={mutate}
          />
        ))}
      </div>
      {accessTokens?.length === 0 ? (
        <span className={"text-neutral-700"}>No tokens created.</span>
      ) : null}
    </FieldSet>
  );
}

function TokenItem({
  token,
  mutate,
  highlightToken,
}: {
  token: AccessToken;
  mutate: () => void;
  highlightToken: (token: string) => void;
}) {
  const [open, setOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const postApi = usePostAPI();
  const patchApi = usePatchAPI();
  const deleteApi = useDeleteAPI();

  const workspaceId = useWorkspaceId();

  const toggleTokenEnabled = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await withErrorToast(async () => {
      await patchApi(`/workspaces/${workspaceId}/access_tokens/${token.id}`, {
        isEnabled: !token.is_enabled,
      });
      mutate();
      toast.success(token.is_enabled ? "Token disabled" : "Token enabled");
    });
    setIsSubmitting(false);
  };

  const rollToken = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    withErrorToast(async () => {
      const { token: newToken } = await postApi<{ token: string }>(
        `/workspaces/${workspaceId}/access_tokens/${token.id}/roll`,
        {}
      );
      mutate();
      toast.success("Token rolled");
      highlightToken(newToken);
      try {
        await navigator.clipboard.writeText(newToken);
      } catch {}
    });
    setIsSubmitting(false);
  };

  const deleteToken = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await withErrorToast(async () => {
      await deleteApi(`/workspaces/${workspaceId}/access_tokens/${token.id}`);
      mutate();
      toast.success("Token deleted");
    });
    setIsSubmitting(false);
  };

  return (
    <div
      className={classNames("grid grid-cols-12 items-center p-2 group", {
        "line-through": isAfter(new Date(), parseISO(token.expires_at)),
      })}
      key={token.id}
    >
      <div className={"col-span-1"}>
        {token.is_enabled ? <CheckCircle size={14} /> : <XCircle size={14} />}
      </div>

      <div className={"col-span-3"}>
        <span className={"text-sm"}>{token.description}</span>
      </div>

      <div className={"col-span-3 flex items-center space-x-1"}>
        <span className={"text-sm text-neutral-500"}>expires</span>

        <DateTimeRendererWithFormats value={token.expires_at} relative />
      </div>

      <div
        className={
          "col-span-5 flex justify-end group-hover:opacity-100 opacity-0 transition"
        }
      >
        <Dropdown
          activator={
            <ButtonWithRef
              size={"small"}
              onClick={() => {}}
              icon={MoreHorizontal}
              role={"secondary"}
            />
          }
          open={open}
          setOpen={setOpen}
        >
          <DropdownListContainer>
            <DropdownItemButtonWithAlertDialog
              onClick={toggleTokenEnabled}
              title={
                token.is_enabled
                  ? "Are you sure you want to disable this access token?"
                  : "Are you sure you want to enable this access token?"
              }
              message={
                token.is_enabled
                  ? "Once disabled, this access token will no longer be able to access the workspace."
                  : "Once enabled, this access token will be able to access the workspace."
              }
            >
              <div
                className={classNames(
                  "flex items-center space-x-2 text-red-500"
                )}
              >
                <PauseCircle size={14} />
                <span>{token.is_enabled ? "Disable..." : "Enable..."}</span>
              </div>
            </DropdownItemButtonWithAlertDialog>

            <DropdownItemButtonWithAlertDialog
              onClick={rollToken}
              title={"Are you sure you want to roll this access token?"}
              message={
                "After rolling, applications using the old token will no longer be able to send requests to the API."
              }
            >
              <div
                className={classNames(
                  "flex items-center space-x-2 text-red-500"
                )}
              >
                <RefreshCw size={14} />
                <span>Roll...</span>
              </div>
            </DropdownItemButtonWithAlertDialog>

            <DropdownItemButtonWithAlertDialog
              onClick={deleteToken}
              title={"Are you sure you want to delete this access token?"}
              message={
                "Once deleted, this access token will no longer be able to access the workspace."
              }
            >
              <div
                className={classNames(
                  "flex items-center space-x-2 text-red-500"
                )}
              >
                <Trash2 size={14} />
                <span>Delete...</span>
              </div>
            </DropdownItemButtonWithAlertDialog>
          </DropdownListContainer>
        </Dropdown>
      </div>
    </div>
  );
}
