import {
  useCRMDeal,
  useCRMDealSchema,
  useEnvironmentEventsForResource,
} from "../../../../../../../../data";
import {
  useDealId,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../../../routes";
import {
  AllEvents,
  CRMCommentKind,
  EventResourceKind,
  ICRMDeal,
} from "@anzuhq/backend";

import { ActivitySection } from "../../../activity";
import { CommentSection } from "../../../comment";

export function CRMDealOverviewPage() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const dealId = useDealId();
  const { deal } = useCRMDeal(workspaceId, environmentId, dealId);

  const schema = useCRMDealSchema(workspaceId, environmentId, deal?.pipeline);

  // TODO render loading indicator
  if (!schema || !deal) {
    return <div className={"animate-pulse"}>loading...</div>;
  }

  return (
    <div className={"grid grid-cols-12 grow"}>
      <div className={"col-span-1"} />
      <div className={"col-span-10"}>
        <CRMActivitySection deal={deal} />
      </div>
      <div className={"col-span-1"} />
    </div>
  );
}

function CRMActivitySection({ deal }: { deal: ICRMDeal }) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { events, mutate: mutateActivity } = useEnvironmentEventsForResource(
    workspaceId,
    environmentId,
    EventResourceKind.CRMDeal,
    deal.id
  );

  if (!events) {
    return <p>loading</p>;
  }

  return (
    <div className={"p-2"}>
      <div className={"border-t border-neutral-100"} />
      <CommentSection
        mutateActivity={mutateActivity}
        kind={CRMCommentKind.Deal}
        parentId={deal.id}
      />
      <ActivitySection events={events.map((e) => e.data as AllEvents)} />
    </div>
  );
}
