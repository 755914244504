import { InsightsEnvironmentConfig } from "@anzuhq/backend";
import toast from "react-hot-toast";
import {
  useEnvironmentInsightsConfig,
  usePatchAPI,
  withErrorToast,
} from "../../../../../../../data";
import { useEnvironmentId, useWorkspaceId } from "../../../../../../routes";

import { FieldSet } from "../../../../../../components/fieldset";
import { LearnMoreLink } from "../../../../../../components/docs";
import {
  ConfigLoadErrorPage,
  SettingsPageLayout,
} from "../../../../../../components/settings";
import { CopyButton } from "../../../../../../../components/basics/button";
import { RenderedCode } from "../../../developers/webhooks/create/code/renderer";

export function useInsightsSettingsConfig() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { insightsConfig, isLoading, mutate } = useEnvironmentInsightsConfig(
    workspaceId,
    environmentId
  );

  const patchApi = usePatchAPI();

  // save changes to backend
  const saveConfig = async (
    newConfig: Partial<InsightsEnvironmentConfig>,
    message?: string
  ) => {
    if (!newConfig) {
      return;
    }

    await withErrorToast(async () => {
      await patchApi<{}>(
        `/workspaces/${workspaceId}/environments/${environmentId}/insights/config`,
        newConfig
      );

      toast.success(message || "Updated configuration successfully!");

      mutate();
    });
  };

  return { insightsConfig, isLoading, saveConfig };
}

export function InsightsSettingsScript() {
  const { insightsConfig, isLoading, saveConfig } = useInsightsSettingsConfig();

  const environmentId = useEnvironmentId();
  const exampleCode = `<Head>
  <script src="${insightsConfig?.scriptUrl}"/>
  <script>
    // initialize Anzu Insights script
    window.insights.init({ environmentId: "${environmentId}" });

    // link a known user identity id to the Insights events
    const userIdentityId = "<your user identity id>";
    window.insights.identify(userIdentityId);

    // push events
    window.insights.push({
      category: "feature_a",
      kind: "did_something",
    });
  </script>
</Head>`;

  if (isLoading) {
    return <FieldSet footer={<></>} title={""} loading />;
  }

  if (!insightsConfig) {
    return <ConfigLoadErrorPage />;
  }

  return (
    <SettingsPageLayout>
      <FieldSet
        footer={
          <LearnMoreLink to={"https://anzuhq.com/docs/blocks/insights/script"}>
            Insights Script
          </LearnMoreLink>
        }
        title={"Insights Script"}
      >
        <p>
          Include this script on your page to start pushing events to Anzu
          Insights.
        </p>

        <div
          className={
            "bg-neutral-100 rounded-md px-2 py-1 flex justify-between items-center"
          }
        >
          <pre className={'overflow-auto max-w-full"'}>
            {insightsConfig.scriptUrl}
          </pre>

          <CopyButton size={"small"} text={insightsConfig.scriptUrl} />
        </div>

        <div className={"p-2 bg-neutral-50 rounded flex flex-col space-y-2"}>
          <span className={"text-xs font-medium text-neutral-700"}>
            Up Next: Integrate the Insights script
          </span>

          <RenderedCode code={exampleCode} language={"jsx"} />
        </div>
      </FieldSet>
    </SettingsPageLayout>
  );
}
