import { FieldSet } from "../../../../../../components/fieldset";
import { LearnMoreLink } from "../../../../../../components/docs";
import {
  ConfigLoadErrorPage,
  SettingsPageLayout,
} from "../../../../../../components/settings";
import { useInsightsSettingsConfig } from "../script/page";
import { DomainListInput } from "../../../../../../../components/basics/input";
import { InsightsConfigResp, InsightsEnvironmentConfig } from "@anzuhq/backend";
import { useState } from "react";
import { Button } from "../../../../../../../components/basics/button";
import { WithTooltip } from "../../../../../../../components/basics/tooltip";
import { AlertTriangle } from "../../../../../../../icons";
import classNames from "classnames";

export function InsightsSettingsAllowedOrigins() {
  const { insightsConfig, isLoading, saveConfig } = useInsightsSettingsConfig();

  if (isLoading) {
    return <FieldSet footer={<></>} title={""} loading />;
  }

  if (!insightsConfig) {
    return <ConfigLoadErrorPage />;
  }

  return (
    <SettingsPageLayout>
      <AllowedOrigins config={insightsConfig} setConfig={saveConfig} />
    </SettingsPageLayout>
  );
}

function AllowedOrigins({
  config,
  setConfig,
}: {
  config: InsightsConfigResp;
  setConfig: (
    config: Partial<InsightsEnvironmentConfig>,
    message?: string
  ) => Promise<void>;
}) {
  const [allowedOrigins, setAllowedOrigins] = useState(
    config.config.allowed_origins
  );
  return (
    <FieldSet
      footer={
        <div className={"flex items-center justify-between"}>
          <LearnMoreLink to={"https://anzuhq.com/docs/blocks/insights/script"}>
            Insights
          </LearnMoreLink>

          <Button
            onClick={() => {
              setConfig(
                { allowed_origins: allowedOrigins },
                "Updated allowed origins"
              );
            }}
            size={"small"}
            disabled={
              JSON.stringify(allowedOrigins) ===
              JSON.stringify(config.config.allowed_origins)
            }
          >
            Save
          </Button>
        </div>
      }
      action={
        <div className={"flex items-center"}>
          {allowedOrigins.length > 0 ? null : (
            <WithTooltip text={"Configure an allowed origin"}>
              <AlertTriangle
                size={16}
                className={classNames("text-amber-600")}
              />
            </WithTooltip>
          )}
        </div>
      }
      title={"Allowed Origins"}
    >
      <p>
        Make sure to redirect users to this URL to sign in or create their
        account.
      </p>

      <DomainListInput value={allowedOrigins} onChange={setAllowedOrigins} />
    </FieldSet>
  );
}
