import { PageError } from "../components/page/error";

export function NotFoundPage() {
  return (
    <PageError
      title={"Not Found"}
      message={"You tried accessing a page that does not exist."}
    />
  );
}
