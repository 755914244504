import {
  useCRMDealNotes,
  useCRMNotesBaseSchema,
} from "../../../../../../../../data";
import {
  useDealId,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../../../routes";

import { NotesList } from "../../../notes";

export function CRMDealNotesList() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const dealId = useDealId();
  const { schema } = useCRMNotesBaseSchema(workspaceId, environmentId);
  const { notes, isLoading } = useCRMDealNotes(
    workspaceId,
    environmentId,
    dealId
  );

  if (!schema) {
    return null;
  }

  return <NotesList data={notes || []} isLoading={isLoading} schema={schema} />;
}
