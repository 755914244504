import {
  useCRMCompany,
  useCRMCompanySchema,
  useEnvironmentEventsForResource,
} from "../../../../../../../../data";
import {
  useCompanyId,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../../../routes";
import {
  AllEvents,
  CRMCommentKind,
  EventResourceKind,
  ICRMCompany,
} from "@anzuhq/backend";

import { ActivitySection } from "../../../activity";
import { CommentSection } from "../../../comment";

export function CRMCompanyOverviewPage() {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const companyId = useCompanyId();
  const { company } = useCRMCompany(workspaceId, environmentId, companyId);

  const { schema } = useCRMCompanySchema(workspaceId, environmentId);

  // TODO render loading indicator
  if (!schema || !company) {
    return <div className={"animate-pulse"}>loading...</div>;
  }

  return (
    <div className={"grid grid-cols-12 grow"}>
      <div className={"col-span-1"} />
      <div className={"col-span-10"}>
        <CRMActivitySection company={company} />
      </div>
      <div className={"col-span-1"} />
    </div>
  );
}

function CRMActivitySection({ company }: { company: ICRMCompany }) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { events, mutate: mutateActivity } = useEnvironmentEventsForResource(
    workspaceId,
    environmentId,
    EventResourceKind.CRMCompany,
    company.id
  );

  if (!events) {
    return <p>loading</p>;
  }

  return (
    <div className={"p-2"}>
      <div className={"border-t border-neutral-100"} />
      <CommentSection
        mutateActivity={mutateActivity}
        kind={CRMCommentKind.Company}
        parentId={company.id}
      />
      <ActivitySection events={events.map((e) => e.data as AllEvents)} />
    </div>
  );
}
