import { Button, ButtonWithRef } from "../../../../../components/basics/button";
import {
  AtSign,
  ChevronRight,
  Linkedin,
  MessageCircle,
  MessageSquare,
  PhoneCall,
  Plus,
  Video,
} from "../../../../../icons";
import { useMemo, useState } from "react";
import { useHotkeys } from "../../../../../hooks/keypress";
import {
  CRMNotesCreatedEvent,
  CRMNotesKind,
  CRMNotesPrimaryResource,
  CRMNotesUpdatedEvent,
  DatabaseSchema,
  EventKind,
  ICRMNotes,
  RootNode,
} from "@anzuhq/backend";
import classNames from "classnames";
import {
  useCRMNotes,
  useCRMNotesSchema,
  usePostAPI,
  withErrorToast,
} from "../../../../../data";
import {
  toEnvironmentCRMNotes,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../routes";
import {
  emptyRichText,
  FakeRichTextRenderer,
  RichTextContentRenderer,
} from "./richtext";
import toast from "react-hot-toast";
import { ActorRenderer } from "./activity";
import { format, formatDistanceToNowStrict, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { TextInput } from "../../../../../components/basics/input";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import {
  getCellRenderer,
  selectColumn,
  TData,
} from "../../../../database/renderer";
import { LightTable } from "../../../../components/table/light-table";
import { getInputRenderer } from "../../../../database/input_renderer";
import { Dialog, DialogHeader } from "../../../../components/dialog";

export function CreateNotes({
  company,
  contact,
  primaryResource,
  deal,
  mutateActivity,
}: {
  primaryResource: CRMNotesPrimaryResource;
  deal?: string | null;
  contact?: string | null;
  company?: string | null;
  mutateActivity: () => void;
}) {
  const [open, setOpen] = useState(false);
  const [kind, setKind] = useState<CRMNotesKind | null>(null);

  const reset = () => {
    setOpen(false);
    setKind(null);
    mutateActivity();
  };

  useHotkeys("option+n,alt+n", () => setOpen(true));

  return (
    <Dialog
      transparent={!kind}
      activator={
        <ButtonWithRef
          size={"medium"}
          onClick={() => setOpen(true)}
          icon={Plus}
          role={"secondary"}
        >
          Add Notes
        </ButtonWithRef>
      }
      open={open}
      setOpen={(o) => {
        setOpen(o);
        if (!o) {
          reset();
        }
      }}
    >
      {kind ? (
        <CreateDialog
          onDone={reset}
          kind={kind}
          primaryResource={primaryResource}
          deal={deal}
          contact={contact}
          company={company}
        />
      ) : (
        <ChooseKind onSelect={setKind} />
      )}
    </Dialog>
  );
}

const kinds = [
  {
    label: "Meeting",
    value: CRMNotesKind.Meeting,
    icon: Video,
    shortcut: "m",
  },
  {
    label: "Email",
    value: CRMNotesKind.Email,
    icon: AtSign,
    shortcut: "e",
  },
  {
    label: "Call",
    value: CRMNotesKind.Call,
    icon: PhoneCall,
    shortcut: "c",
  },
  {
    label: "SMS",
    value: CRMNotesKind.SMS,
    icon: MessageSquare,
    shortcut: "s",
  },
  {
    label: "LinkedIn Message",
    value: CRMNotesKind.LinkedInMessage,
    icon: Linkedin,
    shortcut: "l",
  },
  {
    label: "WhatsApp Message",
    value: CRMNotesKind.WhatsAppMessage,
    icon: MessageCircle,
    shortcut: "w",
  },
];

function ChooseKind({ onSelect }: { onSelect: (kind: CRMNotesKind) => void }) {
  const [highlighted, setHighlighted] = useState(kinds[0].value);
  useHotkeys(
    "right",
    () => {
      const idx = kinds.findIndex((k) => k.value === highlighted) || 0;
      setHighlighted(kinds[(idx + 1) % kinds.length].value);
    },
    [highlighted],
    true
  );
  useHotkeys(
    "left",
    () => {
      const idx = kinds.findIndex((k) => k.value === highlighted) || 0;
      setHighlighted(kinds[(idx - 1 + kinds.length) % kinds.length].value);
    },
    [highlighted],
    true
  );
  useHotkeys(
    "enter",
    () => {
      onSelect(highlighted);
    },
    [highlighted],
    true
  );

  useHotkeys("c", () => onSelect(CRMNotesKind.Call), [], true);
  useHotkeys("m", () => onSelect(CRMNotesKind.Meeting), [], true);
  useHotkeys("e", () => onSelect(CRMNotesKind.Email), [], true);

  return (
    <div className={"flex flex-col space-y-4"}>
      <p className={"text-sm text-neutral-600 backdrop-blur-2xl p-2"}>
        Select notes kind
      </p>

      <div className={"grid grid-cols-3 flex-row gap-8"}>
        {kinds.map(({ icon: Icon, ...k }, idx) => (
          <button
            onFocus={() => setHighlighted(k.value)}
            className={classNames(
              "col-span-1",
              "w-full p-8 flex flex-col items-center align-center",
              " backdrop-blur-2xl rounded-lg shadow-xl",
              "outline-none",
              {
                "bg-neutral-100/60 ring-2 ring-black ring-offset-2":
                  highlighted === k.value,
                "bg-white/60 hover:bg-neutral-100/60 ring-2 ring-transparent hover:ring-neutral-300 ring-offset-2":
                  highlighted !== k.value,
              }
            )}
            onClick={() => onSelect(k.value)}
            key={k.value}
          >
            <Icon size={18} className={"text-neutral-800"} />
            <span className={"my-2 text-sm text-neutral-700 font-medium"}>
              {k.label}
            </span>

            <span
              className={
                "mt-4 px-2 py-1 uppercase text-neutral-500 rounded bg-neutral-100 text-xs"
              }
            >
              {k.shortcut}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
}

function CreateDialog({
  kind,
  onDone,
  contact,
  primaryResource,
  deal,
  company,
}: {
  kind: CRMNotesKind;
  primaryResource: CRMNotesPrimaryResource;
  deal?: string | null;
  contact?: string | null;
  company?: string | null;
  onDone: () => void;
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { schema } = useCRMNotesSchema(workspaceId, environmentId, kind);

  const [doc, setDoc] = useState<RootNode>(emptyRichText());
  const selectedKind = kinds.find((k) => k.value === kind);
  const today = format(new Date(), "MMM d, yyyy");
  const [title, setTitle] = useState<string>(
    `${selectedKind?.label} notes from ${today}`
  );
  const [properties, setProperties] = useState<Record<string, unknown>>({});

  const postApi = usePostAPI();

  const createNotes = async () => {
    await withErrorToast(async () => {
      await postApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/crm/notes`,
        {
          kind,
          primary_resource: primaryResource,
          title,
          contact,
          company,
          deal,
          content: doc,
          ...properties,
        }
      );
      toast.success("Notes created");
      onDone();
    });
  };

  if (!schema) {
    return null;
  }

  return (
    <div className={"w-full h-full flex flex-col space-y-2"}>
      <DialogHeader>
        <div
          className={
            "bg-white/60 backdrop-blur-2xl shadow text-xs font-medium px-4 py-1 rounded text-neutral-700"
          }
        >
          CRM
        </div>

        <ChevronRight size={14} className={"text-neutral-300"} />

        <div className={"text-xs text-neutral-700"}>
          New {selectedKind!.label} notes
        </div>
      </DialogHeader>
      <div className={"p-2 flex flex-col space-y-2 grow"}>
        <TextInput
          noInitialBorder
          placeholder={"Enter a title"}
          value={title}
          onChange={setTitle}
          autoFocus
        />

        <div
          className={
            "transition-colors flex grow border border-transparent focus-within:border-black rounded-md"
          }
        >
          <FakeRichTextRenderer
            placeholder={"Add notes..."}
            content={doc}
            setContent={setDoc}
          />
        </div>
      </div>

      <div className={"flex items-center justify-between shrink-0"}>
        <div
          className={
            "overflow-auto p-2 text-xs text-neutral-700 space-x-2 whitespace-nowrap w-full flex items-center select-none"
          }
        >
          <CRMNotesPropertiesRenderer
            properties={properties}
            setProperties={setProperties}
            kind={kind}
            schema={schema}
          />
        </div>

        <div className={"p-2 shrink-0"}>
          <Button size={"medium"} icon={Plus} onClick={createNotes}>
            Create
          </Button>
        </div>
      </div>
    </div>
  );
}

function getRendererForPropertyField(
  schema: DatabaseSchema,
  field: string,
  properties: Record<string, unknown>,
  setProperties: (props: Record<string, unknown>) => void
) {
  const fieldSchema = schema.fields.find((f) => f.name === field);
  if (!fieldSchema) {
    return null;
  }

  const value = properties[field] || null;

  return getInputRenderer(
    fieldSchema,
    value,
    (v) => {
      setProperties({
        ...properties,
        [field]: v,
      });
    },
    "small",
    "bottom"
  );
}

function CRMNotesPropertiesRenderer({
  kind,
  schema,
  setProperties,
  properties,
}: {
  kind: CRMNotesKind;
  schema: DatabaseSchema;
  properties: Record<string, unknown>;
  setProperties: (props: Record<string, unknown>) => void;
}) {
  switch (kind) {
    case CRMNotesKind.Meeting:
      return (
        <>
          <span className={"text-neutral-500"}>Outcome</span>
          {getRendererForPropertyField(
            schema,
            "outcome",
            properties,
            setProperties
          )}

          <span className={"text-neutral-500"}>Start Time</span>
          {getRendererForPropertyField(
            schema,
            "start_time",
            properties,
            setProperties
          )}

          <span className={"text-neutral-500"}>End Time</span>
          {getRendererForPropertyField(
            schema,
            "end_time",
            properties,
            setProperties
          )}
        </>
      );
    case CRMNotesKind.Email:
      return (
        <>
          <span className={"text-neutral-500"}>Date</span>
          {getRendererForPropertyField(
            schema,
            "date",
            properties,
            setProperties
          )}

          <span className={"text-neutral-500"}>Direction</span>
          {getRendererForPropertyField(
            schema,
            "direction",
            properties,
            setProperties
          )}
        </>
      );
    case CRMNotesKind.LinkedInMessage:
      return (
        <>
          <span className={"text-neutral-500"}>Date</span>
          {getRendererForPropertyField(
            schema,
            "date",
            properties,
            setProperties
          )}

          <span className={"text-neutral-500"}>Direction</span>
          {getRendererForPropertyField(
            schema,
            "direction",
            properties,
            setProperties
          )}
        </>
      );
    case CRMNotesKind.Call:
      return (
        <>
          <span className={"text-neutral-500"}>Direction</span>
          {getRendererForPropertyField(
            schema,
            "direction",
            properties,
            setProperties
          )}

          <span className={"text-neutral-500"}>Start Time</span>
          {getRendererForPropertyField(
            schema,
            "start_time",
            properties,
            setProperties
          )}

          <span className={"text-neutral-500"}>End Time</span>
          {getRendererForPropertyField(
            schema,
            "end_time",
            properties,
            setProperties
          )}

          <span className={"text-neutral-500"}>Outcome</span>
          {getRendererForPropertyField(
            schema,
            "outcome",
            properties,
            setProperties
          )}
        </>
      );
    case CRMNotesKind.SMS:
      return (
        <>
          <span className={"text-neutral-500"}>Date</span>
          {getRendererForPropertyField(
            schema,
            "date",
            properties,
            setProperties
          )}

          <span className={"text-neutral-500"}>Direction</span>
          {getRendererForPropertyField(
            schema,
            "direction",
            properties,
            setProperties
          )}
        </>
      );
    case CRMNotesKind.WhatsAppMessage:
      return (
        <>
          <span className={"text-neutral-500"}>Date</span>
          {getRendererForPropertyField(
            schema,
            "date",
            properties,
            setProperties
          )}

          <span className={"text-neutral-500"}>Direction</span>
          {getRendererForPropertyField(
            schema,
            "direction",
            properties,
            setProperties
          )}
        </>
      );
    case CRMNotesKind.PostalMail:
      return (
        <>
          <span className={"text-neutral-500"}>Date</span>
          {getRendererForPropertyField(
            schema,
            "date",
            properties,
            setProperties
          )}

          <span className={"text-neutral-500"}>Direction</span>
          {getRendererForPropertyField(
            schema,
            "direction",
            properties,
            setProperties
          )}
        </>
      );
    default:
      return null;
  }
}

export function CRMNotesRenderer({
  activity,
}: {
  activity: CRMNotesCreatedEvent | CRMNotesUpdatedEvent;
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { notes } = useCRMNotes(
    workspaceId,
    environmentId,
    activity.payload.id
  );

  if (!notes) {
    return (
      <div
        className={"animate-pulse h-6 w-full rounded-md shadow-md bg-white p-4"}
      ></div>
    );
  }

  return (
    <Link to={toEnvironmentCRMNotes(workspaceId, environmentId, notes.id)}>
      <div
        className={
          "w-full bg-neutral-50 rounded-md p-3 flex flex-col space-y-1 text-sm"
        }
      >
        <div className={"flex items-start"}>
          <div className={"mr-2 flex space-x-1 items-center"}>
            <ActorRenderer activity={activity} />
            <span className={"text-sm text-neutral-600"}>
              {activity.kind === EventKind.CRMNotesCreated
                ? "logged"
                : "updated"}{" "}
              {notes.title} ({kinds.find((k) => k.value === notes.kind)!.label})
            </span>
          </div>
          <div className={"ml-auto text-neutral-500 text-xs"}>
            {formatDistanceToNowStrict(parseISO(activity.createdAt), {
              addSuffix: true,
            })}
          </div>
        </div>
        <RichTextContentRenderer
          preview
          content={notes.content}
          editingDisabled
          setContent={() => {}}
        />
      </div>
    </Link>
  );
}

export function NotesList({
  schema,
  data,
  isLoading,
}: {
  schema: DatabaseSchema;
  data: ICRMNotes[];
  isLoading: boolean;
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const includedFields = ["title", "kind", "created_by", "created_at"];

  const columns = useMemo<ColumnDef<any, any>[]>(() => {
    const columnHelper = createColumnHelper<TData>();

    const defs: ColumnDef<any>[] = [selectColumn()];

    const filteredFields = includedFields
      .map((field) => schema.fields.find((f) => f.name === field))
      .filter((f) => f !== undefined) as DatabaseSchema["fields"];

    for (const field of filteredFields) {
      defs.push(
        columnHelper.accessor(field.name, {
          header: field.label,
          cell: getCellRenderer(field, (notesId: string) =>
            toEnvironmentCRMNotes(workspaceId, environmentId, notesId)
          ),
          footer: (props) => props.column.id,
          enableHiding: true,
          enableResizing: true,
        })
      );
    }

    return defs;
  }, [schema, includedFields]);

  return (
    <LightTable
      data={data}
      isLoading={isLoading}
      columns={columns}
      emptyMessage={"No notes created yet."}
    />
  );
}
