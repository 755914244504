import {
  Currency,
  DatabaseBooleanField,
  DatabaseColor,
  DatabaseEnumField,
  DatabaseField,
  DatabaseFieldKind,
  DatabaseIcon,
  DatabaseIDField,
  DatabaseMonetaryValue,
  DatabaseMonetaryValueField,
  DatabaseNumberField,
  DatabaseRelatedEntity,
  DatabaseStringField,
  DatabaseStringValueType,
} from "@anzuhq/backend";
import {
  BooleanRenderer,
  CRMCompanyLink,
  CRMContactLink,
  CRMDealLink,
  EmptyCell,
  EnumRenderer,
  getStringFieldValueTypeRenderer,
  ImageWithFallback,
  MonetaryValueRenderer,
  NumberRenderer,
  RenderIcon,
  TData,
  TextRenderer,
  UserIdentityLink,
  WorkspaceMemberRenderer,
} from "./renderer";
import {
  Dropdown,
  DropdownItemButton,
  DropdownListContainer,
  DropdownTextInput,
} from "./dropdown";
import React, { useEffect, useMemo, useState } from "react";
import { Button, RawButtonWithRef } from "../../components/basics/button";
import {
  useCRMCompanies,
  useCRMContacts,
  useCRMDealPipelines,
  useCRMDeals,
  useUserManagementUserIdentities,
  useWorkspaceMembers,
} from "../../data";
import { useEnvironmentId, useWorkspaceId } from "../routes";
import { useDebounce } from "../../hooks/debounce";
import { BufferedTextInput } from "../../components/basics/input";
import { DateTimeInputDropdownRenderer } from "./datetime";
import classNames from "classnames";
import { Check, ChevronDown, ChevronUp, X } from "../../icons";
import * as Select from "@radix-ui/react-select";
import { Toggle } from "../../components/basics/checkbox";
export const editButtonStyle =
  "transition bg-transparent group-hover:bg-neutral-100 hover:bg-neutral-100 active:bg-neutral-200 rounded px-4 py-1 flex items-center min-w-0";

export function ClearButton<T>({
  field,
  value,
  updateValue,
}: {
  field: DatabaseField;
  value: T;
  updateValue: (value: T | null) => void;
}) {
  return (
    <>
      {field.isUserEditable && !field.isRequired && value !== null ? (
        <button
          onClick={() => updateValue(null)}
          className={
            "hidden group-hover:block text-xs font-medium text-neutral-500 hover:text-neutral-700 p-px"
          }
        >
          Clear
        </button>
      ) : null}
    </>
  );
}

export function getInputRenderer<T>(
  field: DatabaseField,
  value: T,
  updateValue: (value: T | null) => void,
  size?: "small" | "regular",
  side?: "left" | "right" | "top" | "bottom",
  linkMode?: "inline" | "none" | "separate"
) {
  switch (field.kind) {
    case DatabaseFieldKind.ID:
      if (typeof value !== "string" && value !== null) {
        return null;
      }
      return (
        <IDInputRenderer
          linkMode={linkMode}
          size={size}
          field={field}
          value={value as string | null}
          updateValue={updateValue as (value: unknown) => void}
        />
      );

    case DatabaseFieldKind.Enum:
      if (typeof value !== "string" && value !== null) {
        return null;
      }
      return (
        <EnumInputRenderer
          size={size}
          value={value as string | null}
          field={field}
          updateValue={updateValue as (value: unknown) => void}
          side={side}
        />
      );

    case DatabaseFieldKind.String:
      if (typeof value !== "string" && value !== null) {
        return null;
      }

      if (field.valueType) {
        return getCustomStringInputRenderer(
          field,
          field.valueType,
          value as string | null,
          updateValue as (value: unknown) => void,
          linkMode
        );
      }

      return (
        <TextInputRenderer
          value={value as string | null}
          field={field}
          updateValue={updateValue as (value: unknown) => void}
          linkMode={linkMode}
        />
      );

    case DatabaseFieldKind.DateTime:
      if (typeof value !== "string" && value !== null) {
        return null;
      }

      return (
        <DateTimeInputDropdownRenderer
          value={value as string | null}
          updateValue={updateValue as (value: unknown) => void}
          side={side}
        />
      );

    case DatabaseFieldKind.Number:
      if (typeof value !== "number" && value !== null) {
        return null;
      }
      return (
        <NumberInputRenderer
          field={field}
          value={value as number | null}
          updateValue={updateValue as (value: unknown) => void}
        />
      );

    case DatabaseFieldKind.Boolean:
      if (typeof value !== "boolean" && value !== null) {
        return null;
      }
      return (
        <BooleanInputRenderer
          field={field}
          value={value as boolean | null}
          updateValue={updateValue as (value: unknown) => void}
        />
      );

    case DatabaseFieldKind.MonetaryValue:
      if (
        !(
          typeof value === "object" &&
          value !== null &&
          "value" in value &&
          "currency" in value &&
          typeof value.value === "number" &&
          typeof value.currency === "string"
        ) &&
        value !== null
      ) {
        return null;
      }
      return (
        <MonetaryValueInputRenderer
          field={field}
          value={value as DatabaseMonetaryValue | null}
          updateValue={updateValue as (value: unknown) => void}
        />
      );

    default:
      return null;
  }
}

export function getCustomStringInputRenderer<T extends TData>(
  field: DatabaseStringField,
  renderer: DatabaseStringValueType,
  value: string | null,
  updateValue: (value: unknown) => void,
  linkMode?: "inline" | "none" | "separate"
) {
  switch (renderer) {
    case DatabaseStringValueType.Email:
      return (
        <TextInputRenderer
          value={value}
          field={field}
          updateValue={updateValue}
          type={"email"}
          linkMode={linkMode}
        />
      );
    case DatabaseStringValueType.Avatar:
      return (
        <TextInputRenderer
          value={value}
          field={field}
          updateValue={updateValue}
          type={"url"}
          linkMode={linkMode}
        />
      );
    case DatabaseStringValueType.PhoneNumber:
      return (
        <TextInputRenderer
          value={value}
          field={field}
          updateValue={updateValue}
          type={"tel"}
          linkMode={linkMode}
        />
      );
    case DatabaseStringValueType.URL:
      return (
        <TextInputRenderer
          value={value}
          field={field}
          updateValue={updateValue}
          type={"url"}
          linkMode={linkMode}
        />
      );
  }
}

export function EnumInputRenderer({
  value,
  updateValue,
  field,
  size = "regular",
  side,
}: {
  field: DatabaseEnumField;
  value: string | null;
  updateValue: (value: string | null) => void;
  size?: "small" | "regular";
  side?: "left" | "right" | "top" | "bottom";
}) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const filteredValues = useMemo(() => {
    return field.enumValues.filter((v) =>
      v.label.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, field.enumValues]);

  return (
    <Dropdown
      side={side}
      activator={
        <RawButtonWithRef className={editButtonStyle} onClick={() => {}}>
          <EnumRenderer field={field} value={value} size={size} />
        </RawButtonWithRef>
      }
      open={open}
      setOpen={setOpen}
    >
      <DropdownTextInput
        value={search}
        setValue={setSearch}
        placeholder={"Search..."}
      />
      <DropdownListContainer>
        {filteredValues.map((v) => (
          <DropdownItemButton
            active={v.value === value}
            key={v.value}
            onClick={() => {
              updateValue(v.value);
              setOpen(false);
            }}
          >
            {v.icon ? <RenderIcon icon={v.icon} /> : null}
            <span>{v.label}</span>
          </DropdownItemButton>
        ))}
      </DropdownListContainer>
    </Dropdown>
  );
}

export function IDInputRenderer({
  value,
  updateValue,
  field,
  size,
  linkMode,
}: {
  field: DatabaseIDField;
  value: string | null;
  updateValue: (value: string | null) => void;
  size?: "small" | "regular";
  linkMode?: "inline" | "none" | "separate";
}) {
  const [open, setOpen] = useState(false);

  if (!field.relatedEntity) {
    return null;
  }

  let render;
  let activator;
  let link;

  const updateAndClose = (value: string | null) => {
    updateValue(value);
    setOpen(false);
  };

  switch (field.relatedEntity) {
    case DatabaseRelatedEntity.WorkspaceMember:
      render = (
        <WorkspaceMemberInputRenderer
          size={size}
          value={value}
          updateValue={updateAndClose}
          field={field}
        />
      );
      activator = (
        <WorkspaceMemberRenderer linkMode={"none"} size={size} value={value} />
      );
      link = (
        <WorkspaceMemberRenderer
          linkMode={"separate"}
          size={size}
          value={value}
        />
      );
      break;
    case DatabaseRelatedEntity.CRMCompany:
      render = (
        <CRMCompanyInputRenderer
          size={size}
          field={field}
          value={value}
          updateValue={updateAndClose}
        />
      );
      activator = (
        <CRMCompanyLink linkMode={"none"} size={size} value={value} />
      );
      link = <CRMCompanyLink linkMode={"separate"} size={size} value={value} />;
      break;
    case DatabaseRelatedEntity.CRMContact:
      render = (
        <CRMContactInputRenderer
          size={size}
          field={field}
          value={value}
          updateValue={updateAndClose}
        />
      );
      activator = (
        <CRMContactLink linkMode={"none"} size={size} value={value} />
      );
      link = <CRMContactLink linkMode={"separate"} size={size} value={value} />;
      break;
    case DatabaseRelatedEntity.CRMDeal:
      render = (
        <CRMDealInputRenderer
          size={size}
          field={field}
          value={value}
          updateValue={updateAndClose}
        />
      );
      activator = <CRMDealLink linkMode={"none"} size={size} value={value} />;
      link = <CRMDealLink linkMode={"separate"} size={size} value={value} />;
      break;
    case DatabaseRelatedEntity.UserManagementUserIdentity:
      render = (
        <UserIdentityInputRenderer
          size={size}
          field={field}
          value={value}
          updateValue={updateAndClose}
        />
      );
      activator = (
        <UserIdentityLink linkMode={"none"} size={size} value={value} />
      );
      link = (
        <UserIdentityLink linkMode={"separate"} size={size} value={value} />
      );
      break;
    default:
      return null;
  }

  return (
    <div className={"flex items-stretch space-x-1 grow-0 shrink-0"}>
      <Dropdown
        activator={
          <RawButtonWithRef
            className={classNames(editButtonStyle, "grow")}
            onClick={() => {}}
          >
            {activator}
          </RawButtonWithRef>
        }
        open={open}
        setOpen={setOpen}
      >
        {render}
      </Dropdown>
      {link}
    </div>
  );
}

export function WorkspaceMemberInputRenderer({
  value,
  updateValue,
  field,
  size = "regular",
}: {
  field: DatabaseIDField;
  value: string | null;
  updateValue: (value: string | null) => void;
  size?: "small" | "regular";
}) {
  const workspaceId = useWorkspaceId();
  const { members } = useWorkspaceMembers(workspaceId);

  const [search, setSearch] = useState("");

  const filteredValues = useMemo(() => {
    if (!members) {
      return [];
    }
    return members.filter((v) =>
      v.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, members]);

  return (
    <>
      <DropdownTextInput
        value={search}
        setValue={setSearch}
        placeholder={"Search..."}
      />
      <DropdownListContainer>
        {filteredValues.map((v) => (
          <DropdownItemButton
            active={v.id === value}
            key={v.id}
            onClick={() => {
              updateValue(v.id);
            }}
          >
            <RenderIcon
              icon={{ name: DatabaseIcon.User, color: DatabaseColor.Black }}
            />
            <span>{v.name}</span>
          </DropdownItemButton>
        ))}
        {filteredValues?.length === 0 ? (
          <p className={"flex self-center p-2 text-neutral-700"}>No results</p>
        ) : null}
      </DropdownListContainer>
    </>
  );
}

export function CRMCompanyInputRenderer({
  value,
  updateValue,
  field,
  size = "regular",
}: {
  field: DatabaseIDField;
  value: string | null;
  updateValue: (value: string | null) => void;
  size?: "small" | "regular";
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const [search, setSearch] = useState("");

  const debouncedSearch = useDebounce(search, 250);

  const { companies } = useCRMCompanies(
    workspaceId,
    environmentId,
    { first: 10, after: null, before: null, last: null },
    [],
    null,
    { value: debouncedSearch }
  );

  return (
    <>
      <DropdownTextInput
        value={search}
        setValue={setSearch}
        placeholder={"Search..."}
      />
      <DropdownListContainer>
        {(companies?.data || []).map((v) => (
          <DropdownItemButton
            active={v.id === value}
            key={v.id}
            onClick={() => {
              updateValue(v.id);
            }}
          >
            <RenderIcon
              icon={{ name: DatabaseIcon.User, color: DatabaseColor.Black }}
            />
            <span>{v.name}</span>
          </DropdownItemButton>
        ))}

        {companies?.data.length === 0 ? (
          <p className={"flex self-center p-2 text-neutral-700"}>No results</p>
        ) : null}
      </DropdownListContainer>
    </>
  );
}

export function CRMContactInputRenderer({
  value,
  updateValue,
  field,
  size = "regular",
}: {
  field: DatabaseIDField;
  value: string | null;
  updateValue: (value: string | null) => void;
  size?: "small" | "regular";
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const [search, setSearch] = useState("");

  const debouncedSearch = useDebounce(search, 250);

  const { contacts } = useCRMContacts(
    workspaceId,
    environmentId,
    { first: 10, after: null, before: null, last: null },
    [],
    null,
    { value: debouncedSearch }
  );

  return (
    <>
      <DropdownTextInput
        value={search}
        setValue={setSearch}
        placeholder={"Search..."}
      />
      <DropdownListContainer>
        {(contacts?.data || []).map((v) => (
          <DropdownItemButton
            active={v.id === value}
            key={v.id}
            onClick={() => {
              updateValue(v.id);
            }}
          >
            <RenderIcon
              icon={{ name: DatabaseIcon.User, color: DatabaseColor.Black }}
            />
            <span>{v.name}</span>
          </DropdownItemButton>
        ))}
        {contacts?.data.length === 0 ? (
          <p className={"flex self-center p-2 text-neutral-700"}>No results</p>
        ) : null}
      </DropdownListContainer>
    </>
  );
}

export function CRMDealInputRenderer({
  value,
  updateValue,
  field,
  size = "regular",
}: {
  field: DatabaseIDField;
  value: string | null;
  updateValue: (value: string | null) => void;
  size?: "small" | "regular";
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const [search, setSearch] = useState("");

  const debouncedSearch = useDebounce(search, 250);

  const { dealPipelines } = useCRMDealPipelines(workspaceId, environmentId);

  const { deals } = useCRMDeals(
    workspaceId,
    environmentId,
    dealPipelines ? dealPipelines[0].id : null,
    { first: 10, after: null, before: null, last: null },
    [],
    null,
    { value: debouncedSearch }
  );

  return (
    <>
      <DropdownTextInput
        value={search}
        setValue={setSearch}
        placeholder={"Search..."}
      />
      <DropdownListContainer>
        {(deals?.data || []).map((v) => (
          <DropdownItemButton
            active={v.id === value}
            key={v.id}
            onClick={() => {
              updateValue(v.id);
            }}
          >
            <RenderIcon
              icon={{ name: DatabaseIcon.User, color: DatabaseColor.Black }}
            />
            <span>{v.name}</span>
          </DropdownItemButton>
        ))}
        {deals?.data.length === 0 ? (
          <p className={"flex self-center p-2 text-neutral-700"}>No results</p>
        ) : null}
      </DropdownListContainer>
    </>
  );
}

export function UserIdentityInputRenderer({
  value,
  updateValue,
  field,
  size = "regular",
}: {
  field: DatabaseIDField;
  value: string | null;
  updateValue: (value: string | null) => void;
  size?: "small" | "regular";
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const [search, setSearch] = useState("");

  const debouncedSearch = useDebounce(search, 250);

  const { identities } = useUserManagementUserIdentities(
    workspaceId,
    environmentId,
    { first: 10, after: null, before: null, last: null },
    [],
    null,
    { value: debouncedSearch }
  );

  return (
    <>
      <DropdownTextInput
        value={search}
        setValue={setSearch}
        placeholder={"Search..."}
      />
      <DropdownListContainer>
        {(identities?.data || []).map((v) => (
          <DropdownItemButton
            active={v.id === value}
            key={v.id}
            onClick={() => {
              updateValue(v.id);
            }}
          >
            {v.avatar_url ? (
              <ImageWithFallback src={v.avatar_url} alt={"avatar"} />
            ) : null}
            <span>{v.name}</span>
          </DropdownItemButton>
        ))}
        {identities?.data.length === 0 ? (
          <p className={"flex self-center p-2 text-neutral-700"}>No results</p>
        ) : null}
      </DropdownListContainer>
    </>
  );
}

export function TextInputRenderer<T extends TData>({
  value,
  updateValue,
  field,
  type,
  linkMode,
}: {
  field: DatabaseStringField;
  value: string | null;
  updateValue: (value: unknown) => void;
  type?: "text" | "password" | "email" | "url" | "tel" | "number";
  linkMode?: "inline" | "none" | "separate";
}) {
  const [isEditing, setIsEditing] = useState(false);

  const textRenderer = field.valueType ? (
    getStringFieldValueTypeRenderer(field.valueType, value, "none")
  ) : (
    <TextRenderer linkMode={"none"} value={value} field={field} />
  );
  const linkRenderer = field.valueType
    ? getStringFieldValueTypeRenderer(field.valueType, value, "separate")
    : null;
  return (
    <div className={"flex space-x-1 items-stretch grow-0 max-w-full"}>
      <button
        className={classNames(editButtonStyle, "grow min-w-0")}
        onClick={() => setIsEditing(true)}
      >
        {isEditing ? (
          <BufferedTextInput
            autoFocus
            isMultiline={field.isMultiline}
            onBlur={() => setIsEditing(false)}
            size={"small"}
            value={value || ""}
            noDelete={true}
            onSave={(v) => {
              updateValue(v === "" ? null : v);
              setIsEditing(false);
            }}
            type={type}
            hideButton
          />
        ) : (
          textRenderer
        )}
      </button>

      {linkRenderer}
    </div>
  );
}

export function NumberInputRenderer<T extends TData>({
  value,
  updateValue,
  field,
}: {
  field: DatabaseNumberField;
  value: number | null;
  updateValue: (value: unknown) => void;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);

  useEffect(() => {
    setTempValue(value);
    setIsEditing(false);
  }, [value]);

  if (!isEditing) {
    return (
      <button
        className={classNames(editButtonStyle, "grow")}
        onClick={() => setIsEditing(true)}
      >
        {value !== null ? <NumberRenderer value={value} /> : <EmptyCell />}
      </button>
    );
  }

  return (
    <input
      className={"w-24 appearance-none bg-transparent outline-none shrink"}
      autoFocus
      type={"number"}
      value={tempValue || ""}
      onChange={(e) => {
        setTempValue(parseFloat(e.target.value));
      }}
      onBlur={() => {
        setIsEditing(false);
        if (value === tempValue) {
          return;
        }
        updateValue(tempValue);
      }}
    />
  );
}

export function MonetaryValueInputRenderer<T extends TData>({
  value,
  updateValue,
  field,
}: {
  field: DatabaseMonetaryValueField;
  value: DatabaseMonetaryValue | null;
  updateValue: (value: unknown) => void;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value?.value || 0);
  const [tempCurrency, setTempCurrency] = useState(
    value?.currency || Currency.USDollar
  );

  useEffect(() => {
    setTempValue(value?.value || 0);
    setTempCurrency(value?.currency || Currency.USDollar);
    setIsEditing(false);
  }, [value]);

  if (!isEditing) {
    return (
      <button
        className={classNames(editButtonStyle, "grow")}
        onClick={() => setIsEditing(true)}
      >
        <MonetaryValueRenderer value={value} />
      </button>
    );
  }

  return (
    <div className={"flex items-stretch space-x-2"}>
      <form
        className={"flex items-stretch"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsEditing(false);
          updateValue({ value: tempValue, currency: tempCurrency });
        }}
      >
        <input
          autoFocus
          type="number"
          className={"w-24 appearance-none bg-transparent outline-none shrink"}
          value={tempValue}
          step={".01"}
          placeholder="0.00"
          onChange={(e) => setTempValue(parseFloat(e.target.value))}
          onBlur={() => {
            setIsEditing(false);
            if (value?.value === tempValue) {
              return;
            }
            updateValue({ value: tempValue, currency: tempCurrency });
          }}
        />
      </form>

      <div className={"flex items-stretch"}>
        <CurrencyPicker
          currency={tempCurrency}
          setCurrency={(c) => {
            if (!c) {
              return;
            }
            setTempCurrency(c);
          }}
        />
      </div>

      <div className={"flex items-stretch space-x-1"}>
        <Button
          size={"small"}
          onClick={() => {
            setIsEditing(false);
            updateValue({ value: tempValue, currency: tempCurrency });
          }}
          role={"ghost"}
          icon={Check}
        />

        <Button
          size={"small"}
          onClick={() => {
            setIsEditing(false);
          }}
          role={"ghost"}
          icon={X}
        />
      </div>
    </div>
  );
}

export function BooleanInputRenderer<T extends TData>({
  value,
  updateValue,
  field,
}: {
  field: DatabaseBooleanField;
  value: boolean | null;
  updateValue: (value: unknown) => void;
}) {
  const [isEditing, setIsEditing] = useState(false);

  if (!isEditing) {
    return (
      <button
        className={classNames(editButtonStyle, "grow")}
        onClick={() => setIsEditing(true)}
      >
        {value !== null ? <BooleanRenderer value={value} /> : <EmptyCell />}
      </button>
    );
  }

  return (
    <div className={"flex items-stretch justify-between space-x-2"}>
      <Toggle
        label={""}
        checked={value === true}
        onChange={(c) => {
          setIsEditing(false);
          updateValue(c);
        }}
        id={`toggle-boolean-${field.name}`}
      />

      <Button
        size={"small"}
        onClick={() => {
          setIsEditing(false);
        }}
        role={"ghost"}
        icon={X}
      />
    </div>
  );
}

function CurrencyPicker({
  setCurrency,
  currency,
}: {
  currency: Currency | null;
  setCurrency: (currency: Currency | null) => void;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Select.Root
      open={open}
      onOpenChange={setOpen}
      value={currency || undefined}
      onValueChange={(c) => setCurrency(c as Currency | null)}
    >
      <Select.Trigger
        className={classNames("flex items-center w-full p-2 border group", {
          "border-neutral-200 rounded-md": !open,
          "border-black rounded-t-md": open,
        })}
        aria-label="Currency"
      >
        <Select.Value asChild>
          <span
            className={classNames("text-sm", {
              "text-neutral-300": !currency,
              "text-black": currency,
            })}
          >
            {currency ? currency : "Select a role..."}
          </span>
        </Select.Value>
        <Select.Icon
          className={classNames("ml-auto transition-colors ", {
            "text-neutral-400 group-hover:text-black": !open,
            "text-black": open,
          })}
        >
          <ChevronDown size={18} />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          className="z-30"
          style={{
            width: "var(--radix-popper-anchor-width)",
          }}
          position={"popper"}
        >
          <Select.ScrollUpButton className="SelectScrollButton">
            <ChevronUp />
          </Select.ScrollUpButton>
          <Select.Viewport className="bg-white rounded-b shadow-lg flex flex-col grow">
            <Select.Group className={"flex flex-col grow divide-y"}>
              {Object.values(Currency).map((r) => (
                <Select.Item
                  key={r}
                  className={classNames(
                    "w-full flex items-center justify-between p-2 ",
                    {
                      "bg-neutral-100": r === currency,
                      "hover:bg-neutral-100": r !== currency,
                    }
                  )}
                  value={r}
                >
                  <Select.ItemText asChild>
                    <span className={"text-sm"}>{r}</span>
                  </Select.ItemText>
                  <Select.ItemIndicator className="SelectItemIndicator">
                    <Check size={18} />
                  </Select.ItemIndicator>
                </Select.Item>
              ))}
            </Select.Group>
          </Select.Viewport>
          <Select.ScrollDownButton className="SelectScrollButton">
            <ChevronDown />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}
