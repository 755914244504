import { useBreadcrumbItems, useContextualNavigation } from "../nav";
import { Page } from "../../../../../components/page/page";
import {
  toEnvironmentInsightsInsightsView,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../routes";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  InsightsMostUsedCategory,
  useEnvironmentInsightsMostActiveUsers,
  useEnvironmentInsightsMostUsedCategories,
  useEnvironmentInsightsMostUsedCategoriesCountTimeline,
} from "../../../../../../data";
import {
  InsightsRelativeTimeRange,
  InsightsCategoryCountBucket,
  InsightsTimeRange,
} from "@anzuhq/backend";
import { BarChartResponsive, TimelineLineChartResponsive } from "./chart";
import {
  PageHeaderTitle,
  TabbedHeaderWithTitle,
} from "../../../../../components/header";
import classNames from "classnames";
import { TimeRangePicker } from "../events/page";
import { Link } from "../../../../../../components/basics/link";
import { Clock } from "../../../../../../icons";
import { UserIdentityLink } from "../../../../../database/renderer";
import { formatDistance } from "date-fns";

export function InsightsPage() {
  const items = useContextualNavigation();
  const breadcrumbs = useBreadcrumbItems();
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentView, setCurrentView] = useState<
    "most-active-users" | "most-used-categories"
  >("most-active-users");

  const [timeRange, setTimeRange] = useState<InsightsTimeRange>({
    relative: InsightsRelativeTimeRange.Last7Days,
  });

  useEffect(
    () => {
      if (!searchParams.has("view")) {
        setSearchParams({ view: currentView });
        return;
      }

      const view = searchParams.get("view");
      setCurrentView(view as "most-active-users" | "most-used-categories");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  );

  return (
    <Page
      contextualNavigationItems={items}
      breadcrumbItems={breadcrumbs}
      title={"Insights"}
    >
      <div className={"flex flex-col h-full w-full"}>
        <TabbedHeaderWithTitle
          title={
            <div className={"flex items-center justify-between w-full"}>
              <PageHeaderTitle>Insights</PageHeaderTitle>

              <div className={"ml-auto"}>
                <TimeRangePicker
                  timeRange={timeRange}
                  setTimeRange={setTimeRange}
                />
              </div>
            </div>
          }
          tabs={[
            {
              label: "Users",
              path: toEnvironmentInsightsInsightsView(
                workspaceId,
                environmentId,
                "most-active-users"
              ),
            },
            {
              label: "Categories",
              path: toEnvironmentInsightsInsightsView(
                workspaceId,
                environmentId,
                "most-used-categories"
              ),
            },
          ]}
        />

        {currentView === "most-active-users" ? (
          <MostActiveUsers timeRange={timeRange} />
        ) : null}
        {currentView === "most-used-categories" ? (
          <CategoryInsights timeRange={timeRange} />
        ) : null}
      </div>
    </Page>
  );
}

export function formatActivityDuration(sumSeconds: number) {
  return formatDistance(0, sumSeconds, {});
}

function MostActiveUsers({ timeRange }: { timeRange: InsightsTimeRange }) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const {
    mostActiveUsers,
    error: mostActiveUsersError,
    mutate: mutateActiveUsers,
  } = useEnvironmentInsightsMostActiveUsers(
    workspaceId!,
    environmentId!,
    timeRange
  );

  if (mostActiveUsersError) {
    return <div>{JSON.stringify(mostActiveUsersError)}</div>;
  }

  if (mostActiveUsers && mostActiveUsers.length > 0) {
    return (
      <div className={"bg-neutral-50 pb-8 h-full"}>
        <CardRow>
          <p className={"font-bold mb-4"}>Most Active Users</p>
          <div>
            <div className={"flex flex-col "}>
              <div className={"border-b border-neutral-50 pb-2  "}>
                <div className={"grid grid-cols-12 items-center"}>
                  <div className={"col-span-3"}>
                    <span className={"text-sm font-medium"}>Identity</span>
                  </div>

                  <div className={"col-span-3"}>
                    <span className={"text-sm font-medium"}>User ID</span>
                  </div>

                  <div className={"col-span-2"}>
                    <span className={"text-sm font-medium"}>Time Spent</span>
                  </div>
                </div>
              </div>

              {mostActiveUsers.map((user, idx) => (
                <div
                  key={user.userId}
                  className={classNames(
                    "border-b border-neutral-50 p-2 rounded",
                    {
                      "bg-neutral-50/50": idx % 2 === 0,
                    }
                  )}
                >
                  <div className={"grid grid-cols-12 items-center"}>
                    <div className={"col-span-3 text-sm truncate"}>
                      <UserIdentityLink value={user.userIdentityId || null} />
                    </div>

                    <div className={"col-span-3"}>
                      <pre
                        className={
                          "text-2xs bg-gray-100 px-1 py-px rounded inline"
                        }
                      >
                        {user.userId}
                      </pre>
                    </div>

                    <div className={"col-span-2"}>
                      <span
                        className={
                          "text-sm flex items-center space-x-1 text-neutral-500"
                        }
                      >
                        {formatActivityDuration(user.sumSessionDuration)} active
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </CardRow>
      </div>
    );
  }

  return (
    <div className={"bg-neutral-50 pb-8 h-full"}>
      <CardRow>
        <p className={"font-bold mb-4"}>Most Active Users</p>
        <p className={"text-center"}>
          No events found in the current time range.
        </p>
      </CardRow>
    </div>
  );
}

function CardRow({ children }: { children: React.ReactNode }) {
  return (
    <div className={"grid grid-cols-12 grow"}>
      <div className={"col-span-1"} />
      <div className={"mt-8 mb-6 w-full col-span-10"}>
        <div className={"p-6 shadow-lg bg-white rounded-lg"}>{children}</div>
      </div>
      <div className={"col-span-1"} />
    </div>
  );
}

function CategoryInsights({ timeRange }: { timeRange: InsightsTimeRange }) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { mostUsedCategories } = useEnvironmentInsightsMostUsedCategories(
    workspaceId,
    environmentId,
    timeRange
  );

  const { buckets } = useEnvironmentInsightsMostUsedCategoriesCountTimeline(
    workspaceId,
    environmentId,
    timeRange
  );

  return (
    <div className={"bg-neutral-50 pb-8 h-full"}>
      <MostUsedCategoriesTimeline
        buckets={buckets || null}
        timeRange={timeRange}
      />
      <MostUsedCategories categories={mostUsedCategories || null} />
    </div>
  );
}

function MostUsedCategoriesTimeline({
  buckets,
  timeRange,
}: {
  buckets: InsightsCategoryCountBucket[] | null;
  timeRange: InsightsTimeRange;
}) {
  const isEmpty = buckets && buckets.length === 0;
  if (!buckets || isEmpty) {
    return (
      <div className={"relative"}>
        <div className={"grid grid-cols-12 grow"}>
          <div className={"col-span-1"} />
          <div className={"mt-8 mb-6 w-full col-span-10"}>
            <div
              className={
                "p-6 shadow-lg bg-white rounded-lg " +
                classNames({ "opacity-40": isEmpty })
              }
            >
              <p className={"font-bold mb-4"}>Categories Over Time</p>
              <div className={classNames({ "animate-pulse": !buckets })}>
                <div className="h-36 bg-neutral-200 rounded"></div>
              </div>
            </div>
          </div>
          <div className={"col-span-1"} />
        </div>
        {isEmpty ? (
          <div className={"absolute w-full top-0 flex justify-center"}>
            <div>
              <div className={"bg-white mt-12 shadow-lg p-8 rounded"}>
                <p>No events found! Start pushing some events.</p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  return (
    <div className={"relative"}>
      <div className={"grid grid-cols-12 grow"}>
        <div className={"col-span-1"} />
        <div className={"mt-8 mb-6 w-full col-span-10"}>
          <div
            className={
              "p-6 shadow-lg bg-white rounded-lg " +
              classNames({ "opacity-40": isEmpty })
            }
          >
            <p className={"font-bold mb-4"}>Categories Over Time</p>
            <div>
              <TimelineLineChartResponsive
                height={200}
                data={buckets}
                timeRange={timeRange}
              />
            </div>
          </div>
        </div>
        <div className={"col-span-1"} />
      </div>
    </div>
  );
}

function MostUsedCategories({
  categories,
}: {
  categories: InsightsMostUsedCategory[] | null;
}) {
  const isEmpty = categories && categories.length === 0;

  if (!categories || isEmpty) {
    return (
      <div className={"relative bg-neutral-50"}>
        <div className={"grid grid-cols-12 grow"}>
          <div className={"col-span-1"} />
          <div className={"mt-8 w-full col-span-10"}>
            <div
              className={
                "p-6 shadow-lg bg-white rounded-lg " +
                classNames({ "opacity-40": isEmpty })
              }
            >
              <p className={"font-bold mb-4"}>Most Used Categories</p>
              <div className={classNames({ "animate-pulse": !categories })}>
                <div className="h-36 bg-neutral-200 rounded"></div>
                <div className="mt-6 h-4 bg-neutral-200 rounded"></div>
                <div className="mt-1 h-4 bg-neutral-200 rounded"></div>
                <div className="mt-1 h-4 bg-neutral-200 rounded"></div>
              </div>
            </div>
          </div>
          <div className={"col-span-1"} />
          {isEmpty ? (
            <div className={"absolute w-full top-0 flex justify-center"}>
              <div>
                <div className={"bg-white mt-12 shadow-lg p-8 rounded"}>
                  <p>No events found! Start pushing some events.</p>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }

  if (categories && categories.length > 0) {
    return (
      <div className={"relative bg-neutral-50"}>
        <div className={"grid grid-cols-12 grow"}>
          <div className={"col-span-1"} />
          <div className={"mt-8 w-full col-span-10"}>
            <div className={"p-6 shadow-lg bg-white rounded-lg"}>
              <p className={"font-bold mb-4"}>Most Used Categories</p>
              <BarChartResponsive
                height={160}
                data={(categories || [])
                  .slice(0, 10)
                  .map((c) => ({ label: c.category, value: c.count }))}
              />

              {categories && categories.length > 0 ? (
                <div className={"flex flex-col rounded grow-0 mt-4"}>
                  {categories.map((c) => (
                    <div
                      key={c.category}
                      className={
                        "p-1 border-b border-neutral-100 p-2 flex items-center"
                      }
                    >
                      <div className={""}>{c.category}</div>
                      <div className={"text-xs font-bold ml-auto"}>
                        {c.count} events
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={"col-span-1"} />
        </div>
      </div>
    );
  }

  return null;
}
