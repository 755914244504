import { PropsWithChildren } from "react";
import { ExternalLink } from "../../icons";

export function LearnMoreLink({
  children,
  to,
}: PropsWithChildren<{ to: string }>) {
  return (
    <div className={"flex items-center space-x-1 whitespace-nowrap"}>
      <span>Learn more about</span>
      <a className={"text-blue-500 flex items-center space-x-1"} href={to}>
        <span>{children}</span>
        <ExternalLink size={14} />
      </a>
    </div>
  );
}
