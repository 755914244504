import {
  SettingsPageLayout,
  useUserManagementSettingsConfig,
} from "../general/page";

import {
  UserManagementConfig,
  UserManagementConfigResp,
} from "@anzuhq/backend";

import React from "react";
import { TextInput } from "../../../../../../../components/basics/input";
import { ConfigLoadErrorPage } from "../../../../../../components/settings";
import { FieldSet } from "../../../../../../components/fieldset";
import { LearnMoreLink } from "../../../../../../components/docs";

export function UserManagementSettingsAccessTokens() {
  const { userManagementConfig, isLoading, saveConfig } =
    useUserManagementSettingsConfig();

  if (isLoading) {
    return <FieldSet footer={<></>} title={""} loading />;
  }

  if (!userManagementConfig) {
    return <ConfigLoadErrorPage />;
  }

  return (
    <SettingsPageLayout>
      <SigningSecret config={userManagementConfig} setConfig={saveConfig} />
    </SettingsPageLayout>
  );
}

function SigningSecret({
  config,
  setConfig,
}: {
  config: UserManagementConfigResp;
  setConfig: (
    config: Partial<UserManagementConfig>,
    message?: string
  ) => Promise<void>;
}) {
  return (
    <FieldSet
      footer={
        <LearnMoreLink
          to={"https://anzuhq.com/docs/blocks/user-management/access-tokens"}
        >
          Access Tokens
        </LearnMoreLink>
      }
      title={"Token Signing Secret"}
    >
      <p>Use this secret to validate tokens sent with user requests.</p>

      <TextInput
        value={config.accessTokenSecret}
        onChange={() => {}}
        isSecret
        readOnly
      />
    </FieldSet>
  );
}
