import { Page } from "../../../../../../components/page/page";
import { useBreadcrumbItems, useContextualNavigation } from "../../nav";
import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RenderedCode } from "./code/renderer";
import { StackPicker } from "./code/picker";
import { Language, Stack, StackWithCode } from "./code/stack";
import { navigationHeight } from "../../../../../../navigation";
import {
  useEnvironment,
  usePostAPI,
  useWorkspaceEnvironments,
  withErrorToast,
} from "../../../../../../../data";
import {
  toEnvironmentDevelopersWebhook,
  toEnvironmentDevelopersWebhookCreate,
  toEnvironmentDevelopersWebhooks,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../../routes";
import classNames from "classnames";
import {
  SearchInput,
  TextInput,
} from "../../../../../../../components/basics/input";
import { DocsLink } from "../../../../../../../components/basics/link";
import {
  ButtonLink,
  ButtonLinkWithRef,
  ButtonWithRef,
  CopyButton,
  CreateButton,
} from "../../../../../../../components/basics/button";
import toast from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ChevronDown, ExternalLink } from "../../../../../../../icons";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { useEnvironmentContext } from "../../../../context";
import { EventKind } from "@anzuhq/backend";
import { Toggle } from "../../../../../../../components/basics/checkbox";
import * as Accordion from "@radix-ui/react-accordion";

function useWebhookQuickstartStacks(): StackWithCode[] {
  const workspaceId = useWorkspaceId();
  const [environmentId] = useEnvironmentContext();

  const { environment } = useEnvironment(workspaceId, environmentId);

  return useMemo(() => {
    if (!environment) {
      return [];
    }

    const webhookSecret =
      environment.staging_webhook_secret || "YOUR_WEBHOOK_SECRET";

    return [
      {
        stack: Stack.NodeJS,
        language: Language.JavaScript,
        code: `// server.js
//
// Use this sample code to handle webhook events in your integration.
//
// 1) Paste this code into a new file (server.js)
//
// 2) Install dependencies
//   npm install @anzuhq/sdk-node
//   npm install express
//
// 3) Run the server on http://localhost:8080
//   node server.js

const { webhooks } = require("@anzuhq/sdk-node");
const express = require('express');

const app = express();

// TODO Use an environment variable for production
const secret = "${webhookSecret}";

app.post('/webhook', express.raw({type: 'application/json'}), async (req, res) => {
  const sig = req.headers['anzu-signature'];

  let event;
  try {
    event = webhooks.constructEvent(req.body, sig, secret);
  } catch (err) {
    return res.status(400).send({error: err.message});
  }

  console.log(event);

  res.send({ok: true});
});

app.listen(8080, () => console.log('Listening on port 8080!'));`,
      },
      {
        stack: Stack.Go,
        language: Language.Go,
        code: `// server.go
//
// Use this sample code to handle webhook events in your integration.
//
// 1) Paste this code into a new file (server.go)
//
// 2) Install dependencies
//   go get github.com/anzuhq/sdk/anzu-sdk-go
//
// 3) Run the server on http://localhost:8080
//   go run server.go

package main

import (
  "fmt"
  "log"
  "net/http"
  "io"
  "github.com/anzuhq/sdk/anzu-sdk-go/webhooks"
)

// TODO Use an environment variable for production
secret := "${webhookSecret}"

func main() {
  http.HandleFunc("/webhook", func(w http.ResponseWriter, r *http.Request) {
    sig := r.Header.Get("anzu-signature")
    event, err := webhooks.ConstructEvent(r.Body, sig, secret)
    if err != nil {
      w.WriteHeader(400)
      io.WriteString(w, "Invalid signature")
      return
    }

    fmt.Println(event)

    w.WriteHeader(200)
    io.WriteString(w, "ok")
  })

  log.Fatal(http.ListenAndServe(":8080", nil))
}`,
      },
    ];
  }, [environment]);
}

function useHashView<T extends string>(defaultView: T) {
  const [searchParams, setSearchParams] = useSearchParams();
  const view = (searchParams.get("view") || defaultView) as T;

  return [
    view,
    (view: T) => {
      const next = new URLSearchParams(searchParams);
      next.set("view", view);
      setSearchParams(next);
    },
  ] as const;
}

function toCreatePageWithView(
  workspaceId: string,
  environmentId: string,
  view: "endpoint" | "forward"
) {
  return (
    toEnvironmentDevelopersWebhookCreate(workspaceId, environmentId) +
    `?view=${view}`
  );
}

export function WebhookCreatePage() {
  const navItems = useContextualNavigation();
  const breadcrumbItems = useBreadcrumbItems();
  const postApi = usePostAPI();
  const navigate = useNavigate();

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const stacks = useWebhookQuickstartStacks();
  const [stack, setStack] = useState<Stack | null>(null);

  useEffect(() => {
    if (stacks.length > 0 && stack === null) {
      setStack(stacks[0].stack);
    }
  }, [stack, stacks]);

  const stackWithCode = useMemo(
    () => stacks.find((s) => s.stack === stack),
    [stack, stacks]
  );

  const [mode] = useHashView<"endpoint" | "forward">("endpoint");

  const [name, setName] = useState("");
  const [endpoint, setEndpoint] = useState("");
  const [eventKinds, setEventKinds] = useState<EventKind[]>([]);

  const isValid = useMemo(() => {
    if (name.length < 1) {
      return false;
    }

    if (endpoint.length < 1) {
      return false;
    }

    try {
      const parsed = new URL(endpoint);
      if (parsed.protocol !== "https:") {
        return false;
      }
    } catch (err) {
      return false;
    }

    return true;
  }, [name, endpoint]);

  const create = async () => {
    await withErrorToast(async () => {
      const created = await postApi<{ id: string }>(
        `/workspaces/${workspaceId}/environments/${environmentId}/webhooks`,
        {
          name,
          endpoint,
          event_kinds: eventKinds,
        }
      );

      toast.success("Webhook created");
      navigate(
        toEnvironmentDevelopersWebhook(workspaceId, environmentId, created.id)
      );
    });
  };

  return (
    <Page
      title={"Create Webhook"}
      contextualNavigationItems={navItems}
      breadcrumbItems={breadcrumbItems}
    >
      <div className={"grid grid-cols-1 md:grid-cols-2 h-full"}>
        {/* Form */}
        <div
          className={"col-span-1 p-16 flex flex-col space-y-4 overflow-auto"}
          style={{ height: `calc(100vh - ${navigationHeight})` }}
        >
          <div>
            <h1 className={"text-xl font-medium"}>Listen to Anzu Events</h1>
            {mode === "endpoint" ? (
              <p className={"text-neutral-500"}>
                Set up your webhook endpoint to receive events from Anzu or{" "}
                <DocsLink to={"/platform/webhooks"}>
                  learn more about webhooks
                </DocsLink>
                .
              </p>
            ) : (
              <p className={"text-neutral-500"}>
                Use the Anzu CLI to simulate and forward Anzu events or{" "}
                <DocsLink to={"/platform/webhooks"}>
                  learn more about webhooks
                </DocsLink>
                .
              </p>
            )}
          </div>

          {/* Choose mode */}
          <div>
            <div
              className={
                "bg-neutral-100 rounded p-1 flex items-center space-x-1 max-w-max shadow-inner text-sm"
              }
            >
              <Link
                to={toCreatePageWithView(
                  workspaceId,
                  environmentId,
                  "endpoint"
                )}
                className={classNames(
                  "py-1 px-4 rounded hover:bg-neutral-200 active:bg-neutral-300",
                  {
                    "bg-white hover:bg-white active:bg-white shadow":
                      mode === "endpoint",
                  }
                )}
              >
                Add an endpoint
              </Link>

              <SwitchToStagingDialog
                toRoute={(stagingId) =>
                  toCreatePageWithView(workspaceId, stagingId, "forward")
                }
                disabledChildren={
                  <Link
                    to={toCreatePageWithView(
                      workspaceId,
                      environmentId,
                      "forward"
                    )}
                    className={classNames(
                      "block py-1 px-4 rounded hover:bg-neutral-200 active:bg-neutral-300",
                      {
                        "bg-white hover:bg-white active:bg-white shadow":
                          mode === "forward",
                      }
                    )}
                  >
                    Test in a local environment
                  </Link>
                }
              >
                <span
                  className={classNames(
                    "block py-1 px-4 rounded hover:bg-neutral-200 active:bg-neutral-300",
                    {
                      "bg-white hover:bg-white active:bg-white shadow":
                        mode === "forward",
                    }
                  )}
                >
                  Test in a local environment
                </span>
              </SwitchToStagingDialog>
            </div>
          </div>

          {mode === "endpoint" ? (
            <>
              <TextInput
                labelHorizontal
                value={name}
                onChange={setName}
                label={"Name"}
                placeholder={"My Webhook"}
              />

              <TextInput
                labelHorizontal
                value={endpoint}
                onChange={setEndpoint}
                label={"Endpoint"}
                placeholder={"https://example.com/webhook"}
              />

              <SelectEvents
                selectedEvents={eventKinds}
                setSelectedEvents={setEventKinds}
              />

              <div className={"flex space-x-2 items-center"}>
                <CreateButton
                  size={"medium"}
                  onClick={create}
                  disabled={!isValid}
                />
                <ButtonLink
                  size={"medium"}
                  role={"secondary"}
                  to={toEnvironmentDevelopersWebhooks(
                    workspaceId,
                    environmentId
                  )}
                >
                  Cancel
                </ButtonLink>
              </div>
            </>
          ) : (
            <ol className="relative border-l border-neutral-200">
              {[
                {
                  title: "1. Install the Anzu CLI",
                  description:
                    "The Anzu CLI is a command line tool that allows you to interact with Anzu from your terminal.",
                  link: "https://anzuhq.com/docs/cli/install",
                  linkText: "Install the Anzu CLI",
                },
                {
                  title: "2. Sign in to the Anzu CLI",

                  terminal: "anzu login",
                },
                {
                  title: "3. Forward events to your local environment",
                  terminal: "anzu listen -f localhost:8080/webhook",
                },
                {
                  title: "4. Trigger events with the CLI",
                  description:
                    "Trigger example webhook events for local development. These test webhook events are based on real API objects and may trigger other webhook events as part of the test.",
                  terminal:
                    "anzu trigger user_management.user_identity_created",
                  link: "https://anzuhq.com/docs/cli/trigger",
                  linkText: "Learn more",
                },
              ].map((i, idx) => (
                <li key={idx} className="mb-10 ml-4 flex flex-col space-y-2">
                  <div className="absolute w-3 h-3 bg-neutral-300 rounded-full mt-4 -left-1.5 border border-white"></div>

                  <h3 className="font-semibold text-neutral-900">{i.title}</h3>
                  {i.description ? (
                    <p className="mb-4 text-sm font-normal text-neutral-500">
                      {i.description}
                    </p>
                  ) : null}

                  {i.terminal ? (
                    <div className="bg-neutral-100 rounded-md p-2 pl-4 relative ">
                      <div className={"flex items-center text-sm"}>
                        <span className={"text-neutral-500 mr-2"}>$</span>
                        <pre className="font-mono text-neutral-800">
                          {i.terminal}
                        </pre>

                        <div className={"ml-auto"}>
                          <CopyButton
                            role={"ghost"}
                            text={i.terminal}
                            size={"small"}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {i.link && i.linkText ? (
                    <ButtonLink
                      size={"medium"}
                      to={i.link}
                      role={"secondary"}
                      icon={ExternalLink}
                    >
                      {i.linkText}
                    </ButtonLink>
                  ) : null}
                </li>
              ))}
            </ol>
          )}
        </div>

        {/* Rendered code (right side) */}
        <div
          className={classNames(
            "col-span-1 bg-neutral-50 p-2 flex flex-col space-y-2 w-full grow",
            {
              "animate-pulse": !stack,
            }
          )}
          // To limit the height of the code editor (so has its own scrollbar),
          // we set a specific height on the parent element. This is calculated
          // by subtracting the height of the navigation from the viewport height.
          // Whenever the navigation height changes, we need to recalculate this
          style={{ height: `calc(100vh - ${navigationHeight})` }}
        >
          {stack ? (
            <>
              <div className={"flex shrink-0"}>
                <StackPicker
                  stacks={stacks.map((s) => s.stack)}
                  stack={stack}
                  setStack={(chosen) => {
                    setStack(chosen);
                  }}
                />
              </div>

              {stackWithCode ? (
                <RenderedCode
                  code={stackWithCode.code}
                  language={stackWithCode.language}
                />
              ) : (
                <p>Select a stack.</p>
              )}
            </>
          ) : null}
        </div>
      </div>
    </Page>
  );
}

function SwitchToStagingDialog({
  children,
  toRoute,
  disabledChildren,
}: PropsWithChildren<{
  disabledChildren: JSX.Element;
  toRoute: (stagingEnvironmentId: string) => string;
}>) {
  const [isOpen, setIsOpen] = useState(false);

  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { environments } = useWorkspaceEnvironments(workspaceId);

  const stagingEnvironmentId = environments?.find((e) => !e.is_production)?.id;
  const disabled =
    !stagingEnvironmentId || environmentId === stagingEnvironmentId;

  const dialogRef = useRef<HTMLDivElement>(null);

  if (disabled) {
    return disabledChildren;
  }

  return (
    <AlertDialog.Root
      open={!disabled && isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
      }}
    >
      <AlertDialog.Trigger asChild>
        <button
          onClick={(e) => {
            if (disabled) {
              return;
            }
            setIsOpen(true);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {children}
        </button>
      </AlertDialog.Trigger>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="z-30" />
        <AlertDialog.Content asChild>
          <div
            className="z-30 fixed top-0 left-0 w-screen h-screen grid place-items-center bg-neutral-900/50"
            onClick={(e) => {
              // If clicked outside of immediate children, close
              if (!dialogRef.current?.contains(e.target as Node)) {
                setIsOpen(false);
              }
            }}
          >
            <div
              ref={dialogRef}
              className={
                "shadow-lg bg-white rounded-md border border-neutral-200 whitespace-nowrap select-none"
              }
            >
              <div className={"p-8 flex flex-col space-y-4"}>
                <AlertDialog.Title className="text-lg font-medium">
                  Switch to staging environment?
                </AlertDialog.Title>
                <AlertDialog.Description className="text-sm">
                  This feature is only available in the staging environment.
                  <br />
                  Please switch to the staging environment to continue.
                </AlertDialog.Description>
              </div>
              <div className={"flex items-center justify-end space-x-4 p-4"}>
                <AlertDialog.Cancel asChild>
                  <ButtonWithRef
                    size={"small"}
                    role={"secondary"}
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </ButtonWithRef>
                </AlertDialog.Cancel>
                <AlertDialog.Action asChild>
                  <ButtonLinkWithRef
                    size={"small"}
                    role={"primary"}
                    to={
                      stagingEnvironmentId ? toRoute(stagingEnvironmentId) : ""
                    }
                  >
                    Switch to Staging
                  </ButtonLinkWithRef>
                </AlertDialog.Action>
              </div>
            </div>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}

const eventKinds = [
  {
    name: "Webhooks",
    events: [
      {
        name: "Webhook Created",
        value: EventKind.WebhookCreated,
        description: "Occurs whenever a webhook is created.",
      },
    ],
  },
  {
    name: "User Management",
    events: [
      {
        name: "User Identity Created",
        value: EventKind.UserIdentityCreated,
        description: "Occurs whenever a new user identity is created.",
      },
      {
        name: "User Identity Suspended",
        value: EventKind.UserIdentitySuspended,
        description: "Occurs whenever a user identity is suspended.",
      },
      {
        name: "User Identity Unsuspended",
        value: EventKind.UserIdentityUnsuspended,
        description: "Occurs whenever a user identity is unsuspended.",
      },
      {
        name: "User Identity Deleted",
        value: EventKind.UserIdentityDeleted,
        description: "Occurs whenever a user identity is deleted.",
      },
    ],
  },
  {
    name: "CRM",
    events: [
      {
        name: "Contact Created",
        value: EventKind.CRMContactCreated,
        description: "Occurs whenever a new contact is created.",
      },
      {
        name: "Contact Updated",
        value: EventKind.CRMContactUpdated,
        description: "Occurs whenever a contact is updated.",
      },
      {
        name: "Contact Deleted",
        value: EventKind.CRMContactDeleted,
        description: "Occurs whenever a contact is deleted.",
      },
      {
        name: "Contact Assigned",
        value: EventKind.CRMContactAssigned,
        description: "Occurs whenever a contact is assigned to a user.",
      },
      {
        name: "Contact Unassigned",
        value: EventKind.CRMContactUnassigned,
        description: "Occurs whenever a contact is unassigned from a user.",
      },

      {
        name: "Company Created",
        value: EventKind.CRMCompanyCreated,
        description: "Occurs whenever a new company is created.",
      },
      {
        name: "Company Updated",
        value: EventKind.CRMCompanyUpdated,
        description: "Occurs whenever a company is updated.",
      },
      {
        name: "Company Deleted",
        value: EventKind.CRMCompanyDeleted,
        description: "Occurs whenever a company is deleted.",
      },
      {
        name: "Company Assigned",
        value: EventKind.CRMCompanyAssigned,
        description: "Occurs whenever a company is assigned to a user.",
      },
      {
        name: "Company Unassigned",
        value: EventKind.CRMCompanyUnassigned,
        description: "Occurs whenever a company is unassigned from a user.",
      },

      {
        name: "Deal Created",
        value: EventKind.CRMDealCreated,
        description: "Occurs whenever a new deal is created.",
      },
      {
        name: "Deal Updated",
        value: EventKind.CRMDealUpdated,
        description: "Occurs whenever a deal is updated.",
      },
      {
        name: "Deal Deleted",
        value: EventKind.CRMDealDeleted,
        description: "Occurs whenever a deal is deleted.",
      },
      {
        name: "Deal Assigned",
        value: EventKind.CRMDealAssigned,
        description: "Occurs whenever a deal is assigned to a user.",
      },
      {
        name: "Deal Unassigned",
        value: EventKind.CRMDealUnassigned,
        description: "Occurs whenever a deal is unassigned from a user.",
      },
    ],
  },
];

const flattenedKinds = eventKinds
  .map((e) => e.events)
  .flat()
  .map((e) => e.value);

export function SelectEvents({
  selectedEvents,
  setSelectedEvents,
}: {
  selectedEvents: EventKind[];
  setSelectedEvents: (events: EventKind[]) => void;
}) {
  const [search, setSearch] = useState("");

  const allSelected = useMemo(
    () => selectedEvents.length === flattenedKinds.length,
    [selectedEvents]
  );

  const currentlySelected = useMemo(
    () => new Set(selectedEvents),
    [selectedEvents]
  );

  const filteredKinds = useMemo(() => {
    if (search.length < 1) {
      return null;
    }

    return eventKinds
      .map((e) => e.events)
      .flat()
      .filter((e) => e.name.toLowerCase().includes(search.toLowerCase()));
  }, [search]);

  return (
    <div className={"flex flex-col space-y-4"}>
      <div className={"space-y-1"}>
        <span className={"font-medium text-sm"}>Select events to send</span>

        <SearchInput
          value={search}
          onChange={setSearch}
          placeholder={"Search events"}
        />
      </div>

      {filteredKinds ? (
        <>
          {filteredKinds.length > 0 ? (
            <div className={"flex flex-col space-y-2"}>
              {filteredKinds.map((event) => (
                <div key={event.value}>
                  <Toggle
                    size={"regular"}
                    label={event.name}
                    checked={currentlySelected.has(event.value)}
                    onChange={(s) =>
                      s
                        ? setSelectedEvents([...selectedEvents, event.value])
                        : setSelectedEvents(
                            selectedEvents.filter((e) => e !== event.value)
                          )
                    }
                    id={`select-event-${event.value}`}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className={"text-neutral-700 text-sm"}>No results found.</div>
          )}
        </>
      ) : (
        <>
          <Toggle
            size={"regular"}
            label={"Select all events"}
            checked={allSelected}
            onChange={() =>
              allSelected
                ? setSelectedEvents([])
                : setSelectedEvents(flattenedKinds)
            }
            id={"select-all-events"}
          />

          <Accordion.Root
            className="w-full flex flex-col space-y-4"
            type="multiple"
          >
            {eventKinds.map((kindSection) => (
              <Accordion.Item value={kindSection.name} key={kindSection.name}>
                <Accordion.Header className="w-full border-b border-neutral-100">
                  <div className={"flex items-center space-x-2 py-2"}>
                    <Toggle
                      size={"regular"}
                      label={""}
                      checked={kindSection.events.every((e) =>
                        currentlySelected.has(e.value)
                      )}
                      onChange={(s) =>
                        s
                          ? setSelectedEvents([
                              ...selectedEvents,
                              ...kindSection.events.map((e) => e.value),
                            ])
                          : setSelectedEvents(
                              selectedEvents.filter(
                                (e) =>
                                  !kindSection.events
                                    .map((e) => e.value)
                                    .includes(e)
                              )
                            )
                      }
                      id={`select-section-${kindSection.name}`}
                    />
                    <Accordion.Trigger
                      className={classNames(
                        "flex items-center justify-between w-full"
                      )}
                    >
                      <p className={"text-sm"}>{kindSection.name}</p>

                      <div className={"flex items-center space-x-2"}>
                        <span className={"text-xs text-neutral-500"}>
                          {
                            kindSection.events.filter((e) =>
                              currentlySelected.has(e.value)
                            ).length
                          }{" "}
                          selected
                        </span>

                        <ChevronDown
                          className="AccordionChevron"
                          aria-hidden
                          size={16}
                        />
                      </div>
                    </Accordion.Trigger>
                  </div>
                </Accordion.Header>

                <Accordion.Content asChild>
                  <div
                    className={classNames(
                      "flex flex-col space-y-4 bg-neutral-50 rounded-b",
                      "data-[state=closed]:p-0 data-[state=open]:p-4"
                    )}
                  >
                    {kindSection.events.map((event) => (
                      <div
                        key={event.value}
                        className={"flex items-start space-x-2"}
                      >
                        <Toggle
                          size={"regular"}
                          label={""}
                          checked={currentlySelected.has(event.value)}
                          onChange={(s) =>
                            s
                              ? setSelectedEvents([
                                  ...selectedEvents,
                                  event.value,
                                ])
                              : setSelectedEvents(
                                  selectedEvents.filter(
                                    (e) => e !== event.value
                                  )
                                )
                          }
                          id={`select-event-${event.value}`}
                        />
                        <div className={"flex flex-col"}>
                          <span className={"text-sm font-medium"}>
                            {event.name}
                          </span>
                          <span className={"text-xs text-neutral-500"}>
                            {event.description}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </Accordion.Content>
              </Accordion.Item>
            ))}
          </Accordion.Root>

          <div></div>
        </>
      )}
    </div>
  );
}
