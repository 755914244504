import {
  Database,
  useCurrentView,
  useDatabaseSearch,
  useViewState,
} from "../../../../../database/database";
import {
  toEnvironmentCRMContact,
  toEnvironmentCRMContactsView,
  useEnvironmentId,
  useWorkspaceId,
} from "../../../../../routes";
import { Page } from "../../../../../components/page/page";
import { useBreadcrumbItems, useContextualNavigation } from "../nav";
import {
  isNotAllowed,
  useCountCRMContacts,
  useCRMContact,
  useCRMContacts,
  useCRMContactSchema,
  useDatabaseViews,
  usePatchAPI,
  withErrorToast,
} from "../../../../../../data";
import { PropsWithChildren, useState } from "react";
import { DatabaseViewKind, PaginationArgs } from "@anzuhq/backend";
import { CRMCreateContactDialog } from "./create";
import classNames from "classnames";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { X } from "../../../../../../icons";
import {
  CRMCompanyLink,
  EmptyCell,
  SeparateLink,
} from "../../../../../database/renderer";
import { CRMSidebar, nonSidebarWidth, sidebarWidth } from "../sidebar";
import toast from "react-hot-toast";
import { navigationHeight } from "../../../../../navigation";

export function CRMContactsList() {
  const navItems = useContextualNavigation();
  const breadcrumbItems = useBreadcrumbItems();
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();

  const { schema } = useCRMContactSchema(workspaceId, environmentId);

  const { views, isLoading: isLoadingViews } = useDatabaseViews(
    workspaceId,
    environmentId,
    DatabaseViewKind.CRMContacts
  );

  const currentView = useCurrentView(views || []);
  const {
    setVisibleFields,
    visibleFields,
    filters,
    setFilters,
    sort,
    setSort,
    groupBy,
    setGroupBy,
  } = useViewState(currentView);

  const { debouncedSearch, search, setSearch } = useDatabaseSearch();

  const [paginationArgs, setPaginationArgs] = useState<PaginationArgs>({
    last: null,
    first: 25,
    before: null,
    after: null,
  });

  const {
    contacts: data,
    isLoading: isLoadingContacts,
    mutate: mutateContacts,
  } = useCRMContacts(
    workspaceId,
    environmentId,
    paginationArgs,
    filters,
    sort,
    debouncedSearch
  );

  const {
    data: countAll,
    mutate: mutateCountAll,
    error: contactsErr,
  } = useCountCRMContacts(workspaceId, environmentId);
  const { data: countFiltered, mutate: mutateCountFiltered } =
    useCountCRMContacts(workspaceId, environmentId, filters, debouncedSearch);

  const mutate = async () => {
    await mutateContacts();
    await mutateCountAll();
    await mutateCountFiltered();
  };

  const isLoading = isLoadingContacts || isLoadingViews;

  const [splitViewOpen, setSplitViewOpen] = useState<string | null>(null);

  if (isNotAllowed(contactsErr)) {
    return (
      <Page title={"Contacts - CRM"} contextualNavigationItems={navItems}>
        <div className={"flex flex-col items-center justify-center h-full"}>
          <p
            className={"text-neutral-800 whitespace-nowrap text-sm select-none"}
          >
            You are not allowed to view CRM contacts.
          </p>
        </div>
      </Page>
    );
  }

  return (
    <Page
      title={"Contacts - CRM"}
      contextualNavigationItems={navItems}
      breadcrumbItems={breadcrumbItems}
    >
      <div className={"flex h-full w-full"}>
        <SplitViewMainContainer splitViewOpen={splitViewOpen}>
          <Database
            title={"Contacts"}
            actions={<CRMCreateContactDialog mutate={mutate} />}
            toDetailPage={(id) =>
              toEnvironmentCRMContact(workspaceId, environmentId, id)
            }
            onOpenSplitView={(id) =>
              setSplitViewOpen(splitViewOpen === id ? null : id)
            }
            toDatabaseView={(viewId) =>
              toEnvironmentCRMContactsView(workspaceId, environmentId, viewId)
            }
            countTotal={countAll?.count}
            countFiltered={countFiltered?.count}
            data={data?.data || []}
            isLoading={isLoading}
            views={views || []}
            schema={schema || { fields: [] }}
            pageInfo={
              data?.pageInfo || {
                hasPreviousPage: false,
                hasNextPage: false,
                startCursor: null,
                endCursor: null,
              }
            }
            setSort={setSort}
            sort={sort}
            setFilters={setFilters}
            currentView={currentView}
            filters={filters}
            setPaginationArgs={setPaginationArgs}
            paginationArgs={paginationArgs}
            setVisibleFields={setVisibleFields}
            visibleFields={visibleFields}
            search={search}
            setSearch={setSearch}
            emptyState={
              <p className={"text-neutral-800 whitespace-nowrap text-sm"}>
                No contacts added.
              </p>
            }
            groupBy={groupBy}
            setGroupBy={setGroupBy}
          />
        </SplitViewMainContainer>

        <AnimatePresence>
          {splitViewOpen ? (
            <ContactSplitView
              contactId={splitViewOpen}
              onClose={() => setSplitViewOpen(null)}
            />
          ) : null}
        </AnimatePresence>
      </div>
    </Page>
  );
}

function ContactSplitView({
  contactId,
  onClose,
}: {
  contactId: string;
  onClose: () => void;
}) {
  const workspaceId = useWorkspaceId();
  const environmentId = useEnvironmentId();
  const { contact, mutate } = useCRMContact(
    workspaceId,
    environmentId,
    contactId
  );

  const { schema } = useCRMContactSchema(workspaceId, environmentId);
  const patchApi = usePatchAPI();

  const updateContact = async (id: string, data: Record<string, unknown>) => {
    if (!contact) {
      return;
    }
    await withErrorToast(async () => {
      await mutate(
        {
          ...contact,
          ...data,
        },
        {
          revalidate: false,
        }
      );
      await patchApi(
        `/workspaces/${workspaceId}/environments/${environmentId}/crm/contacts/${id}`,
        data
      );
      await mutate();
      toast.success("Contact updated");
    });
  };

  return (
    <SplitViewSideBar onClose={onClose}>
      <div className={"flex items-stretch justify-between w-full p-4"}>
        <div className={"flex flex-col justify-start"}>
          <span
            className={classNames("overflow-hidden min-w-0 truncate", {
              "animate-pulse": !contact,
            })}
          >
            {contact?.name || "loading"}
          </span>
          {contact?.job_title || contact?.company ? (
            <div className={"flex items-center space-x-1"}>
              <span
                className={classNames(
                  "text-sm text-neutral-500 overflow-hidden min-w-0 truncate",
                  {
                    "animate-pulse": !contact,
                  }
                )}
              >
                {contact?.job_title || "works"}
              </span>

              <span className={"text-sm text-neutral-500"}>at</span>
              {contact?.company ? (
                <CRMCompanyLink
                  size={"regular"}
                  linkMode={"inline"}
                  value={contact.company}
                />
              ) : (
                <EmptyCell />
              )}
            </div>
          ) : null}
        </div>

        <SeparateLink
          permanent
          to={toEnvironmentCRMContact(workspaceId, environmentId, contactId)}
        />
      </div>

      <CRMSidebar
        schema={schema || null}
        updateEntity={updateContact}
        onlyInner
        entity={contact}
        sidebarFieldOrder={[
          "email",
          "phone_number",
          "linkedin_url",
          "assigned_to",
          "stage",
        ]}
      />
    </SplitViewSideBar>
  );
}

export function SplitViewMainContainer({
  splitViewOpen,
  children,
}: PropsWithChildren<{ splitViewOpen: string | null }>) {
  return (
    <motion.div
      // Inherits full page width, so we need to limit when sidebar is open
      // otherwise flex will not properly work but overflow
      style={{
        height: `calc(100vh - ${navigationHeight}`,
      }}
      className={classNames({
        [nonSidebarWidth]: splitViewOpen,
        "w-full": !splitViewOpen,
      })}
    >
      {children}
    </motion.div>
  );
}

export function SplitViewSideBar({
  children,
  onClose,
}: PropsWithChildren<{ onClose: () => void }>) {
  return (
    <motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 100 }}
      transition={{ duration: 0.2 }}
      key={"splitview"}
      className={classNames(
        "border-l border-neutral-200",
        "flex flex-col space-y-4 items-center bg-neutral-50",
        sidebarWidth
      )}
      style={{
        height: `calc(100vh - ${navigationHeight}`,
      }}
    >
      <div
        className={
          "flex items-center justify-end w-full p-4 bg-neutral-50/50 backdrop-blur-2xl"
        }
      >
        <button
          onClick={onClose}
          className={"p-1 text-neutral-400 hover:text-neutral-600 transition"}
        >
          <X size={20} />
        </button>
      </div>

      <div
        className={"flex flex-col space-y-2 grow shrink overflow-auto w-full"}
      >
        {children}
      </div>
    </motion.div>
  );
}
