import { Page } from "../../components/page/page";
import { SimpleHeaderWithTitle } from "../../components/header";
import { LayoutWithStickySidebar } from "../../components/settings";
import { Navigate } from "react-router-dom";
import { toWorkspaceSettingsSection, useWorkspaceId } from "../../routes";
import { useContextualNavigation } from "../environments/[environmentId]/nav";

export function RedirectToMembersSettings() {
  const workspaceId = useWorkspaceId();
  return <Navigate to={toWorkspaceSettingsSection(workspaceId, "members")} />;
}

export function WorkspaceSettingsPage() {
  const contextualItems = useContextualNavigation();
  const workspaceId = useWorkspaceId();

  return (
    <Page
      title={"Settings"}
      contextualNavigationItemsLoading={false}
      contextualNavigationItems={contextualItems}
    >
      <SimpleHeaderWithTitle title={"Settings"} />
      <LayoutWithStickySidebar
        sections={[
          {
            name: "Members",
            id: "members",
          },
          {
            name: "Access Tokens",
            id: "access-tokens",
          },
        ]}
        sectionUrl={(sectionId) =>
          toWorkspaceSettingsSection(workspaceId, sectionId)
        }
      />
    </Page>
  );
}
