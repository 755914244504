import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ErrorCode } from "@anzuhq/backend";
import {
  BounceIfSignedIn,
  CodeStep,
  User,
  useSignInAndRedirectToPrevious,
  useStartAuth,
} from "../../../components/auth";
import { Button, ButtonLink } from "../../../components/basics/button";
import { CenteredLayout } from "../../../components/basics/layout";
import { ApiError, postApi, withErrorToast } from "../../../data";
import { TextInput } from "../../../components/basics/input";
import { useHotkeys } from "../../../hooks/keypress";
import { toRegisterPage, toRegisterPageWithCode } from "../../routes";
import { Page } from "../../components/page/page";
import { useApiEndpointContext } from "../../../env";

export function LoginPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [attemptId, setAttemptId] = useState<string | null>(null);
  const signIn = useSignInAndRedirectToPrevious();

  let view: React.ReactNode = null;

  const sendToRegister = () => {
    if (!attemptId) {
      return;
    }
    navigate(toRegisterPageWithCode(email, code, attemptId), { replace: true });
  };

  if (!attemptId) {
    view = (
      <CenteredLayout heading="Sign in">
        <EmailStep
          email={email}
          setAttemptId={setAttemptId}
          setEmail={setEmail}
        />
      </CenteredLayout>
    );
  } else if (!code) {
    view = (
      <CenteredLayout heading="You've got mail">
        <CodeStep email={email} setCode={setCode} attemptId={attemptId} />
      </CenteredLayout>
    );
  } else {
    view = (
      <CompleteSignInStep
        signIn={signIn}
        email={email}
        code={code}
        attemptId={attemptId}
        signUp={sendToRegister}
      />
    );
  }

  return (
    <BounceIfSignedIn>
      <Page
        title={"Sign in"}
        contextualNavigationItemsLoading={false}
        breadcrumbItems={[]}
        contextualNavigationItems={[]}
      >
        {view}
      </Page>
    </BounceIfSignedIn>
  );
}

export function EmailStep({
  email,
  setEmail,
  setAttemptId,
}: {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setAttemptId: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const startAuth = useStartAuth(email, setAttemptId);
  useHotkeys("enter", startAuth);

  return (
    <div className="space-y-4 flex flex-col">
      <form
        className="space-y-2 flex flex-col"
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          startAuth();
        }}
      >
        <TextInput
          value={email}
          onChange={setEmail}
          placeholder="Enter your email address..."
          autoFocus
          type="email"
          name="email"
        />
        <Button
          type={"submit"}
          disabled={!email || !email.includes("@")}
          onClick={() => {}}
        >
          Continue
        </Button>
      </form>

      <span className="text-neutral-300 self-center">&mdash; or &mdash;</span>

      <ButtonLink grow role={"secondary"} to={toRegisterPage()}>
        Create an account
      </ButtonLink>
    </div>
  );
}

export function CompleteSignInStep({
  code,
  email,
  signIn,
  signUp,
}: {
  email: string;
  code: string;
  attemptId: string;
  signIn: (user: User) => void;
  signUp: () => void;
}) {
  const navigate = useNavigate();
  const [apiEndpoint] = useApiEndpointContext();

  useEffect(
    () => {
      (async function () {
        await withErrorToast(async () => {
          try {
            const res = await postApi<User>(apiEndpoint, `/auth/signin`, {
              email,
              code,
            });
            signIn(res);
          } catch (error) {
            if (
              error instanceof ApiError &&
              error.code === ErrorCode.AuthRequiresSignup
            ) {
              signUp();
              return;
            }
            throw error;
          }
        });
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, signIn, email, code]
  );

  return <p>Signing in...</p>;
}
