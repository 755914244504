import React, { useMemo } from "react";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { HeatmapRect } from "@visx/heatmap";
import { InsightsCountBucket } from "@anzuhq/backend";
import { default as tw } from "tailwindcss/colors";
import {
  addDays,
  addWeeks,
  format,
  isSameDay,
  parseISO,
  startOfWeek,
  subWeeks,
} from "date-fns";
import * as Tooltip from "@radix-ui/react-tooltip";

const defaultMargin = { top: 10, left: 10, right: 10, bottom: 10 };

function binForEachWeek(
  data: InsightsCountBucket[],
  weeks: number
): Array<BinData> {
  // create as many bins as we have weeks
  return Array.from({ length: weeks }, (_, i) => {
    return i;
  }).map((week) => {
    // create 7 bins for each day of the week
    return {
      bin: week,
      bins: binForEachWeekDay(data, weeks, week),
    };
  });
}

function binForEachWeekDay(
  data: InsightsCountBucket[],
  totalWeeks: number,
  week: number
) {
  // Get start of current week
  const startOfThisWeek = startOfWeek(new Date(), {
    weekStartsOn: 1,
  });
  const firstWeek = subWeeks(startOfThisWeek, totalWeeks - 1);

  // for each weekday of current week, fill in value
  return Array.from({ length: 7 }, (_, i) => {
    return i;
  }).map((dayOfWeek) => {
    const nthWeek = addWeeks(firstWeek, week);
    const weekStart = startOfWeek(nthWeek, {
      weekStartsOn: 1,
    });

    // this is the exact day of the nth week we're currently iterating over
    const expectedDay = addDays(weekStart, dayOfWeek);

    // find matching item in bucket data
    const found = data.find((item) => {
      const itemDate = parseISO(item.bucket);
      return isSameDay(itemDate, expectedDay);
    });

    // either we have records for this date
    if (found) {
      return {
        bin: dayOfWeek,
        count: found.count,
        date: expectedDay.toISOString(),
      };
    }

    // or we fall back to 0
    return {
      bin: dayOfWeek,
      count: 0,
      date: expectedDay.toISOString(),
    };
  });
}

function useWeeklyData(data: InsightsCountBucket[], weeks: number) {
  return useMemo<BinData[]>(() => {
    // create four top level bins for each of the last four weeks
    return binForEachWeek(data, weeks);
  }, [data, weeks]);
}

interface Bin {
  bin: number;
  count: number;
  date: string;
}

interface BinData {
  bin: number;
  bins: Bin[];
}

export function CountHeatmap({
  width,
  height,
  margin = defaultMargin,
  data,
}: {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  data: InsightsCountBucket[];
}) {
  // display data for the last 4 months, even if we just load 90 days
  const numWeeks =
    width < 200 ? 1 * 4 : width < 380 ? 2 * 4 : width < 500 ? 3 * 4 : 4 * 4;

  // prepare date
  const binWeeks = useWeeklyData(data, numWeeks);

  const rectGap = 2;
  const separation = 1;
  const binRectSize = 20;
  const textSize = "text-sm";

  const gapBetweenBins = width < 430 ? 10 : width < 480 ? 15 : 20;

  // accessors
  const count = (d: Bin) => d.count;
  const bins = (d: BinData) => d.bins;

  // we'll use those two colors in varying shades
  const cool1 = tw.neutral[400];
  const cool2 = tw.neutral[600];
  const colorMax = Math.max(
    max(binWeeks, (d) => max(bins(d), count)),
    1
  );
  const rectColorScale = scaleLinear<string>({
    range: [cool1, cool2],
    domain: [0, colorMax],
  });
  const opacityScale = scaleLinear<number>({
    range: [0.1, 1],
    domain: [0, colorMax],
  });

  // for each bundle of four weeks, create a range between start and end (used for adding spacing between groups of weeks later on)
  const weekRanges = useMemo(() => {
    return Array.from({ length: numWeeks / 4 }, (_, i) => {
      return [i * 4, i * 4 + 4];
    });
  }, [numWeeks]);

  return (
    <svg width={width} height={height}>
      {/* Render day of week labels */}
      <Group top={margin.top} left={margin.left}>
        {[
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ].map((d, i) => (
          <text
            key={i}
            className={textSize}
            fill={tw.neutral[500]}
            x={margin.left}
            // This spacing is pretty arbitrary and works fine here, but should probably be improved
            y={16 + i * (20 + 1)}
          >
            {d}
          </text>
        ))}
      </Group>

      {/* Offset from the left to make space for day of week labels */}
      <Group top={margin.top} left={margin.left + 100}>
        <HeatmapRect
          data={binWeeks}
          xScale={(week) => {
            // Find out which group of weeks this entry is part of and apply offset to move items further along
            const partOfRange = weekRanges.findIndex(([start, end]) => {
              return week >= start && week < end;
            }, 0);
            const groupOffset = partOfRange * gapBetweenBins;

            // Calculate horizontal position based on current week and size/separation
            const horizontalTileOffset = week * (binRectSize + separation);
            return horizontalTileOffset + groupOffset;
          }}
          yScale={(dayOfWeek) => {
            // Calculate vertical offset based on day of week and rect size / separation
            return dayOfWeek * (binRectSize + separation);
          }}
          colorScale={rectColorScale}
          opacityScale={opacityScale}
          binWidth={binRectSize}
          binHeight={binRectSize}
          gap={rectGap}
        >
          {(heatmap) =>
            heatmap.map((heatmapBins) => {
              return heatmapBins.map((bin) => {
                const currentBin = bin.bin as Bin;

                const parsedBinDate = parseISO(currentBin.date);
                const formattedBinDate = format(parsedBinDate, "MMM d, yyyy");

                const isToday = isSameDay(parsedBinDate, new Date());

                return (
                  <Tooltip.Provider
                    key={`heatmap-rect-${bin.row}-${bin.column}`}
                  >
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <rect
                          rx={2}
                          key={`heatmap-rect-${bin.row}-${bin.column}`}
                          className="visx-heatmap-rect"
                          width={bin.width}
                          height={bin.height}
                          x={bin.x}
                          y={bin.y}
                          fill={bin.color}
                          fillOpacity={bin.opacity}
                          stroke={isToday ? tw.neutral[700] : undefined}
                          strokeWidth={2}
                        />
                      </Tooltip.Trigger>
                      <Tooltip.Portal>
                        <Tooltip.Content
                          className={
                            "text-sm bg-white/60 backdrop-blur-xl p-2 rounded shadow flex items-center space-x-4"
                          }
                        >
                          <span className={"text-neutral-500"}>
                            {formattedBinDate}
                          </span>
                          <span className={"font-medium text-neutral-700"}>
                            {currentBin.count} events
                          </span>
                        </Tooltip.Content>
                      </Tooltip.Portal>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                );
              });
            })
          }
        </HeatmapRect>
      </Group>
    </svg>
  );
}

function max<T>(data: T[], value: (d: T) => number): number {
  return Math.max(...data.map(value));
}
